import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import { BankRow } from 'shared/types/bank';
import { Form, Row, Col, InputGroup } from 'react-bootstrap';
import SimpleModalOpeningButton from 'src/buttons/SimpleModalOpeningButton';
import JSONObjectInput from 'src/inputs/JSONObjectInput';
import constants from 'shared/constants';
import {useFormikContext} from 'formik';
import { loanToRepresentativeExample } from 'shared/format';

export function Id (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="ID"
      required
      {...props}
      name="id"
    />
  );
}

export function Name (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Namn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="name"
    />
  );
}

export function Adapter (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Adapter"
      required
      {...props}
      name="adapter"
    >
      {constants.bank.adapters.map((adapter: string) => (
        <option key={adapter} value={adapter}>{adapter}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function IsActive (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      className="mt-3"
      label="Långivaren är aktiv"
      type="checkbox"
      {...props}
      name="is_active"
    />
  );
}

export function IsHidden (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      className="mt-2"
      label="Långivaren är dold i portalen och i inskickningslistan"
      type="checkbox"
      {...props}
      name="is_hidden"
    />
  );
}

export function SettingsLowValueBids (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      className="mt-2"
      label="Alla bud från denna långivare är lågvärdiga"
      type="checkbox"
      {...props}
      name="settings.low_value_bids"
    />
  );
}

export function SettingsBankySubBank (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <div>
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Banky Solo (Single)"
        value="single"
        type="radio"
        explainer="Utan borgensman"
        required
        {...props}
        name="settings.banky_sub_bank"
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Banky Duo (Guarantor)"
        value="guarantor"
        type="radio"
        explainer="Med borgensman"
        required
        {...props}
        name="settings.banky_sub_bank"
      />
    </div>
  );
}

export function SettingsKknSubBank (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <div>
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="KonsumentKredit"
        value="konsumentkredit"
        type="radio"
        required
        {...props}
        name="settings.kkn_sub_bank"
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Nordnet"
        value="nordnet"
        type="radio"
        required
        {...props}
        name="settings.kkn_sub_bank"
      />
    </div>
  );
}

export function SettingsSveaSubBank (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <div>
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Privatlånet"
        value="privatlanet"
        type="radio"
        required
        {...props}
        name="settings.svea_sub_bank"
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Svealånet"
        value="svealanet"
        type="radio"
        required
        {...props}
        name="settings.svea_sub_bank"
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Samla dina lån"
        value="samladinalan"
        type="radio"
        required
        {...props}
        name="settings.svea_sub_bank"
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Enkla Lånet"
        value="enklalanet"
        type="radio"
        required
        {...props}
        name="settings.svea_sub_bank"
      />
    </div>
  );
}

export function SettingsNorthmillSubBank (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <div>
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Privatlånet"
        value="privatlanet"
        type="radio"
        required
        {...props}
        name="settings.northmill_sub_bank"
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Kontokrediten"
        value="kontokrediten"
        type="radio"
        required
        {...props}
        name="settings.northmill_sub_bank"
      />
    </div>
  );
}

export function SettingsCreateProcessDisabled (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  const formProps = useFormikContext<Pick<BankRow, 'settings'>>();
  return (
    <div>
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="För samtliga"
        name="settings.create_process_disabled.everyone"
        type="checkbox"
        {...props}
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Rådgivare"
        name="settings.create_process_disabled.advisor"
        type="checkbox"
        disabled={Boolean(formProps.values.settings.create_process_disabled?.everyone)}
        {...props}
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Handläggare"
        name="settings.create_process_disabled.salesman"
        type="checkbox"
        disabled={Boolean(formProps.values.settings.create_process_disabled?.everyone)}
        {...props}
      />
      <FormikFormControls.FormGroupCheck
        className="mt-2"
        label="Portal"
        name="settings.create_process_disabled.portal"
        type="checkbox"
        disabled={Boolean(formProps.values.settings.create_process_disabled?.everyone)}
        {...props}
      />
    </div>
  );
}

export function SettingsSubmitDelayActive (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      className="mt-2"
      label="Fördröjd inskickning till långivaren är aktiverad"
      name="settings.submit_delay_active"
      type="checkbox"
      {...props}
    />
  );
}

export function SettingsSubmitDelayMinutes (props: Partial<FormikFormControls.InputGroupControlProps>) {
  const formProps = useFormikContext<Pick<BankRow, 'settings'>>();
  return (
    <FormikFormControls.InputGroupControl
      className="mt-2"
      name="settings.submit_delay_minutes"
      type="number"
      step={1}
      min={0}
      placeholder="Ange antal minuter"
      before={<InputGroup.Text>Skicka in efter</InputGroup.Text>}
      after={<InputGroup.Text>minuter</InputGroup.Text>}
      disabled={!formProps.values.settings.submit_delay_active}
      required={formProps.values.settings.submit_delay_active}
      {...props}
    />
  );
}

export function SettingsSubmitDelayOnMinimumInterestRate (props: Partial<FormikFormControls.InputGroupControlProps>) {
  const formProps = useFormikContext<Pick<BankRow, 'settings'>>();
  return (
    <FormikFormControls.InputGroupControl
      className="mt-2"
      name="settings.submit_delay_on_minimum_interest_rate"
      type="number"
      step={0.01}
      min={0}
      max={100}
      placeholder="Oavsett ränta"
      before={<InputGroup.Text>Om räntan på övriga bud inte är mindre än</InputGroup.Text>}
      explainer="För att ange exempelvis 25 % mata in 0.25"
      disabled={!formProps.values.settings.submit_delay_active}
      emptyValue={null}
      {...props}
    />
  );
}

export function SettingsSubmitDelayUnlessAmountBid (props: Partial<FormikFormControls.InputGroupControlProps>) {
  const formProps = useFormikContext<Pick<BankRow, 'settings'>>();
  return (
    <FormikFormControls.InputGroupControl
      className="mt-2"
      name="settings.submit_delay_unless_amount_bid"
      type="number"
      step={1}
      min={0}
      placeholder="Oavsett beviljad summa"
      before={<InputGroup.Text>Om ingen annan långivare har budat högre än</InputGroup.Text>}
      after={<InputGroup.Text>kr</InputGroup.Text>}
      disabled={!formProps.values.settings.submit_delay_active}
      emptyValue={null}
      {...props}
    />
  );
}

export function SettingsExpireProcessDays (props: Partial<FormikFormControls.InputGroupControlProps>) {
  return (
    <FormikFormControls.InputGroupControl
      label="Automatisk stängning"
      type="number"
      step={1}
      min={0}
      name="settings.expire_process_days"
      before={<InputGroup.Text>Efter</InputGroup.Text>}
      after={<InputGroup.Text>dagar</InputGroup.Text>}
      placeholder="Processen stängs aldrig automatiskt"
      emptyValue={null}
      {...props}
    />
  );
}

export function SettingsExternalBidCallbackType (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Extern återkoppling av högsta bud"
      name="settings.external_bid_callback_type"
      required
      {...props}
    >
      <option value="disabled">Återkopplar aldrig högsta bud</option>
      <option value="regular">Återkopplar högsta bud</option>
      <option value="limited">Återkopplar högsta bud efter utbetalning</option>
    </FormikFormControls.FormGroupSelect>
  );
}

export function RepresentativeExample () {
  const formProps = useFormikContext<Pick<BankRow, 'representative_example'>>();
  return (
    <Form.Group className="mt-3">
      <Form.Label>Representativt lån</Form.Label>
      <Row>
        <Col lg={6}>
          <RepresentativeExamplePreviewFormControl
            language="sv"
            loan={formProps.values.representative_example}
          />
        </Col>
        <Col lg={6}>
          <RepresentativeExamplePreviewFormControl
            language="en"
            loan={formProps.values.representative_example}
          />
        </Col>
      </Row>
      <SimpleModalOpeningButton
        className="mt-3"
        label="Redigera lån"
        variant="secondary"
        modalTitle="Representativt lån"
      >
        <FormikFormControls.FormGroupControl
          as={JSONObjectInput}
          name="representative_example"
        />
      </SimpleModalOpeningButton>
    </Form.Group>
  );
}

export function Note (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Anteckningar"
      as="textarea"
      name="note"
      minLength={0}
      maxLength={10000}
      rows={7}
      {...props}
    />
  );
}

interface RepresentativeExamplePreviewFormControlProps {
  language: 'sv' | 'en';
  loan?: BankRow['representative_example'];
}

const RepresentativeExamplePreviewFormControl: React.FC<RepresentativeExamplePreviewFormControlProps> = React.memo(function RepresentativeExamplePreviewFormControl (props: RepresentativeExamplePreviewFormControlProps) {
  const { loan, language } = props;
  const text = loanToRepresentativeExample(loan, language);
  return (
    <Form.Control
      as="textarea"
      value={text}
      rows={7}
      disabled
    />
  );
});
