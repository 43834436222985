import React from 'react';
import BasicSpinner from 'src/spinners/BasicSpinner';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import HelperTooltip from 'src/components/HelperTooltip';

interface FormGroupLabelProps {
  id?: string;
  labelClassName?: string;
  label?: React.ReactNode;
  helpText?: React.ReactNode;
  isLoading?: boolean;
}

function FormGroupLabel (props: FormGroupLabelProps) {
  const { id, labelClassName, label, helpText, isLoading } = props;
  if (!label) return null;
  const className = classNames('d-inline-flex gap-1 align-items-center', labelClassName ?? 'mb-1 mt-3');
  return (
    <Form.Label className={className} htmlFor={id}>
      {label}
      {helpText && (
        <HelperTooltip>{helpText}</HelperTooltip>
      )}
      <BasicSpinner isLoading={isLoading} />
    </Form.Label>
  );
}

export default React.memo(FormGroupLabel);
