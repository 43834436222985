import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import WorkTriggerTemplateModalBody from 'src/workTriggerTemplate/WorkTriggerTemplateModalBody';
import { Name } from 'src/workTriggerTemplate/WorkTriggerTemplateFormatters';
import { WorkTriggerTemplateRow } from 'shared/types/work_trigger_template';
import Anchor from  'src/anchors/Anchor';

interface WorkTriggerTemplateAnchorProps {
  workTriggerTemplate: WorkTriggerTemplateRow;
  onlyId?: boolean;
}

export default function WorkTriggerTemplateAnchor (props: WorkTriggerTemplateAnchorProps) {
  const { workTriggerTemplate, onlyId, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name onlyId={onlyId} value={workTriggerTemplate} />}
      modalBodyClassName="p-0"
      subTitle={workTriggerTemplate.id}
      name="Redigera Triggermall"
      backdrop="static"
    >
      <WorkTriggerTemplateModalBody workTriggerTemplateId={workTriggerTemplate.id} />
    </ModalAnchor>
  );
}

export function WorkTriggerTemplatePlainAnchor (props: WorkTriggerTemplateAnchorProps) {
  const { workTriggerTemplate, onlyId, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/work_trigger_templates/${workTriggerTemplate.id}`}
      name="Triggermallen"
    >
      <Name value={workTriggerTemplate} onlyId={onlyId} />
    </Anchor>
  );
}
