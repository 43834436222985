/* eslint-disable no-redeclare */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Notyf } from 'notyf';
import { errorToMessage } from 'src/utils/error';

interface RequestProps extends AxiosRequestConfig {
  returnData?: boolean;
  errorToNotyf?: Notyf;
}

export async function request<ReturnDataType = any> (
  props: (RequestProps & { returnData: true }) | (Omit<RequestProps, 'returnData'>)
): Promise<ReturnDataType>;

export async function request<ReturnDataType = any> (
  props: RequestProps & { returnData: false }
): Promise<AxiosResponse<ReturnDataType>>;

export async function request<ReturnDataType = any> (
  props: RequestProps,
): Promise<ReturnDataType | AxiosResponse<ReturnDataType>> {
  const { returnData = true, errorToNotyf, ...otherProps } = props;
  try {
    const response = await axios<ReturnDataType>({
      method: 'get',
      timeout: 60000,
      baseURL: '/api',
      ...otherProps,
      headers: {
        Accept: 'application/json',
        ...(otherProps.headers || {}),
      },
    });

    if (returnData) {
      return response.data as ReturnDataType;
    }

    return response as AxiosResponse<ReturnDataType>;
  } catch (err: unknown) {
    if (!errorToNotyf) throw err;
    errorToNotyf.error(errorToMessage(err));
  }
}

// see crmServer/routes/auth "limitedInfoErrorMiddleware"
export type ErrorLimited = {
  error: string;
  ok: false;
};
