import React from 'react';
import { Col, ColProps, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as UcFormFields from 'src/uc/UcFormFields';
import CustomerConsentModalButton from 'src/customerConsent/CustomerConsentModalButton';
import {Edit3} from 'lucide-react';
import CustomerConsentCreateOnTemplateForm from 'src/customerConsent/CustomerConsentCreateOnTemplateForm';

export interface UcCreateFormValues {
	request_object: string;
	request_coObject: string;
	request_creditSeek: number;
}

interface UcCreateFormProps {
  customerId: string;
  className?: string;
  initialValues: UcCreateFormValues;
  onSubmit: (values: UcCreateFormValues, helpers: FormikHelpers<UcCreateFormValues>) => Promise<any>;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function UcCreateForm (props: UcCreateFormProps) {
  const {
    customerId,
    initialValues,
    submitError,
    onSubmit,
    disabled,
    className,
  } = props;

  const colProps: ColProps = {xl: 6};

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <Row>
              <Col {...colProps}>
                <UcFormFields.RequestObject disabled={disabled} />
              </Col>
              <Col {...colProps}>
                <UcFormFields.RequestCoObject disabled={disabled} />
              </Col>
            </Row>
            <Row>
              <Col>
                <UcFormFields.RequestCreditSeek disabled={disabled} />
              </Col>
            </Row>
          </div>
          <div className="p-3">
            <ErrorAlert error={submitError} />
            <div className="d-flex justify-content-center">
              <CustomerConsentModalButton
                label={<><Edit3 size={14} />{' '}Inhämta samtycke och skicka in UC</>}
                disabled={!formProps.isValid || formProps.isSubmitting}
              >
                <CustomerConsentCreateOnTemplateForm
                  subject="Ny kreditupplysning"
                  allowedMethods={['user_phone', 'customer_bankid_auth']}
                  onConsentCreated={formProps.submitForm}
                  customerConsentTemplateUrl="/customer_consent/template/application_uc"
                  customerId={customerId}
                  submitButtonLabel="Registrera samtycke och skapa UC"
                />
              </CustomerConsentModalButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
