import React, {useMemo} from 'react';
import * as api from 'src/api';
import { Eye, Heart, Link as LinkIcon, Paperclip, PlusCircle, RefreshCw, Trash2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import ConfirmActionModalButton, { ConfirmActionModalButtonProps } from 'src/buttons/ConfirmActionModalButton';
import ModalOpeningButton from 'src/buttons/ModalOpeningButton';
import InspectObjectModal from 'src/modals/InspectObjectModal';
import useNotyf from 'src/hooks/useNotyf';
import IconButton from 'src/buttons/IconButton';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import SimpleModalOpeningButton from 'src/buttons/SimpleModalOpeningButton';
import classNames from 'classnames';

type IconButtonProps = React.ComponentProps<typeof IconButton>;

interface RefreshObjectButtonProps extends IconButtonProps {
  // 99 % of times the "refetch" useQuery fn is used for this component
  // so we make the following special prop just for that
  refetch?: () => any;
}

// typically used in a card header to refresh the card object
export function RefreshObjectButton (props: RefreshObjectButtonProps) {
  // eslint-disable-next-line react/prop-types
  const { refetch, onClick:outerOnClick, ...restOfProps } = props;

  const onClick = useMemo(() => {
    if (refetch) return () => refetch();
    return outerOnClick;
  }, [refetch, outerOnClick]);

  return (
    <IconButton
      variant=""
      size="sm"
      title="Ladda om objektet"
      className="px-0"
      {...restOfProps}
      onClick={onClick}
      Icon={<RefreshCw size={14} />}
    />
  );
}

interface CopyCurrentUrlButtonProps extends IconButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

// typically used in a card header to copy a link to the current page
// TODO: use CopyToClipboardButton
export function CopyCurrentUrlButton (props: CopyCurrentUrlButtonProps) {
  const notyf = useNotyf();

  const onClick: React.MouseEventHandler<HTMLButtonElement> = ev => {
    navigator.clipboard.writeText(window.location.toString());
    notyf.success({type: 'default', message: 'Länk kopierad'});
    props.onClick?.(ev);
  };

  return (
    <IconButton
      variant=""
      size="sm"
      title="Kopiera länk"
      className="px-0"
      {...props}
      onClick={onClick}
      Icon={<LinkIcon size={14} />}
    />
  );
}

interface CopyObjectIdButtonButtonProps extends IconButtonProps {
  id: string;
  onCopyMessage?: string;
}

// typically used in a card header to copy the id of the current object
// TODO: use CopyToClipboardButton
export function CopyObjectIdButton (props: CopyObjectIdButtonButtonProps) {
  const { id, onCopyMessage = 'ID kopierat', ...restOfProps } = props;

  const notyf = useNotyf();

  const onClick: React.MouseEventHandler<HTMLButtonElement> = ev => {
    navigator.clipboard.writeText(id);
    notyf.success({type: 'default', message: onCopyMessage});
    restOfProps.onClick?.(ev);
  };

  return (
    <IconButton
      variant=""
      size="sm"
      title="Kopiera ID"
      className="px-0"
      {...restOfProps}
      onClick={onClick}
      Icon={<Paperclip size={14} />}
    />
  );
}

interface LinkButtonProps {
  to: string;
  title?: string;
  Icon: React.FC<{size: string | number | undefined}>;
}

// typically used in a card header to link to another page
export function LinkButton (props: LinkButtonProps) {
  const { to, title, Icon } = props;
  return (
    <Link
      className="btn btn-sm btn-link"
      to={to}
      title={title}
    >
      <Icon size={14} />
    </Link>
  );
}

// typically used in the card header to create a new object of the type shown in the card
export function CreateLinkButton (props: Omit<LinkButtonProps, 'Icon'>) {
  return (
    <LinkButton
      Icon={PlusCircle}
      title="Skapa ett nytt objekt"
      {...props}
    />
  );
}

// typically used in the card header to create a new object of the type shown in the card
export function CreateButton (props: IconButtonProps) {
  return (
    <IconButton
      variant=""
      size="sm"
      title="Skapa ett nytt objekt"
      className="px-0"
      {...props}
      Icon={<PlusCircle size={16} />}
    />
  );
}

interface SimpleCreateModalButtonProps extends React.PropsWithChildren {
  modalTitle?: string;
}

export function SimpleCreateModalButton (props: SimpleCreateModalButtonProps) {
  const { modalTitle, children } = props;
  return (
    <SimpleModalOpeningButton
      modalTitle={modalTitle}
      modalBodyClassName="m-0 p-0"
      className="btn btn-sm btn-link px-0"
      title="Skapa nytt objekt"
      variant=""
      label={<PlusCircle size={16} />}
    >
      {children}
    </SimpleModalOpeningButton>
  );
}

// typically used in a card header to delete the object on the current page
export function DeleteButton (props: IconButtonProps) {
  return (
    <IconButton
      variant=""
      size="sm"
      title="Radera objektet"
      className="px-0"
      {...props}
      Icon={<Trash2 size={14} />}
    />
  );
}

interface FavoriteButtonProps {
  className?: string;
  id: string;
  type: string;
}

export function FavoriteButton (props: FavoriteButtonProps) {
  const { id, type, className } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();

  const query = useQuery({
    queryKey: ['favorite', {id, type}],
    queryFn: () => api.request({
      returnData: false,
      url: `/account_favorite/${type}/${id}`,
      validateStatus: status => [200, 204].includes(status),
    }).then(response => {
      if (response.status === 204) return null;
      return response.data;
    }),
  });

  const favorited = Boolean(query.data);

  const mutationFavorite = useMutation({
    mutationFn: () => api.request({
      method: 'PUT',
      url: `/account_favorite/${type}/${id}`,
    }),
    onSuccess: data => {
      // add the favorite to the query cache
      queryClient.setQueryData(['favorite', {id, type}], data);
      notyf.success({message: 'Objektet har lagts till som favorit'});
    },
  });

  const mutationUnfavorite = useMutation({
    mutationFn: () => api.request({
      method: 'DELETE',
      url: `/account_favorite/${query.data.id}`,
    }),
    onSuccess: () => {
      // remove the favorite from the query cache
      queryClient.setQueryData(['favorite', {id, type}], null);
      notyf.success({message: 'Objektet har tagits bort från favoriter', type: 'danger'});
    },
  });

  const onClick = () => {
    if (favorited) {
      mutationUnfavorite.mutate();
    } else {
      mutationFavorite.mutate();
    }
  };

  const fill = favorited ? '#d9534f' : 'none';
  const color = favorited ? '#d9534f' : 'currentColor';
  const title = favorited ? 'Ta bort favorit' : 'Lägg till favorit';

  return (
    <IconButton
      variant=""
      size="sm"
      className={classNames(className, 'px-0')}
      title={title}
      onClick={onClick}
      Icon={<Heart fill={fill} color={color} size={14} />}
    />
  );
}

type ConfirmModalDeleteProps = Pick<ConfirmActionModalButtonProps, 'confirmMessage' | 'declineMessage' | 'onConfirm' | 'onDecline' | 'message'>;

// typically used in a card header to delete the object on the current page
export function ConfirmModalDeleteButton (props: ConfirmModalDeleteProps) {
  return (
    <ConfirmActionModalButton {...props} className="btn-link px-0" size="sm" variant="" title="Radera objektet">
      <Trash2 size={14} />
    </ConfirmActionModalButton>
  );
}

type ConfirmModalCreateProps = Pick<ConfirmActionModalButtonProps, 'confirmMessage' | 'declineMessage' | 'onConfirm' | 'onDecline' | 'message'>;

// typically used in a card header to delete the object on the current page
export function ConfirmModalCreateButton (props: ConfirmModalCreateProps) {
  return (
    <ConfirmActionModalButton {...props} className="btn-link px-0" size="sm" variant="" title="Skapa ett nytt objekt">
      <PlusCircle size={16} />
    </ConfirmActionModalButton>
  );
}

interface InspectObjectModalButtonProps {
  object: object;
}

export function InspectObjectModalButton (props: InspectObjectModalButtonProps) {
  const { object } = props;
  const modalProps = useMemo(() => ({object}), [object]);
  return (
    <ModalOpeningButton
      Modal={InspectObjectModal}
      modalProps={modalProps}
      className="btn btn-sm btn-link px-0"
      title="Granska objektet"
      variant=""
    >
      <Eye size={14} />
    </ModalOpeningButton>
  );
}
