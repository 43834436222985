import React, {ElementType} from 'react';
import classNames from 'classnames';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { Card, CardTitleProps } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';

interface CardTitleIconProps extends Omit<CardTitleProps, 'title'> {
  className?: string;
  as?: ElementType<any>;
  Icon?: React.ReactNode;
  title: React.ReactNode;
  spinning?: boolean;
  minHeight?: number;
}

export function CardTitleIcon (props: CardTitleIconProps) {
  const { as = 'h5', Icon, title, spinning, minHeight = 26, children, className, ...restOfProps } = props;
  return (
    <Card.Title
      as={as}
      className={classNames('mb-0 d-flex flex-wrap justify-content-between align-items-center', className)}
      {...restOfProps}
    >
      <div className="d-flex align-items-center gap-2" style={{minHeight: minHeight}}>
        {Icon}
        <span>{title}</span>
        {spinning && (
          <Spinner
            animation="border"
            size="sm"
            variant="default"
            style={{opacity: 0.5}}
          />
        )}
      </div>
      {children && (
        <div className="align-items-end d-flex flex-wrap gap-2">{children}</div>
      )}
    </Card.Title>
  );
}

interface CardTitleIconStatusProps extends CardTitleIconProps {
  className?: string;
  Status: React.ReactNode;
}

export function CardTitleIconStatus (props: CardTitleIconStatusProps) {
  const { as = 'h5', Status, Icon, title, spinning, children, className } = props;
  return (
    <Card.Title as={as} className={classNames('mb-0 d-flex flex-wrap justify-content-center gap-2 justify-content-md-between align-items-center', className)}>
      <div className="d-flex align-items-center gap-2 flex-wrap justify-content-center justify-content-md-start">
        {Icon}
        {title && <span>{title}</span>}
        {Status}
        {spinning && (
          <Spinner
            animation="border"
            size="sm"
            variant="default"
            style={{opacity: 0.5}}
          />
        )}
      </div>
      {children && (
        <div className="align-items-end d-flex flex-wrap gap-2">{children}</div>
      )}
    </Card.Title>
  );
}

interface WidgetListCardProps extends React.PropsWithChildren {
  className?: string;
  controls?: React.ReactNode;
  title?: React.ReactNode;
}

export function WidgetListCard (props: WidgetListCardProps) {
  const {
    className,
    children,
    controls,
    title,
  } = props;
  return (
    <Card className={classNames(className)}>
      <Card.Header className="py-1 px-2 d-flex justify-content-between bg-light border-bottom align-items-baseline">
        <div style={{fontWeight: 500}}>
          {title}
        </div>
        {controls && (
          <div className="d-flex">
            {controls}
          </div>
        )}
      </Card.Header>
      <Card.Body className="p-0">
        {children}
      </Card.Body>
    </Card>
  );
}

interface ErrorAlertCardBodyProps {
  error?: null | string | Error;
  className?: string;
}

export function ErrorAlertCardBody (props: ErrorAlertCardBodyProps) {
  const { error, className } = props;
  if (!error) return null;
  return (
    <Card.Body className={className}>
      <ErrorAlert error={error} className="m-0" />
    </Card.Body>
  );
}

interface CardQuickButtonsProps extends React.PropsWithChildren {
  title: string;
  className?: string;
}

export function CardQuickButtons (props: CardQuickButtonsProps) {
  const { title, children, className:outerClassName } = props;
  const className = classNames('border p-2 d-flex gap-2 flex-wrap justify-content-start align-items-center rounded', outerClassName);
  return (
    <Card.Body className={className}>
      <strong>{title}</strong>
      {children}
    </Card.Body>
  );
}
