import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import FormikSubmitButton from 'src/buttons/FormikSubmitButton';
import {Form} from 'formik';
import * as formUtils from 'src/utils/form';
import * as api from 'src/api';
import CustomerUpdateFormCardBuilder from 'src/customer/CustomerUpdateFormCardBuilder';
import * as CustomerFormFields from 'src/customer/CustomerFormFields';
import {GuardPermission} from 'src/guards/AuthGuards';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import {useNavigate} from 'react-router';
import {Settings} from 'lucide-react';

interface CustomerAdminSettingsFormCardProps {
  customer: CustomerRow;
}

export default function CustomerAdminSettingsFormCard (props: CustomerAdminSettingsFormCardProps) {
  const { customer } = props;

  const navigate = useNavigate();

  const onConfirmSuspectedTerrorist = async () => {
    await api.request({
      url: `/customer/${customer.id}`,
      method: 'PATCH',
      data: {suspected_terrorist: true},
    });
    navigate('/');
  };

  return (
    <CustomerUpdateFormCardBuilder
      title="Kontrollpanel"
      Icon={Settings}
      customer={customer}
      customerToFormValues={customerToFormValues}
      formValuesToUpdate={formValuesToUpdate}
    >
      <div>
        <Form>
          <Card.Body className="py-0 mb-3">

            <Row>
              <Col lg={4}>
                <CustomerFormFields.ChangeStatus currentStatus={customer.status} />
              </Col>

              <Col lg={8}>
                <CustomerFormFields.UserId />
              </Col>
            </Row>

          </Card.Body>
          <Card.Footer className="border-top d-flex align-items-center gap-2">
            <FormikSubmitButton>
              Spara inställningar
            </FormikSubmitButton>
            {!customer.suspected_terrorist && (
              <GuardPermission permission="customer_update:suspected_terrorist">
                <ConfirmActionModalButton
                  variant="danger"
                  message='Är du säker på att du vill flagga kunden för misstanke om penningtvätt eller terrorism? Om du inte är "Centralt Funktionsansvarig" så kommer du ej längre att ha åtkomst till kunden efter detta.'
                  onConfirm={onConfirmSuspectedTerrorist}
                >
                  Misstanke om penningtvätt eller terrorism
                </ConfirmActionModalButton>
              </GuardPermission>
            )}
          </Card.Footer>
        </Form>
      </div>
    </CustomerUpdateFormCardBuilder>
  );
}

type FormValues = {
  change_status: string;
  user_id: null | string;
}

function customerToFormValues (customer: CustomerRow): FormValues {
  return {
    change_status: '',
    user_id: customer.user_id || '',
  };
}

function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<CustomerRow> {
  const changes = formUtils.changes(initialValues, values);
  const update: Partial<CustomerRow> = {};
  if (typeof changes.change_status !== 'undefined') update.status = changes.change_status;
  if (typeof changes.user_id !== 'undefined') update.user_id = changes.user_id || null;
  return update;
}
