import React, {useId, useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import * as constants from 'shared/constants';
import * as workTriggerTemplateFilter from 'shared/filter/work_trigger_template';
import * as FormikFormControls from 'src/form/FormikFormControls';

// TODO add the rest of the form fields from src/workTriggerTemplate/WorkTriggerTemplateForm.tsx here

export function Task (props: Partial<FormikFormControls.InputGroupControlProps>) {

  const query = useQuery<{id: string, name: string}[]>({
    queryKey: ['/work/task/selectOptions', {is_active: true}],
  });

  const options = useMemo(() => {
    if (!query.data) return [];
    return query.data.map(o => ({value: o.id, label: o.name}));
  }, [query.data]);

  const datalistId = useId();

  const after = (
    <datalist id={datalistId}>
      {options.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </datalist>
  );

  return (
    <FormikFormControls.InputGroupControl
      {...props}
      after={after}
      list={datalistId}
      label="Rutin"
      name="task"
      minLength={1}
      maxLength={255}
      required
    />
  );
}

export function Description (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Beskrivning"
      minLength={1}
      maxLength={255}
      {...props}
      name="description"
    />
  );
}

export function ObjectType (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Objektstyp"
      {...props}
      name="object_type"
    >
      {constants.workTriggerTemplate.objectTypes.map((type: string) => (
        <option key={type} value={type}>{workTriggerTemplateFilter.objectType(type)}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}
