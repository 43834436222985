import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { VirtualTemplateRow } from 'shared/types/template';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as TemplateFormFields from 'src/template/TemplateFormFields';

export interface TemplateCreateFormValues extends Pick<VirtualTemplateRow, 'name'> {
}

interface TemplateCreateFormProps {
  className?: string;
  initialValues: TemplateCreateFormValues;
  onSubmit: (values: TemplateCreateFormValues, helpers: FormikHelpers<TemplateCreateFormValues>) => Promise<any>;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function TemplateCreateForm (props: TemplateCreateFormProps) {
  const { initialValues, submitError, onSubmit, className } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <TemplateFormFields.Name />
          </div>
          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-between">
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
