import React from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import {useMutation, useQuery} from '@tanstack/react-query';
import {UserRow} from 'shared/types/user';
import {TaskRow} from 'shared/types/task';
import {Card, Col, Row, Table} from 'react-bootstrap';
import {Zap} from 'lucide-react';
import * as api from 'src/api';
import {CardTitleIcon, ErrorAlertCardBody} from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import {GuardPermission} from 'src/guards/AuthGuards';
import useAuth from 'src/hooks/useAuth';
import ErrorAlert from 'src/alerts/ErrorAlert';
import useNotyf from 'src/hooks/useNotyf';
import UserAnchor from 'src/user/UserAnchor';
import BlockSpinner from 'src/spinners/BlockSpinner';

export default function TaskLotteryPage () {
  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Lotteriet" />
        <Row>
          <Col xl={4} md={6}>
            <LotteryInfoCard title="Högvärdiga ansökningar" lotteryType="high_value" />
          </Col>
          <Col xl={4} md={6}>
            <LotteryInfoCard title="Lågvärdiga ansökningar" lotteryType="low_value" />
          </Col>
          <Col xl={4} md={6}>
            <LotteryInfoCard title="Alla ansökningar (körs ej längre)" />
          </Col>
        </Row>
      </Content>
    </Main>
  );
}

interface LotteryInfoResponseBody {
  subscribers: string[];
  users: Pick<UserRow, 'id' | 'name' | 'role' | 'photo'>[];
  tasks: TaskRow[];
}

interface LotteryRunResponseBody {
  applicationIds: string[];
  taskIds: string[];
}

type LotterySubscribeResponseBody = string[];

interface LotteryInfoCardProps {
  title: string;
  lotteryType?: string;
}

function LotteryInfoCard (props: LotteryInfoCardProps) {
  const { title, lotteryType } = props;

  const auth = useAuth();
  const notyf = useNotyf();

  const queryKey = lotteryType ? `/task_lottery/info/${lotteryType}` : '/task_lottery/info';

  const query = useQuery<LotteryInfoResponseBody>({
    queryKey: [queryKey],
  });

  const data = query.data;

  const isSubscribed = data ? data.subscribers.includes(auth.userId) : null;

  const subscribeMutation = useMutation<LotterySubscribeResponseBody>({
    mutationFn: async () => {
      const action = isSubscribed ? 'unsubscribe' : 'subscribe';
      const result = await api.request({
        url: lotteryType ? `/task_lottery/${action}/${lotteryType}` : `/task_lottery/${action}`,
        method: 'POST',
        data: {},
      });
      await query.refetch();
      const message = `Du är ${isSubscribed ? 'avanmäld från' : 'anmäld till'} lotteriet`;
      notyf.success({type: 'default', message});
      return result;
    },
  });

  const runMutation = useMutation<LotteryRunResponseBody>({
    mutationFn: async () => {
      const result = await api.request({
        url: lotteryType ? `/task_lottery/run/${lotteryType}` : '/task_lottery/run',
        method: 'POST',
        data: {},
      });
      notyf.success({type: 'success', message: 'Lotteriet kördes'});
      return result;
    },
  });

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title={title}
          Icon={<Zap size={16} />}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <BlockSpinner isLoading={query.isLoading} className="m-3" />
      {data && (
        <>
          <Card.Body className="pb-0">
            <Table size="sm" className="mb-0" bordered>
              <tbody>
                <tr>
                  <th>Antal anmälda</th>
                  <td>{data.subscribers.length} st</td>
                </tr>
                <tr>
                  <th>Antal återkomster</th>
                  <td>{data.tasks.length} st</td>
                </tr>
                <tr>
                  <th>Anmälda</th>
                  <td>
                    {data.users.map(user => (
                      <UserAnchor key={user.id} user={user} />
                    ))}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          <GuardPermission any permission={['task_lottery_run', 'task_lottery_subscribe']}>
            <Card.Footer>
              <ErrorAlert error={runMutation.error} />
              <ErrorAlert error={subscribeMutation.error} />
              <div className="d-flex flex-wrap gap-2 align-items-center">
                <GuardPermission permission="task_lottery_subscribe">
                  <ButtonPromiseSpinner
                    onClick={subscribeMutation.mutateAsync}
                    variant={isSubscribed ? 'primary' : 'outline-primary'}
                  >
                    {isSubscribed ? 'Avanmäl mig' : 'Anmäl mig'}
                  </ButtonPromiseSpinner>
                </GuardPermission>
                <GuardPermission permission="task_lottery_run">
                  <ButtonPromiseSpinner
                    onClick={runMutation.mutateAsync}
                    variant="outline-secondary"
                  >
                    Kör lotteriet
                  </ButtonPromiseSpinner>
                </GuardPermission>
              </div>
            </Card.Footer>
          </GuardPermission>
        </>
      )}
    </Card>
  );
}
