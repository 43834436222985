import React, {useEffect} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { Spinner } from 'react-bootstrap';
import qs from 'qs';

interface AuthLoaderGuardProps extends React.PropsWithChildren {

}

export default function AuthLoaderGuard (props: AuthLoaderGuardProps) {
  const { children } = props;

  const { isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      const src = new URL(window.location.toString());
      const dstPathname = '/login/password';
      const dstSearch = qs.stringify({then: src.pathname + src.search + src.hash}, {
        strictNullHandling: true,
        addQueryPrefix: true,
      });
      const dst = dstPathname + dstSearch;
      navigate(dst);
    }
  }, [isAuthenticated, isInitialized, navigate]);

  if (!isInitialized) {
    return (
      <div className="container p-4 d-flex justify-content-center">
        <Spinner animation="border" variant="default" />
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}

// same as AuthLoaderGuard except blocks if authed and shows a fallback
export function UnauthedLoaderGuard (props: AuthLoaderGuardProps) {
  const { children } = props;

  const { isAuthenticated, isInitialized } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const then = searchParams.get('then');

  useEffect(() => {
    if (isInitialized && isAuthenticated) {
      navigate(then ?? '/');
    }
  }, [isAuthenticated, isInitialized, navigate, then]);

  if (!isInitialized) {
    return (
      <div className="container p-4 d-flex justify-content-center">
        <Spinner animation="border" variant="default" />
      </div>
    );
  }

  if (isAuthenticated) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}
