import React, {useMemo} from 'react';
import { Formik, FormikHelpers } from 'formik';
import { BankProcessRow } from 'shared/types/bank_process';
import { Form } from 'react-bootstrap';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import * as FormikFormControls from 'src/form/FormikFormControls';
import bankAdapterSettings from 'shared/bank_adapter/settings';
import useAuth from 'src/hooks/useAuth';
import * as bankProcessFilter from 'shared/filter/bank_process';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import {useMutation} from '@tanstack/react-query';
import {errorToMessage} from 'src/utils/error';
import {BankAdapterValidatorResult} from 'shared/types/bank_adapter';

interface FormValues {
  method: string;
}

interface InvokeMutationResult {
  error?: BankAdapterValidatorResult;
  process: BankProcessRow;
  worker: unknown[];
}

interface BankProcessInvokeFormProps {
  bankProcess: BankProcessRow;
}

export default React.memo(function BankProcessInvokeForm (props: BankProcessInvokeFormProps) {
  const { bankProcess } = props;

  const auth = useAuth();
  const notyf = useNotyf();

  const invokeMutation = useMutation<InvokeMutationResult, Error, FormValues>({
    mutationFn: async data => {
      const result = await api.request({
        errorToNotyf: notyf,
        url: `/bank_process/${bankProcess.id}/invoke`,
        method: 'POST',
        data,
      });
      if (result.error) {
        notyf.error(errorToMessage(result.error));
      } else {
        notyf.success({type: 'default', message: 'Åtgärden utfördes'});
      }
      return result;
    },
  });

  const onSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    await invokeMutation.mutateAsync(values);
    helpers.resetForm({values: {method: ''}});
  };

  const initialValues = {
    method: '',
  };

  const methods = useMemo(() => {
    const { status, bank_adapter:adapter } = bankProcess;
    const extraActions = bankAdapterSettings?.[adapter]?.extraActions ?? [];
    return [
      auth.hasAnyPermissions('bank_process_execute:invoke:submit') ?  'submit' : null,
      status !== 'open' && auth.hasAnyPermissions('bank_process_execute:invoke:set_status_open') ? 'set_status_open' : null,
      status !== 'new' && auth.hasAnyPermissions('bank_process_execute:invoke:set_status_new') ? 'set_status_new' : null,
      status !== 'waiting' && auth.hasAnyPermissions('bank_process_execute:invoke:set_status_waiting') ? 'set_status_waiting' : null,
      status !== 'accepted' && auth.hasAnyPermissions('bank_process_execute:invoke:set_status_accepted') ? 'set_status_accepted' : null,
      status !== 'disbursed' && auth.hasAnyPermissions('bank_process_execute:invoke:set_status_disbursed') ? 'set_status_disbursed' : null,
      ...extraActions.filter(() => auth.hasAnyPermissions('admin')),
    ].filter(method => method);
  }, [bankProcess, auth]);

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur enableReinitialize>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <FormikFormControls.InputGroupSelect
            name="method"
            required
            after={(
              <ButtonSpinner
                type="submit"
                variant="primary"
                disabled={!formProps.isValid || !formProps.values.method || invokeMutation.isPending}
                isLoading={invokeMutation.isPending}
              >
                Utför
              </ButtonSpinner>
            )}
          >
            <option value="">Välj en särskild åtgärd</option>
            {methods.map(method => (
              <option key={method} value={method}>{bankProcessFilter.invokeMethod(method)}</option>
            ))}
          </FormikFormControls.InputGroupSelect>
          <ErrorAlert error={invokeMutation.error} className="mt-3 mb-0" />
        </Form>
      )}
    </Formik>
  );
});
