

/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.columnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'customer_id': return 'Kund';
    case 'application_id': return 'Ansökan';
    case 'created_by_user_id': return 'Skapad av användare';
    case 'created_by_customer': return 'Skapad av kund';
    case 'result_code': return 'Resultatkod';
    case 'result_reason': return 'Resultat';
    case 'error': return 'Fel';
    case 'form': return 'Formulär';
    case 'status': return 'Status';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'actions': return 'Åtgärder';
  }
};

exports.field = function (customerTryggsamLead, columnKey) {
  const get = (...args) => getter(customerTryggsamLead, ...args);
  switch (columnKey) {
    default: return get(columnKey);
    case 'status': return exports.status(get('status'));
    case 'customer_id': return get('Customer.name') || get('Customer.id') || get('customer_id') || '';
    case 'created_by_user_id': return get('Creator.name') || get('Creator.id') || get('created_by_user_id') || '';
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'submitted': return 'Inskickad';
    case 'success': return 'Lead mottagen';
    case 'failed': return 'Lead ej accepterad';
    case 'error': return 'Fel';
  }
};
