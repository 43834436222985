import React from 'react';
import DateTime from 'src/formatters/DateTime';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as api from 'src/api';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import UserLogDataTypeIcon from 'src/user/UserLogDataTypeIcon';
import UserLogDescription from 'src/user/UserLogDescription'; 
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import {
  Row,
  Card,
  Col,
  Table,
  Form,
} from 'react-bootstrap';
import {
  Activity,
} from 'lucide-react';

export default function AccountLogCard (props) {
  const [day, setDay] = React.useState(moment().format('YYYY-MM-DD'));

  const query = useQuery({
    queryKey: ['AccountLogCard', day],
    queryFn: () => api.request({
      url: '/account/log',
      params: {day},
    }),
  });

  return (
    <Card className="flex-fill w-100">
      <Card.Header className="card-header-flush">
        <CardTitleIcon title="Händelselogg" Icon={<Activity size={18} />} spinning={query.isRefetching}>
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="flex-grow-0 border-bottom pb-0">
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm={2} className="text-sm-right text-nowrap">
            Datum
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type="date"
              name="day"
              value={day}
              onChange={ev => setDay(ev.target.value)}
            />
          </Col>
        </Form.Group>
      </Card.Body>
      <div className="position-relative table-responsive">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <Table striped className="my-0 border-bottom">
          <tbody>
            {query.isSuccess && query.data.map(item => (
              <tr key={item.id}>
                <td>
                  <DateTime value={item.date} format="HH:mm" />
                </td>
                <td>
                  <UserLogDataTypeIcon type={item.data.type} />
                </td>
                <td>
                  <UserLogDescription data={item.data} />
                </td>
              </tr>
            ))}
            {!query.data?.length && (
              <tr>
                <td colSpan={3} className="text-center">Inga händelser på valt datum</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Card>
  );
}
