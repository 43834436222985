import React from 'react';
import {
  Button,
  Navbar as BsNavbar,
  Nav,
} from 'react-bootstrap';
import { Plus, RefreshCw } from 'lucide-react';
import PassportButton from 'src/buttons/PassportButton';
import useSidebar from 'src/hooks/useSidebar';
import NavbarUser from 'src/navbar/NavbarUser';
import NavbarLogDropdown from 'src/navbar/NavbarLogDropdown';
import NavbarTaskDropdown from 'src/navbar/NavbarTaskDropdown';
import NavbarQuickSearch from 'src/navbar/NavbarQuickSearch';
import {useIsFetching, useQueryClient} from '@tanstack/react-query';
import {DateTimeFormatProvider} from 'src/contexts/DateTimeFormatContext';
import {GuardFunction, GuardPermission} from 'src/guards/AuthGuards';

const Navbar = React.memo(function Navbar () {
  const { isOpen, setIsOpen } = useSidebar();

  const queryClient = useQueryClient();
  const isFetching = useIsFetching();

  const onClick = () => {
    setIsOpen(!isOpen);
  };

  const onClickReload = () => {
    queryClient.refetchQueries();
  };

  return (
    <BsNavbar variant="light" expand className="navbar-bg sticky-top border-bottom">
      <DateTimeFormatProvider disabled>
        <span
          role="switch"
          aria-checked={isOpen}
          tabIndex={0}
          onKeyPress={onClick}
          onClick={onClick}
          className="sidebar-toggle d-flex"
        >
          <i className="hamburger align-self-center" />
        </span>

        <GuardPermission permission="misc_quicksearch">
          <NavbarQuickSearch
            className="d-none d-sm-inline-block"
          />
        </GuardPermission>

        <GuardPermission permission="application_create">
          <Button className="d-none d-sm-inline-flex gap-1 align-items-center ms-sm-2 bg-gradient" variant="success" size="sm" disabled>
            <Plus size={18} />
            Ny ansökan
          </Button>
        </GuardPermission>

        <GuardPermission permission="admin">
          <Button
            className="d-none d-sm-inline-flex p-1 gap-1 align-items-center ms-sm-2"
            size="sm"
            variant="outline-secondary"
            title="Ladda om allt på sidan"
            onClick={onClickReload}
            disabled={isFetching > 0}
          >
            <RefreshCw size={14} style={{height: '14px', width: '14px'}}/>
          </Button>
        </GuardPermission>

        <GuardFunction fn="crm">
          <PassportButton
            className="d-none d-sm-inline-flex align-items-center ms-sm-2 bg-gradient"
            variant="outline-secondary"
            size="sm"
          />
        </GuardFunction>

        <BsNavbar.Collapse>
          <Nav className="navbar-align">
            <GuardPermission permission="task_user">
              <NavbarTaskDropdown />
            </GuardPermission>
            <GuardPermission permission="account_log">
              <NavbarLogDropdown />
            </GuardPermission>
            <NavbarUser />
          </Nav>
        </BsNavbar.Collapse>
      </DateTimeFormatProvider>
    </BsNavbar>
  );
});

export default Navbar;
