import React from 'react';
import { Form, Card, Row, Col, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as BankProcessFormFields from 'src/bankProcess/BankProcessFormFields';
import { BankProcessRow } from 'shared/types/bank_process';
import IdProvider from 'src/components/IdProvider';
import CopyToClipboardButton from 'src/buttons/CopyToClipboardButton';
import moment from 'moment';
import {useMutation} from '@tanstack/react-query';
import * as api from 'src/api';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';
import {GuardPermission} from 'src/guards/AuthGuards';
import BankProcessCloseModalButton from 'src/bankProcess/BankProcessCloseModalButton';

interface FormValues {
  created_at: string;
  closed_at: string;
  accepted_at: string;
  disbursed: string;
  closed_reason: string;
}

interface BankProcessControlPanelFormProps {
  bankProcess: BankProcessRow;
}

export default React.memo(function BankProcessControlPanelForm (props: BankProcessControlPanelFormProps) {
  const { bankProcess } = props;

  const notyf = useNotyf();
  const initialValues = bankProcessToFormValues(bankProcess);

  const updateMutation = useMutation<BankProcessRow, Error, Partial<BankProcessRow>>({
    mutationFn: vars => api.request({
      url: `/bank_process/${bankProcess.id}`,
      method: 'PATCH',
      data: vars,
    }),
    onSuccess: () => {
      const message = `Processen med "${bankProcess.bank_id}" har blivit uppdaterad`;
      notyf.success({type: 'default', message});
    },
  });

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<BankProcessRow, FormValues, Partial<BankProcessRow>>({
    queryDataToFormValues: bankProcessToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  const isClosed = bankProcess.status === 'closed';
  const isFinished = ['disbursed', 'closed'].includes(bankProcess.status);
  const isAccepted = ['accepted', 'disbursed'].includes(bankProcess.status);

  const colProps = {md: 6};
  return (
    <Formik initialValues={initialValues} onSubmit={formCycleHelpers.onSubmit} enableReinitialize>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="pt-0">
            <Row>

              <Col {...colProps}>
                <BankProcessFormFields.CreatedAt />
              </Col>

              <Col {...colProps}>
                <BankProcessFormFields.AcceptedAt
                  required={isAccepted}
                  disabled={!isAccepted}
                />
              </Col>

              <Col {...colProps}>
                <BankProcessFormFields.ClosedAt required={isFinished} disabled={!isFinished} />
              </Col>

              <Col {...colProps}>
                <BankProcessFormFields.Disbursed
                  required={isAccepted}
                  disabled={!isAccepted}
                  bankProcessStatus={bankProcess.status}
                />
              </Col>

              <Col lg={12}>
                <BankProcessFormFields.ClosedReason required={isClosed} disabled={!isClosed} />
              </Col>

              <Col {...colProps}>
                <IdProvider>
                  {id => (
                    <Form.Group className="mb-1 mt-3">
                      <Form.Label htmlFor={id}>Långivarens referens</Form.Label>
                      <InputGroup>
                        <Form.Control
                          id={id}
                          name="their_id"
                          value={bankProcess.their_id ?? '-'}
                          disabled
                          className="text-truncate"
                        />
                        <CopyToClipboardButton
                          disabled={!Boolean(bankProcess.their_id)}
                          copy={bankProcess.their_id}
                          size="sm"
                          variant="outline-info"
                        />
                      </InputGroup>
                    </Form.Group>
                  )}
                </IdProvider>
              </Col>

              <Col {...colProps}>
                <IdProvider>
                  {id => (
                    <Form.Group className="mb-1 mt-3">
                      <Form.Label htmlFor={id}>Tryggas referens</Form.Label>
                      <InputGroup>
                        <Form.Control
                          id={id}
                          name="our_id"
                          value={bankProcess.our_id ?? '-'}
                          disabled
                          className="text-truncate"
                        />
                        <CopyToClipboardButton
                          disabled={!Boolean(bankProcess.our_id)}
                          copy={bankProcess.our_id}
                          size="sm"
                          variant="outline-info"
                        />
                      </InputGroup>
                    </Form.Group>
                  )}
                </IdProvider>
              </Col>

            </Row>
          </Card.Body>
          <Card.Footer className="border-top">
            <ErrorAlert error={updateMutation.error} />
            <div className="d-flex gap-2 flex-wrap">
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
              <GuardPermission permission="bank_process_execute:invoke:set_status_closed">
                <BankProcessCloseModalButton
                  bankProcess={bankProcess}
                />
              </GuardPermission>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
});

export function bankProcessToFormValues (bankProcess: BankProcessRow): FormValues {
  const { created_at, closed_at, accepted_at, disbursed, closed_reason } = bankProcess;
  return {
    created_at: created_at ? moment(created_at).format('YYYY-MM-DD') : '',
    closed_at: closed_at ? moment(closed_at).format('YYYY-MM-DD') : '',
    accepted_at: accepted_at ? moment(accepted_at).format('YYYY-MM-DD') : '',
    disbursed: disbursed ?? '',
    closed_reason: closed_reason ?? '',
  };
}

export function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<BankProcessRow> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
