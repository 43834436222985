import React, {useState} from 'react';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {Button, Card, Collapse} from 'react-bootstrap';
import {Shield} from 'lucide-react';
import {ApplicationRow} from 'shared/types/application';
import {WorkRow} from 'shared/types/work';
import {CustomerTryggsamLeadRow} from 'shared/types/customer_tryggsam_lead';
import {keepPreviousData, useMutation, useQuery} from '@tanstack/react-query';
import * as IconButtons from 'src/buttons/IconButtons';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import DateTime from 'src/formatters/DateTime';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import useAuth from 'src/hooks/useAuth';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import {GuardPermission} from 'src/guards/AuthGuards';
import * as CustomerTryggsamLeadFormatters from 'src/customerTryggsamLead/CustomerTryggsamLeadFormatters';
import * as CustomerTryggsamLeadBlocks from 'src/customerTryggsamLead/CustomerTryggsamLeadBlocks';
import delay from 'delay';

interface CustomerTryggsamLeadSubmitResponse {
  lead: CustomerTryggsamLeadRow;
  work: WorkRow;
  application_id: string;
  customer_id: string;
}

interface ApplicationCustomerTryggsamLeadCardProps {
  application: ApplicationRow;
}

export default function ApplicationCustomerTryggsamLeadCard (props: ApplicationCustomerTryggsamLeadCardProps) {
  const { application } = props;

  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const onClickToggleCreateForm = () => {
    setShowCreateForm(showCreateForm => !showCreateForm);
  };

  const notyf = useNotyf();
  const auth = useAuth();

  const query = useQuery<CustomerTryggsamLeadRow[]>({
    queryKey: ['/customer_tryggsam_lead/search', {params: {application_id: application.id}}],
    placeholderData: keepPreviousData,
  });

  const createAndSubmitMutation = useMutation<CustomerTryggsamLeadRow, Error, void>({
    mutationFn: async () => {
      // create
      const customerTryggsamLead = await api.request<CustomerTryggsamLeadRow>({
        errorToNotyf: notyf,
        url: '/customer_tryggsam_lead',
        method: 'POST',
        data: {
          application_id: application.id,
          customer_id: application.customer_id,
        },
      });

      // execute submit
      const result = await api.request<CustomerTryggsamLeadSubmitResponse>({
        url: `/customer_tryggsam_lead/${customerTryggsamLead.id}/submit`,
        method: 'POST',
        data: {},
      });

      await delay(400); // give the response a chance to come
      await query.refetch();
      notyf.success({type: 'success', message: 'Lead skickades in till Tryggsam'});

      setShowCreateForm(false);

      return result.lead;
    },
  });

  const onClickSubmitAndCreate = async () => {
    return await createAndSubmitMutation.mutateAsync();
  };

  const leadList = query.data ?? [];
  const hasLead = leadList.length > 0;

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Tryggsam ID-skydd"
          Icon={<Shield size={18} />}
        >
          <GuardPermission permission="sanction_list_match_application">
            <IconButtons.CreateButton onClick={onClickToggleCreateForm} />
          </GuardPermission>
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            refetch={query.refetch}
          />
        </CardTitleIcon>
      </Card.Header>
      <GuardPermission permission={['customer_tryggsam_lead_create', 'customer_tryggsam_lead_execute']}>
        <Collapse in={showCreateForm}>
          <div>
            <div className="p-3 d-flex justify-content-center">
              <ButtonPromiseSpinner
                onClick={onClickSubmitAndCreate}
                variant="success"
                disabled={createAndSubmitMutation.isPending || (hasLead ? !auth.permission('admin') : false)}
              >
                {hasLead ? (
                  <span>Skicka huvudsökande som lead till Tryggsam igen</span>
                ) : (
                  <span>Skicka huvudsökande som lead till Tryggsam</span>
                )}
              </ButtonPromiseSpinner>
            </div>
          </div>
        </Collapse>
      </GuardPermission>
      {query.isSuccess && (
        <Card.Body className="p-2 pb-0 border-top">
          {leadList.length > 0 ? (
            <>
              {leadList.map(customerTryggsamLead => (
                <CustomerTryggsamLeadCard
                  key={customerTryggsamLead.id}
                  className="mb-2"
                  customerTryggsamLead={customerTryggsamLead}
                />
              ))}
            </>
          ) : (
            <p className="mb-2 text-center text-muted">Huvudsökande har ej skickats till Tryggsam.</p>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

interface CustomerTryggsamLeadCardProps {
  customerTryggsamLead: CustomerTryggsamLeadRow;
  className?: string;
}

function CustomerTryggsamLeadCard (props: CustomerTryggsamLeadCardProps) {
  const { className, customerTryggsamLead } = props;

  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const onClickShowMoreInfo = () => {
    setShowMoreInfo(showMoreInfo => !showMoreInfo);
  };

  return (
    <Card className={className}>
      <Card.Header className="p-2 bg-light">
        <div className="d-flex gap-2 align-items-center flex-wrap justify-content-between">
          <div className="d-flex gap-2 flex-wrap flex-grow-1">
            <CustomerTryggsamLeadFormatters.StatusLabel
              value={customerTryggsamLead.status}
              style={{fontSize: '80%', minWidth: '40%'}}
              short
            />
            <DateTime className="small" value={customerTryggsamLead.created_at} format="YYYY-MM-DD" />
          </div>
          <div className="d-flex gap-1 flex-wrap">
            <Button className="py-0" variant="outline-info" size="sm" onClick={onClickShowMoreInfo}>
              {showMoreInfo ? <span>Dölj</span> : <span>Mer</span>}
            </Button>
            <InspectObjectModalButton object={customerTryggsamLead} size="sm" className="py-0" />
          </div>
        </div>
      </Card.Header>
      <Collapse in={showMoreInfo}>
        <div>
          <Card.Body className="border-top p-0">
            <CustomerTryggsamLeadBlocks.ResultTable customerTryggsamLead={customerTryggsamLead} />
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
}
