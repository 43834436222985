import React from 'react';
import classNames from 'classnames';
import { ExternalLink } from 'lucide-react';

interface ExternalLinkAnchorProps extends React.HTMLAttributes<HTMLAnchorElement> {
  className?: string;
  href: string;
  iconSize?: number;
}

export default function ExternalLinkAnchor (props: ExternalLinkAnchorProps) {
  const { children, className, iconSize = 14, href, ...restOfProps } = props;
  const classes = classNames('d-inline-flex align-items-baseline gap-1 border rounded px-1 font-monospace', className);
  const pxSize = `${iconSize}px`;
  return (
    <a
      href={href}
      title="Öppna extern länk"
      {...restOfProps}
      target="_blank"
      rel="noopener noreferrer"
      className={classes}
    >
      {children}
      <span className="d-inline-flex">
        <ExternalLink className="position-relative feather" style={{top: '2px', height: pxSize, width: pxSize}}/>
      </span>
    </a>
  );
}
