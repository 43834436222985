import React, {useState} from 'react';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {Button, Card, Collapse} from 'react-bootstrap';
import {CreditCard} from 'lucide-react';
import {ApplicationRow} from 'shared/types/application';
import {keepPreviousData, useMutation, useQuery} from '@tanstack/react-query';
import * as IconButtons from 'src/buttons/IconButtons';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import {UcRow} from 'shared/types/uc';
import DateTime from 'src/formatters/DateTime';
import * as UcFormatters from 'src/uc/UcFormatters';
import * as UcBlocks from 'src/uc/UcBlocks';
import UcCreateForm, {UcCreateFormValues} from 'src/uc/UcCreateForm';
import {useEventModelEmitCreates} from 'src/hooks/useEventModels';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';
import * as validate from 'shared/validate';
import * as format from 'shared/format';
import ConfirmActionModalExecuteButton from 'src/buttons/ConfirmActionModalExecuteButton';

interface ApplicationUcCardProps {
  application?: ApplicationRow;
  applicationId: ApplicationRow['id'];
}

export default function ApplicationUcCard (props: ApplicationUcCardProps) {
  const { application, applicationId } = props;

  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const onClickToggleCreateForm = () => {
    setShowCreateForm(showCreateForm => !showCreateForm);
  };

  const query = useQuery<UcRow[]>({
    queryKey: ['/uc/search', {params: {application_id: applicationId}}],
    placeholderData: keepPreviousData,
  });

  const ucList = query.data ?? [];

  const emitCreate = useEventModelEmitCreates<UcRow>('uc');
  const notyf = useNotyf();

  const createMutation = useMutation<UcRow, Error, UcCreateFormValues>({
    mutationFn: async data => {
      const uc = await api.request({
        errorToNotyf: notyf,
        url: `/uc/application/${applicationId}`,
        method: 'POST',
        data,
      });

      notyf.success({type: 'success', message: 'UC:n skapades'});
      emitCreate(uc);

      setShowCreateForm(false);
      await query.refetch();

      return uc;
    },
  });

  const onReload = async () => {
    await query.refetch();
  };

  const onSubmit = async values => {
    return await createMutation.mutateAsync(values);
  };

  const mainPersonalNumber = application?.form?.main_applicant?.ssn;
  const hasMainPersonalNumber = Boolean(mainPersonalNumber) && validate.ssn(mainPersonalNumber);

  const coPersonalNumber = application?.form?.co_applicant?.ssn;
  const hasCoPersonalNumber = Boolean(coPersonalNumber) && validate.ssn(coPersonalNumber);

  const initialValues = {
    request_object: hasMainPersonalNumber ? format.ssn('short-dash', mainPersonalNumber) : '',
    request_coObject: hasCoPersonalNumber ? format.ssn('short-dash', coPersonalNumber) : '',
    request_creditSeek: (application?.form?.loan?.amount?.new ?? 0) + (application?.form?.loan?.amount?.now ?? 0),
  };

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Kreditupplysning/UC"
          Icon={<CreditCard size={18} />}
          spinning={query.isRefetching}
        >
          <IconButtons.CreateButton onClick={onClickToggleCreateForm} />
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            refetch={query.refetch}
          />
        </CardTitleIcon>
      </Card.Header>
      <Collapse in={showCreateForm}>
        <div>
          <div className="border-bottom p-0">
            <UcCreateForm
              customerId={application.customer_id}
              initialValues={initialValues}
              onSubmit={onSubmit}
              disabled
            />
          </div>
        </div>
      </Collapse>
      {query.isSuccess && (
        <Card.Body className="p-2 pb-0">
          {ucList.length > 0 ? (
            <>
              {ucList.map(uc => (
                <UcCard key={uc.id} className="mb-2" uc={uc} onReload={onReload} />
              ))}
            </>
          ) : (
            <p className="mb-2 text-center text-muted">Ansökan har ingen kreditupplysning.</p>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

interface UcCardProps {
  uc: UcRow;
  className?: string;
  onReload: () => Promise<unknown>;
}

function UcCard (props: UcCardProps) {
  const { uc, onReload, className } = props;

  const auth = useAuth();

  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const onClickShowMoreInfo = () => {
    setShowMoreInfo(showMoreInfo => !showMoreInfo);
  };

  const canExecuteSubmit = auth.permission('uc_execute:submit') && moment(uc.created_at).isSame(moment(), 'day');
  const canExecuteCopyProperties = auth.permission('uc_execute:copy_properties') && Boolean(uc.response_main);

  return (
    <Card className={className}>
      <Card.Header className="p-2 border-bottom-0">
        <div className="d-flex gap-2 align-items-center flex-wrap justify-content-between">
          <div className="d-flex gap-2 flex-wrap flex-grow-1">
            <UcFormatters.StatusLabel
              value={uc.status}
              style={{fontSize: '80%', minWidth: '40%'}}
              short
            />
            <DateTime className="small" value={uc.created_at} format="YYYY-MM-DD" />
          </div>
          <div className="d-flex gap-1 flex-wrap">
            <Button className="py-0" variant="outline-info" size="sm" onClick={onClickShowMoreInfo}>
              {showMoreInfo ? <span>Dölj</span> : <span>Mer</span>}
            </Button>
            <InspectObjectModalButton object={uc} size="sm" className="py-0" />
          </div>
        </div>
      </Card.Header>
      <Collapse in={showMoreInfo}>
        <div>
          <Card.Body className="p-0 border-to border-top">
            <UcBlocks.ResponseTable uc={uc} hasAnchor />
          </Card.Body>
          {(canExecuteSubmit || canExecuteCopyProperties) && (
            <Card.Footer className="p-2 flex-wrap d-flex justify-content-center gap-2">
              {canExecuteSubmit && (
                <ConfirmActionModalExecuteButton
                  url={`/uc/${uc.id}/submit`}
                  className="px-1"
                  method="POST"
                  onSuccess={onReload}
                  onSuccessNotyf={{type: 'success', message: 'Kreditupplysningen skickades in igen'}}
                  size="sm"
                  variant="outline-primary"
                  message="Är du säker på att du vill skicka in kreditupplysningen igen?"
                >
                  Skicka in igen
                </ConfirmActionModalExecuteButton>
              )}
              {canExecuteCopyProperties && (
                <ConfirmActionModalExecuteButton
                  url={`/uc/${uc.id}/copy_properties`}
                  className="px-1"
                  method="POST"
                  onSuccess={onReload}
                  onSuccessNotyf={{type: 'success', message: 'Uppgifterna kopierades in på kunden och ansökan'}}
                  size="sm"
                  variant="outline-primary"
                  message="Är du säker på att du vill skicka in infoga uppgifterna från kreditupplysningen på kunden och ansökan?"
                >
                  Infoga uppgifter
                </ConfirmActionModalExecuteButton>
              )}
            </Card.Footer>
          )}
        </div>
      </Collapse>
    </Card>
  );
}
