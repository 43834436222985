import React from 'react';
import {keepPreviousData, useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import { BankProcessRow, BankProcessRowWithRelations } from 'shared/types/bank_process';
import {Card, Nav, Tab, Col, Row, CardProps, Collapse} from 'react-bootstrap';
import {Activity, DollarSign, Edit2, FastForward, Repeat, Settings} from 'lucide-react';
import BankProcessLogCard from 'src/bankProcess/BankProcessLogCard';
import { CardQuickButtons, CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import * as BankProcessFormatters from 'src/bankProcess/BankProcessFormatters';
import * as IconButtons from 'src/buttons/IconButtons';
import DateTime from 'src/formatters/DateTime';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import {GuardPermission} from 'src/guards/AuthGuards';
import BankProcessControlPanelForm from 'src/bankProcess/BankProcessControlPanelForm';
import BankProcessInvokeForm from 'src/bankProcess/BankProcessInvokeForm';
import BankProcessApplicationForm from 'src/bankProcess/BankProcessApplicationForm';
import BankProcessAssociatedObjectList from 'src/bankProcess/BankProcessAssociatedObjectList';
import {BankProcessBidRow} from 'shared/types/bank_process_bid';
import BankProcessBidSingleCollapsible from 'src/bankProcessBid/BankProcessBidSingleCollapsible';
import HelperTooltip from 'src/components/HelperTooltip';
import ExecuteButton from 'src/buttons/ExecuteButton';
import NavLinkLockable from 'src/components/NavLinkLockable';
import BankProcessCardDecisionTabCard from 'src/bankProcess/BankProcessCardDecisionTabCard';

interface BankProcessCardProps {
  applicationId: string;
  bankProcess: BankProcessRowWithRelations;
  activeBankProcessId: string;
  activeBankProcessTab: string;
}

export default function BankProcessCard (props: BankProcessCardProps) {
  const { applicationId, bankProcess, activeBankProcessId, activeBankProcessTab } = props;

  const hasOffer = Boolean(bankProcess.data?.offer);

  return (
    <Card className="rounded">
      <BankProcessNavLink
        applicationId={applicationId}
        bankProcess={bankProcess}
        activeBankProcessId={activeBankProcessId}
        activeBankProcessTab={activeBankProcessTab}
      />
      <Collapse in={activeBankProcessId === bankProcess.id} mountOnEnter unmountOnExit appear>
        <div className="border-top">
          {bankProcess && (
            <BankProcessAssociatedObjectList bankProcess={bankProcess} />
          )}
          <Tab.Container
            activeKey={activeBankProcessTab}
            transition={false}
            unmountOnExit={true}
            mountOnEnter={true}
          >
            <Nav className="nav-tabs pt-3 px-3">

              <NavLinkLockable
                to={`/application/${applicationId}/bankProcess/${bankProcess.id}/form`}
                Icon={Edit2}
                title="Ansökan"
              />

              <NavLinkLockable
                to={`/application/${applicationId}/bankProcess/${bankProcess.id}/offer`}
                Icon={DollarSign}
                title="Erbjudanden"
                locked={!hasOffer}
              />

              <NavLinkLockable
                to={`/application/${applicationId}/bankProcess/${bankProcess.id}/decision`}
                Icon={FastForward}
                title="Beslut"
                locked={!hasOffer}
              />

              <NavLinkLockable
                to={`/application/${applicationId}/bankProcess/${bankProcess.id}/log`}
                Icon={Activity}
                title="Logg"
              />

            </Nav>

            <Row className="px-4 pt-4">
              <Col lg={8}>

                <Tab.Content>

                  <Tab.Pane eventKey="form">
                    <Card>
                      <Card.Header>
                        <CardTitleIcon
                          title="Ansökan"
                          Icon={<Edit2 size={18} />}
                        />
                      </Card.Header>
                      <BankProcessApplicationForm bankProcess={bankProcess} />
                    </Card>
                  </Tab.Pane>

                  {hasOffer && (
                    <Tab.Pane eventKey="offer">
                      <BankProcessCardOfferTabCard bankProcess={bankProcess} />
                    </Tab.Pane>
                  )}

                  {hasOffer && (
                    <Tab.Pane eventKey="decision">
                      <BankProcessCardDecisionTabCard bankProcess={bankProcess} />
                    </Tab.Pane>
                  )}

                  <Tab.Pane eventKey="log">
                    <BankProcessLogCard bankProcessId={bankProcess.id} />
                  </Tab.Pane>

                </Tab.Content>

              </Col>
              <Col lg={4}>
                <BankProcessControlPanelCard
                  className="border"
                  bankProcess={bankProcess}
                />
              </Col>
            </Row>

          </Tab.Container>
        </div>
      </Collapse>
    </Card>
  );
}

interface BankProcessNavLinkProps {
  applicationId: string;
  bankProcess: BankProcessRowWithRelations;
  activeBankProcessId?: string;
  activeBankProcessTab?: string;
}

function BankProcessNavLink (props: BankProcessNavLinkProps) {
  const { applicationId, activeBankProcessId, activeBankProcessTab, bankProcess } = props;

  const notyf = useNotyf();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deleteMutation = useMutation({
    mutationFn: async () => {
      await api.request({
        errorToNotyf: notyf,
        url: `/bank_process/${bankProcess.id}`,
        method: 'DELETE',
      });

      await queryClient.invalidateQueries({queryKey: [`/application/${applicationId}/process`]});
      const message = `Långivarprocessen med "${bankProcess.Bank.name ?? bankProcess.bank_id}" har raderats`;
      notyf.success({message, type: 'warning'});
      navigate(`/application/${applicationId}/bankProcess`);
    },
  });

  const onClickDelete = async () => {
    await deleteMutation.mutateAsync();
  };

  const activeBankProcessIsThis = bankProcess.id === activeBankProcessId;
  const to = activeBankProcessIsThis ? `/application/${applicationId}/bankProcess` : `/application/${applicationId}/bankProcess/${bankProcess.id}/${activeBankProcessTab || 'form'}`;

  return (
    <div className="bg-light card-header d-flex justify-content-center justify-content-md-between flex-wrap">
      <NavLink className="text-reset text-decoration-none flex-grow-1" to={to}>
        <Card.Title as="span" className="d-flex align-items-center justify-content-center justify-content-md-start gap-2 m-0 p-0 btn btn-link">
          <Repeat size={16} />
          {bankProcess.Bank.name ?? bankProcess.bank_id}
        </Card.Title>
      </NavLink>
      <div className="d-flex justify-content-md-end justify-content-center gap-3 flex-wrap align-items-center">
        <div className="d-flex align-items-center flex-wrap justify-content-center justify-content-md-start gap-2">
          <DateTime
            title="Datum för statusuppdatering"
            value={bankProcess.status_updated_at}
            className="small"
            style={{top: '1px', position: 'relative'}}
          />
          <GuardPermission permission="bank_process_delete">
            <IconButtons.ConfirmModalDeleteButton
              onConfirm={onClickDelete}
              message="Är du säker på att du vill radera den här långivarprocessen?"
            />
          </GuardPermission>
          <IconButtons.FavoriteButton type="bank_process" id={bankProcess.id} />
          <IconButtons.InspectObjectModalButton object={bankProcess} />
          <IconButtons.CopyObjectIdButton id={bankProcess.id} />
        </div>
        <BankProcessFormatters.StatusLabel style={{minWidth: '150px'}} value={bankProcess.status} short />
      </div>
    </div>
  );
}

interface BankProcessControlPanelCardProps extends CardProps {
  bankProcess: BankProcessRowWithRelations;
}

function BankProcessControlPanelCard (props: BankProcessControlPanelCardProps) {
  const { bankProcess, ...restOfProps } = props;

  return (
    <Card {...restOfProps}>
      <Card.Header>
        <CardTitleIcon
          title="Kontrollpanel (process)"
          Icon={<Settings size={18} />}
        >
        </CardTitleIcon>
      </Card.Header>
      <GuardPermission permission="bank_process_execute:invoke">
        <Card.Body className="border-bottom">
          <BankProcessInvokeForm bankProcess={bankProcess} />
        </Card.Body>
      </GuardPermission>
      <BankProcessControlPanelForm bankProcess={bankProcess} />
    </Card>
  );
}

interface BankProcessCardOfferTabCardQueryResult {
  process: BankProcessRow;
  bids: BankProcessBidRow[];
}

interface BankProcessCardOfferTabCardProps {
  bankProcess: BankProcessRow;
}

function BankProcessCardOfferTabCard (props: BankProcessCardOfferTabCardProps) {
  const { bankProcess } = props;

  const query = useQuery<BankProcessCardOfferTabCardQueryResult>({
    queryKey: [`/bank_process/${bankProcess.id}/bids`],
    placeholderData: keepPreviousData,
  });

  const bids = query.data?.bids ?? [];

  const bankProcessBid = bankProcess.data?.offer;
  const showBankProcessBid = Boolean(['closed_at', 'accepted', 'disbursed'].includes(bankProcess.status) && bankProcessBid);

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Erbjudanden"
          Icon={<DollarSign size={18} />}
          spinning={query.isFetching || query.isRefetching}
        >
          <IconButtons.InspectObjectModalButton object={{bankProcessBid, bids}} />
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            refetch={query.refetch}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      {query.isSuccess && (
        <Card.Body>

          <GuardPermission permission="sms_create_application_quickcontact">
            <CardQuickButtons title="Snabbkontakt: " className="mb-3">
              <ExecuteButton
                url={`/sms/application/${bankProcess.application_id}/quickcontact/offer_sign_with_portal_link`}
                vars={{hintImportantProcessId: bankProcess.id}}
                variant="secondary"
                size="sm"
                onSuccessNotyf={{type: 'success', message: 'SMS:et skickades'}}
              >
                Skicka SMS med länk till signering i portal
              </ExecuteButton>
            </CardQuickButtons>
          </GuardPermission>

          {showBankProcessBid && (
            <div className="mb-4">
              <h4 className="border-bottom pb-1">
                Processbud{' '}
                <HelperTooltip>
                  Processbudet är det bud som har "lyfts upp" från budlistan till processen. Beroende på långivare så kan det antingen motsvara det accepterade budet, det utbetalda budet, eller helt enkelt bara det senast inkomna budet.
                </HelperTooltip>
              </h4>
              <BankProcessBidSingleCollapsible
                initialOpen
                className="mb-0 mt-3"
                bid={bankProcessBid}
                theirId={bankProcess.their_id}
                ourId={bankProcess.our_id}
              />
            </div>
          )}
          <h4 className="border-bottom pb-1 mb-0">
            Budlista{' '}
            <HelperTooltip>
              Budlistan innehåller en komplett lista över alla bud som inkommit på den här processen.
            </HelperTooltip>
          </h4>
          {bids.length > 0 ? (
            <>
              {bids.map((bid, index) => (
                <BankProcessBidSingleCollapsible
                  initialOpen={index === 0 && !showBankProcessBid}
                  id={bid.id}
                  className="mb-0 mt-3"
                  index={index}
                  key={bid.id}
                  bid={bid.bid}
                  theirId={bid.their_id}
                  ourId={bid.our_id}
                  status={bid.status}
                />
              ))}
            </>
          ) : (
            <p>Det finns inga bud på den här processen</p>
          )}
        </Card.Body>
      )}
    </Card>
  );
}
