import React, {useMemo} from 'react';
import triggerFilter from 'shared/filter/trigger';
import * as TableCells from 'src/tables/TableCells';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';

interface TriggerTablePageProps {
  objectType: string;
  label: string;
  routeUrl: string;
}

export default function TriggerTablePage (props: TriggerTablePageProps) {
  const { objectType, routeUrl, label } = props;

  const columnDefs = useMemo(() => {
    return getColumnsDefs(objectType);
  }, [objectType]);

  const filterDefs = useMemo(() => {
    return getFilterDefs(objectType);
  }, [objectType]);

  return (
    <TablePage
      title={`Triggerkörningar (${label})`}
      pageTitle={`Triggerkörningar (${label})`}
      columnDefinitions={columnDefs}
      filterDefinitions={filterDefs}
      routeUrl={routeUrl}
      baseQueryUrl={`/trigger/${objectType}/table`}
      baseQueryKey={`TriggerTablePage_${objectType}`}
    />
  );
}

const getFilterDefs = (objectType: string): TableFilterDefinition[] => {
  const relatedObjectFilter = getRelatedObjectFilterDef(objectType);
  return [
    commonFilterDefs.uuid({id: 'id'}),
    commonFilterDefs.uuid({id: 'work_trigger_template_id'}),
    commonFilterDefs.triggerStatus({id: 'status'}),
    relatedObjectFilter,
    commonFilterDefs.string({id: 'work_id'}),
    commonFilterDefs.dateRange({id: 'created_at'}),
    commonFilterDefs.dateRelative({id: 'created_at_relative'}),
    commonFilterDefs.dateRange({id: 'scheduled_at'}),
    commonFilterDefs.dateRelative({id: 'scheduled_at_relative'}),
    commonFilterDefs.dateRange({id: 'updated_at'}),
    commonFilterDefs.dateRelative({id: 'updated_at_relative'}),
  ].map(filterDefinition => ({
    title: triggerFilter.indexColumnName(filterDefinition.id),
    ...filterDefinition,
  }));
};

const getColumnsDefs = (objectType: string): TableColumnDefinition[] => {
  const relatedObjectColumn = getRelatedObjectColumnDef(objectType);
  return [
    ...(([
      commonColumnDefs.cell('id', TableCells.IdShortTableCell),
      relatedObjectColumn,
      commonColumnDefs.wrapSearch(commonColumnDefs.cell('work_trigger_template_id', TableCells.WorkTriggerTemplateTableCell, {idKey: 'work_trigger_template_id'})),
      commonColumnDefs.cell('work_id', TableCells.WorkTableCell, {
        idKey: 'work_id',
        objKey: 'Work',
        showStatus: true,
      }),
      commonColumnDefs.cell('task'),
      commonColumnDefs.cell('description'),
      commonColumnDefs.datetime({
        id: 'created_at',
        sortable: true,
        groups: ['dates', 'sortable'],
      }),
      commonColumnDefs.datetime({
        id: 'updated_at',
        sortable: true,
        groups: ['dates', 'sortable'],
      }),
      commonColumnDefs.datetime({
        id: 'scheduled_at',
        sortable: true,
        groups: ['dates', 'sortable'],
      }),
      commonColumnDefs.cell('status', TableCells.TriggerStatusTableCell),
    ] as TableColumnDefinition[]).filter(v => v).map(columnDefinition => {
      const { id } = columnDefinition;
      const header = triggerFilter.indexColumnName(id);
      const cellValue = row => triggerFilter.indexField(row, id);
      return {
        ...columnDefinition,
        Cell: columnDefinition.Cell ?? (({value}) => <>{value}</>),
        cellValue,
        Header: ({value}) => <>{value}</>,
        title: header,
      };
    })),
    commonColumnDefs.actions({}, [
      TableCellActions.InspectRow,
    ]),
  ];
};

function getRelatedObjectFilterDef (objectType: string): null | TableFilterDefinition {
  switch (objectType) {
    default: return null;
    case 'application': return commonFilterDefs.applicationId({id: 'application_id'});
    case 'prospect': return commonFilterDefs.uuid({id: 'prospect_id'});
    case 'task': return commonFilterDefs.uuid({id: 'task_id'});
    case 'jaycom_insurance': return commonFilterDefs.uuid({id: 'jaycom_insurance_id'});
  }
}

function getRelatedObjectColumnDef (objectType: string): null | TableColumnDefinition {
  switch (objectType) {
    default: return null;
    case 'application': return commonColumnDefs.cell('application_id', TableCells.ApplicationTableCell, {idKey: 'application_id'});
    case 'prospect': return commonColumnDefs.cell('prospect_id', TableCells.ProspectTableCell, {idKey: 'prospect_id'});
    case 'task': return commonColumnDefs.cell('task_id', TableCells.TaskTableCell, {idKey: 'task_id'});
    case 'jaycom_insurance': return commonColumnDefs.cell('jaycom_insurance_id', TableCells.CustomerJaycomInsuranceTableCell, {idKey: 'jaycom_insurance_id'});
  }
}
