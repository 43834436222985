import React, {useState} from 'react';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import * as IconButtons from 'src/buttons/IconButtons';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { Smartphone, Trash } from 'lucide-react';
import { SmsRow } from 'shared/types/sms';
import {Card} from 'react-bootstrap';
import TemplateQuickCreateForm from 'src/template/TemplateQuickCreateForm';
import {GuardPermission} from 'src/guards/AuthGuards';
import {PermissionDefinition} from 'src/contexts/AuthContext';
import { TableStateOrder  } from 'src/tables/types';
import { TableContextProvider } from 'src/tables/TableContext';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCells from 'src/tables/TableCells';
import * as TableCellActions from 'src/tables/TableCellActions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import {useTableUpdateRowMutation} from 'src/hooks/useTableUtils';
import { useEventModelSubscribeToQuery } from 'src/hooks/useEventModels';
import classNames from 'classnames';

interface SmsContactControlCardProps {
  className?: string;
  createPermission?: PermissionDefinition;
  searchParams: Record<string, any>;
  searchTemplateParams: Record<string, any>;
  objectType: string;
  objectId: string;
}

export default function SmsContactControlCard (props: SmsContactControlCardProps) {
  const { className, searchParams, searchTemplateParams, createPermission, objectId, objectType } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const queryKey = ['/sms/search', {params: {...searchParams, order}}];
  const query = useQuery<SmsRow[], Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  useEventModelSubscribeToQuery({queryKey: [queryKey[0]]}, 'sms');

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
    onSuccess: () => {
      query.refetch();
    },
  });

  const reload = () => {
    query.refetch();
  };

  const rows = query.data ?? [];

  return (
    <Card className={classNames('flex-fill w-100', className)}>
      <Card.Header className="border-bottom-0">
        <CardTitleIcon
          title="SMS"
          Icon={<Smartphone size={18} />}
          spinning={query.isRefetching}
        >
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={reload}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
        <CardBodyTable
          striped
          order={order}
          columnDefinitions={columnDefs}
          setOrder={setOrder}
          rows={rows}
          isFetched={query.isFetched}
        />
      </TableContextProvider>
      {createPermission && (
        <GuardPermission permission={createPermission}>
          <Card.Footer className="position-relative border-top-0">
            <TemplateQuickCreateForm
              objectType={objectType}
              objectId={objectId}
              category="sms"
              searchParams={searchTemplateParams}
              onCreated={reload}
            />
          </Card.Footer>
        </GuardPermission>
      )}
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.smsId({
    id: 'id',
    title: 'SMS',
    cellProps: {rowAsObject: true},
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.SmsStatusTableCell,
  }),
  commonColumnDefs.basic({
    id: 'gsm',
    title: 'Till',
  }),
  commonColumnDefs.datetime({
    id: 'status_at',
    title: 'Statustid',
    sortable: true,
    cellProps: {emptyValue: '-'},
  }),
  commonColumnDefs.actions({}, [
    props => (
      <GuardPermission permission="sms_execute">
        {props.row.status === 'new' && (
          <TableCellActions.ExecuteButton
            {...props}
            title="Skicka SMS:et"
            mutateVars={{
              rowId: String(props.row.id),
              url: `/sms/${props.row.id}/send`,
              method: 'POST',
              onSuccessNotyf: {type: 'default', message: 'SMS:et har köats för skickning'},
            }}
          >
            Skicka
          </TableCellActions.ExecuteButton>
        )}
      </GuardPermission>
    ),
    props => (
      <GuardPermission permission="sms_delete">
        <TableCellActions.ExecuteModalConfirmButton
          {...props}
          title="Radera SMS:et"
          variant="outline-danger"
          modalProps={{message: 'Är du säker på att du vill radera SMS:et?'}}
          mutateVars={{
            rowId: String(props.row.id),
            url: `/sms/${props.row.id}`,
            deletion: true,
            method: 'DELETE',
            onSuccessNotyf: {type: 'warning', message: 'SMS:et har raderats'},
          }}
        >
          <span><Trash size={14} /></span>
        </TableCellActions.ExecuteModalConfirmButton>
      </GuardPermission>
    ),
    TableCellActions.InspectRow,
  ]),
];
