import React, {useCallback, useMemo} from 'react';
import { isEqual } from 'lodash';
import {FieldValidator, FormikErrors, FormikHelpers, useField} from 'formik';
import { maybeAxiosErrorToErrorMap } from 'src/utils/error';
import {AxiosError} from 'axios';
import moment from 'moment';
import { emailRegExp } from 'shared/constants';
import {isFiniteNumber} from 'src/numbers';

export function emptyFormikOnSubmit () {
}

// returns all fields in obj that exist in base, but has a changed value in obj
export function changes <T> (base: T, obj: Partial<T>): Partial<T> {
  const changes = {} as Partial<T>;
  for (const baseKey in base) {
    if (baseKey in obj && !isEqual(base[baseKey], obj[baseKey])) {
      changes[baseKey] = obj[baseKey];
    }
  }
  return changes;
}

// just like changes() but returns all fields in obj that are changed from base
// howeever iterates of keys from obj instead of base
export function changesInverted <T> (base: T, obj: Partial<T>): Partial<T> {
  const changes = {} as Partial<T>;
  for (const key in obj) {
    if (!isEqual(base[key], obj[key])) {
      changes[key] = obj[key];
    }
  }
  return changes;
}

// returns all fields in b and a that are different from the other object
// NOTE NOT the same behavior as changes() above
export function allChanges (a: object, b: object): object {
  const changes = {};

  for (const aKey in a) {
    if (!isEqual(a[aKey], b[aKey])) {
      changes[aKey] = a[aKey];
    }
  }

  for (const bKey in b) {
    if (!isEqual(a[bKey], b[bKey])) {
      changes[bKey] = b[bKey];
    }
  }

  return changes;
}

// form values never want null values, but many database columns are nullable
// this function replaces all nulls with empty strings
export function rowToFormValues (row: Record<string, any>): Record<string, any> {
  const result = {};

  for (const key in row) {
    const value = row[key];
    if (value === null) result[key] = '';
    else result[key] = value;
  }

  return result;
}

// returns the number of lines in the string
// may be used to set the "rows" attribute of a textarea to be adaptive
export function numberOfLines (str: string): number {
  return str.split('\n').length;
}

interface InputAttributes {
  disabled?: boolean;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  min?: string | number;
  max?: string | number;
  step?: number;
  pattern?: string;
  customValidate?: FieldValidator;
}

export function inputPropsToFormikValidate (attrs: InputAttributes): FieldValidator {
  const { type, disabled, required, min, max, /* step,  */minLength, maxLength, pattern, customValidate } = attrs;
  return function (value) {
    if (disabled) return '';
    if (!required && [null, '', undefined].includes(value)) return '';
    if (required && [null, '', undefined].includes(value)) return 'Värdet måste anges';
    if (typeof minLength === 'number' && minLength > 0 && value?.length < minLength) return `Värdet måste vara minst ${minLength} tecken långt`;
    if (typeof maxLength === 'number' && maxLength > 0 && value?.length > maxLength) return `Värdet får vara högst ${maxLength} tecken långt`;
    if (type === 'number') {
      if (typeof min === 'number' && value < min) return `Värdet måste vara lägst ${min}`;
      if (typeof max === 'number' && value > max) return `Värdet får vara högst ${max}`;
      // if (typeof step === 'number' && (value % step !== 0)) {
      //   return `Värdet måste vara delbart med ${step}`;
      // }
    } else if (type === 'date') {
      const date = moment(value, 'YYYY-MM-DD');
      if (!date.isValid()) return required ? 'Datumet är ogiltigt' : '';
      if (typeof min === 'string' && moment(min, 'YYYY-MM-DD').isAfter(date)) return `Datumet måste vara efter ${min}`;
      if (typeof max === 'string' && moment(max, 'YYYY-MM-DD').isBefore(date)) return `Datumet måste vara innan ${max}`;
    } else if (type === 'email') {
      if (!emailRegExp.test(value)) return 'E-postadressen är ogiltig';
    } else if (type === 'tel') {
      if (!/^\d+$/.test(value)) return 'Telefonnumret får bara bestå av siffror';
    }
    if (typeof pattern === 'string') {
      const regExp = new RegExp(pattern);
      if (!regExp.test(value)) return `Värdet måste matcha önskat format: ${pattern}`;
    }
    if (customValidate) return customValidate(value);
    return '';
  };
}

export function inputPropsToValue (attrs: InputAttributes, outerValue: unknown): object | boolean | string | number {
  const { type } = attrs;
  if (outerValue === null) return '';
  if (typeof outerValue === 'undefined') return '';
  if (type === 'number') {
    if (typeof outerValue === 'number') return outerValue;
    if (typeof outerValue === 'string') {
      const parsed = parseFloat(outerValue);
      if (isFiniteNumber(parsed)) return parsed;
    }
    return '';
  } else if (type === 'date') {
    const date = moment(outerValue);
    if (date.isValid()) return date.format('YYYY-MM-DD');
    return '';
  }
  return outerValue as any;
  // return String(outerValue);
}

// interface UseFieldExtendedConfig extends Formik.Fie {
//   emptyValue: any;
// }

export function useFieldExtended (config: any): ReturnType<typeof useField> {
  const { emptyValue, ...restOfConfig } = config;

  const [outerField, meta, helpers] = useField(restOfConfig as any);

  const onChangeOuter = outerField.onChange;

  const onChange: React.ChangeEventHandler<HTMLFormElement> = useCallback(ev => {
    if (typeof emptyValue !== 'undefined' && ev.target.value === '') {
      helpers.setValue(emptyValue);
      return;
    }
    onChangeOuter(ev);
  }, [helpers, onChangeOuter, emptyValue]);

  const result: ReturnType<typeof useField> = useMemo(() => {
    return [{...outerField, onChange}, meta, helpers];
  }, [outerField, onChange, meta, helpers]);

  return result;
}

interface SelectAttributes {
  disabled?: boolean;
  required?: boolean;
  customValidate?: FieldValidator;
}

export function selectPropsToFormikValidate (attrs: SelectAttributes): FieldValidator {
  const { required, customValidate, disabled } = attrs;
  return function (value) {
    if (disabled) return '';
    if (required && !value) return 'Värdet måste anges';
    if (customValidate) return customValidate(value);
    return '';
  };
}

interface CheckAttributes {
  disabled?: boolean;
  required?: boolean;
  customValidate?: FieldValidator;
}

export function checkPropsToFormikValidate (attrs: CheckAttributes): FieldValidator {
  const { disabled, customValidate, required } = attrs;
  return function (value) {
    if (disabled) return '';
    if (required && !value) return 'Värdet måste väljas';
    if (customValidate) return customValidate(value);
    return '';
  };
}

// the basic form cycle works like this:
// 1. an object of type QueryData is read from the server (via useQuery or useMutation)
// 2. the object is converted to form values of type FormValues
// 3. the form values are converted to an update (mutationVars for a useMutation)
// 4. the useMutation is executed and it is expected to return an object of type QueryData
// 5. the cycle repeats at step 1
interface FormikFormCycleHelperOptions <QueryData, FormValues, MutationVars> {
  queryDataToFormValues: (data: QueryData) => FormValues;
  formValuesToMutationVars?: (formValues: FormValues) => MutationVars;
  mutateAsync: (vars: MutationVars) => Promise<QueryData>;
  skipUpdateWhenEmpty?: boolean;
}

export function getFormikFormCycleHelpers <QueryData = Record<string, any>, FormValues = Record<string, any>, MutationVars = Record<string, any>> (options: FormikFormCycleHelperOptions<QueryData, FormValues, MutationVars>) {
  const {
    queryDataToFormValues,
    formValuesToMutationVars = formValues => formValues as unknown as MutationVars,
    mutateAsync,
    skipUpdateWhenEmpty = true,
  } = options;

  const onSubmit = async function (newFormValues: FormValues, formHelpers: FormikHelpers<FormValues>) {
    const update = formValuesToMutationVars(newFormValues);

    if (skipUpdateWhenEmpty && !Object.keys(update as any).length) {
      return;
    }

    try {
      const queryData = await mutateAsync(update);
      formHelpers.resetForm({values: queryDataToFormValues(queryData)});
      formHelpers.validateForm();
    } catch (err) {
      formHelpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err as AxiosError);
      if (errorMap) {
        formHelpers.setErrors(errorMap as FormikErrors<FormValues>);
        return;
      }
      throw err;
    }
  };

  return {
    onSubmit,
  };
}
