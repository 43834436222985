import React, {useState} from 'react';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { ApplicationLogRowWithRelations } from 'shared/types/application_log';
import * as applicationLogFilters from 'shared/filter/application_log';
import * as bankProcessLogFilters from 'shared/filter/bank_process_log';
import { TableStateOrder  } from 'src/tables/types';
import { Card } from 'react-bootstrap';
import { Activity } from 'lucide-react';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as TableCells from 'src/tables/TableCells';
import {TableColumnCellProps, TableFilterDefinition} from 'src/tables/Table';
import {AuthContextValue} from 'src/contexts/AuthContext';
import useAuth from 'src/hooks/useAuth';
import BankProcessAnchor from 'src/bankProcess/BankProcessAnchor';

const defaultFilter = {
  combined_log: '',
};

interface ApplicationLogCardProps {
  className?: string;
  applicationId: string;
}

interface ApplicationLogCardFilter {
  combined_log: string;
}

export default function ApplicationLogCard (props: ApplicationLogCardProps) {
  const { applicationId, className } = props;
  const auth = useAuth();

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});
  const [filter, setFilter] = useState<ApplicationLogCardFilter>(defaultFilter);

  const filterDefs = getFilterDefs(auth);
  const columnDefs = getColumnDefs(filter.combined_log === 'combined');

  const url = `/application/${applicationId}/${filter.combined_log === 'combined' ? 'combined_log' : 'log'}`;

  const query = useQuery<ApplicationLogRowWithRelations[], Error>({
    queryKey: [url, {params: {order}}],
    placeholderData: keepPreviousData,
  });

  const rows = query.data ?? [];

  return (
    <Card className={className}>
      <Card.Header className="border-bottom-0">
        <CardTitleIcon
          title="Ansökningslogg"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        order={order}
        columnDefinitions={columnDefs}
        filterDefinitions={filterDefs}
        filter={filter}
        setFilter={setFilter}
        setOrder={setOrder}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const getColumnDefs = (combinedLog: boolean) => [
  commonColumnDefs.datetime({
    id: 'date',
    title: 'Datum',
    sortable: true,
  }),
  !combinedLog ? null : commonColumnDefs.basic({
    Cell: TypeTableCell,
    id: 'type',
    title: 'Objektstyp',
  }),
  commonColumnDefs.userId({
    id: 'user_id',
    title: 'Utförare',
    sortable: true,
    cellProps: {idKey: 'user_id', objKey: 'User', value: 'System'},
  }),
  commonColumnDefs.basic({
    id: 'data.ip',
    title: 'IP',
    Cell: TableCells.IpWithCountryFlag,
    cellProps: {ipCountryKey: 'data.ip_country'},
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    Cell: props => (
      <span>
        {props.row.type === 'bank_process' ? (
          <span>{bankProcessLogFilters.description(props.row)}</span>
        ) : (
          <span>{applicationLogFilters.description(props.row)}</span>
        )}
      </span>
    ),
  }),
  commonColumnDefs.inspect(),
].filter(v => v);

function TypeTableCell (props: TableColumnCellProps) {
  const { row } = props;
  if (row.type === 'bank_process') {
    const { bank_process_id:id, bank_id, application_id } = row;
    return <BankProcessAnchor bankProcess={{id, bank_id, application_id}} />;
  }
  return (
    <span>Ansökan</span>
  );
}

const getFilterDefs = (auth: AuthContextValue): TableFilterDefinition[] => {
  if (!auth.permission('application_read_combined_log')) return undefined;
  return [
    commonFilterDefs.select({
      id: 'combined_log',
      title: 'Kombinerad logg',
      filterProps: {
        placeholder: 'Bara ansökningsloggen',
        options: [
          {value: 'combined', label: 'Kombinerad med processlogg'},
        ],
      },
    }) as any,
  ];
};
