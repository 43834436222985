import React, {useState} from 'react';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {Button, Card, Collapse} from 'react-bootstrap';
import {Thermometer} from 'lucide-react';
import {ApplicationRow} from 'shared/types/application';
import {PepQueryRow} from 'shared/types/pep_query';
import {keepPreviousData, useMutation, useQuery} from '@tanstack/react-query';
import * as IconButtons from 'src/buttons/IconButtons';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import DateTime from 'src/formatters/DateTime';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import useAuth from 'src/hooks/useAuth';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import {GuardPermission} from 'src/guards/AuthGuards';
import * as PepQueryFormatters from 'src/pepQuery/PepQueryFormatters';
import * as PepQueryBlocks from 'src/pepQuery/PepQueryBlocks';
import delay from 'delay';

interface ApplicationPepQueryCardProps {
  applicationId: ApplicationRow['id'];
}

export default function ApplicationPepQueryCard (props: ApplicationPepQueryCardProps) {
  const { applicationId } = props;

  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const onClickToggleCreateForm = () => {
    setShowCreateForm(showCreateForm => !showCreateForm);
  };

  const notyf = useNotyf();
  const auth = useAuth();

  const query = useQuery<PepQueryRow[]>({
    queryKey: [`/pep/application/${applicationId}`],
    placeholderData: keepPreviousData,
  });

  const createAndSubmitMutation = useMutation<PepQueryRow, Error, void>({
    mutationFn: async () => {
      // create
      let pepQuery = await api.request<PepQueryRow>({
        errorToNotyf: notyf,
        url: `/pep/application/${applicationId}`,
        method: 'POST',
        data: {},
      });

      // execute submit
      pepQuery = await api.request<PepQueryRow>({
        url: `/pep/${pepQuery.id}/submit`,
        method: 'POST',
        data: {},
      });

      await delay(400); // give the response a chance to come
      await query.refetch();
      notyf.success({type: 'success', message: 'PEP-kontrollen utfördes'});

      setShowCreateForm(false);

      return pepQuery;
    },
  });

  const onClickSubmitAndCreate = async () => {
    return await createAndSubmitMutation.mutateAsync();
  };

  const pepQueryList = query.data ?? [];
  const hasPepQuery = pepQueryList.length > 0;

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Kontroll av PEP/Trapets"
          Icon={<Thermometer size={18} />}
        >
          <GuardPermission permission="sanction_list_match_application">
            <IconButtons.CreateButton onClick={onClickToggleCreateForm} />
          </GuardPermission>
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            refetch={query.refetch}
          />
        </CardTitleIcon>
      </Card.Header>
      <GuardPermission permission={['pep_create_by_application', 'pep_execute:submit']}>
        <Collapse in={showCreateForm}>
          <div>
            <div className="border-bottom p-3 d-flex justify-content-center">
              <ButtonPromiseSpinner
                onClick={onClickSubmitAndCreate}
                variant="success"
                disabled={createAndSubmitMutation.isPending || (hasPepQuery ? !auth.permission('admin') : false)}
              >
                Utför kontroll på huvudsökande{hasPepQuery ? <span> igen</span> : ''}
              </ButtonPromiseSpinner>
            </div>
          </div>
        </Collapse>
      </GuardPermission>
      {query.isSuccess && (
        <Card.Body className="p-2 pb-0">
          {pepQueryList.length > 0 ? (
            <>
              {pepQueryList.map(pepQuery => (
                <PepQueryCard key={pepQuery.id} className="mb-2" pepQuery={pepQuery} />
              ))}
            </>
          ) : (
            <p className="mb-2 text-center text-muted">Ansökan har ej kontrollerats mot Trapets.</p>
          )}
        </Card.Body>
      )}
    </Card>
  );
}

interface PepQueryCardProps {
  pepQuery: PepQueryRow;
  className?: string;
}

function PepQueryCard (props: PepQueryCardProps) {
  const { className, pepQuery } = props;

  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const onClickShowMoreInfo = () => {
    setShowMoreInfo(showMoreInfo => !showMoreInfo);
  };

  return (
    <Card className={className}>
      <Card.Header className="p-2">
        <div className="d-flex gap-2 align-items-center flex-wrap justify-content-between">
          <div className="d-flex gap-2 flex-wrap flex-grow-1">
            <PepQueryFormatters.StatusResultLabel
              value={pepQuery}
              style={{fontSize: '80%', minWidth: '40%'}}
              short
            />
            <DateTime className="small" value={pepQuery.trapets_doquery_result_at} format="YYYY-MM-DD" />
          </div>
          <div className="d-flex gap-1 flex-wrap">
            <Button className="py-0" variant="outline-info" size="sm" onClick={onClickShowMoreInfo}>
              {showMoreInfo ? <span>Dölj</span> : <span>Mer</span>}
            </Button>
            <InspectObjectModalButton object={pepQuery} size="sm" className="py-0" />
          </div>
        </div>
      </Card.Header>
      <Collapse in={showMoreInfo}>
        <div>
          <Card.Body className="border-top p-0">
            <PepQueryBlocks.ResultTable pepQuery={pepQuery} className="no-border-bottom" />
            {pepQuery.trapets_doquery_result?.individuals && (
              <PepQueryBlocks.ResultIndividualTable
                className="border-top"
                individuals={pepQuery.trapets_doquery_result.individuals}
              />
            )}
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
}

