

/**
 * WARNING: This file is used clientside
 */

exports.status = function (status) {
  switch (status) {
    default: return '-';
    case 'active': return 'Aktivt bud';
    case 'inactive': return 'Inaktivt bud';
  }
};
