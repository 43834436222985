import React from 'react';
import ButtonSpinner, { ButtonSpinnerProps } from 'src/spinners/ButtonSpinner';
import { useMutation } from '@tanstack/react-query';
import useNotyf from 'src/hooks/useNotyf';
import * as api from 'src/api';
import { AxiosRequestConfig } from 'axios';
import { NotyfNotification } from 'src/contexts/NotyfContext';

export interface ExecuteButtonProps extends ButtonSpinnerProps {
  url: string;
  vars?: Record<string, any>;
  method?: AxiosRequestConfig['method'];
  onSuccess?: (result: unknown) => void;
  onSuccessNotyf?: NotyfNotification;
}

export default function ExecuteButton (props: ExecuteButtonProps) {
  const {
    url,
    vars = {},
    children,
    disabled,
    onSuccess,
    onSuccessNotyf,
    method = 'POST',
    ...restOfProps
  } = props;

  const notyf = useNotyf();

  const mutation = useMutation<unknown, Error, Record<string, any>>({
    mutationFn: async vars => {
      const result = await api.request({
        url,
        method,
        data: vars,
        errorToNotyf: notyf,
      });
      if (onSuccessNotyf) notyf.open(onSuccessNotyf);
      onSuccess?.(result);
      return result;
    },
  });

  const onClick = () => {
    mutation.mutateAsync(vars);
  };

  return (
    <ButtonSpinner
      {...restOfProps}
      isLoading={mutation.isPending}
      disabled={disabled || mutation.isPending}
      onClick={onClick}
    >
      {children}
    </ButtonSpinner>
  );
}
