import React from 'react';
import { INotyfNotificationOptions, Notyf } from 'notyf';

export type NotyfNotification = Partial<INotyfNotificationOptions> & Pick<INotyfNotificationOptions, 'message' | 'type'>;

const notyf = new Notyf({
  duration: 3000,
  position: {x: 'center', y: 'top'},
  dismissible: true,
  ripple: false,
  types: [
    {
      type: 'default',
      backgroundColor: '#3B7DDD',
      icon: {
        className: 'notyf__icon--success',
        tagName: 'i',
      },
    },
    // should be same as default
    {
      type: 'primary',
      backgroundColor: '#3B7DDD',
      icon: {
        className: 'notyf__icon--success',
        tagName: 'i',
      },
    },
    {
      type: 'success',
      backgroundColor: '#28a745',
      icon: {
        className: 'notyf__icon--success',
        tagName: 'i',
      },
    },
    {
      type: 'warning',
      backgroundColor: '#ffc107',
      icon: {
        className: 'notyf__icon--error',
        tagName: 'i',
      },
    },
    {
      type: 'danger',
      backgroundColor: '#dc3545',
      icon: {
        className: 'notyf__icon--error',
        tagName: 'i',
      },
    },
  ],
});

export default React.createContext(notyf);
