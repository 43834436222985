import React from 'react';
import { UserRow } from 'shared/types/user';
import AssociatedObjectList from 'src/components/AssociatedObjectList';
import {oldCrmUrl} from 'src/misc';

interface UserAssociatedObjectListProps {
  user: UserRow;
}

function UserAssociatedObjectList (props: UserAssociatedObjectListProps) {
  const { user } = props;

  const externalLinks = [
    {url: oldCrmUrl(`/user/${user.id}/props`), label: 'Gamla CRM'},
  ].filter(v => v);

  const associatedObjects = [
    user.bank_id ? {
      type: 'bank',
      value: {id: user.bank_id},
      label: 'Bank',
    } : null,
  ].filter(v => v);

  return (
    <AssociatedObjectList
      associatedObjects={associatedObjects}
      externalLinks={externalLinks}
    />
  );
}
export default React.memo(UserAssociatedObjectList);
