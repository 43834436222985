import React from 'react';
import { omit } from 'lodash';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { WorkTriggerTemplateRow, WorkTriggerTemplateRowForm } from 'shared/types/work_trigger_template';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import WorkTriggerTemplateForm, {formValuesToUpdate, workTriggerTemplateToFormValues} from 'src/workTriggerTemplate/WorkTriggerTemplateForm';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { StatusLabel } from 'src/workTriggerTemplate/WorkTriggerTemplateFormatters';
import { useEventModelEmitCreates, useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';
import useModalStateContext from 'src/hooks/useModalStateContext';
import * as formUtils from 'src/utils/form';
import {useNavigate} from 'react-router';

interface WorkTriggerTemplateModalBodyProps {
  workTriggerTemplateId: string;
}

function WorkTriggerTemplateModalBody (props: WorkTriggerTemplateModalBodyProps) {
  const { workTriggerTemplateId:id } = props;

  const modalStateContext = useModalStateContext();
  const notyf = useNotyf();
  const navigate = useNavigate();

  const queryKey = [`/work_trigger_template/${id}`];
  const query = useQuery<WorkTriggerTemplateRow | null, Error>({
    queryKey,
  });

  const queryClient = useQueryClient();

  const emitUpdate = useEventModelEmitUpdates<WorkTriggerTemplateRow>('work_trigger_template');
  const emitCreate = useEventModelEmitCreates<WorkTriggerTemplateRow>('work_trigger_template');
  const emitDelete = useEventModelEmitDeletes<WorkTriggerTemplateRow>('work_trigger_template');

  const updateMutation = useMutation<WorkTriggerTemplateRow, Error, Partial<WorkTriggerTemplateRow>>({
    mutationFn: vars => api.request({
      url: `/work_trigger_template/${id}`,
      method: 'PATCH',
      data: vars,
    }),
    onSuccess: obj => {
      notyf.success({type: 'default', message: 'Triggermallen uppdaterades'});
      emitUpdate(obj);
      modalStateContext.onHide();
      queryClient.setQueryData(queryKey, obj);
    },
  });

  const cloneMutation = useMutation<WorkTriggerTemplateRow, Error, Omit<WorkTriggerTemplateRow, 'id'>>({
    mutationFn: async data => {
      const workTriggerTemplate = await api.request({
        url: '/work_trigger_template',
        method: 'POST',
        data,
      });

      notyf.success({type: 'success', message: 'Triggermallen klonades. Du redigerar nu kopian.'});
      emitCreate(workTriggerTemplate);

      modalStateContext.onHide();
      navigate(`/work_trigger_templates/${workTriggerTemplate.id}`);

      return workTriggerTemplate;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => api.request({
      url: `/work_trigger_template/${id}`,
      method: 'DELETE',
    }),
    onSuccess: () => {
      notyf.success({type: 'danger', message: 'Triggermallen raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onClone = async (values: WorkTriggerTemplateRowForm) => {
    const clone = omit({
      ...values,
      is_active_from: null,
      is_active_to: null,
      description: values.description + ' (klon)',
      is_active: false,
    }, 'id', 'created_at', 'updated_at');
    return cloneMutation.mutateAsync(clone as any);
  };

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const workTriggerTemplate = query.data;

  const initialValues = workTriggerTemplate ? workTriggerTemplateToFormValues(workTriggerTemplate) : {};

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<WorkTriggerTemplateRow, WorkTriggerTemplateRowForm, Partial<WorkTriggerTemplateRow>>({
    queryDataToFormValues: workTriggerTemplateToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues as any, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="my-3" />
      <BlockSpinner isLoading={query.isLoading || query.isRefetching} className="m-3" />
      {workTriggerTemplate && (
        <>
          <StatusLabelContainer className="mt-3">
            <StatusLabel value={workTriggerTemplate.is_active} />
          </StatusLabelContainer>
          {!query.isRefetching && (
            <WorkTriggerTemplateForm
              initialValues={initialValues as any}
              onSubmit={formCycleHelpers.onSubmit}
              submitError={updateMutation.error}
              onClone={onClone}
              onDelete={onDelete}
            />
          )}
          <ErrorAlert error={cloneMutation.error} className="m-3" />
          <ErrorAlert error={deleteMutation.error} className="m-3" />
        </>
      )}
    </div>
  );
}
export default React.memo(WorkTriggerTemplateModalBody);
