import React from 'react';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as api from 'src/api';
import { CustomerJaycomInsuranceRow } from 'shared/types/customer_jaycom_insurance';
import { Card } from 'react-bootstrap';
import { LifeBuoy } from 'lucide-react';
import * as IconButtons from 'src/buttons/IconButtons';
import useNotyf from 'src/hooks/useNotyf';
import {GuardPermission} from 'src/guards/AuthGuards';
import {useNavigate} from 'react-router-dom';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';

// TODO decide if we really want to use a "colored" button in the header for creation

interface JaycomInsuranceListCardProps {
  searchParams: Record<string, any>;
  createParams?: Record<string, any>;
  className?: string;
}

export default function JaycomInsuranceListCard (props: JaycomInsuranceListCardProps) {
  const { createParams, searchParams, className } = props;

  const notyf = useNotyf();
  const navigate = useNavigate();

  const query = useQuery<CustomerJaycomInsuranceRow[], Error>({
    queryKey: ['/customer_jaycom_insurance/search', {params: searchParams}],
  });

  const createMutation = useMutation<CustomerJaycomInsuranceRow, Error, void>({
    mutationFn: () => api.request({
      url: '/customer_jaycom_insurance',
      method: 'POST',
      data: createParams,
    }),
    onSuccess: insurance => {
      query.refetch().then(() => {
        return navigate(`/customer_jaycom_insurance/${insurance.id}/form`);
      });
      notyf.success({type: 'success', message: 'Försäkringen skapades'});
    },
  });

  const onConfirmCreate = () => {
    return createMutation.mutateAsync();
  };

  const reload = () => {
    query.refetch();
  };

  const rows = query.data ?? [];

  return (
    <Card className={className}>
      <Card.Header className="border-bottom-0">
        <CardTitleIcon
          title="Försäkringar"
          Icon={<LifeBuoy size={18} />}
          spinning={query.isRefetching}
        >
          <GuardPermission permission="customer_jaycom_insurance_create">
            <IconButtons.ConfirmModalCreateButton
              message="Är du säker på att du vill skapa en ny försäkring på kunden?"
              onConfirm={onConfirmCreate}
            />
          </GuardPermission>
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={reload}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        tableClassName="no-border-bottom"
        striped
        columnDefinitions={columnDefs}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.customerJaycomInsuranceId({
    id: 'id',
    title: 'ID',
    cellProps: {rowAsObject: true},
  }),
  commonColumnDefs.datetime({
    id: 'created_at',
    title: 'Skapad',
  }),
  commonColumnDefs.userId({
    id: 'created_by_user_id',
    title: 'Skapare',
    cellProps: {rowKey: 'created_by_user_id', objKey: 'Creator'},
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.CustomerJaycomInsuranceStatusTableCell,
  }),
  commonColumnDefs.inspect(),
].filter(v => v);
