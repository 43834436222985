
exports.objectType = objectType => {
  switch (objectType) {
    default: return objectType;
    case 'application': return 'Ansökan';
    case 'task': return 'Återkomst';
    case 'jaycom_insurance': return 'Försäkring (Jaycom)';
    case 'prospect': return 'Prospekt';
  }
};
