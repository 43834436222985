import React, {useRef, useState} from 'react';
import { useDebounce } from 'use-debounce';
import { useQuery, keepPreviousData, QueryFunctionContext, useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import classNames from 'classnames';
import {X} from 'lucide-react';

export interface QuickSelectState {
  results: any[];
  selectedIndex: number;
  hasValidSelection: boolean;
  onSelectCurrentIndex: () => void;
  onSelectIndex: (index: number) => void;
}

interface QuickSelectProps {
  className?: string;
  placeholder?: string;
  onSelect: (value: any) => void;
  queryKeyBase: string;
  queryFn: (ctx: QueryFunctionContext) => Promise<any[]>;
  children: (state: QuickSelectState) => React.ReactNode;
  after?: (state: QuickSelectState) => React.ReactNode;
  before?: (state: QuickSelectState) => React.ReactNode;
}

export default function QuickSelect (props: QuickSelectProps) {
  const {
    className,
    placeholder = 'Sök',
    onSelect:onSelectOuter,
    queryKeyBase,
    queryFn,
    children,
    after,
    before,
  } = props;

  const queryClient = useQueryClient();

  const [input, setInput] = useState<string>('');
  const [focused, setFocused] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const onChangeInput: React.ChangeEventHandler<HTMLInputElement> = ev => {
    setInput(ev.target.value);
  };

  const onFocusInput: React.FocusEventHandler<HTMLInputElement> = () => {
    setFocused(true);
  };

  const onBlurInput: React.FocusEventHandler = ev => {
    const { relatedTarget } = ev;
    if (outerRef.current.contains(relatedTarget)) {
      return;
    }
    setFocused(false);
  };

  const onSelect = result => {
    onSelectOuter(result);
    const list: any[] = queryClient.setQueryData([queryKeyBase, inputDebounced], (list: any[]) => {
      return list.filter(item => item !== result);
    });
    inputRef.current.focus();
    setSelectedIndex(Math.min(results.length - 2, selectedIndex));
    if (!list.length) {
      resetQuery();
    }
  };

  const onSelectCurrentIndex = () => {
    if (!results[selectedIndex]) return;
    onSelect(results[selectedIndex]);
  };

  const onSelectIndex = (index: number) => {
    if (!results[index]) return;
    onSelect(results[index]);
  };

  const resetQuery = () => {
    setInput('');
    setSelectedIndex(0);
  };

  const onKeyDownInput: React.KeyboardEventHandler<HTMLInputElement> = ev => {
    switch (ev.key) {
      default: return;
      case 'Escape':
        resetQuery();
        ev.preventDefault();
        ev.stopPropagation();
        break;
      case 'Enter': {
        const chosenResult = results[selectedIndex];
        if (!chosenResult) return;
        onSelect(chosenResult);
        ev.preventDefault();
        ev.stopPropagation();
      } break;
      case 'ArrowUp':
        setSelectedIndex(Math.max(0, selectedIndex - 1));
        ev.preventDefault();
        ev.stopPropagation();
        break;
      case 'ArrowDown':
        setSelectedIndex(Math.min(queryObject?.data?.length ?? 0, selectedIndex + 1));
        ev.preventDefault();
        ev.stopPropagation();
        break;
    }
  };

  const [inputDebounced] = useDebounce<string>(input, 500);

  const queryObject = useQuery<any[]>({
    placeholderData: keepPreviousData,
    queryKey: [queryKeyBase, inputDebounced],
    queryFn,
  });

  const hasInput = input.length > 0;
  const results = queryObject?.data ?? [];

  const isLoading = queryObject.isFetching || queryObject.isRefetching;

  const theClassName = classNames(
    className,
    'quickselect',
    {
      'show-results': focused,
      'has-query': hasInput,
    },
  );

  const outerRef = useRef<null | HTMLDivElement>(null);
  const inputRef = useRef<null | HTMLInputElement>(null);

  const state = {
    results,
    selectedIndex,
    hasValidSelection: Boolean(results[selectedIndex]),
    onSelectCurrentIndex,
    onSelectIndex,
  };

  return (
    <div className={theClassName} ref={outerRef}>
      <InputGroup>
        {before?.(state)}
        <Form.Control
          name="input"
          onFocus={onFocusInput}
          onBlur={onBlurInput}
          placeholder={placeholder}
          onChange={onChangeInput}
          onKeyDown={onKeyDownInput}
          value={input}
          ref={inputRef}
          size="sm"
        />
        <Button className="px-1" size="sm" variant="outline-primary" onClick={resetQuery} disabled={!hasInput}>
          <X size={16} />
        </Button>
        {after?.(state)}
      </InputGroup>
      {focused && hasInput ? (
        <div className="results border rounded mt-1 p-2 pb-0 bg-white">
          {isLoading && (
            <div className="d-flex justify-content-center align-items-center mb-2">
              <Spinner size="sm" />
            </div>
          )}
          {(results?.length ?? 0) > 0 && (
            <ol className="list-unstyled m-0 p-0">
              {children(state)}
            </ol>
          )}
          {!results.length && !isLoading && (
            <div className="text-center mb-2 text-muted small">
              Inga sökresultat
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
