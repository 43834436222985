import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { GroupRow } from 'shared/types/group';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as GroupFormFields from 'src/group/GroupFormFields';

export interface GroupCreateFormValues extends Pick<GroupRow, 'name'> {
}

interface GroupCreateFormProps {
  className?: string;
  initialValues: GroupCreateFormValues;
  onSubmit: (values: GroupCreateFormValues, helpers: FormikHelpers<GroupCreateFormValues>) => Promise<any>;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function GroupCreateForm (props: GroupCreateFormProps) {
  const {
    initialValues,
    submitError,
    onSubmit,
    disabled,
    className,
  } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <Row>
              <Col>
                <GroupFormFields.Name disabled={disabled} />
              </Col>
            </Row>
          </div>
          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
