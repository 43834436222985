import React from 'react';
import ButtonSpinner, { ButtonSpinnerProps } from 'src/spinners/ButtonSpinner';

// TODO move into src/buttons

export interface ButtonPromiseSpinnerProps extends Omit<ButtonSpinnerProps, 'isLoading'> {
  onClick: (ev: any) => Promise<any>;
}

export default function ButtonPromiseSpinner (props: ButtonPromiseSpinnerProps) {
  const { onClick:onClickOuter, disabled:disabledOuter, ...buttonProps } = props;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const onClick = React.useCallback((ev: any) => {
    setIsLoading(true);
    onClickOuter(ev).finally(() => {
      setIsLoading(false);
    });
  }, [onClickOuter, setIsLoading]);

  return (
    <ButtonSpinner {...buttonProps} isLoading={isLoading} disabled={disabledOuter || isLoading} onClick={onClick} />
  );
}
