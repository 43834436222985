import React, {useCallback, useMemo, useState} from 'react';
import {Button, Col, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import { ModalOpeningButtonModalProps } from 'src/buttons/ModalOpeningButton';
import {TableColumn} from 'src/tables/TableColumnForm';
import { TableState } from 'src/tables/types';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import {useOnChangeFilterValue} from 'src/tables/TableFilterForm';
import { preventDefaultSubmit } from 'src/misc';
import TableFilterFormGroup from 'src/tables/TableFilterFormGroup';
import * as tableHelpers from 'src/tables/helpers';
import IdProvider from 'src/components/IdProvider';
import {Columns, Filter, Settings, X} from 'lucide-react';
import useAuth from 'src/hooks/useAuth';
import {sortBy} from 'lodash';

interface TableSettingsModalProps extends ModalOpeningButtonModalProps {
  columnDefinitions: TableColumnDefinition[];
  filterDefinitions: TableFilterDefinition[];
  stateFilter: TableState['filter'];
  stateFilterVisible: TableState['filterVisible'];
  stateTitleAuto: TableState['titleAuto'];
  updateState: (newState: TableState) => void;
  stateColumns: TableState['columns'];
  count: number | null;
}

export default function TableSettingsModal (props: TableSettingsModalProps) {
  const {
    show,
    onHide,
    columnDefinitions,
    updateState,
    stateColumns,
    stateFilter,
    filterDefinitions,
    stateFilterVisible,
    stateTitleAuto,
    count,
  } = props;

  const auth = useAuth();

  const [viewOptions, setViewOptions] = useState<ViewOptions>({
    searchText: '',
    sortBy: 'title',
    showOnlySelected: false,
    showOnlyActiveFilters: false,
  });

  const valueByFilterId = useMemo(() => stateFilter.reduce((map, stateFilter) => {
    map[stateFilter.key] = stateFilter.value;
    return map;
  }, {}), [stateFilter]);

  const eligibleColumnDefinitions = useMemo(() => {
    const result = columnDefinitions.filter(columnDefinition => {
      const { permission } = columnDefinition;
      const hasPermission = auth.permission(permission);
      return hasPermission && shouldShowByViewOptions(viewOptions, columnDefinition, stateColumns);
    });
    return viewOptions.sortBy === 'title' ? sortBy(result, 'title') : result;
  }, [columnDefinitions, viewOptions, auth, stateColumns]);

  const eligibleFilterDefinitions = useMemo(() => {
    const result = filterDefinitions.filter(filterDefinition => {
      const { id, permission } = filterDefinition;
      const hasPermission = auth.permission(permission);
      if (viewOptions.showOnlyActiveFilters && !tableHelpers.filterValueIsDefined(valueByFilterId[id])) {
        return false;
      }
      return hasPermission && shouldShowByViewOptions(viewOptions, filterDefinition, stateFilterVisible);
    });
    return viewOptions.sortBy === 'title' ? sortBy(result, 'title') : result;
  }, [filterDefinitions, viewOptions, auth, stateFilterVisible, valueByFilterId]);

  const onToggleVisibleFilter = useCallback((id: string) => {
    const isVisible = stateFilterVisible.includes(id);
    const newFilterVisible = isVisible ? stateFilterVisible.filter((iid: string) => iid !== id) : [...stateFilterVisible, id];
    updateState({filterVisible: newFilterVisible});
  }, [stateFilterVisible, updateState]);

  const onToggleVisibleColumn = useCallback((columnId: string, visible: boolean) => {
    const allColumnsOrdered = columnDefinitions.map(c => c.id);
    if (visible) {
      const newColumns = tableHelpers.addColumnInStableOrder(columnId, stateColumns, allColumnsOrdered);
      updateState({columns: newColumns});
    } else {
      const newColumns = stateColumns.filter(id => id !== columnId);
      updateState({columns: newColumns});
    }
  }, [updateState, stateColumns, columnDefinitions]);

  const onChangeFilterValue = useOnChangeFilterValue(stateFilter, updateState, filterDefinitions, stateTitleAuto);

  const onClickToggleEligibleFilters: React.MouseEventHandler<HTMLButtonElement> = useCallback(ev => {
    const action = ev.currentTarget.dataset['action'];
    if (action === 'show') {
      // add all eligible filters
      const newFilters = eligibleFilterDefinitions.filter(filter => {
        return !stateFilterVisible.includes(filter.id);
      }).map(f => f.id);
      const newFilterVisible = [...stateFilterVisible, ...newFilters];

      updateState({filterVisible: newFilterVisible});
    } else if (action === 'hide') {
      // remove all eligible filters
      const newFilterVisible = stateFilterVisible.filter(filterId => {
        const eligible = eligibleFilterDefinitions.find(f => f.id === filterId);
        return !Boolean(eligible);
      });

      updateState({filterVisible: newFilterVisible});
    }
  }, [updateState, eligibleFilterDefinitions, stateFilterVisible]);

  const onClickToggleEligibleColumns: React.MouseEventHandler<HTMLButtonElement> = useCallback(ev => {
    const action = ev.currentTarget.dataset['action'];
    if (action === 'show') {
      // add all eligible columns
      const newColumns = eligibleColumnDefinitions.filter(filter => {
        return !stateColumns.includes(filter.id);
      }).map(f => f.id);
      const newColumnVisible = [...stateColumns, ...newColumns];

      updateState({columns: newColumnVisible});
    } else if (action === 'hide') {
      // remove all eligible columns
      const newColumns = stateColumns.filter(filterId => {
        const eligible = eligibleColumnDefinitions.find(f => f.id === filterId);
        return !Boolean(eligible);
      });

      updateState({columns: newColumns});
    }
  }, [updateState, eligibleColumnDefinitions, stateColumns]);

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <Settings size={21} />{' '}
          Tabellinställningar
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ViewOptionsForm
          viewOptions={viewOptions}
          setViewOptions={setViewOptions}
        />
        <div className="p-3 pb-0">
          <Row>
            <Col md={8}>
              <h4 className="d-flex justify-content-between align-items-center flex-wrap mb-3 border-bottom pb-2">
                <div className="d-flex align-items-center gap-1 flex-wrap">
                  <span>
                    <Filter size={17} />{' '}
                    Filter
                  </span>
                  <span className="text-muted" style={{fontSize: '60%'}}>
                    ({eligibleFilterDefinitions.length}/{filterDefinitions.length})
                  </span>
                  {count !== null && (
                    <span style={{fontSize: '60%'}}>
                      ({count} rader)
                    </span>
                  )}
                </div>
                <div className="d-flex gap-1 flex-wrap">
                  <Button
                    size="sm"
                    variant="outline-info"
                    onClick={onClickToggleEligibleFilters}
                    data-action="show"
                    title="Välj alla filter som visas"
                  >
                    Välj
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-info"
                    onClick={onClickToggleEligibleFilters}
                    data-action="hide"
                    title="Dölj alla filter som visas"
                  >
                    Dölj
                  </Button>
                </div>
              </h4>
              {eligibleFilterDefinitions.length > 0 ? (
                <Form onSubmit={preventDefaultSubmit}>
                  <div className="d-flex flex-wrap">
                    {eligibleFilterDefinitions.map(filterDefinition => (
                      <TableFilterFormGroup
                        key={filterDefinition.id}
                        id={filterDefinition.id}
                        filterDefinition={filterDefinition}
                        value={valueByFilterId[filterDefinition.id]}
                        visible={stateFilterVisible.includes(filterDefinition.id)}
                        onToggleVisible={onToggleVisibleFilter}
                        onChangeFilterValue={onChangeFilterValue}
                      />
                    ))}
                  </div>
                </Form>
              ) : (
                <p>Det finns inga filter här</p>
              )}
            </Col>
            <Col md={4}>
              <h4 className="d-flex justify-content-between align-items-center flex-wrap mb-3 border-bottom pb-2">
                <div className="d-flex align-items-center gap-1 flex-wrap">
                  <span>
                    <Columns size={17} />{' '}
                    Kolumner
                  </span>
                  <span className="text-muted" style={{fontSize: '60%'}}>
                    ({eligibleColumnDefinitions.length}/{columnDefinitions.length})
                  </span>
                </div>
                <div className="d-flex gap-1 flex-wrap">
                  <Button
                    size="sm"
                    variant="outline-info"
                    onClick={onClickToggleEligibleColumns}
                    data-action="show"
                    title="Välj alla kolumner som visas"
                  >
                    Välj
                  </Button>
                  <Button
                    size="sm"
                    variant="outline-info"
                    onClick={onClickToggleEligibleColumns}
                    data-action="hide"
                    title="Dölj alla kolumner som visas"
                  >
                    Dölj
                  </Button>
                </div>
              </h4>
              {eligibleColumnDefinitions.length > 0 ? (
                <Form onSubmit={preventDefaultSubmit} className="mb-3">
                  {eligibleColumnDefinitions.map(columnDefinition => (
                    <TableColumn
                      key={columnDefinition.id}
                      columnDefinition={columnDefinition}
                      visible={tableHelpers.columnIsVisible(stateColumns, columnDefinition.id)}
                      onToggleVisible={onToggleVisibleColumn}
                    />
                  ))}
                </Form>
              ) : (
                <p>Det finns inga kolumner här</p>
              )}
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
}

interface ViewOptions {
  searchText: string;
  sortBy: string;
  showOnlySelected: boolean;
  showOnlyActiveFilters: boolean;
}

interface ViewOptionsFormProps {
  viewOptions: ViewOptions;
  setViewOptions: React.Dispatch<React.SetStateAction<ViewOptions>>;
}

function ViewOptionsForm (props: ViewOptionsFormProps) {
  const { viewOptions, setViewOptions } = props;

  const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const newSettings = {...viewOptions, [ev.target.name]: ev.target.value};
    setViewOptions(newSettings);
  }, [viewOptions, setViewOptions]);

  const onChangeCheckbox: React.ChangeEventHandler<HTMLInputElement> = useCallback(ev => {
    const newSettings = {...viewOptions, [ev.target.name]: ev.target.checked};
    setViewOptions(newSettings);
  }, [viewOptions, setViewOptions]);

  const onClickResetSearchText = useCallback(() => {
    setViewOptions(viewOptions => ({...viewOptions, searchText: ''}));
  }, [setViewOptions]);

  return (
    <Form onSubmit={preventDefaultSubmit} className="p-3 border-bottom d-flex gap-3">
      <Form.Group>
        <InputGroup>
          <Form.Control
            htmlSize={30}
            name="searchText"
            value={viewOptions.searchText || ''}
            onChange={onChange}
            placeholder="Sök efter en kolumn eller ett filter"
          />
          {viewOptions.searchText && (
            <Button
              variant="outline-primary"
              className="p-1"
              onClick={onClickResetSearchText}
              disabled={!viewOptions.searchText}
              title="Ta bort söktexten"
            >
              <X size={19} />
            </Button>
          )}
        </InputGroup>
      </Form.Group>
      <Form.Group>
        <div className="d-flex align-items-center border rounded gap-3 px-2 py-1 flex-wrap">
          <strong>Sortering: </strong>
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                type="radio"
                name="sortBy"
                value="natural"
                label="Visningsordning"
                checked={viewOptions.sortBy === 'natural'}
                onChange={onChange}
                id={id}
              />
            )}
          </IdProvider>
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                type="radio"
                name="sortBy"
                value="title"
                label="Bokstavsordning"
                checked={viewOptions.sortBy === 'title'}
                onChange={onChange}
                id={id}
              />
            )}
          </IdProvider>
        </div>
      </Form.Group>
      <Form.Group>
        <div className="d-flex align-items-center border rounded gap-3 px-2 py-1 flex-wrap">
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                type="checkbox"
                name="showOnlySelected"
                label="Bara valda"
                checked={viewOptions.showOnlySelected === true}
                onChange={onChangeCheckbox}
                id={id}
              />
            )}
          </IdProvider>
        </div>
      </Form.Group>
      <Form.Group>
        <div className="d-flex align-items-center border rounded gap-3 px-2 py-1 flex-wrap">
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                type="checkbox"
                name="showOnlyActiveFilters"
                label="Bara aktiva filter"
                checked={viewOptions.showOnlyActiveFilters === true}
                onChange={onChangeCheckbox}
                id={id}
              />
            )}
          </IdProvider>
        </div>
      </Form.Group>
    </Form>
  );
}

function shouldShowByViewOptions (viewOptions: ViewOptions, definition: TableColumnDefinition | TableFilterDefinition, currentSelection: string[]) {
  const { searchText, showOnlySelected } = viewOptions;

  if (showOnlySelected && !currentSelection.includes(definition.id)) {
    return false;
  }

  if (searchText && searchText.length > 0) {
    const regexp = new RegExp(searchText, 'gi');
    const { id, title } = definition;
    if (!regexp.test(id) && !regexp.test(title ?? '')) {
      return false;
    }
  }

  return true;
}
