import React, { createContext, useCallback, useEffect, useReducer } from 'react';
import * as api from 'src/api';
import axios from 'axios';
import { isPlainObject } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { UserSession } from 'shared/types/user';
import useNotyf from 'src/hooks/useNotyf';

export type PermissionDefinition = string | string[];

export type PermissionCheckFunction = (input?: PermissionDefinition) => boolean;

export interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  user: null | UserSession;
  userId: string; // will be empty when not authenticated
  sessionId?: string | null;
  permission: PermissionCheckFunction;
  hasAllPermissions: PermissionCheckFunction;
  hasAnyPermissions: PermissionCheckFunction;
  hasAllFunctions: PermissionCheckFunction;
  hasAnyRole: PermissionCheckFunction;
}

export interface AuthContextValue extends AuthState {
  login: (session: UserSession) => Promise<any>;
  logout: () => Promise<any>;
  passport: (destination: string, redirect?: boolean) => Promise<any>;
  switchTo: (userId: string) => Promise<SwitchResponseBody>;
  switchBack: () => Promise<SwitchResponseBody>;
}

type AuthContextReducerInitializeAction = {
  type: 'INITIALIZE';
  payload: UserSession | null;
}

type AuthContextReducerLoginAction = {
  type: 'LOGIN';
  payload: UserSession;
};

type AuthContextReducerLogoutAction = {
  type: 'LOGOUT';
};

type AuthContextReducerSwitchToAction = {
  type: 'SWITCH_TO';
};

type AuthContextReducerSwitchBackAction = {
  type: 'SWITCH_BACK';
};

type AuthContextReducerAction =
  | AuthContextReducerInitializeAction
  | AuthContextReducerLoginAction
  | AuthContextReducerLogoutAction
  | AuthContextReducerSwitchToAction
  | AuthContextReducerSwitchBackAction
;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  userId: '',
  sessionId: null,

  permission: () => false,
  hasAllPermissions: () => false,
  hasAnyPermissions: () => false,
  hasAllFunctions: () => false,
  hasAnyRole: () => false,
  login: () => Promise.reject(new Error('Funktionen är oinitialiserad')),
  logout: () => Promise.reject(new Error('Funktionen är oinitialiserad')),
  passport: () => Promise.reject(new Error('Funktionen är oinitialiserad')),
  switchTo: () => Promise.reject(new Error('Funktionen är oinitialiserad')),
  switchBack: () => Promise.reject(new Error('Funktionen är oinitialiserad')),
};

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
});

const AuthProvider: React.FC<React.PropsWithChildren> = React.memo(function AuthProvider (props: React.PropsWithChildren) {
  const { children } = props;

  const [state, dispatch] = useReducer(reducer, initialState, withHelpers);

  const query = useQuery<UserSession, Error>({
    queryKey: ['/account'],
  });

  const notyf = useNotyf();

  useEffect(() => {
    if (!query.error) return;
    dispatch({type: 'INITIALIZE', payload: null});
  }, [query.error]);

  useEffect(() => {
    if (query.data) {
      const user = query.data || null;
      dispatch({type: 'INITIALIZE', payload: user});
    }
  }, [query.data]);

  useEffect(() => {
    const isLoggedIn = state.isInitialized && state.isAuthenticated;
    if (!isLoggedIn) return;

    let dispatched = false;

    const interceptorId = axios.interceptors.response.use(null, err => {
      if (err.response.status === 401) {
        // we have probably been logged out, send to login again
        if (dispatched) return;
        notyf.open({
          type: 'warning',
          message: 'Din session har utgått och du behöver logga in igen',
          position: {x: 'center', y: 'bottom'},
        });
        dispatch({type: 'LOGOUT'});
        dispatched = true;
        return;
      }
      throw err;
    });

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [state.isInitialized, state.isAuthenticated, notyf]);

  const login = useCallback(async (session: UserSession) => {
    if (session.default_app === 'crm') {
      await passportRequest('crm');
      if (session.role === 'bank' || session.functions.includes('app')) {
        // no reason to dispatch login when the user can't see this page anyway
        return;
      }
    }
    dispatch({type: 'LOGIN', payload: session});
  }, [dispatch]);

  const logout = useCallback(async () => {
    await api.request({method: 'post', url: '/auth/logout'});
    notyf.success({
      type: 'primary',
      message: 'Du har blivit utloggad',
      position: {x: 'center', y: 'bottom'},
    });
    dispatch({type: 'LOGOUT'});
  }, [dispatch, notyf]);

  const passport = useCallback(async (destination: string, redirect?: boolean) => {
    const response = await passportRequest(destination);
    if (redirect) {
      window.open(response.verifyUrl);
    }
    return response;
  }, []);

  const switchTo = useCallback(async (userId: string) => {
    const response = await switchToRequest(userId);
    dispatch({type: 'SWITCH_TO'});
    await query.refetch();
    window.open(response.redirectTo, '_self');
    return response;
  }, [dispatch, query]);

  const switchBack = useCallback(async () => {
    const response = await switchBackRequest();
    dispatch({type: 'SWITCH_BACK'});
    await query.refetch();
    window.open(response.redirectTo, '_self');
    return response;
  }, [dispatch, query]);

  const value = {
    ...state,
    login,
    logout,
    passport,
    switchTo,
    switchBack,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
});

export { AuthContext, AuthProvider };

function reducer (state: AuthContextValue, action: AuthContextReducerAction): AuthContextValue {
  switch (action.type) {
    default: return withHelpers(state);

    case 'INITIALIZE': return withHelpers({
      ...state,
      isInitialized: true,
      isAuthenticated: Boolean(action.payload),
      user: action.payload ?? null,
      userId: action.payload?.id ?? '',
      sessionId: action.payload?.session_id ?? null,
    });

    case 'LOGIN': return withHelpers({
      ...state,
      isAuthenticated: true,
      user: action.payload,
      userId: action.payload.id,
      sessionId: action.payload.session_id,
    });

    case 'LOGOUT': return withHelpers({
      ...state,
      isAuthenticated: false,
      user: null,
      userId: '',
      sessionId: null,
    });

    case 'SWITCH_BACK':
    case 'SWITCH_TO': return withHelpers({
      ...state,
      // isInitialized: false,
      // isAuthenticated: false,
      // user: null,
      // userId: '',
      // sessionId: null,
    });

  }
}

function withHelpers (state: AuthContextValue) {

  state.permission = function (permission?: PermissionDefinition | {function: string}): boolean {
    if (typeof permission === 'string') return state.hasAllPermissions(permission);
    if (isPlainObject(permission) && !Array.isArray(permission)) {
      const { function:functionName } = permission as {function: string};
      if (functionName) return state.hasAllFunctions(functionName);
    }
    return true;
  };

  state.hasAllPermissions = function (permissions) {
    if (state.isInitialized && !state.isAuthenticated) {
      return false;
    }
    const userPermissions = state.user?.permissions || [];
    return listHasAll(userPermissions, permissions);
  };

  state.hasAnyPermissions = function (permissions) {
    if (state.isInitialized && !state.isAuthenticated) {
      return false;
    }
    const userPermissions = state.user?.permissions || [];
    return listHasAny(userPermissions, permissions);
  };

  state.hasAllFunctions = function (functions) {
    if (state.isInitialized && !state.isAuthenticated) {
      return false;
    }
    const userFunctions = state.user?.functions || [];
    return listHasAll(userFunctions, functions);
  };

  state.hasAnyRole = function (roles) {
    if (state.isInitialized && !state.isAuthenticated) {
      return false;
    }
    const userRoles = state.user?.role || [];
    return listHasAny(userRoles, roles);
  };

  return state;
}

// returns true if all values in rawValue (string or array) exists in list
function listHasAll (list, rawValue) {
  if (!rawValue || !rawValue.length) return false;
  const value = ensureArray(rawValue);
  return value.every(value => list.includes(value));
}

function listHasAny (list, rawValue) {
  if (!rawValue || !rawValue.length) return false;
  const value = ensureArray(rawValue);
  return value.some(value => list.includes(value));
}

function ensureArray (value: unknown): unknown[] {
  if (Array.isArray(value)) return value;
  return typeof value === 'undefined' ? [] : [value];
}

interface PassportResponseBody {
  token: string;
  sessionId: string;
  validUntil: string;
  verifyUrl: string;
  destination: string;
}

async function passportRequest (destination: string): Promise<PassportResponseBody> { 
  const data = await api.request<PassportResponseBody>({
    method: 'POST',
    data: {destination},
    url: '/auth/passport',
  });
  return data;
}

interface SwitchResponseBody {
  redirectTo: string;
}

async function switchToRequest (userId: string): Promise<SwitchResponseBody> { 
  const data = await api.request<SwitchResponseBody>({
    method: 'POST',
    data: {user_id: userId},
    url: '/auth/switch',
  });
  return data;
}

async function switchBackRequest (): Promise<SwitchResponseBody> { 
  const data = await api.request<SwitchResponseBody>({
    method: 'DELETE',
    url: '/auth/switch',
  });
  return data;
}
