import React, {useState} from 'react';
import ConfirmActionModal from 'src/modals/ConfirmActionModal';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { ConfirmActionModalButtonProps } from 'src/buttons/ConfirmActionModalButton';
import { ExecuteButtonProps } from 'src/buttons/ExecuteButton';
import {useMutation} from '@tanstack/react-query';
import useNotyf from 'src/hooks/useNotyf';

// This combines ExecuteButton and ConfirmActionModalButton
// The action is executed if the modal confirms it
// Set to intersect the props of both components even if no code is shared

type ConfirmActionModalExecuteButtonProps = ExecuteButtonProps & Omit<ConfirmActionModalButtonProps, 'onConfirm'>;

export default function ConfirmActionModalExecuteButton (props: ConfirmActionModalExecuteButtonProps) {
  const {
    children,
    url,
    vars = {},
    method = 'POST',
    confirmMessage = 'Ja',
    declineMessage = 'Nej',
    onDecline,
    disabled,
    message,
    onSuccess,
    onSuccessNotyf,
    ...buttonProps
  } = props;

  const notyf = useNotyf();

  const mutation = useMutation<unknown, Error, Record<string, any>>({
    mutationFn: async data => {
      const result = await api.request({
        errorToNotyf: notyf,
        url,
        method,
        data,
      });
      if (onSuccessNotyf) notyf.open(onSuccessNotyf);
      onSuccess(result);
      return result;
    },
  });

  const [showModal, setShowModal] = useState<boolean>(false);

  const onHide = () => {
    setShowModal(false);
  };

  const onClick = () => {
    setShowModal(true);
  };

  const onConfirm = async () => {
    await mutation.mutateAsync(vars);
  };

  return (
    <>
      <ButtonSpinner
        {...buttonProps}
        onClick={onClick}
        isLoading={mutation.isPending}
        disabled={disabled || mutation.isPending}
      >
        {children}
      </ButtonSpinner>
      <ConfirmActionModal
        message={message}
        confirmMessage={confirmMessage}
        declineMessage={declineMessage}
        onConfirm={onConfirm}
        onDecline={onDecline}
        show={showModal}
        onHide={onHide}
      />
    </>
  );
}
