import React, {useState} from 'react';
import { Button, Collapse, Modal } from 'react-bootstrap';
import { ErrorType, errorToDetails, errorToMessage } from 'src/utils/error';
import CopyToClipboardButton from 'src/buttons/CopyToClipboardButton';
import {Paperclip} from 'lucide-react';
import useAuth from 'src/hooks/useAuth';
import {useLocation} from 'react-router';

interface ErrorModalProps {
  error?: ErrorType | null;
  title?: string;
}

// NOTE! Required to conditionally render ErrorModal (unlike ErrorAlert)
// this ensures that a new modal is rendered on new errors (from i.e. the same query/mutation)
// when the first modal was closed
export default function ErrorModal (props: ErrorModalProps) {
  const { error, title } = props;
  const [show, setShow] = useState<boolean>(true);
  const [showExtras, setShowExtras] = useState<boolean>(false);

  const auth = useAuth();
  const location = useLocation();

  if (!error) {
    return null;
  }

  const message = errorToMessage(error);
  const details = errorToDetails(error);

  const info = {
    time: (new Date()).toISOString(),
    userId: auth.userId,
    location,
    error,
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
    >
      {title && (
        <Modal.Header className="bg-danger rounded-top" closeButton>
          <h3 className="mb-0 text-white">
            {title}
          </h3>
        </Modal.Header>
      )}
      <Modal.Body className="m-3 mb-0 pt-1 pb-0">
        <h4 className="mb-3">
          {message}
        </h4>
        {details && (
          <div>
            {details.list && (
              <ol>
                {details.list.map((detail, index) => (
                  <li key={index}>{detail.message}</li>
                ))}
              </ol>
            )}
            {details.extras && (
              <div className="">
                <Collapse in={showExtras} unmountOnExit mountOnEnter>
                  <div>
                    <div className="card rounded border p-3" style={{maxHeight: '200px'}}>
                      <pre className="mb-0 text-start" style={{overflowX: 'hidden', whiteSpace: 'normal'}}>
                        {JSON.stringify(details.extras, null, 2)}
                      </pre>
                    </div>
                  </div>
                </Collapse>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <div className="d-flex gap-2">
          <CopyToClipboardButton
            copy={JSON.stringify(info)}
            onCopyMessage="Felinformation kopierad"
            variant="outline-info"
          >
            <Paperclip size={16} />{' '} 
            Kopiera felinfo
          </CopyToClipboardButton>
          {details && details.extras && (
            <Button
              variant="outline-info"
              onClick={() => setShowExtras(!showExtras)}
            >
              {showExtras ? 'Dölj mer info' : 'Visa mer info'}
            </Button>
          )}
        </div>
        <Button variant="outline-danger" onClick={() => setShow(false)}>
          Stäng
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
