import React from 'react';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import { useQuery } from '@tanstack/react-query';
import { StatisticToplistRow } from 'shared/types/statistic_toplist';

import { Card, Table } from 'react-bootstrap';
import { Star } from 'lucide-react';

type FilterState = Record<string, any>;

interface StatisticsToplistQueryResult {
  sum_disbursed: StatisticToplistRow[];
  sum_ever_accepted: StatisticToplistRow[];
  tot: StatisticToplistRow[];
}

interface StatisticsToplistCardProps {
  className?: string;
  filter?: FilterState;
  url: string;
  title: string;
  tableKey: keyof StatisticsToplistQueryResult;
}

export default function StatisticsToplistCard (props: StatisticsToplistCardProps) {
  const { title, tableKey, filter, className, url } = props;

  const query = useQuery<StatisticsToplistQueryResult>({
    queryKey: [url, {filter}],
  });

  const rows = query.data?.[tableKey] ?? [];

  return (
    <Card className={className}>
      <Card.Header>
        <CardTitleIcon
          title={title}
          Icon={<Star size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            refetch={query.refetch}
          />
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="p-0 position-relative">
        <ErrorAlert error={query.error} className="mx-3 mt-3 mb-2" />
        <OverlaySpinner isLoading={query.isLoading} />
        <div className="table-responsive">
          <Table className="my-0 border-bottom border-top" striped>
            <tbody>
              {rows.length > 0 ? (
                <>
                  {rows.map((row, index) => (
                    <tr key={row.user_id}>
                      <td>{index + 1}</td>
                      <td>{row.user_name}</td>
                      <td>{row.value}</td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={3}>Ingenting här</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
}

type StatisticsToplistGroupProps = Pick<StatisticsToplistCardProps, 'tableKey' | 'title'>;

export function StatisticsToplistGroup (props: StatisticsToplistGroupProps) {
  return (
    <StatisticsToplistCard
      {...props}
      url="/statistic/toplist/group"
    />
  );
}

type StatisticsToplistAdvisorProps = Pick<StatisticsToplistCardProps, 'tableKey' | 'title'>;

export function StatisticsToplistAdvisor (props: StatisticsToplistAdvisorProps) {
  return (
    <StatisticsToplistCard
      {...props}
      url="/statistic/toplist/advisor"
    />
  );
}
