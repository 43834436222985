const get = require('shared/get');

// functions for an abstract Trigger model/Row

exports.status = function (status) {
  switch (status) {
    default: return status;
    case null: return 'Ingen';
    case 'new': return 'Nyskapad';
    case 'scheduled': return 'Schemalagd';
    case 'executing': return 'Kör';
    case 'ok': return 'Utförd';
    case 'error': return 'Fel';
    case 'masked': return 'Maskerad';
  }
};

exports.indexColumnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'work_trigger_template_id': return 'Trigger';
    case 'status': return 'Status';
    case 'task': return 'Rutin';
    case 'work_id': return 'Jobb';
    case 'description': return 'Beskrivning';
    case 'scheduled_at': return 'Schemalagd';
    case 'created_at': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'scheduled_at_relative': return 'Schemalagd';

      // specific for
    case 'application_id': return 'Ansökan';
    case 'task_id': return 'Återkomst';
    case 'jaycom_insurance_id': return 'Försäkring';
    case 'prospect_id': return 'Prospekt';
  }
};

exports.indexField = function (row, fieldName) {
  switch (fieldName) {
    default: return get(row, fieldName);
    case 'task': return get(row, 'WorkTriggerTemplate.task');
    case 'description': return get(row, 'WorkTriggerTemplate.description');
  }
};
