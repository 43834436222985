import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import WorkModalBody from 'src/work/WorkModalBody';
import { Name } from 'src/work/WorkFormatters';
import { WorkRow } from 'shared/types/work';

interface WorkAnchorProps extends Partial<ModalAnchorProps> {
  work: WorkRow;
  showStatus?: boolean;
}

export default function WorkAnchor (props: WorkAnchorProps) {
  const { work, showStatus, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={work} showStatus={showStatus} />}
      name={<>Jobb #<Name value={work} /></>}
      backdrop
    >
      <WorkModalBody id={work.id} />
    </ModalAnchor>
  );
}
