import React, {useMemo, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import * as IconButtons from 'src/buttons/IconButtons';
import Pluralizer from 'src/formatters/Pluralizer';
import * as TemplateFormatters from 'src/template/TemplateFormatters';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import { ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { Scissors } from 'lucide-react';
import {
  Container,
  Card,
} from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { LetterTemplateRow } from 'shared/types/letter_template';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { TableStateOrder, TableStateFilterMap, TableQueryKey } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as miscFilters from 'shared/filter/misc';
import * as userFilters from 'shared/filter/user';
import constants from 'shared/constants';
import {useEventModelSubscribeToQuery} from 'src/hooks/useEventModels';
import {useTableUpdateRowMutation} from 'src/hooks/useTableUtils';
import {TableContextProvider} from 'src/tables/TableContext';
import TemplateCreateModalBody from 'src/template/TemplateCreateModalBody';
import RouteParameterModal from 'src/modals/RouteParameterModal';
import LetterTemplateModalBody from 'src/letterTemplate/LetterTemplateModalBody';
import {LetterTemplatePlainAnchor} from 'src/letterTemplate/LetterTemplateAnchor';

interface LetterTemplateRowsTablePageFilter extends TableStateFilterMap {
  is_archived?: boolean | null;
  searchTerm?: string;
}

const defaultFilter = {
  is_archived: false,
  searchTerm: '',
};

export default function BanksTablePage () {
  const [filter, setFilter] = useState<LetterTemplateRowsTablePageFilter>(defaultFilter);
  const [order, setOrder] = useState<TableStateOrder>({id: 'asc'});

  const queryKey: TableQueryKey = useMemo(() => {
    return ['/template/letter/search', {params: {...filter, order}}];
  }, [filter, order]);

  const query = useQuery<LetterTemplateRow[], Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  useEventModelSubscribeToQuery({queryKey: [queryKey[0]]}, 'letterTemplate');

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
  });

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Brevmallar" />
        <Container fluid className="p-0">
          <Card className="mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Brevmallar"
                Icon={<Scissors size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <IconButtons.SimpleCreateModalButton modalTitle="Ny brevmall">
                  <TemplateCreateModalBody type="letter" />
                </IconButtons.SimpleCreateModalButton>
                <IconButtons.RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1">
                <small>
                  <Pluralizer
                    count={query.data?.length ?? 0}
                    zero="Inga brevmallar"
                    one="Visar 1 brevmall"
                    otherwise="Visar %% brevmallar"
                  />
                </small>
              </Card.Subtitle>
            </Card.Header>
            <RouteParameterModal
              parameter="letterTemplateId"
              title="Redigera brevmall"
              backdrop="static"
              subTitle={letterTemplateId => letterTemplateId}
              hideUrl="/letter_template"
              size="xl"
            >
              {letterTemplateId => (
                <LetterTemplateModalBody id={letterTemplateId} />
              )}
            </RouteParameterModal>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
              <CardBodyTable
                filter={filter}
                order={order}
                filterDefinitions={filterDefinitions}
                columnDefinitions={columnDefinitions}
                setFilter={setFilter}
                setOrder={setOrder}
                rows={query?.data || []}
                isFetched={query.isFetched}
              />
            </TableContextProvider>
          </Card>
        </Container>
      </Content>
    </Main>
  );
}

const userRoleFilterOptions = ['salesman', 'advisor'].map((value: string) => ({
  value,
  label: userFilters.role(value),
}));

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.string({
    id: 'searchTerm',
    title: 'Sök',
  }),
  commonFilterDefs.select({
    id: 'language',
    title: 'Språk',
  }, constants.languages.map(language => ({
    value: language,
    label: miscFilters.language(language),
  }))),
  commonFilterDefs.select({
    id: 'role_visibility',
    title: 'Kan skapa',
  }, userRoleFilterOptions),
  commonFilterDefs.select({
    id: 'role_editable',
    title: 'Kan redigera',
  }, userRoleFilterOptions),
  commonFilterDefs.boolean({
    id: 'is_archived',
    title: 'Status',
    filterProps: {labels: {false: 'Valbar/Ej arkiverad', true: 'Arkiverad'}},
  }),
];

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.basic({
    id: 'id',
    Cell: props => <LetterTemplatePlainAnchor letterTemplate={props.row} />,
    title: 'ID',
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'template',
    Cell: props => <>{props.row.template}</>,
    title: 'Renderingsmall',
  }),
  commonColumnDefs.basic({
    id: 'language',
    Cell: props => <>{miscFilters.language(props.row.language)}</>,
    title: 'Språk',
  }),
  commonColumnDefs.basic({
    id: 'role_visibility',
    Cell: props => <TemplateFormatters.RoleList value={props.row.role_visibility} />,
    title: 'Kan skapa',
  }),
  commonColumnDefs.basic({
    id: 'role_editable',
    Cell: props => <TemplateFormatters.RoleList value={props.row.role_editable} />,
    title: 'Kan redigera',
  }),
  commonColumnDefs.inspect(),
];

