import React from 'react';
import NavbarDropdown from 'src/navbar/NavbarDropdown';
import * as api from 'src/api';
import { useQuery } from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';
import last from 'lodash/last';
import BlockSpinner from 'src/spinners/BlockSpinner';
import { Table } from 'react-bootstrap';
import { Activity } from 'lucide-react';

import DateTime from 'src/formatters/DateTime';
import UserLogDataTypeIcon from 'src/user/UserLogDataTypeIcon';
import UserLogDescription from 'src/user/UserLogDescription'; 
import moment from 'moment';
import useWebsocketModelCreates from 'src/hooks/useWebsocketModelCreates';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';
import useLocalStorage from 'src/hooks/useLocalStorage';
import useAuth from 'src/hooks/useAuth';
import { UserLogRow } from 'shared/types/user_log';

const MAX = 3; // maximum notifications to show

const NavbarLogDropdown = React.memo(function NavbarLogDropdown () {
  const [sinceTimestamp, setSinceTimestamp] = useLocalStorage<number>('NavbarLogDropdownSinceTimestamp', 0);
  const [lastOpenTimestamp, setLastOpenTimestamp] = useLocalStorage<number>('NavbarLogDropdownLastOpenTimestamp', 0);

  const { userId } = useAuth();

  const query = useQuery<UserLogRow[], Error>({
    queryKey: ['NavbarLogDropdown'],
    queryFn: async () => {
      const data = await api.request({
        url: '/account/log',
        params: {since: moment(sinceTimestamp).toISOString()},
      });
      if (!data.length) return data;
      const newSinceTimestamp = getNewSinceTimestamp(sinceTimestamp, data);
      setSinceTimestamp(newSinceTimestamp);
      return data;
    },
  });

  const queryClientUtils = useQueryClientUtils();

  const wsModelInserts = useWebsocketModelCreates<UserLogRow>('user_log', function (id, create) {
    queryClientUtils.applyCreates({queryKey: ['NavbarLogDropdown']}, [create]);
    const newData = [create, ...(query.data) ?? []];
    const newSinceTimestamp = getNewSinceTimestamp(sinceTimestamp, newData);
    setSinceTimestamp(newSinceTimestamp);
  });
  React.useEffect(() => {
    wsModelInserts.setIds([userId]);
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onToggleDropdown = React.useCallback((nextShow: boolean) => {
    if (!nextShow) return;
    setLastOpenTimestamp(Date.now());
  }, [setLastOpenTimestamp]);

  const logs: any = React.useMemo(() => query.data || [], [query.data]);

  const unseenLogs = React.useMemo(() => logs.filter(log => {
    return (new Date(log.date)).valueOf() > lastOpenTimestamp;
  }), [logs, lastOpenTimestamp]);

  return (
    <NavbarDropdown
      icon={Activity}
      count={unseenLogs.length > 3 ? '3+' : unseenLogs.length}
      showBadge={unseenLogs.length > 0}
      autoClose="outside"
      onToggle={onToggleDropdown}
    >
      <div className="dropdown-menu-header position-relative">
        {headerText(logs.length)}
      </div>
      <div className="position-relative">
        <BlockSpinner className="p-3 justify-content-center border-bottom" isLoading={query.isLoading} />
        {query.error && (
          <div className="p-2 border-bottom">
            <ErrorAlert error={query.error} className="my-1" />
          </div>
        )}
        <Table striped className="my-0 border-bottom">
          <tbody>
            {query.isSuccess && logs.slice(0, 3).map(item => (
              <tr key={item.id}>
                <td>
                  <DateTime value={item.date} format="HH:mm" />
                </td>
                <td>
                  <UserLogDataTypeIcon type={item.data.type} />
                </td>
                <td>
                  <UserLogDescription data={item.data} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* <Dropdown.Header className="dropdown-menu-footer"> */}
      {/*   <a href="/all_events">Visa alla händelser</a> */}
      {/* </Dropdown.Header> */}
    </NavbarDropdown>
  );
});
export default NavbarLogDropdown;

function headerText (length) {
  if (length === 0) return 'Inga nya händelser';
  if (length === 1) return 'En ny händelse';
  return `${length} nya händelser`;
}

// we aim to always show the last three notifications regardless of when the user
// last clicked to open the dropdown
function getNewSinceTimestamp (prev: number, data: UserLogRow[]): number {
  const oldest = last(data.slice(0, MAX));
  if (!oldest) return prev;
  return moment(oldest.date).valueOf();
}
