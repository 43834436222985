import React, {useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { Card, Form } from 'react-bootstrap';
import {
  CustomerConsentRow,
  CustomerConsentMethod,
  CustomerMethodPart,
} from 'shared/types/customer_consent';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import {CustomerRow} from 'shared/types/customer';
import BlockSpinner from 'src/spinners/BlockSpinner';
import useNotyf from 'src/hooks/useNotyf';
import { maybeAxiosErrorToErrorMap } from 'src/utils/error';
import { AxiosError } from 'axios';
import {Formik, FormikErrors, FormikHelpers} from 'formik';
import * as CustomerConsentFormFields from 'src/customerConsent/CustomerConsentFormFields';
import useAuth from 'src/hooks/useAuth';
import CustomerConsentBankIdMethodPart from 'src/customerConsent/CustomerConsentBankIdMethodPart';
import * as customerConsentFilters from 'shared/filter/customer_consent';
import IdProvider from 'src/components/IdProvider';
import CustomerConsentUserPhoneMethodPart from 'src/customerConsent/CustomerConsentUserPhonePart';
import useModalStateContext from 'src/hooks/useModalStateContext';

interface FormValues {
  subject: string;
}

interface CreateConsentBody {
  customer_id: string;
  subject: string;
  method: CustomerConsentMethod;
  method_parts: CustomerMethodPart;
}

interface CustomerConsentCreateOnTemplateFormProps {
  customerId: string;
  onConsentCreated: (consent: CustomerConsentRow) => unknown | Promise<unknown>;
  customerConsentTemplateUrl: string;
  allowedMethods: CustomerConsentMethod[];
  submitButtonLabel?: string;
  subject: string;
  methodComponentProps?: Partial<Record<CustomerConsentMethod, Record<string, any>>>;
}

export default function CustomerConsentCreateOnTemplateForm (props: CustomerConsentCreateOnTemplateFormProps) {
  const {
    allowedMethods = [],
    customerConsentTemplateUrl,
    onConsentCreated,
    customerId,
    submitButtonLabel = 'Registrera samtycke',
    subject,
    methodComponentProps = {},
  } = props;

  const notyf = useNotyf();
  const auth = useAuth();
  const modalStateContext = useModalStateContext();

  const [method, setMethod] = useState<CustomerConsentMethod>(allowedMethods[0]);
  const [methodParts, setMethodPart] = useState<CustomerMethodPart | null>(null);

  const onConsentMethodPartGiven = (methodPartsKey: CustomerConsentMethod, methodParts: any) => {
    (setMethodPart as any)({[methodPartsKey]: methodParts});
  };

  const onConsentMethodPartRevoked = () => {
    setMethodPart(null);
  };

  const createMutation = useMutation<CustomerConsentRow, Error, CreateConsentBody>({
    mutationFn: async formValues => {
      const consent = await api.request({
        url: customerConsentTemplateUrl,
        method: 'POST',
        data: formValues,
      });
      notyf.success({type: 'success', message: 'Samtycket registrerades på kunden'});
      await onConsentCreated(consent);
      modalStateContext?.onHide();
      return consent;
    },
  });

  const onSubmitCreate = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    if (!methodParts) return;
    try {
      await createMutation.mutateAsync({
        ...values,
        customer_id: customerId,
        subject,
        method,
        method_parts: methodParts,
      });
    } catch (err) {
      helpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err as AxiosError);
      if (errorMap) {
        helpers.setErrors(errorMap as FormikErrors<FormValues>);
        return;
      }
      throw err;
    }
  };

  const onChangeMethod: React.ChangeEventHandler<HTMLSelectElement> = ev => {
    setMethod(ev.target.value as any);
    setMethodPart(null);
  };

  const query = useQuery<CustomerRow>({
    queryKey: [`/customer/${customerId}`],
  });

  const initialValues: FormValues = {
    subject,
  };

  const customer = query.data;

  return (
    <div>
      <div>
        <IdProvider>
          {id => (
            <Form.Group>
              <Form.Label htmlFor={id}>Samtyckesmetod</Form.Label>
              <Form.Select
                id={id}
                name="method"
                value={method}
                onChange={onChangeMethod}
                required
              >
                {allowedMethods.map(allowedMethod => (
                  <option key={allowedMethod} value={allowedMethod}>
                    {customerConsentFilters.method(allowedMethod)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
        </IdProvider>
      </div>
      <BlockSpinner className="my-3" isLoading={query.isLoading} />
      <Card className="mt-4">
        <Card.Body className="p-0">
          {customer && ['customer_bankid_auth', 'customer_bankid_sign'].includes(method) && (
            <CustomerConsentBankIdMethodPart
              userId={auth.userId}
              method={method as 'customer_bankid_auth' | 'customer_bankid_sign'}
              personalNumber={customer.ssn}
              onConsentMethodPartGiven={onConsentMethodPartGiven}
              {...methodComponentProps[method] ?? {}}
            />
          )}
          {method === 'user_phone' && (
            <CustomerConsentUserPhoneMethodPart
              userId={auth.userId}
              subject={subject}
              onConsentMethodPartGiven={onConsentMethodPartGiven}
              onConsentMethodPartRevoked={onConsentMethodPartRevoked}
              {...methodComponentProps[method] ?? {}}
            />
          )}
        </Card.Body>
      </Card>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitCreate}
        className="border-top"
        validateOnMount
      >
        {formProps => (
          <Card className="mb-1">
            <Form onSubmit={formProps.handleSubmit}>
              <Card.Body className="pt-1 pb-2">
                <CustomerConsentFormFields.Subject disabled />
              </Card.Body>
              <Card.Footer className="border-top">
                <ErrorAlert error={createMutation.error} />
                <div className="d-flex gap-2 flex-wrap">
                  <ButtonSpinner
                    type="submit"
                    className="rounded"
                    variant="success"
                    isLoading={formProps.isSubmitting}
                    disabled={!formProps.isValid || formProps.isSubmitting || !methodParts}
                  >
                    {submitButtonLabel}
                  </ButtonSpinner>
                </div>
              </Card.Footer>
            </Form>
          </Card>
        )}
      </Formik>
    </div>
  );
}
