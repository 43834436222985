import React from 'react';
import { Helmet } from 'react-helmet-async';
import { UnauthedLoaderGuard } from 'src/guards/AuthLoaderGuard';
import { Card } from 'react-bootstrap';
import { NavLink, useParams } from 'react-router-dom';
import { Tab, Nav } from 'react-bootstrap';
import Main from 'src/ui/Main';
import { ReactComponent as CompanyLogoCircle } from 'src/assets/img/trygga_circle.svg';
import LoginPasswordForm from 'src/login/LoginPasswordForm';
import LoginBankId from 'src/login/LoginBankId';
import { isMobile } from 'react-device-detect';

interface LoginPageParams {
  tab?: string;
}

export default function LoginPage () {
  const { tab = 'password' } = useParams() as unknown as LoginPageParams;

  return (
    <UnauthedLoaderGuard>
      <Helmet title="Inloggning" />
      <Main className="d-flex w-100 mt-5">
        <div className="d-flex flex-column container">
          <div className="h-100 row">
            <div className="mx-auto d-table h-100 col-xl-5 col-lg-6 col-md-8 col-sm-10">
              <div className="d-table-cell align-middle">
                <div className="text-center mb-4">
                  <div className="d-inline-block w-50 mb-3">
                    <CompanyLogoCircle style={{maxHeight: '50px'}} />
                  </div>
                  <h2>Trygga CRM</h2>
                  <p className="lead">Välj en inloggningsmetod.</p>
                </div>

                <Card className="rounded-4">
                  <Card.Body className="p-2">
                    <div className="m-sm-3">
                      <Tab.Container
                        activeKey={tab}
                        transition={false}
                        unmountOnExit={true}
                        mountOnEnter={true}
                      >
                        <Nav variant="tabs" fill>
                          <Nav.Item>
                            <NavLink className="nav-link" to="/login/password">
                              <span className="d-inline-flex gap-1 align-items-center">
                                Lösenord
                              </span>
                            </NavLink>
                          </Nav.Item>
                          <Nav.Item>
                            <NavLink className="nav-link" to="/login/bankid_local">
                              <span className="d-inline-flex gap-1 align-items-center">
                                {isMobile ? (
                                  'BankID på den här mobilen'
                                ) : (
                                  'BankID på denna enhet'
                                )}
                              </span>
                            </NavLink>
                          </Nav.Item>
                          <Nav.Item>
                            <NavLink className="nav-link" to="/login/bankid">
                              <span className="d-inline-flex gap-1 align-items-center">
                                {isMobile ? (
                                  'BankID på en annan enhet'
                                ) : (
                                  'Mobilt BankID'
                                )}
                              </span>
                            </NavLink>
                          </Nav.Item>
                        </Nav>
                        <Tab.Content>
                          <Tab.Pane eventKey="password">
                            <LoginPasswordForm />
                          </Tab.Pane>
                          <Tab.Pane eventKey="bankid">
                            <LoginBankId />
                          </Tab.Pane>
                          <Tab.Pane eventKey="bankid_local">
                            <LoginBankId local />
                          </Tab.Pane>
                        </Tab.Content>
                      </Tab.Container>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Main>
    </UnauthedLoaderGuard>
  );
}
