import React from 'react';
import useAuth from 'src/hooks/useAuth';
import { PermissionCheckFunction } from 'src/contexts/AuthContext';
import { TableColumnCellProps, TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';
import { TaskRow } from 'shared/types/task';
import * as TableCellActions from 'src/tables/TableCellActions';
import * as TableCells from 'src/tables/TableCells';
import taskFilter from 'shared/filter/task';
import constants from 'shared/constants';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import { CheckCircle, XCircle, Meh, Calendar, Edit2, Trash } from 'lucide-react';
import * as taskUtils from 'src/utils/task';
import { TableBatchDefinition } from 'src/tables/types';
import PostponeTaskModal from 'src/task/PostponeTaskModal';
import EditTaskModal from 'src/task/EditTaskModal';
import ModalOpeningButton from 'src/buttons/ModalOpeningButton';
import { TaskFlags } from 'src/task/TaskFormatters';

export default function TaskTablePage () {
  return (
    <TablePage
      title="Återkomster"
      pageTitle="Återkomsttabell"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      batchDefinition={batchDefinition}
      routeUrl="/task"
      baseQueryUrl="/task/table"
      baseQueryKey="TaskTable"
      queryResultRowsKey="tasks"
    />
  );
}

const batchDefinition: TableBatchDefinition = {
  permission: 'task_batch',
  url: '/task/batch',
  actions: [
    {value: 'delete', label: 'Radera'},
  ],
};

const taskTypeFilterOptions = constants.task.types.map(value => ({
  value,
  label: taskFilter.type(value),
}));

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.uuid({id: 'id'}),
  commonFilterDefs.boolean({id: 'is_done'}),
  commonFilterDefs.applicationId({id: 'application_id'}),
  commonFilterDefs.userId({id: 'user_id'}),
  commonFilterDefs.select({id: 'type'}, taskTypeFilterOptions),
  commonFilterDefs.boolean({id: 'lottery_assign_application'}),
  commonFilterDefs.dateRangeOnField({id: 'date'}, [
    {value: 'time_starts', label: 'Börjar'},
    {value: 'time_expires', label: 'Slutar'},
  ]),
  ...[
    'time_starts',
    'time_expires',
    'created_at',
    'updated_at',
    'distributed_at',
    'postponed_at',
    'completed_at',
    'failed_at',
  ].map(id => ([
    commonFilterDefs.dateRange({id}),
    commonFilterDefs.dateRelative({id: id + '_relative'}),
  ])).reduce((a, b) => a.concat(b), []),
].map(filterDefinition => ({
  title: taskFilter.indexColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.select(),
  ...([
    commonColumnDefs.taskId({
      id: 'id',
      cellProps: {rowAsObject: true},
    }),
    commonColumnDefs.cell('temporal_status', TableCells.TaskTemporalStatusTableCell),
    commonColumnDefs.cell('type'),
    commonColumnDefs.applicationId({
      id: 'application_id',
      cellProps: {idKey: 'application_id', objKey: 'Application'},
    }),
    commonColumnDefs.cell('time'),
    commonColumnDefs.datetime({id: 'time_starts', sortable: true}),
    commonColumnDefs.datetime({id: 'time_expires', sortable: true}),
    commonColumnDefs.userId({
      id: 'user_id',
      cellProps: {idKey: 'user_id', objKey: 'User'},
    }),
    commonColumnDefs.cell('description'),
    commonColumnDefs.cell('postponed'),
    commonColumnDefs.datetime({id: 'postponed_at', sortable: true}),
    commonColumnDefs.cell('score'),
    commonColumnDefs.cell('stage'),
    commonColumnDefs.datetime({id: 'distributed_at', sortable: true}),
    commonColumnDefs.datetime({id: 'completed_at', sortable: true}),
    commonColumnDefs.datetime({id: 'failed_at', sortable: true}),
    commonColumnDefs.datetime({id: 'created_at', sortable: true}),
    commonColumnDefs.datetime({id: 'updated_at', sortable: true}),
    commonColumnDefs.booleanNull({id: 'lottery_assign_application'}),
    commonColumnDefs.cell('flags', TaskFlagTableCell),
  ].map(columnDefinition => {
    const { id } = columnDefinition;
    const title = taskFilter.indexColumnName(id);
    const cellValue = task => taskFilter.indexField(task, id);
    return {
      ...columnDefinition,
      cellValue,
      title,
    };
  })),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
    props => (
      <TaskExecuteGuard {...props} permissionFn={taskUtils.canComplete}>
        <TableCellActions.ExecuteButton
          {...props}
          title="Markera återkomsten som avklarad"
          variant="outline-success"
          mutateVars={{
            rowId: String(props.row.id),
            url: `/task/${props.row.id}/complete`,
            method: 'POST',
            onSuccessNotyf: {type: 'success', message: 'Återkomsten markerad som avklarad'},
          }}
        >
          <CheckCircle size={14} />
        </TableCellActions.ExecuteButton>
      </TaskExecuteGuard>
    ),
    props => (
      <TaskExecuteGuard {...props} permissionFn={taskUtils.canUncomplete}>
        <TableCellActions.ExecuteButton
          {...props}
          title="Markera återkomsten som oavklarad"
          variant="outline-primary"
          mutateVars={{
            rowId: String(props.row.id),
            url: `/task/${props.row.id}/uncomplete`,
            method: 'POST',
            onSuccessNotyf: {type: 'default', message: 'Återkomsten markerad som oavklarad'},
          }}
        >
          <XCircle size={14} />
        </TableCellActions.ExecuteButton>
      </TaskExecuteGuard>
    ),
    props => (
      <TaskExecuteGuard {...props} permissionFn={taskUtils.canFail}>
        <TableCellActions.ExecuteButton
          {...props}
          title="Markera återkomsten som misslyckad"
          variant="outline-danger"
          mutateVars={{
            rowId: String(props.row.id),
            url: `/task/${props.row.id}/fail`,
            method: 'POST',
            onSuccessNotyf: {type: 'danger', message: 'Återkomsten markerad som misslyckad'},
          }}
        >
          <Meh size={14} />
        </TableCellActions.ExecuteButton>
      </TaskExecuteGuard>
    ),
    PostponeTaskTableCellAction,
    EditTaskTableCellAction,
    props => (
      <TaskExecuteGuard {...props} permissionFn={taskUtils.canDelete}>
        <TableCellActions.ExecuteModalConfirmButton
          {...props}
          title="Radera återkomsten"
          variant="outline-danger"
          mutateVars={{
            deletion: true,
            rowId: String(props.row.id),
            url: `/task/${props.row.id}`,
            method: 'DELETE',
            onSuccessNotyf: {type: 'warning', message: 'Återkomsten raderades'},
          }}
        >
          <Trash size={14} />
        </TableCellActions.ExecuteModalConfirmButton>
      </TaskExecuteGuard>
    ),
  ]),
];

function EditTaskTableCellAction (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <TaskExecuteGuard {...props} permissionFn={taskUtils.canEdit}>
      <ModalOpeningButton
        Modal={EditTaskModal as any}
        modalProps={{taskId: row.id}}
        variant="outline-primary"
        className="px-1"
        title="Redigera återkomsten"
      >
        <Edit2 size={14} />
      </ModalOpeningButton>
    </TaskExecuteGuard>
  );
}

function PostponeTaskTableCellAction (props: TableColumnCellProps) {
  const { row } = props;
  return (
    <TaskExecuteGuard {...props} permissionFn={taskUtils.canPostpone}>
      <ModalOpeningButton
        Modal={PostponeTaskModal as any}
        modalProps={{taskId: row.id}}
        variant="outline-primary"
        className="px-1"
        title="Skjut upp återkomsten"
      >
        <Calendar size={14} />
      </ModalOpeningButton>
    </TaskExecuteGuard>
  );
}

interface TaskRowExecuteGuard extends TableColumnCellProps {
  permissionFn: (task: TaskRow, userId: string, permission: PermissionCheckFunction) => boolean;
}

function TaskExecuteGuard (props: TaskRowExecuteGuard) {
  const { row, permissionFn, children } = props;
  const { userId, permission } = useAuth();
  if (!permissionFn(row as TaskRow, userId, permission)) {
    return null;
  }
  return <>{children}</>;
}

function TaskFlagTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return <TaskFlags value={row as TaskRow} size={14} />;
}
