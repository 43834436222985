import React from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { SmsTemplateRow } from 'shared/types/sms_template';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as SmsTemplateFormFields from 'src/smsTemplate/SmsTemplateFormFields';
import * as TemplateFormFields from 'src/template/TemplateFormFields';
import * as formUtils from 'src/utils/form';
import { VariableTable } from 'src/template/TemplateVariableTables';
import {Copy, Trash} from 'lucide-react';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import {GuardPermission} from 'src/guards/AuthGuards';

export interface SmsTemplateEditFormValues extends SmsTemplateRow {
}

interface SmsTemplateEditFormProps {
  className?: string;
  initialValues: SmsTemplateEditFormValues;
  onSubmit: (values: SmsTemplateEditFormValues, helpers: FormikHelpers<SmsTemplateEditFormValues>) => Promise<any>;
  onDelete?: () => void;
  onClone?: (values: SmsTemplateEditFormValues) => Promise<any>;
  submitError?: Error | null;
  cloneError?: Error | null;
  disabled?: boolean;
}

export default function SmsTemplateEditForm (props: SmsTemplateEditFormProps) {
  const { initialValues, submitError, cloneError, onDelete, onClone, onSubmit, className } = props;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className} enableReinitialize>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>

          <Row className="px-3">
            <Col xl={6}>
              <TemplateFormFields.Name className="mt-0" />
              <TemplateFormFields.IsArchived />
              <Row>
                <Col>
                  <TemplateFormFields.Language />
                </Col>
                <Col>
                  <SmsTemplateFormFields.Category />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col>
                  <TemplateFormFields.RoleVisible />
                </Col>
                <Col>
                  <TemplateFormFields.RoleEditable />
                </Col>
              </Row>
              <hr className="mb-0" />
              <SmsTemplateFormFields.Body />
            </Col>
            <Col xl={6}>
              <VariableTable
                className="mt-3 mb-1"
                templateType="sms"
                templateCategory={formProps.values.category}
              />
            </Col>
          </Row>

          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <ErrorAlert error={cloneError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
              {onDelete ? (
                <GuardPermission permission="template_delete">
                  <ConfirmActionModalButton
                    type="button"
                    className="rounded d-flex gap-1 align-items-center"
                    variant="outline-danger"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    onConfirm={onDelete}
                    message="Är du säker på att du vill radera SMS-mallen?"
                    title="Radera den här SMS-mallen"
                  >
                    <Trash size={18} />
                    Radera
                  </ConfirmActionModalButton>
                </GuardPermission>
              ) : <div></div>}
              <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
                {onClone && (
                  <ButtonPromiseSpinner
                    type="button"
                    className="rounded d-flex gap-1"
                    variant="success"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    onClick={() => onClone(formProps.values)}
                    title="Klona den här mallen"
                  >
                    <Copy size={18} />
                    Klona
                  </ButtonPromiseSpinner>
                )}
                <ButtonSpinner
                  type="submit"
                  className="rounded"
                  variant="success"
                  isLoading={formProps.isSubmitting}
                  disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                >
                  Spara
                </ButtonSpinner>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export function smsTemplateToFormValues (smsTemplate: SmsTemplateRow): SmsTemplateEditFormValues {
  return formUtils.rowToFormValues(smsTemplate) as SmsTemplateEditFormValues;
}

export function formValuesToUpdate (initialValues: SmsTemplateEditFormValues, values: SmsTemplateEditFormValues): Partial<SmsTemplateEditFormValues> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
