import React from 'react';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import { Card } from 'react-bootstrap';
import { Number } from 'src/formatters/NumberFormatters';
import styled from 'styled-components';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { CardTitleIcon } from 'src/cards/CardHelpers';

const CounterContainer = styled.div`
  display: block;
  font-size: 400%;
  line-height: 1.25em;
`;

const CounterUnit = styled.span`
  font-size: 80%;
  line-height: 1em;
`;

interface CounterCardProps {
  isLoading?: boolean;
  onRefetch?: () => void;
  title: string;
  count?: number;
  highMark?: number;
  url?: string;
}

const CounterCard: React.FC<CounterCardProps> = React.memo(function CounterCard (props: CounterCardProps) {
  const { url, title, count, highMark, isLoading, onRefetch } = props;

  const hasCount = typeof count === 'number';
  const isHigh = typeof highMark !== 'undefined' && count > highMark;

  return (
    <Card className="flex-fill w-100">
      <Card.Header className="pb-0 card-header-flush">
        <CardTitleIcon
          title={title}
          spinning={isLoading}
        >
          <RefreshObjectButton
            disabled={isLoading}
            onClick={onRefetch}
          />
        </CardTitleIcon>
      </Card.Header>
      <CounterContainer className="ps-3 pb-2 font-weight-bold">
        {url ? (
          <Link to={url} className={classNames({'text-danger': isHigh})}>
            {hasCount ? <Number value={count} /> : '-'}
            {' '}<CounterUnit className="unit">st</CounterUnit>
          </Link>
        ) : (
          <>
            {hasCount ? <Number value={count} /> : '-'}
            {' '}<CounterUnit className="unit">st</CounterUnit>
          </>
        )}
      </CounterContainer>
    </Card>
  );
});
export default CounterCard;
