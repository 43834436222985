import React from 'react';
import {Edit3} from 'lucide-react';
import SimpleModalOpeningButton, { SimpleModalOpeningButtonProps } from 'src/buttons/SimpleModalOpeningButton';

export default function CustomerConsentModalButton (props: SimpleModalOpeningButtonProps) {
  const { children, ...restOfProps } = props;
  return (
    <SimpleModalOpeningButton
      modalProps={{backdrop: 'static'}}
      modalBodyClassName=""
      className="d-flex gap-1 align-items-center"
      label={<><Edit3 size={14} />{' '}Nytt samtycke</>}
      variant="outline-success"
      modalTitle="Nytt samtycke"
      {...restOfProps}
    >
      {children}
    </SimpleModalOpeningButton>
  );
}
