import React, {useMemo, useRef} from 'react';
import classNames from 'classnames';
import { Button, Card } from 'react-bootstrap';
import useRemount from 'src/hooks/useRemount';

interface TemplatePreviewCardProps {
  className?: string;
  url: string;
  height?: number;
  onReload?: () => void;
}

export default function TemplatePreviewCard (props: TemplatePreviewCardProps) {
  const { className, height = 400, url } = props;

  const [mounted, remount] = useRemount();

  const ref = useRef<HTMLObjectElement>();

  const onClickReload = () => {
    if (!ref.current) return;
    remount();
  };

  return (
    <Card className={classNames('p-2', className)}>
      {mounted && (
        <object
          data={url}
          ref={ref}
          type="text/html"
          width="100%"
          height={height}
        >
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="text-center">
              Webbläsaren stödjer inte inbäddade objekt eller så inträffade ett fel på servern när förhandsgranskningen skulle laddas.
            </p>
            <Button onClick={onClickReload}>Tvinga omladdning</Button>
          </div>
        </object>
      )}
    </Card>
  );
}

interface ReloadOnSubmissionUrlProviderProps {
  url: string;
  isSubmitting: boolean;
  children: (url: null | string) => React.ReactNode;
}

export function ReloadOnSubmissionUrlProvider (props: ReloadOnSubmissionUrlProviderProps) {
  const { url:outerUrl, isSubmitting, children } = props;

  const url = useMemo(() => {
    return isSubmitting ? null : outerUrl;
  }, [isSubmitting, outerUrl]);

  return children(url);
}
