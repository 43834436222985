import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import GroupModalBody from 'src/group/GroupModalBody';
import { Name } from 'src/group/GroupFormatters';
import {GroupRow} from 'shared/types/group';
import Anchor from  'src/anchors/Anchor';

interface GroupAnchorProps {
  group: {id: GroupRow['id'], name?: GroupRow['name']} ;
}

export default function GroupAnchor (props: GroupAnchorProps) {
  const { group, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={group} />}
      name="Grupp"
    >
      <GroupModalBody id={group.id} />
    </ModalAnchor>
  );
}

export function GroupPlainAnchor (props: GroupAnchorProps) {
  const { group, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/groups/${group.id}`}
      name="Gruppen"
    >
      <Name value={group} />
    </Anchor>
  );
}
