import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {ApplicationRow} from 'shared/types/application';
import CustomerNoteListCard from 'src/customer/CustomerNoteListCard';
import ApplicationUcCard from 'src/application/ApplicationUcCard';
import ApplicationSanctionListCard from 'src/application/ApplicationSanctionListCard';
import ApplicationPepQueryCard from 'src/application/ApplicationPepQueryCard';
import ApplicationCustomerTryggsamLeadCard from 'src/application/ApplicationTryggsamCard';
import ApplicationRoleCard from 'src/application/ApplicationRoleCard';
import ApplicationControlPanelCard from 'src/application/ApplicationControlPanelCard';
import ApplicationBidCard from 'src/application/ApplicationBidCard';

interface ApplicationPageTabComparatorProps {
  application: ApplicationRow;
}

export default function ApplicationPageTabComparator (props: ApplicationPageTabComparatorProps) {
  const { application } = props;
  return (
    <div>
      <Row className="px-4 pt-4">
        <Col md={8}>
          <ApplicationBidCard applicationId={application.id} />
        </Col>
        <Col md={4}>
          <ApplicationControlPanelCard application={application} />
          <ApplicationRoleCard application={application} />
          <CustomerNoteListCard customerId={application.customer_id} extendedTitle />
          <ApplicationUcCard application={application} applicationId={application.id} />
          <ApplicationSanctionListCard application={application} applicationId={application.id} />
          <ApplicationCustomerTryggsamLeadCard application={application} />
          <ApplicationPepQueryCard applicationId={application.id} />
        </Col>
      </Row>
    </div>
  );
}


