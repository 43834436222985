import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as miscFilters from 'shared/filter/misc';
import constants from 'shared/constants';

export function Name (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Namn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="name"
    />
  );
}

export function Language (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Språk"
      required
      {...props}
      name="language"
    >
      {constants.languages.map((language: string) => (
        <option key={language} value={language}>{miscFilters.language(language)}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function IsArchived (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      className="mt-3"
      label="Mallen är arkiverad (ej valbar/dold)"
      type="checkbox"
      {...props}
      name="is_archived"
    />
  );
}

export function RoleEditable () {
  return (
    <div>
      <div className="form-label mb-0 mt-3">Kan redigera underobjekt</div>
      <FormikFormControls.StringListCheck
        fieldName="role_editable"
        className="mt-1"
        label="Handläggare"
        value="salesman"
      />
      <FormikFormControls.StringListCheck
        fieldName="role_editable"
        className="mt-1"
        label="Rådgivare"
        value="advisor"
      />
    </div>
  );
}

export function RoleVisible () {
  return (
    <div>
      <div className="form-label mb-0 mt-3">Kan skapa underobjekt</div>
      <FormikFormControls.StringListCheck
        fieldName="role_visibility"
        className="mt-1"
        label="Handläggare"
        value="salesman"
      />
      <FormikFormControls.StringListCheck
        fieldName="role_visibility"
        className="mt-1"
        label="Rådgivare"
        value="advisor"
      />
    </div>
  );
}
