import React, {useState} from 'react';
import { CardTitleIcon, ErrorAlertCardBody  } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GroupRow, GroupRowWithRelations } from 'shared/types/group';
import { Card, Form } from 'react-bootstrap';
import { Trash, Users } from 'lucide-react';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableContextProvider } from 'src/tables/TableContext';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import {useTableUpdateRowMutation} from 'src/hooks/useTableUtils';
import CardBodyTable from 'src/tables/CardBodyTable';
import {GuardPermission} from 'src/guards/AuthGuards';
import useNotyf from 'src/hooks/useNotyf';
import * as api from 'src/api';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ButtonSpinner from 'src/spinners/ButtonSpinner';

interface UserGroupListCardProps {
  userId: string;
  searchParams?: Record<string, any>;
  className?: string;
}

export default function UserGroupListCard (props: UserGroupListCardProps) {
  const { userId, searchParams = {}, className } = props;

  const queryKey = ['/group/search', {params: {...searchParams, user_id: userId, include: 'users'}}];

  const query = useQuery<GroupRowWithRelations[], Error>({
    queryKey,
  });

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
    onSuccess: () => {
      query.refetch();
    },
  });

  const rows = query.data ?? [];

  const memberGroupIds = rows.map(group => group.id);

  return (
    <Card className={className}>
      <Card.Header>
        <CardTitleIcon
          title="Grupper"
          Icon={<Users size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
        <CardBodyTable
          striped
          params={{userId}}
          columnDefinitions={columnDefs}
          rows={rows}
          isFetched={query.isFetched}
        />
      </TableContextProvider>
      <GuardPermission permission="group_user_add">
        <UserAddGroupForm
          userId={userId}
          searchParams={{not_id: memberGroupIds}}
          onAdded={() => query.refetch()}
        />
      </GuardPermission>
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.groupId({
    id: 'id',
    title: 'Användarens grupper',
    cellProps: {rowAsObject: true},
  }),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
    props => (
      <GuardPermission permission="group_user_remove">
        <TableCellActions.ExecuteModalConfirmButton
          {...props}
          title="Ta bort medlemskap i grupp"
          variant="outline-danger"
          modalProps={{message: 'Är du säker på att du vill ta bort användaren från gruppen?'}}
          mutateVars={{
            rowId: String(props.row.id),
            url: `/group/${props.row.id}/users/${props.params.userId}`,
            deletion: true,
            method: 'DELETE',
            onSuccessNotyf: {type: 'warning', message: 'Medlemskapet har tagits bort'},
          }}
        >
          <span><Trash size={14} /></span>
        </TableCellActions.ExecuteModalConfirmButton>
      </GuardPermission>
    ),
  ]),
];

interface UserAddGroupFormProps {
  userId: string;
  searchParams?: Record<string, any>;
  onAdded: (groupId: string) => void;
}

function UserAddGroupForm (props: UserAddGroupFormProps) {
  const { userId, searchParams = {}, onAdded } = props;

  const notyf = useNotyf();

  const query = useQuery<GroupRow[], Error>({
    queryKey: ['/group/search', {params: {is_active: true, ...searchParams}}],
  });

  const addMutation = useMutation<any, Error, string>({
    mutationFn: async groupId => {
      await api.request({
        errorToNotyf: notyf,
        url: `/group/${groupId}/users/${userId}`,
        method: 'PUT',
        data: {},
      });

      notyf.success({type: 'success', message: 'Användaren lades till i gruppen'});
      onAdded?.(groupId);
      setGroupId('');
    },
  });

  const [groupId, setGroupId] = useState<string>('');

  const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    setGroupId(ev.target.value);
  };

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    addMutation.mutate(groupId);
  };

  const data = query.data || [];
  return (
    <Card.Footer className="position-relative">
      <Form onSubmit={onSubmit}>
        <OverlaySpinner isLoading={query.isLoading || query.isRefetching} />
        <ErrorAlert error={query.error} className="mb-2" />
        <div className="d-flex gap-2">
          <Form.Group className="flex-grow w-100">
            <Form.Select
              name="groupId"
              disabled={!query.isSuccess || !data.length}
              onChange={onChange}
              value={groupId}
            >
              <option value="">Välj en grupp</option>
              {query.isSuccess && data.map(item => (
                <option value={item.id} key={item.id}>{item.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <ButtonSpinner
            className="flex-shrink-0"
            isLoading={addMutation.isPending}
            type="submit"
            disabled={!groupId || addMutation.isPending}
            variant="success"
          >
            Lägg till användare
          </ButtonSpinner>
        </div>
      </Form>
    </Card.Footer>
  );
}
