import React, {useMemo} from 'react';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import * as api from 'src/api';
import { useParams, useNavigate } from 'react-router-dom';
import ApplicationPageTabProcess from 'src/application/ApplicationPageTabProcess';
import ApplicationPageTabForm from 'src/application/ApplicationPageTabForm';
import ApplicationPageTabComparator from 'src/application/ApplicationPageTabComparator';
import BlockSpinner from 'src/spinners/BlockSpinner';
import useWebsocketModelUpdates from 'src/hooks/useWebsocketModelUpdates';
import * as IconButtons from 'src/buttons/IconButtons';
import {useMutation, useQuery} from '@tanstack/react-query';
import {ApplicationRow, ApplicationRowWithRelations} from 'shared/types/application';
import Breadcrumbs from 'src/components/Breadcrumbs';
import { ErrorAlertCardBody, CardTitleIconStatus, CardQuickButtons  } from 'src/cards/CardHelpers';
import * as ApplicationFormatters from 'src/application/ApplicationFormatters';
import {Card, Col, Nav, Row, Tab} from 'react-bootstrap';
import {Activity, BarChart2, Box, Edit2, Mail, Repeat} from 'lucide-react';
import {GuardPermission} from 'src/guards/AuthGuards';
import ApplicationAssociatedObjectList from 'src/application/ApplicationAssociatedObjectList';
import ApplicationLogCard from 'src/application/ApplicationLogCard';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';
import useNotyf from 'src/hooks/useNotyf';
import TriggerTable from 'src/trigger/TriggerTable';
import EmailContactControlCard from 'src/email/EmailContactControlCard';
import SmsContactControlCard from 'src/sms/SmsContactControlCard';
import LetterContactControlCard from 'src/letter/LetterContactControlCard';
import ExecuteButton from 'src/buttons/ExecuteButton';
import ApplicationControlPanelCard from 'src/application/ApplicationControlPanelCard';
import useAuth from 'src/hooks/useAuth';
import ApplicationRoleCard from 'src/application/ApplicationRoleCard';
import NavLinkLockable from 'src/components/NavLinkLockable';

interface ApplicationPageParams {
  applicationId: string;
  tab?: string;
}

export default function ApplicationPage () {
  const { applicationId, tab = 'form' } = useParams() as unknown as ApplicationPageParams;

  const queryClientUtils = useQueryClientUtils();
  const notyf = useNotyf();
  const navigate = useNavigate();
  const auth = useAuth();

  const query = useQuery<ApplicationRowWithRelations, Error>({
    queryKey: [`/application/${applicationId}`],
    meta: {
      onSuccess: application => {
        wsModelUpdates.setIds([application.id]);
      },
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/application/${applicationId}`,
        method: 'DELETE',
      });
      navigate('/');
      notyf.success({type: 'danger', message: 'Ansökan raderades'});
    },
  });

  const wsModelUpdates = useWebsocketModelUpdates<ApplicationRow>('application', function (id, update) {
    const row = {...update, id};
    queryClientUtils.applyUpdates({queryKey: [`/application/${applicationId}`]}, [row], {single: true});
  });

  const onClickDelete = () => {
    return deleteMutation.mutateAsync();
  };

  const application = query.data;

  const canDelete: boolean = useMemo(() => {
    if (auth.permission('application_delete:non_empty')) return true;
    return auth.permission('application_delete') && application?.status === 'new';
  }, [application, auth]);

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Ansökan" />
        <h1>Ansökan</h1>
        <Breadcrumbs
          crumbs={[
            ['/applications/all', 'Ansökningar'],
            [`/application/${applicationId}/form`, application ? <ApplicationFormatters.Title value={application} /> : applicationId],
          ]}
        />
        <BlockSpinner isLoading={query.isLoading} />
        <ErrorAlertCardBody error={query.error} className="mx-0 mt-0 mb-3" />
        {application && (
          <Card>
            <Card.Header>
              <CardTitleIconStatus
                as="h2"
                title={<ApplicationFormatters.Title value={application} />}
                Status={<ApplicationFormatters.StatusLabel value={application.status} className="px-3" />}
                Icon={<Box size={16} />}
                spinning={query.isRefetching}
              >
                {canDelete && (
                  <IconButtons.ConfirmModalDeleteButton
                    onConfirm={onClickDelete}
                    message="Är du säker på att du vill radera den här ansökan permanent?"
                  />
                )}
                <IconButtons.FavoriteButton type="application" id={application.id} />
                <IconButtons.InspectObjectModalButton object={application} />
                <IconButtons.CopyObjectIdButton id={application.id} />
                <IconButtons.CopyCurrentUrlButton />
                <IconButtons.RefreshObjectButton
                  disabled={query.isRefetching}
                  refetch={query.refetch}
                />
              </CardTitleIconStatus>
            </Card.Header>
            {application && (
              <ApplicationAssociatedObjectList application={application} />
            )}
            <Tab.Container
              activeKey={tab}
              transition={false}
              unmountOnExit={true}
              mountOnEnter={true}
            >
              <Nav className="nav-tabs pt-3 px-3">
                <NavLinkLockable
                  to={`/application/${applicationId}/form`}
                  Icon={Edit2}
                  title="Formulär"
                />
                <NavLinkLockable
                  to={`/application/${applicationId}/bankProcess`}
                  locked={application.status === 'new' || !auth.permission('application_read_bids')}
                  Icon={Repeat}
                  title="Processer"
                />
                <NavLinkLockable
                  to={`/application/${applicationId}/comparator`}
                  locked={application.status === 'new'}
                  Icon={BarChart2}
                  title="Profil"
                />
                <NavLinkLockable
                  to={`/application/${applicationId}/contact`}
                  Icon={Mail}
                  title="Kontakt"
                  locked={!auth.permission('application_read_contact')}
                />
                <NavLinkLockable
                  to={`/application/${applicationId}/triggers`}
                  Icon={Activity}
                  title="Händelsetriggers"
                  locked={!auth.permission('trigger:application')}
                />
                <NavLinkLockable
                  to={`/application/${applicationId}/log`}
                  Icon={Activity}
                  title="Logg"
                  locked={!auth.permission('application_read_log')}
                />
              </Nav>

              <Tab.Content>

                <Tab.Pane eventKey="form">
                  <ApplicationPageTabForm application={application} />
                </Tab.Pane>

                <GuardPermission permission="application_read_bids">
                  <Tab.Pane eventKey="bankProcess">
                    <Row className="px-4 pt-4">
                      <Col lg={9}>
                        <ApplicationPageTabProcess />
                      </Col>
                      <Col lg={3}>
                        <ApplicationControlPanelCard application={application} />
                        <ApplicationRoleCard application={application} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </GuardPermission>

                <Tab.Pane eventKey="comparator">
                  <ApplicationPageTabComparator application={application} />
                </Tab.Pane>

                <GuardPermission permission="application_read_contact">
                  <Tab.Pane eventKey="contact">
                    <Row className="px-4 pt-4">
                      <Col lg={9}>
                        <ApplicationPageTabContact />
                      </Col>
                      <Col lg={3}>
                        <ApplicationControlPanelCard application={application} />
                        <ApplicationRoleCard application={application} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </GuardPermission>

                <GuardPermission permission="trigger:application">
                  <Tab.Pane eventKey="triggers">
                    <Row className="px-4 pt-4">
                      <Col lg={9}>
                        <TriggerTable objectType="application" objectId={applicationId} />
                      </Col>
                      <Col lg={3}>
                        <ApplicationControlPanelCard application={application} />
                        <ApplicationRoleCard application={application} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </GuardPermission>

                <GuardPermission permission="application_read_log">
                  <Tab.Pane eventKey="log">
                    <Row className="px-4 pt-4">
                      <Col lg={9}>
                        <ApplicationLogCard applicationId={applicationId} />
                      </Col>
                      <Col lg={3}>
                        <ApplicationControlPanelCard application={application} />
                        <ApplicationRoleCard application={application} />
                      </Col>
                    </Row>
                  </Tab.Pane>
                </GuardPermission>

              </Tab.Content>

            </Tab.Container>
          </Card>
        )}
      </Content>
    </Main>
  );
}

// most tabs are exported here temporarily

export function ApplicationPageTabTriggers () {
  const { applicationId } = useParams() as unknown as ApplicationPageParams;
  return (
    <TriggerTable objectType="application" objectId={applicationId} />
  );
}

export function ApplicationPageTabContact () {
  const { applicationId } = useParams() as unknown as ApplicationPageParams;
  return (
    <div>
      <GuardPermission any permission={['email_create_application_quickcontact', 'sms_create_application_quickcontact']}>
        <CardQuickButtons title="Snabbkontakt: " className="mb-4">
          <GuardPermission permission="email_create_application_quickcontact">
            <ExecuteButton
              url={`/email/application/${applicationId}/quickcontact/offers_with_portal_link`}
              variant="secondary"
              size="sm"
              onSuccessNotyf={{type: 'success', message: 'E-postmeddelandet skickades'}}
            >
              Erbjudanden och portallänk (e-post)
            </ExecuteButton>
            <ExecuteButton
              url={`/email/application/${applicationId}/quickcontact/offers`}
              variant="secondary"
              size="sm"
              onSuccessNotyf={{type: 'success', message: 'E-postmeddelandet skickades'}}
            >
              Erbjudanden (e-post)
            </ExecuteButton>
          </GuardPermission>
          <GuardPermission permission="sms_create_application_quickcontact">
            <ExecuteButton
              url={`/sms/application/${applicationId}/quickcontact/offers_with_portal_link`}
              variant="secondary"
              size="sm"
              onSuccessNotyf={{type: 'success', message: 'SMS:et skickades'}}
            >
              Erbjudanden och portallänk (SMS)
            </ExecuteButton>
            <ExecuteButton
              url={`/sms/application/${applicationId}/quickcontact/unreachable`}
              variant="secondary"
              size="sm"
              onSuccessNotyf={{type: 'success', message: 'SMS:et skickades'}}
            >
              Kontaktförsök (SMS)
            </ExecuteButton>
          </GuardPermission>
        </CardQuickButtons>
      </GuardPermission>
      <SmsContactControlCard
        createPermission="sms_create_application"
        searchParams={{application_id: applicationId}}
        searchTemplateParams={{category: 'application', is_archived: 'false'}}
        objectType="application"
        objectId={applicationId}
      />
      <EmailContactControlCard
        createPermission="email_create_application"
        searchParams={{application_id: applicationId}}
        searchTemplateParams={{category: 'application', is_archived: 'false'}}
        objectType="application"
        objectId={applicationId}
      />
      <LetterContactControlCard
        createPermission="letter_create"
        searchParams={{application_id: applicationId}}
        searchTemplateParams={{is_archived: 'false'}}
        objectType="application"
        objectId={applicationId}
      />
    </div>
  );
}
