import React from 'react';
import classNames from 'classnames';
import {Table} from 'react-bootstrap';
import DateTime from 'src/formatters/DateTime';
import {PepQueryRow, TrapetsDoQueryResultIndividual} from 'shared/types/pep_query';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';

interface ResultTableProps {
  className?: string;
  pepQuery: PepQueryRow;
}

export function ResultTable (props: ResultTableProps) {
  const { className, pepQuery } = props;
  return (
    <Table size="sm" className={classNames(className, 'mb-0 align-middle')} style={{fontSize: '90%'}}>
      <tbody>
        <tr>
          <th>Utförd</th>
          <td>
            <DateTime value={pepQuery.trapets_doquery_result_at} />
          </td>
        </tr>
        <tr>
          <th>Träffar</th>
          <td>
            {pepQuery.trapets_doquery_result?.individuals?.length ?? 0} st
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

interface ResultIndividualTableProps {
  className?: string;
  individuals: TrapetsDoQueryResultIndividual[];
}
 
export function ResultIndividualTable (props: ResultIndividualTableProps) {
  const { className, individuals } = props;
  return (
    <Table size="sm" className={classNames(className, 'mb-0 align-middle')} style={{fontSize: '90%'}}>
      <thead>
        <tr>
          <th>Personens namn</th>
          <th>Källa</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {individuals.map(individual => (
          <tr key={individual.id}>
            <td>{individual.name}</td>
            <td>{individual.sourceName}</td>
            <td className="text-end"><InspectObjectModalButton object={individual} size="sm" className="py-0" /></td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
