import React from 'react';
import * as TableCells from 'src/tables/TableCells';
import { isFiniteNumber , numberfmt } from 'src/numbers';
import {TableColumnCellProps} from 'src/tables/Table';

interface DayCellProps extends TableColumnCellProps {

}

export function DayCell (props: DayCellProps) {
  const { value } = props;
  if (!isFiniteNumber(value)) return '-';
  return (
    <>
      {numberfmt(value, {maximumFractionDigits: 1})}
      {' '}dgr
    </>
  );
}

interface MaybeUserTableCellProps extends TableColumnCellProps {

}

export function MaybeUserTableCell (props: MaybeUserTableCellProps) {
  const { row } = props;
  if (row.id !== 'sum') {
    if (row.id === 'null') return <>-</>;
    const value = {id: row.id, name: row.values.user_name};
    return <TableCells.UserTableCell {...props} row={value} rowAsObject />;
  } 
  return (
    <strong>Summa</strong>
  );
}
