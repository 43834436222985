import React from 'react';
import sortBy from 'lodash/sortBy';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import classNames from 'classnames';
import { Plus } from 'lucide-react';
import { Move, Trash } from 'lucide-react';
import { Button, ButtonGroup, ButtonProps, Form } from 'react-bootstrap';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {
  WidgetGroups,
  WidgetList as WidgetListType,
  WidgetListState,
  WidgetListStateAction,
} from 'src/widgets/types';

interface WidgetListProps {
  widgetGroups?: WidgetGroups;
  allWidgets: WidgetListType;
  widgetProps?: Record<string, any>;
  state: WidgetListState;
  dispatch: React.Dispatch<WidgetListStateAction>;
}

export default function WidgetList (props: WidgetListProps) {
  const {
    widgetGroups = [],
    allWidgets,
    widgetProps = {},
    dispatch,
    state,
  }  = props;

  const handleDragEnd = result => {
    if (!result.destination) return;
    const { destination, source } = result;
    dispatch({type: 'swap', from: source.index, to: destination.index});
  };

  const availableWidgets = Object.keys(allWidgets).filter(id => {
    return !(state.current || []).find(item => item.id === id);
  }).map(id => ({...allWidgets[id]}));

  const itemsIndexed = state.current.map((item, index) => ({
    ...item,
    index,
  })).filter(item => allWidgets[item.id]);

  return (
    <>
      <ControlPanel
        layout={state.active}
        widgetGroups={widgetGroups}
        availableWidgets={availableWidgets}
        onChangeLayout={index => dispatch({type: 'activate', index})}
        onSubmit={id => dispatch({type: 'add', id})}
      />

      <div className="p-3 py-0">

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {itemsIndexed.map(item => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={item.index}
                  >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <WidgetContainer
                          Component={allWidgets[item.id].Component}
                          title={allWidgets[item.id].title}
                          dragHandleProps={provided.dragHandleProps}
                          isDragging={snapshot.isDragging}
                          onRemove={() => dispatch({type: 'remove', id: item.id})}
                          widgetProps={widgetProps}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {!itemsIndexed.length && (
          <p>Det finns ingenting här.</p>
        )}

      </div>
    </>
  );
}

function ControlPanel (props) {
  const { availableWidgets, widgetGroups, onSubmit, layout, onChangeLayout } = props;

  const hasGroups = widgetGroups?.length > 0;

  const groupedWidgets = hasGroups ? widgetGroups.map(group => {
    const widgets = Object.keys(availableWidgets).filter(id => {
      return availableWidgets[id].group === group.id;
    }).map(id => availableWidgets[id]);

    return {...group, widgets: sortBy(widgets, 'title')};
  }) : [];

  const [choice, setChoice] = React.useState('');

  const handleSubmit = ev => {
    ev.preventDefault();
    setChoice('');
    onSubmit(choice);
  };

  return (
    <Form onSubmit={handleSubmit} className="mx-3 mt-1 mb-3 d-flex justify-content-between gap-2">
      <ButtonGroup size="sm" className="rounded">
        {[0, 1, 2].map(value => (
          <FormControlWidgetLayoutButton
            key={value}
            value={value}
            layout={layout}
            onChangeLayout={onChangeLayout}
            disabled={layout === value}
          />
        ))}
      </ButtonGroup>
      <Form.Group className="d-flex flex-fill gap-2">
        <Form.Select
          size="sm"
          name="id"
          disabled={!availableWidgets.length}
          onChange={ev => setChoice(ev.target.value)}
          value={choice}
          required
        >
          <option value="">
            {!availableWidgets.length ? 'Inga fler rutor att lägga till' : 'Välj en ruta att lägga till'}
          </option>
          {hasGroups ? (
            <>
              {groupedWidgets.map(group => (
                <optgroup label={group.title} key={group.id}>
                  {group.widgets.map(({id, title}) => (
                    <option key={id} value={id}>{title}</option>
                  ))}
                </optgroup>
              ))}
            </>
          ) : (
            <>
              {Object.keys(availableWidgets).map(id => (
                <option key={id} value={availableWidgets[id].id}>{availableWidgets[id].title}</option>
              ))}
            </>
          )}
        </Form.Select>
        <Button
          type="submit"
          variant="primary"
          size="sm"
          className="px-1 rounded"
          disabled={!availableWidgets.find(w => w.id === choice)}
        >
          <Plus size={16} /> 
        </Button>
      </Form.Group>
    </Form>
  );
}

function WidgetContainer (props) {
  const {
    widgetProps,
    dragHandleProps,
    onRemove,
    isDragging,
    Component,
    title,
  } = props;

  const className = classNames('m-0 border', {'border-primary': isDragging});
  return (
    <div className="pb-3">
      <ErrorBoundary FallbackComponent={ErrorAlert}>
        <Component
          {...widgetProps}
          title={title}
          className={className}
          controls={(
            <span className="d-flex gap-1 flex-wrap align-items-center">
              <Button variant="" size="sm" className="btn-link p-0" {...dragHandleProps} title="Flytta">
                <Move size={16} />
              </Button>
              <Button variant="" size="sm" className="btn-link p-0" onClick={onRemove} title="Ta bort">
                <Trash size={16} />
              </Button>
            </span>
          )}
        />
      </ErrorBoundary>
    </div>
  );
}

interface FormControlWidgetLayoutButtonProps extends ButtonProps {
  value: number;
  layout: number;
  onChangeLayout: (index: number) => unknown;
}

function FormControlWidgetLayoutButton (props: FormControlWidgetLayoutButtonProps) {
  const { value, layout, onChangeLayout, ...otherProps } = props;
  return (
    <Button
      variant="outline-primary"
      size="sm"
      onClick={() => onChangeLayout(value)}
      className={value === layout ? 'text-black font-weight-bold' : ''}
      {...otherProps}
    >
      {value + 1}
    </Button>
  );
}
