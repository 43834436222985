import React from 'react';
import * as api from 'src/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { WorkTriggerTemplateRow } from 'shared/types/work_trigger_template';
import WorkTriggerTemplateCreateForm, {WorkTriggerTemplateCreateFormValues} from 'src/workTriggerTemplate/WorkTriggerTemplateCreateForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import {useNavigate} from 'react-router';
import { useEventModelEmitCreates } from 'src/hooks/useEventModels';

export default function WorkTriggerTemplateCreateModalBody () {
  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const emitCreate = useEventModelEmitCreates<WorkTriggerTemplateRow>('work_trigger_template');

  const createMutation = useMutation<WorkTriggerTemplateRow, Error, WorkTriggerTemplateCreateFormValues>({
    mutationFn: async vars => {
      const template = await api.request({
        url: '/work_trigger_template',
        method: 'POST',
        data: vars,
      });

      queryClient.setQueryData([`/work_trigger_template/${template.id}`], template);
      notyf.success({type: 'success', message: 'Triggermallen skapades'});
      modalStateContext.onHide();
      emitCreate(template);
      navigate(`/work_trigger_templates/${template.id}`);

      return template;
    },
  });

  const onSubmit = async values => {
    return await createMutation.mutateAsync(values);
  };

  const initialValues: any = {
    description: '',
    task: 'noop.reflect',
    object_type: 'application',
  };

  return (
    <div>
      <WorkTriggerTemplateCreateForm
        className="mt-0"
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitError={createMutation.error}
      />
    </div>
  );
}
