import React from 'react';
import NavbarDropdown from 'src/navbar/NavbarDropdown';
import * as api from 'src/api';
import { useQuery } from '@tanstack/react-query';
import {
  Row,
  Col,
  ListGroup,
  Dropdown,
} from 'react-bootstrap';
import {
  Phone,
} from 'lucide-react';

// TODO
// 1. Implement tasks fetching
// 2. Show badge when have upcoming tasks
// 3. Error handling

export default function NavbarLogDropdown (props) {
  const query = useQuery({
    queryKey: ['accountTasks'],
    queryFn: () => api.request({
      url: '/task/user',
      params: {},
    }),
  });

  const tasks = query.data?.tasks || [];
  return (
    <NavbarDropdown
      footer="Visa alla återkomster"
      icon={Phone}
      count={tasks.length}
      showBadge={tasks.length > 0}
    >
      <div className="dropdown-menu-header position-relative">
        Återkomster
      </div>
      <ListGroup>
        {tasks.map((item, key) => (
          <NavbarDropdownTask
            key={key}
            title={item.name}
            description={item.description}
            time={item.time}
            spacing
          />
        ))}
      </ListGroup>
      <Dropdown.Header className="dropdown-menu-footer">
        <span className="text-muted">Visa alla återkomster</span>
      </Dropdown.Header>
    </NavbarDropdown>
  );
}

function NavbarDropdownTask (props) {
  const { icon, title, description, time, spacing } = props;
  return (
    <ListGroup.Item>
      <Row className="align-items-center g-0">
        <Col xs={2}>{icon}</Col>
        <Col xs={10} className={spacing ? 'pl-2' : ''}>
          <div className="text-dark">{title}</div>
          <div className="text-muted small mt-1">{description}</div>
          <div className="text-muted small mt-1">{time}</div>
        </Col>
      </Row>
    </ListGroup.Item>
  );
}
