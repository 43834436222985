import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { SIDEBAR_BEHAVIOR } from 'src/constants';
import {
  // PieChart,
  Settings,
  // Tool,
  LogOut,
  Minimize2,
  Maximize2,
  CornerDownLeft,
} from 'lucide-react';
import useSidebar from 'src/hooks/useSidebar';
import useAuth from 'src/hooks/useAuth';
import { AvatarImg } from 'src/user/UserFormatters';
import IdProvider from 'src/components/IdProvider';

export default function NavbarUser () {
  const { behavior, setBehavior } = useSidebar();
  const auth = useAuth();

  const onCycleBehavior = () => {
    const nextValue = behavior === SIDEBAR_BEHAVIOR.STICKY ? SIDEBAR_BEHAVIOR.COMPACT : SIDEBAR_BEHAVIOR.STICKY;
    setBehavior(nextValue);
  };

  const onClickLogout = () => {
    auth.logout();
  };

  const onClickSwitchBack = () => {
    auth.switchBack();
  };

  return (
    <Dropdown className="nav-item" align="end" autoClose="outside">
      <span className="d-inline-block d-sm-none">
        <Dropdown.Toggle as="a" className="nav-link">
          <Settings size={18} className="align-middle" />
        </Dropdown.Toggle>
      </span>
      <span className="d-none d-sm-inline-block">
        <Dropdown.Toggle as="a" className="nav-link">
          <AvatarImg
            user={auth.user}
            className="me-1"
          />
          <span className="text-dark">{auth.user?.name}</span>
        </Dropdown.Toggle>
      </span>
      <Dropdown.Menu>
        {/* <Dropdown.Item> */}
        {/*   <Tool size={18} className="align-middle me-2" /> */}
        {/*   Inställningar */}
        {/* </Dropdown.Item> */}
        {/* <Dropdown.Item> */}
        {/*   <PieChart size={18} className="align-middle me-2" /> */}
        {/*   Min statistik */}
        {/* </Dropdown.Item> */}
        {/* <Dropdown.Divider /> */}
        {/* <Dropdown.Divider /> */}
        <div className="px-2">
          <IdProvider>
            {id => (
              <Form.Switch
                id={id}
                label={<><Minimize2 size={15} /> / <Maximize2 size={15} /> Meny</>}
                name="behavior"
                checked={behavior === SIDEBAR_BEHAVIOR.STICKY}
                onChange={onCycleBehavior}
              />
            )}
          </IdProvider>
        </div>
        <Dropdown.Divider />
        {auth.user?.oldUser && (
          <>
            <Dropdown.Item as="button" onClick={onClickSwitchBack} type="button">
              <CornerDownLeft size={18} className="align-middle me-2" />
              Återgå
            </Dropdown.Item>
            <Dropdown.Divider />
          </>
        )}
        <Dropdown.Item as="button" onClick={onClickLogout} type="button">
          <LogOut size={18} className="align-middle me-2" />
          Logga ut
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
