import React from 'react';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import { Card } from 'react-bootstrap';
import {useMutation} from '@tanstack/react-query';
import * as api from 'src/api';
import StatusBaseLabel from 'src/formatters/StatusBaseLabel';

type AccountValidatorResult = {
  valid: false;
  error: Error;
} | {
  valid: boolean;
  bankName: string;
  sortingCode: string;
  accountNumber: string;
  type: '1' | '2';
  comment: string;
};

interface AccountNumberValidatorCardProps {
  className?: string;
  accountNumber?: null | string;
  clearingNumber?: null | string;
}

type AccountValidatorVars = Pick<AccountNumberValidatorCardProps, 'clearingNumber' | 'accountNumber'>;

function AccountNumberValidatorCard (props: AccountNumberValidatorCardProps) {
  const { className, clearingNumber, accountNumber } = props;

  const validateMutation = useMutation<AccountValidatorResult, Error, AccountValidatorVars>({
    mutationFn: vars => api.request({
      method: 'POST',
      data: vars,
      url: '/misc/accountNumberValidate',
    }),
  });

  const onClick = () => {
    validateMutation.mutateAsync({clearingNumber, accountNumber});
  };

  const disabled = !clearingNumber || !accountNumber || validateMutation.isPending;
  const result = validateMutation.data;

  return (
    <Card className={className}>
      <Card.Body className="p-3 d-flex gap-2 flex-wrap align-items-center">
        <ButtonSpinner
          variant="outline-info"
          isLoading={validateMutation.isPending}
          disabled={disabled}
          onClick={onClick}
        >
          Kontrollera kontonummer
        </ButtonSpinner>
        {validateMutation.isSuccess && result && (
          <>
            <StatusBaseLabel value={result.valid ? 'green' : 'yellow'} className="px-2">
              {result.valid ? 'Giltigt' : 'Ej giltigt'}
            </StatusBaseLabel>
            {!('error' in result) && (
              <div className="border rounded p-1">
                <strong>Konto: </strong>{' '}<code>{result.sortingCode}/{result.accountNumber}</code>
                {' '}<strong>Bank: </strong>{result.bankName}
                {' '}<strong>Kontotyp: </strong>{result.type}
              </div>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
}
export default React.memo(AccountNumberValidatorCard);
