export type SidebarPosition = 'left' | 'right';

export const SIDEBAR_POSITION: Record<string, SidebarPosition> = {
  LEFT: 'left',
  RIGHT: 'right',
};

export type SidebarBehavior = 'sticky' | 'fixed' | 'compact';

export const SIDEBAR_BEHAVIOR: Record<string, SidebarBehavior> = {
  STICKY: 'sticky',
  FIXED: 'fixed',
  COMPACT: 'compact',
};

export type Layout = 'fluid' | 'boxed';

export const LAYOUT: Record<string, Layout> = {
  FLUID: 'fluid',
  BOXED: 'boxed',
};

export const THEME_PALETTE_LIGHT = {
  primary: '#3B82EC',
  'primary-dark': '#1659c7',
  'primary-light': '#84aef2',
  secondary: '#495057',
  success: '#4BBF73',
  info: '#1F9BCF',
  warning: '#f0ad4e',
  danger: '#d9534f',
  white: '#fff',
  'gray-100': '#f4f7f9',
  'gray-200': '#e2e8ee',
  'gray-300': '#dee6ed',
  'gray-400': '#ced4da',
  'gray-500': '#adb5bd',
  'gray-600': '#6c757d',
  'gray-700': '#495057',
  'gray-800': '#020202',
  'gray-900': '#212529',
  black: '#000',
};
