import React from 'react';
import classNames from 'classnames';
import {Table} from 'react-bootstrap';
import DateTime from 'src/formatters/DateTime';
import {CustomerTryggsamLeadRow} from 'shared/types/customer_tryggsam_lead';
import UserAnchor from 'src/user/UserAnchor';

interface ResultTableProps {
  className?: string;
  customerTryggsamLead: CustomerTryggsamLeadRow;
}

export function ResultTable (props: ResultTableProps) {
  const { className, customerTryggsamLead } = props;
  return (
    <Table size="sm" className={classNames(className, 'mb-0 align-middle')} style={{fontSize: '90%'}}>
      <tbody>
        <tr>
          <th>Skapad</th>
          <td>
            <DateTime value={customerTryggsamLead.created_at} />
          </td>
        </tr>
        {customerTryggsamLead.created_by_user_id && (
          <tr>
            <th>Skapad av</th>
            <td><UserAnchor user={{id: customerTryggsamLead.created_by_user_id}} /></td>
          </tr>
        )}
        {customerTryggsamLead.result_code && (
          <tr>
            <th>Resultat</th>
            <td>
              {customerTryggsamLead.result_code} / {customerTryggsamLead.result_reason}
            </td>
          </tr>
        )}
        {customerTryggsamLead.error && (
          <tr className="align-top">
            <th>Fel</th>
            <td>
              <pre className="mb-0">{JSON.stringify(customerTryggsamLead.error, null, 2)}</pre>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

