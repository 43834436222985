import React, {useMemo, useState} from 'react';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {Card} from 'react-bootstrap';
import {BarChart} from 'lucide-react';
import {ApplicationRow} from 'shared/types/application';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import { TableStateOrder  } from 'src/tables/types';
import * as IconButtons from 'src/buttons/IconButtons';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { BankProcessBidRow } from 'shared/types/bank_process_bid';
import { LoanRow } from 'shared/types/loan';
import * as loanFilter from 'shared/filter/loan';
import LinkButton from 'src/buttons/LinkButton';
import { OfferValue } from 'src/bankProcessBid/BankProcessBidFormatters';

const defaultFilter: TableFilter = {
  show: 'selectable',
};

interface TableFilter {
  show: 'selectable' | 'latest' | 'all';
}

interface QueryResponse {
  bids: BankProcessBidRow[];
  bidsAsLoans: LoanRow[];
}

interface ApplicationBidCardProps {
  applicationId: ApplicationRow['id'];
}

export default function ApplicationBidCard (props: ApplicationBidCardProps) {
  const { applicationId } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});
  const [filter, setFilter] = useState<TableFilter>(defaultFilter);

  const filterDefs = getFilterDefs();
  const columnDefs = getColumnDefs();

  const query = useQuery<QueryResponse>({
    queryKey: [`/application/${applicationId}/bids`, {params: {...filter, order}}],
    placeholderData: keepPreviousData,
  });

  const rows = useMemo(() => {
    if (!query.data) return [];
    return query.data.bidsAsLoans.map(loan => ({
      id: loan.bid_id,
      ...loan,
    }));
  }, [query.data]);

  return (
    <Card>
      <Card.Header className="border-bottom-0">
        <CardTitleIcon
          title="Erbjudanden"
          Icon={<BarChart size={18} />}
          spinning={query.isRefetching}
        >
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            refetch={query.refetch}
          />
        </CardTitleIcon>
      </Card.Header>
      <CardBodyTable
        striped
        order={order}
        columnDefinitions={columnDefs}
        filterDefinitions={filterDefs}
        filter={filter}
        setFilter={setFilter}
        setOrder={setOrder}
        rows={rows}
        isFetched={query.isFetched}
        params={{applicationId}}
      />
    </Card>
  );
}

const getColumnDefs = () => [
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    sortable: true,
  }),
  commonColumnDefs.datetime({
    id: 'bid_date',
    title: 'Datum',
    sortable: true,
    cellProps: {format: 'YYYY-MM-DD'},
  }),
  commonColumnDefs.basic({
    id: 'principal',
    title: 'Bud',
    sortable: true,
    Cell: props => <OfferValue type="principal" value={props.value} />,
  }),
  commonColumnDefs.basic({
    id: 'repayment_periods',
    title: 'Löptid',
    sortable: true,
    Cell: props => <span>{loanFilter.repaymentTime(props.row.repayment_periods, true)}</span>,
  }),
  commonColumnDefs.basic({
    id: 'interest_rate',
    title: 'Ränta',
    sortable: true,
    Cell: props => <OfferValue type="interest_rate" value={props.value * 100} />,
  }),
  commonColumnDefs.basic({
    id: 'interest_rate_effective',
    title: 'Eff.',
    sortable: true,
    Cell: props => <OfferValue type="interest_rate_effective" value={props.value * 100} />,
  }),
  commonColumnDefs.basic({
    id: 'period_cost',
    title: 'Månadskostnad',
    sortable: true,
    Cell: props => <OfferValue type="period_cost" value={props.value} />,
  }),
  commonColumnDefs.actions({}, [
    props => (
      <LinkButton
        variant="outline-primary"
        to={`/application/${props.params.applicationId}/bankProcess/${props.row.bank_process_id}/offer`}
        size="sm"
      >
        Visa
      </LinkButton>
    ),
    TableCellActions.InspectRow,
  ]),
].filter(v => v);

const getFilterDefs = (): any[] => [
  commonFilterDefs.inlineRadio({id: 'show', title: 'Visa'}, [
    {value: 'selectable', label: 'Valbara bud'},
    {value: 'latest', label: 'Senaste bud'},
    {value: 'all', label: 'Alla bud'},
  ]),
];
