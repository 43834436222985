import React, { useCallback, useContext } from 'react';
import { FieldMetaProps, FieldInputProps } from 'formik';

export type FormikOnUpdateCallback = (update: Record<string, any>) => void | Promise<any>;

type WrapFormikOnBlurFn = (field: FieldInputProps<any>, meta: FieldMetaProps<any>) => React.FocusEventHandler<any>;
type WrapFormikOnChangeFn = (field: FieldInputProps<any>, meta: FieldMetaProps<any>, getValue: (ev: React.ChangeEvent<any>) => any) => React.ChangeEventHandler<any>;

interface FormikOnUpdateContextType {
  update: FormikOnUpdateCallback;
  wrapOnBlur: WrapFormikOnBlurFn;
  wrapOnChange: WrapFormikOnChangeFn;
}

const initialValue: FormikOnUpdateContextType = {
  update: () => {},
  wrapOnBlur: (field) => (ev) => field.onBlur(ev),
  wrapOnChange: (field) => (ev) => field.onChange(ev),
};

const FormikOnUpdateContext = React.createContext<FormikOnUpdateContextType>(initialValue);

interface FormikOnUpdateProviderProps extends React.PropsWithChildren {
  onUpdate: FormikOnUpdateCallback;
}

function FormikOnUpdateProvider (props: FormikOnUpdateProviderProps) {
  const { onUpdate, children } = props;

  const update: FormikOnUpdateCallback = useCallback((...args) => {
    onUpdate(...args);
  }, [onUpdate]);

  // wraps the formik onBlur event handler and runs the update just after this event
  const wrapOnBlur: WrapFormikOnBlurFn = useCallback((field: FieldInputProps<any>, meta: FieldMetaProps<any>) => {
    return ev => {
      field.onBlur(ev);
      if (!Boolean(meta.error)) update({[field.name]: field.value});
    };
  }, [update]);

  // wraps the formik onChange event handler and runs the update just after this event
  // the problem here is that the field value is not committed so we read the 
  const wrapOnChange: WrapFormikOnChangeFn = useCallback((field, meta, getValue) => {
    return ev => {
      field.onChange(ev);
      if (!Boolean(meta.error)) update({[field.name]: getValue(ev)});
    };
  }, [update]);

  const value: FormikOnUpdateContextType = {
    ...initialValue,
    update,
    wrapOnBlur,
    wrapOnChange,
  };

  return (
    <FormikOnUpdateContext.Provider value={value}>
      {children}
    </FormikOnUpdateContext.Provider>
  );
}

const useFormikOnUpdate = () => useContext(FormikOnUpdateContext);

export { useFormikOnUpdate, FormikOnUpdateProvider, FormikOnUpdateContext };
