import React, { useEffect, useState } from 'react';
import { Modal, ModalProps } from 'react-bootstrap';
import ModalStateContext from 'src/contexts/ModalStateContext';
import { useNavigate, useParams } from 'react-router-dom';

interface RouteParameterModalProps extends ModalProps {
  title: React.ReactNode;
  subTitle?: React.ReactNode | ((parameterValue: string) => React.ReactNode);
  parameter: string;
  children: (parameterValue: string) => React.ReactNode;
  hideUrl: string;
}

function RouteParameterModal (props: RouteParameterModalProps) {
  const {
    title,
    subTitle,
    hideUrl,
    children,
    parameter,
    ...restOfProps
  } = props;

  const params = useParams();
  const navigate = useNavigate();

  const [show, setShow] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  const onHide = () => {
    navigate(hideUrl);
  };

  const onExited = () => {
    setMounted(false);
  };

  useEffect(() => {
    if (params[parameter]) {
      setShow(true);
      setMounted(true);
    } else {
      setShow(false);
    }
  }, [params, parameter, setShow, setMounted]);

  if (!mounted) {
    return null;
  }

  const param = params[parameter];

  return (
    <Modal {...restOfProps} show={show} onHide={onHide} onExited={onExited}>
      <Modal.Header>
        <div className="d-flex justify-content-between align-items-center w-100">
          <Modal.Title>
            {title}
            {subTitle && (
              <small className="d-block text-muted">
                {Boolean(typeof subTitle === 'function' && param) ? (subTitle as any)(param) : subTitle}
              </small>
            )}
          </Modal.Title>
          <button type="button" className="btn-close" onClick={onHide}></button>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <ModalStateContext.Provider value={{onHide}}>
          {param && children(params[parameter])}
        </ModalStateContext.Provider>
      </Modal.Body>
    </Modal>
  );
}
export default React.memo(RouteParameterModal);
