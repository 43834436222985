import React from 'react';
import { Formik, FormikHelpers } from 'formik';
import { ApplicationRow } from 'shared/types/application';
import { Form } from 'react-bootstrap';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import * as FormikFormControls from 'src/form/FormikFormControls';
import useAuth from 'src/hooks/useAuth';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import {useMutation} from '@tanstack/react-query';
import {errorToMessage} from 'src/utils/error';
import useAwaitConfirmActionModalProps from 'src/hooks/useAwaitConfirmActionModalProps';
import ConfirmActionModal from 'src/modals/ConfirmActionModal';
import {useNavigate} from 'react-router';

interface FormValues {
  method: string;
}

interface ApplicationInvokeFormProps {
  application: ApplicationRow;
}

export default React.memo(function ApplicationInvokeForm (props: ApplicationInvokeFormProps) {
  const { application } = props;

  const navigate = useNavigate();
  const auth = useAuth();
  const notyf = useNotyf();
  const awaitConfirmActionModal = useAwaitConfirmActionModalProps();

  const invokeMutation = useMutation<unknown, Error, FormValues>({
    mutationFn: async data => {
      if (data.method === 'reset_to_new') {
        const msg = (
          <p>
            Är du säker på att du vill radera alla processer återställa till status "Nyskapad"?
            <br />Detta är en åtgärd som leder till permanent dataförlust, och går inte att ångra.
          </p>
        );
        if (!(await awaitConfirmActionModal.confirm(msg))) {
          return;
        }
      }

      const result = await api.request({
        url: `/application/${application.id}/invoke`,
        method: 'POST',
        data,
      });

      if (result.error) {
        notyf.error(errorToMessage(result.error));
      } else {
        notyf.success({type: 'default', message: 'Åtgärden utfördes'});
        if (data.method === 'reset_to_new') {
          navigate(`/application/${application.id}/form`);
        }
      }

      return result;
    },
  });

  const onSubmit = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    await invokeMutation.mutateAsync(values);
    helpers.resetForm({values: {method: ''}});
  };

  const initialValues = {
    method: '',
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validateOnBlur enableReinitialize>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <ConfirmActionModal {...awaitConfirmActionModal.props} />
          <FormikFormControls.InputGroupSelect
            name="method"
            required
            after={(
              <ButtonSpinner
                type="submit"
                variant="primary"
                disabled={!formProps.isValid || !formProps.values.method || invokeMutation.isPending}
                isLoading={invokeMutation.isPending}
              >
                Utför
              </ButtonSpinner>
            )}
          >
            <option value="">Välj en särskild åtgärd</option>

            {(auth.permission('application_execute:invoke:other') && application.status !== 'disbursed') && (
              <option value="set_status_disbursed">
                Ändra status till "Utbetald" på enbart ansökan
              </option>
            )}

            {(auth.permission('application_execute:invoke:disconnect_accepted_process') && application.accepted_process_id) && (
              <option value="disconnect_accepted_process">
                Koppla bort accepterad process så att en annan process kan accepteras
              </option>
            )}

            {(auth.permission('application_execute:invoke:other') && application.status !== 'new') && (
              <option value="reset_to_new">
                Radera alla processer och återställ till status "Nyskapad"
              </option>
            )}
          </FormikFormControls.InputGroupSelect>
          <ErrorAlert error={invokeMutation.error} className="mt-3 mb-0" />
        </Form>
      )}
    </Formik>
  );
});
