import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {ApplicationRow} from 'shared/types/application';
import CustomerNoteListCard from 'src/customer/CustomerNoteListCard';
import ApplicationForm from 'src/application/ApplicationForm';
import ApplicationUcCard from 'src/application/ApplicationUcCard';
import ApplicationSanctionListCard from 'src/application/ApplicationSanctionListCard';
import ApplicationPepQueryCard from 'src/application/ApplicationPepQueryCard';
import ApplicationCustomerTryggsamLeadCard from 'src/application/ApplicationTryggsamCard';
import ApplicationRoleCard from 'src/application/ApplicationRoleCard';
import ApplicationControlPanelCard from 'src/application/ApplicationControlPanelCard';

interface ApplicationPageTabFormProps {
  application: ApplicationRow;
}

export default function ApplicationPageTabForm (props: ApplicationPageTabFormProps) {
  const { application } = props;
  return (
    <div>
      <Row className="px-4 pt-4">
        <Col md={6} xl={7} xxl={6}>
          <ApplicationForm application={application} />
        </Col>
        <Col md={6} xl={5} xxl={6}>
          <Row>
            <Col xxl={6}>
              <ApplicationRoleCard application={application} />
              <CustomerNoteListCard customerId={application.customer_id} extendedTitle />
            </Col>
            <Col xxl={6}>
              <ApplicationControlPanelCard application={application} />
              <ApplicationUcCard application={application} applicationId={application.id} />
              <ApplicationSanctionListCard application={application} applicationId={application.id} />
              <ApplicationCustomerTryggsamLeadCard application={application} />
              <ApplicationPepQueryCard applicationId={application.id} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

