import React, {useEffect, useMemo} from 'react';
import { Helmet } from 'react-helmet-async';
import { mapValues } from 'lodash';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import * as IconButtons from 'src/buttons/IconButtons';
import useWebsocketModelUpdates from 'src/hooks/useWebsocketModelUpdates';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';
import Pluralizer from 'src/formatters/Pluralizer';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as TableCells from 'src/tables/TableCells';
import { getWorkTriggerTemplateAssociatedIds } from 'shared/format';
import { ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { DollarSign } from 'lucide-react';
import { EmailTemplateRow } from 'shared/types/email_template';
import { LetterTemplateRow } from 'shared/types/letter_template';
import { SmsTemplateRow } from 'shared/types/sms_template';
import SmsTemplateAnchor from 'src/smsTemplate/SmsTemplateAnchor';
import EmailTemplateAnchor from 'src/emailTemplate/EmailTemplateAnchor';
import LetterTemplateAnchor from 'src/letterTemplate/LetterTemplateAnchor';
import {
  Container,
  Card,
  Button,
  Stack,
} from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { WorkTriggerTemplateRow } from 'shared/types/work_trigger_template';
import { TableColumnCellProps, TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { TableStateOrder, TableQueryKey, TableStateFilterMap } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCellActions from 'src/tables/TableCellActions';
import { useEventModelSubscribeToQuery } from 'src/hooks/useEventModels';
import RouteParameterModal from 'src/modals/RouteParameterModal';
import WorkTriggerTemplateModalBody from 'src/workTriggerTemplate/WorkTriggerTemplateModalBody';
import {WorkTriggerTemplatePlainAnchor} from 'src/workTriggerTemplate/WorkTriggerTemplateAnchor';
import WorkTriggerTemplateCreateModalBody from 'src/workTriggerTemplate/WorkTriggerTemplateCreateModalBody';

const defaultFilter = {
  is_active: true,
  searchTerm: '',
};

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.workTriggerTemplateObjectType({
    id: 'object_type',
    title: 'Objektstyp',
  }) as any,
  commonFilterDefs.string({
    id: 'search',
    title: 'Sök',
  }),
  commonFilterDefs.boolean({
    id: 'is_active',
    title: 'Status',
    filterProps: {labels: {false: 'Inaktiv', true: 'Aktiv'}},
  }),
  commonFilterDefs.string({
    id: 'task',
    title: 'Rutin',
  }),
  commonFilterDefs.applicationId({
    id: 'applicable_application_id',
    title: 'Ansöknings-ID',
  }),
  commonFilterDefs.uuid({
    id: 'conditions_match_trigger_template_id',
    title: 'Villkor',
  }),
  commonFilterDefs.uuid({
    id: 'msg_match_trigger_template_id',
    title: 'Parametrar',
  }),
  commonFilterDefs.uuid({
    id: 'schedule_options_match_trigger_template_id',
    title: 'Schemaläggning',
  }),
];

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.basic({
    id: 'id',
    Cell: props => <WorkTriggerTemplatePlainAnchor workTriggerTemplate={props.row} onlyId />,
    title: 'ID',
    sortable: true,
  }),
  // commonColumnDefs.workTriggerTemplateId({
  //   id: 'id',
  //   cellProps: {rowAsObject: true, onlyId: true},
  //   title: 'ID',
  //   sortable: true,
  // }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'is_active',
    Cell: TableCells.WorkTriggerTemplateStatusCell,
    title: 'Status',
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'conditions',
    title: 'Villkor',
    Cell: TableCells.JSONStringifyTableCell,
    cellProps: {cellKey: 'conditions', indent: 0},
  }),
  commonColumnDefs.basic({
    id: 'task',
    title: 'Rutin',
    sortable: true,
  }),
  commonColumnDefs.basic({
    id: 'msg',
    title: 'Parametrar',
    Cell: JSONStringifyAndAssociatedObjectTableCell,
    cellProps: {cellKey: 'msg', indent: 0},
  }),
  commonColumnDefs.basic({
    id: 'schedule_options',
    title: 'Schemaläggning',
    Cell: TableCells.JSONStringifyTableCell,
    cellProps: {cellKey: 'schedule_options', indent: 0},
  }),
  commonColumnDefs.actions({}, [
    props => (
      <SetFilterButtonCellAction
        {...props}
        filterKey="conditions_match_trigger_template_id"
        title="Visa alla triggermallar med samma villkor"
      >
        V
      </SetFilterButtonCellAction>
    ),
    props => (
      <SetFilterButtonCellAction
        {...props}
        filterKey="task"
        filterValue={props?.row?.task}
        title="Visa alla triggermallar med samma rutin"
      >
        R
      </SetFilterButtonCellAction>
    ),
    props => (
      <SetFilterButtonCellAction
        {...props}
        filterKey="msg_match_trigger_template_id"
        title="Visa alla triggermallar med samma parametrar"
      >
        P
      </SetFilterButtonCellAction>
    ),
    props => (
      <SetFilterButtonCellAction
        {...props}
        filterKey="schedule_options_match_trigger_template_id"
        title="Visa alla triggermallar med schemaläggning"
      >
        S
      </SetFilterButtonCellAction>
    ),
    TableCellActions.InspectRow,
  ]),
];

interface WorkTriggerTemplateResponse {
  workTriggerTemplates: WorkTriggerTemplateRow[];
  associatedObjects: Record<string, Record<string, any>>;
}

export default function WorkTriggerTemplateTablePage () {
  const [filter, setFilter] = React.useState<TableStateFilterMap>(defaultFilter);
  const [order, setOrder] = React.useState<TableStateOrder>({id: 'asc'});

  const queryKey: TableQueryKey = React.useMemo(() => {
    return ['/work_trigger_template', {params: {...filter, order}}];
  }, [filter, order]);

  const subKey = 'workTriggerTemplates';

  const query = useQuery<WorkTriggerTemplateResponse, Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  const queryClientUtils = useQueryClientUtils();

  const wsModelUpdates = useWebsocketModelUpdates<WorkTriggerTemplateRow>('work_trigger_template', (id, update) => {
    const row = {...update, id};
    queryClientUtils.applyUpdates({queryKey: [queryKey[0]]}, [row], {subKey});
  });

  useEventModelSubscribeToQuery({queryKey: [queryKey[0]]}, 'work_trigger_template', {subKey});

  const rows = useMemo(() => {
    return query.data?.[subKey] ?? [];
  }, [query]);

  useEffect(() => {
    if (!rows) return;
    wsModelUpdates.setIds(rows.map(row => row.id));
  }, [rows, wsModelUpdates]);

  const data = query.data as any;
  const params = React.useMemo(() => {
    return {setFilter, associatedObjects: data?.associatedObjects};
  }, [setFilter, data?.associatedObjects]);

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Triggermallar" />
        <Container fluid className="p-0">
          <Card className="mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Triggermallar"
                Icon={<DollarSign size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <IconButtons.SimpleCreateModalButton modalTitle="Ny triggermall">
                  <WorkTriggerTemplateCreateModalBody />
                </IconButtons.SimpleCreateModalButton>
                <IconButtons.RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1">
                <small>
                  <Pluralizer
                    count={rows?.length ?? 0}
                    zero="Inga jobbtriggers"
                    one="Visar 1 jobbtrigger"
                    otherwise="Visar %% jobbtriggers"
                  />
                </small>
              </Card.Subtitle>
            </Card.Header>
            <RouteParameterModal
              parameter="workTriggerTemplateId"
              title="Redigera Triggermall"
              backdrop="static"
              subTitle={workTriggerTemplateId => workTriggerTemplateId}
              hideUrl="/work_trigger_templates"
            >
              {workTriggerTemplateId => (
                <WorkTriggerTemplateModalBody workTriggerTemplateId={workTriggerTemplateId} />
              )}
            </RouteParameterModal>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <CardBodyTable
              align="top"
              filter={filter}
              order={order}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              setFilter={setFilter}
              setOrder={setOrder}
              rows={rows}
              params={params}
              isFetched={query.isFetched}
            />
          </Card>
        </Container>
      </Content>
    </Main>
  );
}

// TODO this might be useful as generic table action
function SetFilterButtonCellAction (props: TableColumnCellProps) {
  const {
    row,
    title,
    children,
    filterKey,
    filterValue:outerFilterValue,
    params: { setFilter },
  } = props;

  const filterValue = typeof outerFilterValue === 'undefined' ? row.id : outerFilterValue;

  const onClick = () => {
    if (!setFilter) return;
    setFilter({[filterKey]: filterValue});
  };

  return (
    <Button
      variant="outline-primary"
      onClick={onClick}
      size="sm"
      className="d-flex align-items-center px-2 py-0"
      disabled={!setFilter}
      title={title}
    >
      <span className="font-monospace">
        {children}
      </span>
    </Button>
  );
}

interface Associations {
  emailTemplate: EmailTemplateRow[];
  letterTemplate: LetterTemplateRow[];
  smsTemplate: SmsTemplateRow[];
}

function JSONStringifyAndAssociatedObjectTableCell (props: TableColumnCellProps) {
  const { row, params: { associatedObjects } } = props;
  const msg = row?.msg ?? {};
  const associatedIds = getWorkTriggerTemplateAssociatedIds(msg, {});

  const associations = mapValues(associatedIds, (ids, key) => {
    return ids.map(id => {
      const object = associatedObjects?.[key]?.[id];
      return object;
    }).filter(v => v);
  }) as Associations;

  return (
    <>
      <TableCells.JSONStringifyTableCell {...props} />
      <Stack dir="vertical" gap={1} className="mt-1">
        {associations.emailTemplate && associations.emailTemplate.map(emailTemplate => (
          <EmailTemplateAnchor key={emailTemplate.id} emailTemplate={emailTemplate} />
        ))}
        {associations.smsTemplate && associations.smsTemplate.map(smsTemplate => (
          <SmsTemplateAnchor key={smsTemplate.id} smsTemplate={smsTemplate} />
        ))}
        {associations.letterTemplate && associations.letterTemplate.map(letterTemplate => (
          <LetterTemplateAnchor key={letterTemplate.id} letterTemplate={letterTemplate} />
        ))}
      </Stack>
    </>
  );
}
