import React from 'react';
import { QueryFilters, useQueryClient } from '@tanstack/react-query';
import * as immer from 'immer';

export interface QueryClientUtilsMethodOptions {
  subKey?: string;
}

interface QueryClientUtilsMethodApplyUpdatesOptions extends QueryClientUtilsMethodOptions {
  single?: boolean; // when true, the update will apply to a single object (a list not expected)
}

interface ObjectWithId {
  id: React.Key;
  [key: string]: any;
}

export default function useQueryClientUtils<T extends ObjectWithId> () {
  const queryClient = useQueryClient();

  return {

    applyDeletionsById: (queryFilter: QueryFilters, deletedIds: React.Key[], options: QueryClientUtilsMethodOptions = {}) => {
      const { subKey } = options;
      if (!deletedIds.length) {
        return;
      }

      queryClient.setQueriesData<T[]>(queryFilter, previousData => {
        const updatedData = immer.produce(previousData, draft => {
          if (!draft) return;
          if (subKey) {
            draft[subKey] = draft[subKey].filter((row: T) => {
              return !deletedIds.includes(row.id);
            });
          } else if (Array.isArray(draft)) {
            return draft.filter(row  => {
              return !deletedIds.includes(row.id);
            });
          }
        });
        return updatedData;
      });
    },

    applyUpdates: (queryFilter: QueryFilters, updates: T[], options: QueryClientUtilsMethodApplyUpdatesOptions = {}) => {
      const { subKey, single } = options;
      if (!updates.length) {
        return;
      }

      // updates a single object with the first update. the queryKey refer to a single object, not a list of objects.
      if (single) {
        queryClient.setQueriesData<T>(queryFilter, previousData => {
          const updatedData = immer.produce(previousData, draft => {
            if (!draft) return;
            const updatedRow = updates[0];
            if (updatedRow) {
              for (const key in updatedRow) {
                (draft as any)[key] = updatedRow[key];
              }
            }
          });
          return updatedData;
        });
        return;
      }

      const updatedRowById = updates.reduce((map, update) => {
        map[(update as any).id] = update;
        return map;
      }, {});

      queryClient.setQueriesData<T[]>(queryFilter, previousData => {
        const updatedData = immer.produce(previousData, draft => {
          if (!draft) return;
          const list: T[] = subKey ? draft[subKey] as T[] : draft as T[];
          if (!Array.isArray(list)) return;

          for (const row of list) {
            const updatedRow = updatedRowById[(row as any).id];
            if (updatedRow) {
              for (const key in updatedRow) {
                (row as any)[key] = updatedRow[key];
              }
            }
          }
        });
        return updatedData;
      });
    },

    applyCreates: (queryFilter: QueryFilters, inserts: T[], options: QueryClientUtilsMethodOptions = {}) => {
      const { subKey } = options;
      if (!inserts.length) {
        return;
      }
      queryClient.setQueriesData<T[]>(queryFilter, previousData => {
        const updatedData = immer.produce(previousData, draft => {
          if (!draft) return;
          if (subKey) {
            if (!Array.isArray(draft[subKey])) return;
            draft[subKey].unshift(...inserts);
          } else {
            if (!Array.isArray(draft)) return;
            draft.unshift(...inserts as any);
          }
        });
        return updatedData;
      });

    },

  };
}
