import React from 'react';
import { Route, Routes, Outlet } from 'react-router-dom';
import { WebSocketProvider } from 'src/contexts/WebSocketContext';
import AppError from 'src/ui/AppError';
import { ErrorBoundary } from 'react-error-boundary';

import Wrapper from 'src/ui/Wrapper';
import Sidebar from 'src/sidebar/Sidebar';
import { NotFoundAuthedPage, NotFoundPage } from 'src/pages/NotFoundPages';
import AuthLoaderGuard from 'src/guards/AuthLoaderGuard';
import ApplicationsTablePage from 'src/application/ApplicationsTablePage';
import ApplicationPage from 'src/application/ApplicationPage';
import BankPage from 'src/bank/BankPage';
import BankCreatePage from 'src/bank/BankCreatePage';
import BankProcessesTablePage from 'src/bankProcess/BankProcessesTablePage';
import BanksTablePage from 'src/bank/BanksTablePage';
import ComplaintPage from 'src/complaint/ComplaintPage';
import ComplaintsTablePage from 'src/complaint/ComplaintsTablePage';
import CustomerPage from 'src/customer/CustomerPage';
import CustomersTablePage from 'src/customer/CustomersTablePage';
import CustomerConsentPrintPage from 'src/customerConsent/CustomerConsentPrintPage';
import DashboardPage from 'src/dashboard/DashboardPage';
import EmailTablePage from 'src/email/EmailTablePage';
import EmailForbiddenTablePage from 'src/emailForbidden/EmailForbiddenTablePage';
import FilesTablePage from 'src/file/FilesTablePage';
import CustomerConsentTablePage from 'src/customerConsent/CustomerConsentTablePage';
import CustomerCloudInsurancePage from 'src/cloudInsurance/CloudInsurancePage';
import CustomerJaycomInsurancePage from 'src/jaycomInsurance/JaycomInsurancePage';
import CustomerCloudInsuranceTablePage from 'src/cloudInsurance/CloudInsuranceTablePage';
import CustomerJaycomInsuranceTablePage from 'src/jaycomInsurance/JaycomInsuranceTablePage';
import LetterTablePage from 'src/letter/LetterTablePage';
import PepQueryTablePage from 'src/pepQuery/PepQueryTablePage';
import ProspectPage from 'src/prospect/ProspectPage';
import ProspectsTablePage from 'src/prospect/ProspectsTablePage';
import SettingsPage from 'src/settings/SettingsPage';
import SmsTablePage from 'src/sms/SmsTablePage';
import StatisticsAdvisorPage from 'src/statistics/StatisticsAdvisorPage';
import StatisticsBanksPage from 'src/statistics/StatisticsBanksPage';
import StatisticsDisbursedPage from 'src/statistics/StatisticsDisbursedPage';
import StatisticsSalesmanPage from 'src/statistics/StatisticsSalesmanPage';
import TaskLotteryPage from 'src/taskLottery/TaskLotteryPage';
import TaskTablePage from 'src/task/TaskTablePage';
import UcTablePage from 'src/uc/UcTablePage';
import UserTablePage from 'src/user/UserTablePage';
import UserPage from 'src/user/UserPage';
import WorkScheduledTablePage from 'src/workScheduled/WorkScheduledTablePage';
import WorkTablePage from 'src/work/WorkTablePage';
import WorkTriggerTemplateTablePage from 'src/workTriggerTemplate/WorkTriggerTemplateTablePage';
import LoginPage from 'src/login/LoginPage';
import TriggerTablePage from 'src/trigger/TriggerTablePage';
import GroupTablePage from 'src/group/GroupTablePage';
import EmailTemplateTablePage from 'src/emailTemplate/EmailTemplateTablePage';
import SmsTemplateTablePage from 'src/smsTemplate/SmsTemplateTablePage';
import LetterTemplateTablePage from 'src/letterTemplate/LetterTemplateTablePage';

export default function Router () {
  return (
    <Routes>
      <Route path="/login/:tab" element={<LoginPage />} />
      <Route element={<AuthenticatedPageWrapper />}>
        <Route path="/" element={<DashboardPage />}/>
        <Route path="/applications/all/:initialStateId?" element={<ApplicationsTablePage type="all" />} />
        <Route path="/applications/own/:initialStateId?" element={<ApplicationsTablePage type="own" title="Mina ansökningar" pageTitle="Mina ansökningar" />} />
        <Route path="/application/:applicationId/:tab?/:part1?/:part2?" element={<ApplicationPage />} />
        <Route
          path="/application_triggers/:initialStateId?"
          element={<TriggerTablePage objectType="application" routeUrl="/application_triggers" label="ansökning" />}
        />
        <Route path="/banks" element={<BanksTablePage />} />
        <Route path="/bank/create" element={<BankCreatePage />} />
        <Route path="/bank/:bankId/:tab?" element={<BankPage />} />
        <Route path="/complaint/:complaintId" element={<ComplaintPage />} />
        <Route path="/customer_cloud_insurance/:insuranceId" element={<CustomerCloudInsurancePage />} />
        <Route path="/customer_jaycom_insurance/:insuranceId/:tab?" element={<CustomerJaycomInsurancePage />} />
        <Route path="/files/:fileId?" element={<FilesTablePage />} />
        <Route path="/prospects/:initialStateId?" element={<ProspectsTablePage />} />
        <Route path="/prospect/:prospectId/:tab?" element={<ProspectPage />} />
        <Route
          path="/prospect_triggers/:initialStateId?"
          element={<TriggerTablePage objectType="prospect" routeUrl="/prospect_triggers" label="prospekt" />}
        />
        <Route path="/uc/:initialStateId?" element={<UcTablePage />} />
        <Route path="/customer_consent/:initialStateId?" element={<CustomerConsentTablePage />} />
        <Route path="/customers/:initialStateId?" element={<CustomersTablePage />} />
        <Route path="/customer/:customerId/:tab?" element={<CustomerPage />} />
        <Route path="/customer_consent/:customerConsentId/print" element={<CustomerConsentPrintPage />} />
        <Route path="/complaints/:initialStateId?" element={<ComplaintsTablePage />} />
        <Route path="/customer_cloud_insurances/:initialStateId?" element={<CustomerCloudInsuranceTablePage />} />
        <Route path="/customer_jaycom_insurances/:initialStateId?" element={<CustomerJaycomInsuranceTablePage />} />
        <Route
          path="/jaycom_insurance_triggers/:initialStateId?"
          element={<TriggerTablePage objectType="jaycom_insurance" routeUrl="/jaycom_insurance_triggers" label="försäkring" />}
        />
        <Route path="/bank_processes/:initialStateId?" element={<BankProcessesTablePage />} />
        <Route path="/email_forbidden/:initialStateId?" element={<EmailForbiddenTablePage />} />
        <Route path="/email_template/:emailTemplateId?" element={<EmailTemplateTablePage />} />
        <Route path="/sms_template/:smsTemplateId?" element={<SmsTemplateTablePage />} />
        <Route path="/sms/:initialStateId?" element={<SmsTablePage />} />
        <Route path="/email/:initialStateId?" element={<EmailTablePage />} />
        <Route path="/letter/:initialStateId?" element={<LetterTablePage />} />
        <Route path="/letter_template/:letterTemplateId?" element={<LetterTemplateTablePage />} />
        <Route path="/groups/:groupId?" element={<GroupTablePage />} />
        <Route path="/users/:initialStateId?" element={<UserTablePage />} />
        <Route path="/user/:userId/:tab?" element={<UserPage />} />
        <Route path="/statistics/banks" element={<StatisticsBanksPage />} />
        <Route path="/statistics/disbursed" element={<StatisticsDisbursedPage />} />
        <Route path="/statistics/advisors" element={<StatisticsAdvisorPage />} />
        <Route path="/statistics/salesmen" element={<StatisticsSalesmanPage />} />
        <Route path="/pep_query/:initialStateId?" element={<PepQueryTablePage />} />
        <Route path="/task/:initialStateId?" element={<TaskTablePage />} />
        <Route
          path="/task_triggers/:initialStateId?"
          element={<TriggerTablePage objectType="task" routeUrl="/task_triggers" label="återkomst" />}
        />
        <Route path="/task_lottery" element={<TaskLotteryPage />} />
        <Route path="/work/:initialStateId?" element={<WorkTablePage />} />
        <Route path="/work_scheduled/:initialStateId?" element={<WorkScheduledTablePage />} />
        <Route path="/work_trigger_templates/:workTriggerTemplateId?" element={<WorkTriggerTemplateTablePage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="*" element={<NotFoundAuthedPage />}/>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

function AuthenticatedPageWrapper () {
  return (
    <AuthLoaderGuard>
      <WebSocketProvider>
        <Wrapper>
          <Sidebar />
          <ErrorBoundary FallbackComponent={AppError}>
            <Outlet />
          </ErrorBoundary>
        </Wrapper>
      </WebSocketProvider>
    </AuthLoaderGuard>
  );
}
