import React from 'react';
import * as numberUtils from 'src/numbers';

interface PercentageProps {
  value: any;
  format?: numberUtils.PartialFormatOptions;
}

export function Percentage (props: PercentageProps) {
  const { value, format } = props;
  return (
    <>{numberUtils.percentage(value, format)}</>
  );
}

interface PercentProps {
  value: any;
  format?: numberUtils.PartialFormatOptions;
}

export function Percent (props: PercentProps) {
  const { value, format } = props;
  return (
    <>{numberUtils.numberfmt(value, format)}&nbsp;%</>
  );
}

interface NumberProps {
  value: any;
  format?: numberUtils.PartialFormatOptions;
}

export function Number (props: NumberProps) {
  const { value, format } = props;
  return (
    <>{numberUtils.numberfmt(value, format)}</>
  );
}

interface CurrencyProps {
  value: any;
  format?: numberUtils.PartialFormatOptions;
}

export function Currency (props: CurrencyProps) {
  const { value, format } = props;
  return (
    <>{numberUtils.currency(value, format)}</>
  );
}
