import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';

export function Subject (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Subjekt"
      minLength={1}
      maxLength={255}
      name="subject"
      explainer="Vad kunden samtycker till"
      required
      {...props}
    />
  );
}
