import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/emailTemplate/EmailTemplateFormatters';
import { EmailTemplateRow } from 'shared/types/email_template';
import EmailTemplateModalBody from 'src/emailTemplate/EmailTemplateModalBody';

interface EmailTemplateAnchorProps {
  emailTemplate: Pick<EmailTemplateRow, 'id' | 'name'>;
}

export default function EmailTemplateAnchor (props: EmailTemplateAnchorProps) {
  const { emailTemplate, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={emailTemplate} />}
      name="Redigera E-postmall"
      subTitle={emailTemplate.id}
      size="xl"
      backdrop="static"
    >
      <EmailTemplateModalBody id={emailTemplate.id} />
    </ModalAnchor>
  );
}

export function EmailTemplatePlainAnchor (props: EmailTemplateAnchorProps) {
  const { emailTemplate, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/email_template/${emailTemplate.id}`}
      name="E-postmallen"
    >
      <Name value={emailTemplate} />
    </Anchor>
  );
}
