import React, {useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { Accordion, Alert, Card, Form } from 'react-bootstrap';
import { BankIdPhoneCallInitiator } from 'shared/types/bankid';
import {
  CustomerConsentBankIdStartRequestBody,
  CustomerConsentBankIdStartResponseBody,
  CustomerConsentBankIdCollectResponseBody,
  CustomerConsentBankIdCollectResponseBodyComplete,
  CustomerConsentMethodPartBankIdAuth,
  CustomerConsentMethodPartBankIdSign,
} from 'shared/types/customer_consent';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import classNames from 'classnames';
import IdProvider from 'src/components/IdProvider';

interface CustomerConsentBankIdMethodPartProps {
  className?: string;
  userId: string;
  personalNumber: string;
  method?: 'customer_bankid_auth' | 'customer_bankid_sign';
  onConsentMethodPartGiven: (methodKey: string, part: CustomerConsentMethodPartBankIdAuth | CustomerConsentMethodPartBankIdSign) => unknown;
  userVisibleData?: string;
}

export default function CustomerConsentBankIdMethodPart (props: CustomerConsentBankIdMethodPartProps) {
  const { className, userId, method, personalNumber, userVisibleData, onConsentMethodPartGiven } = props;

  const onBankIdPhoneAuthComplete = (data: CustomerConsentBankIdCollectResponseBodyComplete) => {
    onConsentMethodPartGiven(method, {
      done: true,
      user_id: userId,
      created_at: (new Date()).toISOString(),
      metadata: {
        ip: data.completionData.device.ipAddress,
        uhi: data.completionData.device.uhi,
        personalNumber: data.completionData.user.personalNumber,
        orderRef: data.orderRef,
        signData: userVisibleData,
      },
    });
  };

  return (
    <div className={className}>
      <BankIdPhoneMethod
        method={method === 'customer_bankid_sign' ? 'sign' : 'auth'}
        personalNumber={personalNumber}
        onComplete={onBankIdPhoneAuthComplete}
        userVisibleData={userVisibleData}
      />
    </div>
  );
}

interface BankIdPhoneAuthState {
  lastCollectResponse: null | CustomerConsentBankIdCollectResponseBody;
  polling: boolean;
  orderRef: null | string;
}

interface BankIdPhoneMethodProps {
  personalNumber: string;
  method: 'sign' | 'auth';
  onComplete: (data: CustomerConsentBankIdCollectResponseBodyComplete) => void;
  userVisibleData?: string;
}

function BankIdPhoneMethod (props: BankIdPhoneMethodProps) {
  const { personalNumber, method, userVisibleData, onComplete } = props;

  const [callInitiator, setCallInitiator] = useState<BankIdPhoneCallInitiator>('RP');

  const onChangeCallInitiator: React.ChangeEventHandler<HTMLInputElement> = ev => {
    setCallInitiator(ev.target.value as BankIdPhoneCallInitiator);
  };

  const [state, setState] = useState<BankIdPhoneAuthState>({
    lastCollectResponse: null,
    polling: false,
    orderRef: null,
  });

  const startMutation = useMutation<CustomerConsentBankIdStartResponseBody, Error, CustomerConsentBankIdStartRequestBody>({
    mutationFn: async data => {
      const response = await api.request({
        url: `/customer_consent/bankid/phone/${method}`,
        timeout: 10000,
        method: 'POST',
        data,
      });

      const { orderRef } = response;

      setState({
        lastCollectResponse: null,
        polling: true,
        orderRef,
      });

      return response;
    },
  });

  const collectQuery = useQuery<CustomerConsentBankIdCollectResponseBody, Error>({
    enabled: state.polling,
    queryKey: [`/customer_consent/bankid/${state.orderRef}`],
    refetchInterval: 2000,
    retry: 3,
    retryDelay: 2000,
    queryFn: async ctx => {
      const lastCollectResponse = await api.request({
        url: `/customer_consent/bankid/${state.orderRef}`,
        signal: ctx.signal,
        method: 'GET',
        timeout: 10000,
      });

      setState(prevState => ({
        ...prevState,
        lastCollectResponse,
      }));

      if (lastCollectResponse.status !== 'pending') {
        setState(prevState => ({
          ...prevState,
          polling: false,
          orderRef: null,
        }));

        if (lastCollectResponse.status === 'complete') {
          onComplete(lastCollectResponse);
        }
      }

      return lastCollectResponse;
    },
  });

  const onClickStart = () => {
    setState({
      polling: false,
      lastCollectResponse: null,
      orderRef: null,
    });

    const startData: CustomerConsentBankIdStartRequestBody = {
      callInitiator,
      personalNumber,
    };

    if (method === 'sign') {
      startData.userVisibleData = userVisibleData;
      startData.userVisibleDataFormat = 'simpleMarkdownV1';
    }

    startMutation.mutateAsync(startData);
  };

  const startDisabled = startMutation.isPending || state.polling;

  return (
    <div>
      <div className="border-bottom p-3 mb-3">
        <Form.Group className="mb-3">
          <Form.Label className="mb-1">Personnummer</Form.Label>
          <Form.Control
            name="ssn"
            value={personalNumber}
            disabled
          />
        </Form.Group>
        <Form.Group>
          <div className="form-label">Välj vem som ringde upp vem</div>
          <IdProvider>
            {id => (
              <Form.Check
                id={id}
                type="radio"
                name="callInitiator"
                value="RP"
                label="Du som användare ringde kunden"
                checked={callInitiator === 'RP'}
                onChange={onChangeCallInitiator}
              />
            )}
          </IdProvider>
          <IdProvider>
            {id => (
              <Form.Check
                id={id}
                type="radio"
                name="callInitiator"
                value="user"
                label="Kunden ringde in"
                checked={callInitiator === 'user'}
                onChange={onChangeCallInitiator}
              />
            )}
          </IdProvider>
        </Form.Group>
        {userVisibleData && (
          <Accordion className="mt-3">
            <Accordion.Item eventKey="userVisibleData">
              <Accordion.Header><strong>Signeringstext i kundens BankID-app</strong></Accordion.Header>
              <Accordion.Body dangerouslySetInnerHTML={{__html: userVisibleData}}>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        )}
      </div>
      <div className="pt-0 p-3">
        <ErrorAlert className="mb-1" error={startMutation.error} />
        <ButtonSpinner
          className="d-flex align-items-center gap-1 flex-wrap justify-content-center"
          variant="secondary"
          onClick={onClickStart}
          disabled={startDisabled}
          isLoading={startDisabled}
        >
          Påbörja {method === 'auth' ? 'identifiering' : 'signering'}
        </ButtonSpinner>
        <ErrorAlert error={collectQuery.error} className="mt-3 mb-1" />
        {state.lastCollectResponse && (
          <>
            {'error' in state.lastCollectResponse ? (
              <Alert variant="danger" className="text-danger mt-3 mb-0 p-3 justify-content-center">
                {state.lastCollectResponse.error as any}
              </Alert>
            ) : (
              <BankIdStatusAlert response={state.lastCollectResponse} />
            )}
            {'status' in state.lastCollectResponse && state.lastCollectResponse.status === 'complete' && (
              <BankIdCompleteCard
                completeCollectResponse={state.lastCollectResponse}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

interface BankIdCompleteCardProps {
  completeCollectResponse: CustomerConsentBankIdCollectResponseBodyComplete;
}

function BankIdCompleteCard (props: BankIdCompleteCardProps) {
  const { completeCollectResponse:response } = props;
  const { user, device } = response.completionData;
  return (
    <Card className="rounded mt-3 mb-0">
      <Card.Header className="p-3">
        <Card.Title as="h6" className="mb-0">Identifiering lyckades</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex flex-wrap justify-content-between py-3">
        <dl className="mb-0 w-50">
          <dt>Personnummer</dt>
          <dd>{user.personalNumber}</dd>
          <dt>Namn</dt>
          <dd className="mb-0">{user.name}</dd>
        </dl>
        <dl className="mb-0 w-50">
          <dt>IP-adress</dt>
          <dd><code>{device.ipAddress}</code></dd>
          <dt>Hårdvaru-ID</dt>
          <dd className="mb-0"><code>{device.uhi}</code></dd>
        </dl>
      </Card.Body>
    </Card>
  );
}

interface BankIdStatusAlertProps {
  response: CustomerConsentBankIdCollectResponseBody;
}

export function BankIdStatusAlert (props: BankIdStatusAlertProps) {
  const { response } = props;
  if ('status' in response && response.status === 'complete') return null;

  const hintCode = 'hintCode' in response ? response.hintCode : null;
  const message = getMessage(response, hintCode);
  const variant = getVariant(response);
  const textColorClassName = `text-${variant}`;
  return (
    <Alert variant={variant} className={classNames('mt-3 mb-0 p-3 justify-content-center', textColorClassName)}>
      <span>{message}</span>
    </Alert>
  );
}

function getVariant (response: CustomerConsentBankIdCollectResponseBody): string {
  if ('errorCode' in response) return 'danger';
  switch (response.status) {
    default: return 'info';
    case 'pending': return 'info';
    case 'failed': return 'danger';
  }
}

function getMessage (response: CustomerConsentBankIdCollectResponseBody, hintCode?: null | string): string {
  if ('errorCode' in response) {
    const { errorCode, details } = response;
    return `Ett fel inträffade: ${errorCode} - ${details}`;
  }
  const { status } = response;
  if (status === 'started') {
    return 'Väntar på att kunden startar BankID-appen.';
  } else if (status === 'pending') {
    switch (hintCode) {
      default: return `Signering pågår. (${hintCode})`;
      case 'userCallConfirm': return 'Väntar på att kunden bekräftar att ett telefonsamtal pågår.';
      case 'noClient':
      case 'outstandingTransaction':
        return 'Väntar på att kunden ska starta BankID-appen.';
      case 'started':
        return 'Söker efter BankID, det kan ta en liten stund…';
      case 'userSign': return 'Väntar på att kunden skriver in sin säkerhetskod och väljer "Identifiera".';
    }
  } else if (status === 'failed') {
    switch (hintCode) {
      default: return `Okänt fel. Försök igen. (${hintCode})`;
      case 'expiredTransaction': return 'BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Försök sedan igen.';
      case 'certificateErr': return 'Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller hämta ett nytt hos din internetbank.';
      case 'userCancel': return 'Åtgärden avbruten.';
      case 'cancelled': return 'Åtgärden avbruten. Försök igen.';
      case 'startFailed':
        return 'BankID-start misslyckades.';
      case 'notSupportedByUserApp': return 'Anropet stöds inte av din BankID-app. Var god uppdatera appen.';
    }
  }
  return `${status}: ${hintCode}`;
}
