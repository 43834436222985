import React from 'react';
import * as api from 'src/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { VirtualTemplateRow } from 'shared/types/template';
import TemplateCreateForm, {TemplateCreateFormValues} from 'src/template/TemplateCreateForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitCreates } from 'src/hooks/useEventModels';
import {useNavigate} from 'react-router';

interface TemplateCreateModalBodyProps {
  type: 'letter' | 'sms' | 'email';
}

export default function TemplateCreateModalBody (props: TemplateCreateModalBodyProps) {
  const { type } = props;
  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const emitCreate = useEventModelEmitCreates<VirtualTemplateRow>(type + 'Template');

  const createMutation = useMutation<VirtualTemplateRow, Error, TemplateCreateFormValues>({
    mutationFn: async data => {
      const template = await api.request({
        url: `/template/${type}`,
        method: 'POST',
        data,
      });

      queryClient.setQueryData([`/template/${type}/${template.id}`], template);
      notyf.success({type: 'success', message: 'Mallen skapades'});
      modalStateContext.onHide();
      emitCreate(template);
      navigate(`/${type}_template/${template.id}`);

      return template;
    },
  });

  const onSubmit = async values => {
    return await createMutation.mutateAsync(values);
  };

  const initialValues: TemplateCreateFormValues = {
    name: '',
  };

  return (
    <TemplateCreateForm
      className="mt-0"
      initialValues={initialValues}
      onSubmit={onSubmit}
      submitError={createMutation.error}
    />
  );
}
