import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import IdProvider from 'src/components/IdProvider';
import { UserRow } from 'shared/types/user';
import {useQuery} from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ButtonSpinner from 'src/spinners/ButtonSpinner';

export interface UserPickModalProps {
  show: boolean;
  onHide: () => void;
  userQueryParams?: Record<string, any>;
  excludeUserIds?: string[];
  onPick: (userId: string) => void | Promise<void>;
}

export default function UserPickModal (props: UserPickModalProps) {
  const {
    excludeUserIds = [],
    userQueryParams = {is_active: true},
    show,
    onHide,
    onPick,
  } = props;

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [userId, setUserId] = useState<string>('');

  const query = useQuery<UserRow[], Error>({
    queryKey: ['/user/search', {params: {...userQueryParams, order: {name: 'asc'}}}],
  });

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = ev => {
    setUserId(ev.target.value);
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async ev => {
    ev.stopPropagation();
    ev.preventDefault();
    const maybePromise = onPick(userId);
    if (maybePromise) {
      setIsSaving(true);
      maybePromise?.finally(() => {
        setIsSaving(false);
        onHide();
      });
    } else {
      onHide();
    }
  };

  const users = (query.data ?? []).filter(user => !excludeUserIds.includes(user.id));

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          Välj användare
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ErrorAlert error={query.error} />
        <Form onSubmit={onSubmit}>
          <IdProvider>
            {id => (
              <Form.Group>
                <Form.Select
                  name="user_id"
                  value={userId}
                  required
                  id={id}
                  onChange={onChange}
                >
                  <option value="">Välj en användare</option>
                  {users.map(user => (
                    <option key={user.id} value={user.id}>{user.name ?? user.id}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            )}
          </IdProvider>
          <div className="d-flex flex-wrap gap-2 mt-2">
            <ButtonSpinner
              type="submit"
              variant="outline-secondary"
              disabled={!userId || isSaving}
              isLoading={isSaving}
            >
              Välj
            </ButtonSpinner>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
