import React from 'react';
import {Table} from 'react-bootstrap';
import { CustomerJaycomInsuranceRow } from 'shared/types/customer_jaycom_insurance';
import * as JaycomInsuranceFormatters from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import BooleanFmt from 'src/formatters/Boolean';
import jaycomFilters from 'shared/filter/customer_jaycom_insurance';
import HelperTooltip from 'src/components/HelperTooltip';

interface PropertiesTableProps {
  insurance: CustomerJaycomInsuranceRow;
}

export function PropertiesTable (props: PropertiesTableProps) {
  const { insurance } = props;

  return (
    <Table>
      <tbody>
        <tr>
          <th>
            Jaycom kund-ID{' '}
            <HelperTooltip>
              Kund-ID i Jaycom som hör till försäkringen. När försäkringen aktiveras så skapas antingen kunden direkt i Jaycom, eller så hämtas Kund-ID (om möjligt) från det tillhörande kundobjektet.
            </HelperTooltip>
          </th>
          <td><strong>{insurance.jaycom_customer_id || '-'}</strong></td>
        </tr>
        <tr>
          <th>
            Jaycom försäkrings-ID{' '}
            <HelperTooltip>
              Försäkrings-ID i Jaycom som motsvarar den här försäkringen efter att den har aktiverats.
            </HelperTooltip>
          </th>
          <td><strong>{insurance.jaycom_insurance_id || '-'}</strong></td>
        </tr>
        {insurance.cancelled_reason && (
          <tr>
            <th>Annullationsorsak</th>
            <td>{insurance.cancelled_reason}</td>
          </tr>
        )}
        {insurance.error && (
          <tr>
            <th>Felorsak</th>
            <td>
              <pre className="mb-0">{JSON.stringify(insurance.error, null, 2)}</pre>
            </td>
          </tr>
        )}
        <tr>
          <th>
            Försäkrat månadsbelopp{' '}
            <HelperTooltip>
              När detta värde är angivet så betyder det att försäkringen är aktiverad med detta månadsbelopp. Beloppet i formuläret saknar därmed betydelse.
            </HelperTooltip>
          </th>
          <td><JaycomInsuranceFormatters.InsuredAmount value={insurance.insured_amount} /></td>
        </tr>
        <tr>
          <th>Någonsin aktiv?</th>
          <td><BooleanFmt colored value={insurance.flag_status_ever_active} /></td>
        </tr>
        <tr>
          <th>Skapad av</th>
          <td>
            {jaycomFilters.creator(insurance)}
          </td>
        </tr>
        <tr>
          <th className="border-bottom-0">Adtraction-kod</th>
          <td><BooleanFmt colored value={Boolean(insurance.portal_tracking_code_adtraction)} /></td>
        </tr>
      </tbody>
    </Table>
  );
}
