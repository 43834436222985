import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import {Button, Card, Collapse, Stack, Table} from 'react-bootstrap';
import {Eye, EyeOff} from 'lucide-react';
import {BankProcessBidRow} from 'shared/types/bank_process_bid';
import * as BankProcessBidFormatters from 'src/bankProcessBid/BankProcessBidFormatters';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import CopyToClipboardButton from 'src/buttons/CopyToClipboardButton';
import {BankProcessRowOffer} from 'shared/types/bank_process';

interface BankProcessBidSingleCollapsibleProps {
  className?: string;
  index?: number;
  id?: string;
  bid: BankProcessRowOffer;
  initialOpen?: boolean;
  ourId?: string;
  theirId?: string;
  status?: BankProcessBidRow['status'];
}

export default function BankProcessBidSingleCollapsible (props: BankProcessBidSingleCollapsibleProps) {
  const { className, id, index, bid, initialOpen, ourId, theirId, status } = props;

  const [open, setOpen] = useState<boolean>(initialOpen ?? false);

  const onClickOpen = useCallback(() => {
    setOpen(prev => !prev);
  }, [setOpen]);


  const firstKeys = [
    'amount',
    'repayment_years',
    'interest_rate_effective',
    'interest_rate',
    'monthly_cost',
    'monthly_cost_last',
    'amortization_type',
  ].filter(key => typeof bid[key] !== 'undefined');

  const lastKeys = [
    'verifications',
    'signing_link',
    'signing_link_main',
    'signing_link_co',
    'their_id',
    'our_id',
  ].filter(key => typeof bid[key] !== 'undefined');

  const otherKeys = Object.keys(bid).filter(key => {
    return !firstKeys.includes(key) && !lastKeys.includes(key) && typeof bid[key] !== 'undefined';
  });

  const bidKeys = [
    ...firstKeys,
    ...otherKeys,
    ...lastKeys,
  ];

  return (

    <Card className={classNames(className, {'border-bottom-0': open})}>
      <Card.Header className="p-2 d-flex justify-content-between">
        <BankProcessBidFormatters.TitleOneLiner value={bid} index={index} />
        <Stack gap={1} direction="horizontal" className="flex-wrap">
          {status && (
            <BankProcessBidFormatters.StatusLabel value={status} short />
          )}
          <Button variant="outline-info" size="sm" className="px-1 py-0" onClick={onClickOpen}>
            {open ? <><Eye size={14} /> Dölj</> : <><EyeOff size={14} /> Visa</>}
          </Button>
          {id && (
            <CopyToClipboardButton
              copy={id}
              variant="outline-info"
              size="sm"
              className="py-0 px-1"
              onCopyMessage="Bud-ID kopierat"
            />
          )}
          <InspectObjectModalButton object={bid} size="sm" className="py-0" />
        </Stack>
      </Card.Header>
      <Collapse in={open}>
        <div>
          <Table size="sm" striped className="border-top mb-0">
            <tbody>
              {bidKeys.map(key => (
                <tr key={key}>
                  <th><BankProcessBidFormatters.OfferType value={key} /></th>
                  <td><BankProcessBidFormatters.OfferValue type={key} value={bid[key]} /></td>
                </tr>
              ))}
              {ourId && (
                <tr>
                  <th>Tryggas ans/bud-ID</th>
                  <td>
                    <code>{ourId}</code>{' '}
                    <CopyToClipboardButton
                      copy={ourId}
                      size="sm"
                      className="px-1 py-0"
                      variant="outline-info"
                      onCopyMessage="Tryggas ID kopierat"
                    />
                  </td>
                </tr>
              )}
              {theirId && (
                <tr className="align-middle">
                  <th>Långivarens ans/bud-ID</th>
                  <td className="align-middle">
                    <code>{theirId}</code>{' '}
                    <CopyToClipboardButton
                      copy={theirId}
                      size="sm"
                      className="px-1 py-0"
                      variant="outline-info"
                      onCopyMessage="Långivarens ID kopierat"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Collapse>
    </Card>
  );
}
