import React, {useState} from 'react';
import { ConfirmActionModalProps } from 'src/modals/ConfirmActionModal';

interface AwaitConfirmActionModalProps extends ConfirmActionModalProps {
}

interface UseAwaitConfirmActionModalPropsHook {
  confirm: (message: React.ReactNode) => Promise<boolean>;
  props: AwaitConfirmActionModalProps;
}

const getEmptyProps = (): AwaitConfirmActionModalProps  => ({
  show: false,
  message: '',
  onConfirm: () => {},
  onDecline: () => {},
  onHide: () => {},
});

export default function useAwaitConfirmActionModalProps (): UseAwaitConfirmActionModalPropsHook {
  const [props, setProps] = useState<AwaitConfirmActionModalProps>(getEmptyProps());

  const confirm = async (message: React.ReactNode) => {
    return new Promise<boolean>(resolve => {
      setProps({
        message,
        show: true,
        onHide: () => {
          resolve(false);
        },
        onDecline: () => {
          resolve(false);
        },
        onConfirm: () => {
          resolve(true);
        },
      });
    }).finally(() => {
      setProps(getEmptyProps());
    });
  };

  return {confirm, props};
}
