import React, {useCallback, useMemo} from 'react';
import TableSavedStateForm from 'src/tables/TableSavedStateForm';
import TableFilterForm from 'src/tables/TableFilterForm';
import BasicSpinner from 'src/spinners/BasicSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import omit from 'lodash/omit';
import qs from 'qs';
import {
  RefreshCw,
  Link,
  Minimize,
  Maximize,
  Settings,
} from 'lucide-react';
import { Col, Row, Card, Button, ButtonGroup } from 'react-bootstrap';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { TableState } from 'src/tables/types';
import {UseQueryResult} from '@tanstack/react-query';
import CopyToClipboardButton from 'src/buttons/CopyToClipboardButton';
import ModalOpeningButton from 'src/buttons/ModalOpeningButton';
import TableSettingsModal from 'src/tables/TableSettingsModal';

interface TableControlPanelHeadProps {
  title: string;
  error?: Error;
  columnDefinitions: TableColumnDefinition[];
  filterDefinitions: TableFilterDefinition[];
  defaultState: TableState;
  onSwapState: (state: TableState) => void;
  onSaveNewState: (state: TableState) => Promise<TableState>;
  onDeleteState: (state: TableState) => Promise<TableState>;
  setSavedStates: (states: TableState[]) => void;
  savedStates: TableState[];
  setState: (state: TableState) => void;
  updateState: (state: TableState) => void;
  state: TableState;
  routeUrl: string;
  refetch: UseQueryResult['refetch'];
  isLoading: boolean;
  extraButtons?: React.ReactNode;
  count: number | null;
}

const TableControlPanelHead: React.FC<TableControlPanelHeadProps> = React.memo(function TableControlPanelHead (props: TableControlPanelHeadProps) {
  const {
    columnDefinitions,
    filterDefinitions,
    defaultState,
    extraButtons,
    error,
    isLoading,
    onSaveNewState,
    onSwapState,
    onDeleteState,
    savedStates,
    setSavedStates,
    setState,
    state,
    title,
    refetch,
    updateState,
    routeUrl,
    count,
  } = props;

  const hasFilters = Object.keys(filterDefinitions).length > 0;

  const stateColumns = state.columns ?? [];

  const onClickRefetch = useCallback(() => {
    return refetch({throwOnError: true, cancelRefetch: false});
  }, [refetch]);

  return (
    <div>
      <Card.Header className="rounded-0 card-header-flush">
        <Row>
          <Col xs={12} sm={2} className="d-flex align-items-center">
            <Card.Title className="mb-0 d-flex align-items-center">
              {title}
              <BasicSpinner
                className="d-inline-block position-relative ms-2"
                isLoading={isLoading}
                style={{top: '2px'}}
              />
            </Card.Title>
          </Col>
          <Col className="mt-2 mt-md-0 ">
            <TableSavedStateForm
              state={state}
              savedStates={savedStates}
              setState={setState}
              defaultState={defaultState}
              onSwapState={onSwapState}
              onSaveNewState={onSaveNewState}
              onDeleteState={onDeleteState}
              setSavedStates={setSavedStates}
              filterDefinitions={filterDefinitions}
            />
          </Col>
          <Col className="d-flex gap-2 mt-2 mt-md-0 justify-content-start justify-content-md-end">
            {extraButtons}
            <Button
              className="px-2"
              variant="outline-primary"
              title="Ladda om tabellen"
              disabled={isLoading}
              onClick={onClickRefetch}
            >
              <RefreshCw size={16} />
            </Button>
            <ButtonGroup>
              <Button
                className="px-2"
                variant={state.size === 'sm' ? 'secondary' : 'outline-secondary'}
                title="Kompakta rader"
                onClick={() => updateState({size: 'sm'})}
              >
                <Minimize size={18} />
              </Button>
              <Button
                className="px-2"
                variant={state.size !== 'sm' ? 'secondary' : 'outline-secondary'}
                title="Stora rader"
                onClick={() => updateState({size: 'lg'})}
              >
                <Maximize size={18} />
              </Button>
            </ButtonGroup>
            <CopyTableLinkButton
              state={state}
              routeUrl={routeUrl}
            />
            <ModalOpeningButton
              Modal={TableSettingsModal}
              modalProps={{
                columnDefinitions,
                filterDefinitions,
                updateState,
                stateColumns,
                stateFilter: state.filter,
                stateFilterVisible: state.filterVisible,
                stateTitleAuto: state.titleAuto,
                count,
              }}
              className="px-2"
              variant="outline-primary"
              title="Visa tabellinställningar"
            >
              <Settings size={16} />{' '}
              <span className="d-none d-md-inline-block">
                Inställningar
              </span>
            </ModalOpeningButton>
          </Col>
        </Row>
        <ErrorAlert error={error} className="mt-3 mb-0" />
      </Card.Header>
      {hasFilters ? (
        <div style={{maxHeight: '40vh', overflowY: 'auto'}}>
          <Card.Body className="p-0">
            <TableFilterForm
              onlyShowVisibleFilters
              filterDefinitions={filterDefinitions}
              stateFilter={state.filter as any}
              stateFilterVisible={state.filterVisible as any}
              stateTitleAuto={state.titleAuto as any}
              updateState={updateState}
            />
          </Card.Body>
        </div>
      ) : null}
    </div>
  );
});
export default TableControlPanelHead;

interface CopyTableLinkButtonProps {
  state: TableState;
  routeUrl: string;
}

function CopyTableLinkButton (props: CopyTableLinkButtonProps) {
  const { state, routeUrl } = props;

  const url = useMemo(() => {
    const url = new URL(window.location.toString());
    url.pathname = routeUrl;
    url.search = qs.stringify({overrideSearch: JSON.stringify(omit(state, 'id'))}, {strictNullHandling: true});
    return url.toString();
  }, [state, routeUrl]);

  return (
    <CopyToClipboardButton
      className="px-2 ms-md-2"
      onCopyMessage="Länk till tabellen kopierad"
      copy={url}
      variant="outline-info"
    >
      <Link size={16} />
    </CopyToClipboardButton>
  );
}
