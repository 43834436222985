import React, {useCallback, useEffect, useState} from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { stateFilterToAutoTitle } from 'src/tables/helpers';
import Table, { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import { v4 as uuid } from 'uuid';
import omit from 'lodash/omit';
import clone from 'lodash/clone';
import {
  useMutation,
  useQuery,
  keepPreviousData,
  useIsFetching,
  useIsMutating,
} from '@tanstack/react-query';
import * as api from 'src/api';
import {
  TableSelectedRows,
  TableState,
  TableBatchDefinition,
} from 'src/tables/types';
import { useTableBatchActionMutation, useTableUpdateRowMutation } from 'src/hooks/useTableUtils';
import {TableContextProvider} from 'src/tables/TableContext';

export interface StateTableProps {
  batchDefinition?: TableBatchDefinition;
  columnDefinitions: TableColumnDefinition[];
  defaultState: TableState;
  exportUrl?: string;
  extraButtons?: React.ReactNode;
  filterDefinitions: TableFilterDefinition[];
  initialSavedStates: TableState[];
  initialState: TableState;
  queryKey: string;
  queryResultRowsKey?: string;
  queryUrl: string;
  routeUrl: string;
  title: string;
}

const StateTable: React.FC<StateTableProps> = React.memo(function StateTable (props: StateTableProps) {
  const {
    routeUrl,
    exportUrl,
    extraButtons,
    queryUrl,
    queryKey,
    queryResultRowsKey = 'rows',
    defaultState,
    initialState,
    initialSavedStates,
    columnDefinitions,
    filterDefinitions,
    title,
    batchDefinition,
  } = props;

  const [searchParams] = useSearchParams();

  let firstState: TableState = {...omit(initialState, 'id'), skipCount: true};
  const { initialStateId } = useParams();
  if (initialStateId) {
    const foundState = initialSavedStates.find((state: TableState) => state.id === initialStateId);
    if (foundState) {
      firstState = foundState;
    }
  } else if (searchParams.has('overrideSearch')) {
    const searchParamState = JSON.parse(searchParams.get('overrideSearch') || '');
    firstState = {...defaultState, ...searchParamState};
  }

  const [state, setStateRaw] = useState<TableState>(firstState);
  const [savedStates, setSavedStates] = useState<TableState[]>(initialSavedStates);
  const [selectedRows, setSelectedRows] = useState<TableSelectedRows>({});

  // keeps the state object and the possibly saved state in savedStates updated
  const setState = useCallback((newState: TableState) => {
    const { id } = newState;
    setStateRaw(newState);
    if (id) {
      const newSavedStates = savedStates.map((state: TableState) => {
        if (state.id !== id) return state;
        return clone(newState);
      });
      setSavedStates(newSavedStates);
    }
  }, [savedStates, setSavedStates, setStateRaw]);

  const navigate = useNavigate();
  const handleSwapState = useCallback((state: TableState) => {
    const { id } = state;
    const url = id ? `${routeUrl}/${id}` : routeUrl;
    navigate(url, {replace: true});
    setState(state);
  }, [navigate, setState, routeUrl]);

  // deletes a previously saved state (search)
  const deleteStateMutation = useMutation({
    mutationKey: [queryKey, 'deleteState'],
    mutationFn: (deleteState: TableState) => api.request({
      method: 'DELETE',
      url: `${queryUrl}/savedSearches/${deleteState.id}`,
    }),
    onSuccess: (data, deleteState) => {
      const { savedSearches:savedStates } = data;
      setState(omit(deleteState, 'id'));
      setSavedStates(savedStates);
      navigate(`${routeUrl}`, {replace: true});
    },
  });

  // upserts a saved state (search)
  const saveStateMutation = useMutation({
    mutationKey: [queryKey, 'saveState'],
    mutationFn: (saveState: TableState) => api.request({
      method: 'PUT',
      data: saveState,
      url: `${queryUrl}/savedSearches/${saveState.id}`,
    }),
    onSuccess: (data, saveState) => {
      const { savedSearches:savedStates } = data;
      setState(saveState);
      setSavedStates(savedStates);
      navigate(`${routeUrl}/${saveState.id}`, {replace: true});
    },
  });

  // helper function to save an entirely new state
  const handleSaveNewState = useCallback((state: TableState) => {
    const saveState = {
      ...state,
      title: stateFilterToAutoTitle(state.filter, filterDefinitions),
      titleAuto: true,
      id: uuid(),
    };
    return saveStateMutation.mutateAsync(saveState);
  }, [saveStateMutation, filterDefinitions]);

  // helper function to delete the state given
  const handleDeleteState = useCallback((state: TableState) => {
    return deleteStateMutation.mutateAsync(state);
  }, [deleteStateMutation]);

  // this is the actual query for the table data
  const query = useQuery({
    queryKey: [queryKey, state],
    placeholderData: keepPreviousData,
    queryFn: async props => {
      const state = {...(props.queryKey[1] as any)};
      const data = await api.request({
        url: queryUrl,
        params: state,
      });
      const { savedSearches:savedStates } = data;
      setSavedStates(savedStates);
      return data;
    },
  });

  const updateRowMutation = useTableUpdateRowMutation({
    queryKey: [queryKey, state],
    subKey: queryResultRowsKey,
  });

  const batchActionMutation = useTableBatchActionMutation({
    queryKey: [queryKey, state],
    selectedRows,
    setSelectedRows,
    batchDefinition,
    subKey: queryResultRowsKey,
    refetch: query.refetch,
  });

  const isFetching = useIsFetching({
    queryKey: [queryKey],
  });

  const isMutating = useIsMutating({
    mutationKey: [queryKey],
  });

  // this scrolls to the top of the table on changes in the dependency list
  useEffect(() => {
    const el = document.getElementById(queryKey);
    if (!el) return;
    el.scrollIntoView({behavior: 'smooth'});
  }, [
    queryKey,
    state.order,
    state.limit,
    state.filter,
    state.page,
  ]);

  const rows = query.data ? query.data[queryResultRowsKey] ?? [] : [];

  return (
    <TableContextProvider updateRowMutation={updateRowMutation}>
      <Table
        id={queryKey}
        title={title}
        isLoading={(isFetching > 0) || (isMutating > 0)}
        isInitialLoading={query.isFetching}
        error={query.error}
        rows={rows}
        count={(query.data?.count) as any}
        refetch={query.refetch}
        state={state}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        setState={setState as any}
        savedStates={savedStates}
        setSavedStates={setSavedStates}
        defaultState={defaultState}
        columnDefinitions={columnDefinitions}
        filterDefinitions={filterDefinitions}
        onSwapState={handleSwapState}
        onSaveNewState={handleSaveNewState}
        onDeleteState={handleDeleteState}
        exportUrl={exportUrl}
        routeUrl={routeUrl}
        batchActionMutateAsync={batchActionMutation.mutateAsync}
        batchDefinition={batchDefinition}
        extraButtons={extraButtons}
      />
    </TableContextProvider>
  );
});
export default StateTable;
