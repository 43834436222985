import React, {useCallback, useMemo} from 'react';
import TableControlPanelHead from 'src/tables/TableControlPanelHead';
import TableControlPanelFoot from 'src/tables/TableControlPanelFoot';
import TableHead from 'src/tables/TableHead';
import TableBody from 'src/tables/TableBody';
import TableFoot from 'src/tables/TableFoot';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import {
  TableState,
  TableSelectedRows,
  TableBatchDefinition,
  TableBatchActionMutationDate,
  TableStateOrder,
  TableRowId,
  TableColumnIdentifier,
} from 'src/tables/types';
import { Card, Table as BsTable } from 'react-bootstrap';
import { PermissionDefinition } from 'src/contexts/AuthContext';

export interface TableRow {
  id: TableRowId;
  [key: string]: any;
}

// just some custom parameters that can be passed to the table
export type TTableParams = Record<string, any>;

export interface IColumnHeaderProps {
  value: string;
  allRowsSelected: boolean;
  anyRowsSelected: boolean;
  onChangeAllSelected: React.ChangeEventHandler<HTMLInputElement>;
  [key: string]: any;
}

export interface TableColumnCellProps {
  value: any;
  original?: any;
  row: TableRow;
  selected: boolean;
  onChangeSelected: React.ChangeEventHandler<HTMLInputElement>;
  params?: TTableParams;
  [key: string]: any;
}

export interface IColumnFooterProps {
  selectedRows: TableSelectedRows;
  rows: TableRow[];
  allRowsSelected: boolean;
  anyRowsSelected: boolean;
  [key: string]: any;
}

export interface TableColumnDefinition {
  id: TableColumnIdentifier;
  title?: string;
  cellValue?: any;
  cellOriginal?: any | ((row: TableRow) => any);
  cellProps?: ({[key: string]: any});
  headerProps?: ({[key: string]: any});
  sortable?: boolean;
  Header?: React.ComponentType<IColumnHeaderProps>;
  Cell?: React.ComponentType<TableColumnCellProps>;
  Footer?: React.ComponentType<IColumnFooterProps>;
  permission?: PermissionDefinition;
  groups?: string[];
}

export interface TableColumnDefinitionMap {
  [key: string]: TableColumnDefinition;
}

export interface IFilterFilterProps {
  id: string;
  name: string;
  value: any;
  onChangeValue: (value: any) => void;
  [key: string]: any;
}

export interface IFilterTitleProps {
}

export interface TableFilterDefinition {
  id: string;
  filterValue?: (value: any) => React.ReactNode;
  Filter: React.ComponentType<IFilterFilterProps>;
  Title?: React.ComponentType<IFilterTitleProps>;
  title?: string;
  filterProps?: Record<string, any>;
  groups?: string[];
  permission?: PermissionDefinition;
  required?: boolean;
}

export type TableFilterDefinitionMap = Record<string, TableFilterDefinition>;

interface TableProps {
  id: string;
  title: string;
  isLoading: boolean;
  isInitialLoading: boolean;
  error: unknown;
  rows: TableRow[];
  count?: number;
  refetch: () => Promise<any>;
  state: TableState;
  setState: React.Dispatch<React.SetStateAction<TableState>>;
  savedStates: TableState[];
  setSavedStates: React.Dispatch<React.SetStateAction<TableState[]>>;
  defaultState: TableState;
  exportUrl?: string;
  routeUrl: string;
  extraButtons?: React.ReactNode;
  columnDefinitions: TableColumnDefinition[];
  filterDefinitions: TableFilterDefinition[];
  selectedRows: TableSelectedRows;
  setSelectedRows: React.Dispatch<React.SetStateAction<TableSelectedRows>>;
  onSwapState: (state: TableState) => void;
  onSaveNewState: (state: TableState) => Promise<any>;
  onDeleteState: (state: TableState) => Promise<any>;
  batchDefinition?: TableBatchDefinition;
  batchActionMutateAsync: UseMutateAsyncFunction<unknown, Error, TableBatchActionMutationDate>;
}

const Table: React.FC<TableProps> = React.memo(function Table (props: TableProps) {
  const {
    id,
    title,
    isLoading,
    isInitialLoading,
    error,
    rows,
    count,
    refetch,
    state,
    extraButtons,
    setState,
    savedStates,
    setSavedStates,
    defaultState,
    columnDefinitions,
    filterDefinitions,
    selectedRows,
    setSelectedRows,
    onSwapState,
    onSaveNewState,
    onDeleteState,
    exportUrl,
    routeUrl,
    batchDefinition,
    batchActionMutateAsync,
  } = props;

  const updateState = useCallback((extendWith = {}) => setState({
    ...state,
    ...extendWith,
  }), [state, setState]);

  const updateStateOrder = useCallback((newOrder: TableStateOrder) => {
    updateState({order: newOrder});
  }, [updateState]);

  const columnDefinitionsById: TableColumnDefinitionMap = useMemo(() => columnDefinitions.reduce((map, def) => {
    map[def.id] = def;
    return map;
  }, {}), [columnDefinitions]);

  return (
    <>
      <Card className="mb-0 rounded-0 border-0 overflow-hidden flex-grow-1">
        <TableControlPanelHead
          title={title}
          isLoading={isLoading}
          error={error as any}
          state={state}
          refetch={refetch}
          setState={setState}
          updateState={updateState}
          savedStates={savedStates}
          setSavedStates={setSavedStates}
          defaultState={defaultState}
          columnDefinitions={columnDefinitions}
          filterDefinitions={filterDefinitions}
          onSwapState={onSwapState}
          onSaveNewState={onSaveNewState}
          onDeleteState={onDeleteState}
          routeUrl={routeUrl}
          extraButtons={extraButtons}
          count={count ?? null}
        />
        <Card.Body className="p-0 border-top" style={{overflowY: 'auto', overflowX: 'scroll'}}>
          <BsTable
            id={id}
            className="mb-0 border-bottom table-sticky-thead"
            striped
            borderless
            size={state.size === 'sm' ? 'sm' : undefined}
          >
            <TableHead
              columnDefinitionsById={columnDefinitionsById}
              columns={state.columns}
              order={state.order}
              setOrder={updateStateOrder}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              rows={rows}
            />
            <TableBody
              columnDefinitionsById={columnDefinitionsById}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              rows={rows}
              columns={state.columns}
              isInitialLoading={isInitialLoading}
            />
            <TableFoot
              columnDefinitionsById={columnDefinitionsById}
              selectedRows={selectedRows}
              rows={rows}
              columns={state.columns}
            />
          </BsTable>
        </Card.Body>
      </Card>
      <TableControlPanelFoot
        state={state}
        updateState={updateState}
        count={count ?? null}
        selectedRows={selectedRows}
        exportUrl={exportUrl}
        batchDefinition={batchDefinition}
        batchActionMutateAsync={batchActionMutateAsync}
      />
    </>
  );
});
export default Table;
