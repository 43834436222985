import React from 'react';
import * as api from 'src/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UserRow } from 'shared/types/user';
import UserCreateForm, {UserCreateFormValues} from 'src/user/UserCreateForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useNavigate } from 'react-router-dom';

export default function UserCreateModalBody () {
  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const createMutation = useMutation<UserRow, Error, UserCreateFormValues>({
    mutationFn: async vars => {
      const user = await api.request({
        url: '/user',
        method: 'POST',
        data: vars,
      });

      queryClient.setQueryData([`/user/${user.id}`], user);
      notyf.success({type: 'success', message: 'Användaren skapades'});
      modalStateContext.onHide();
      navigate(`/user/${user.id}/form`);

      return user;
    },
  });

  const onSubmit = async values => {
    return await createMutation.mutateAsync(values);
  };

  const initialValues = {
    id: '',
  };

  return (
    <UserCreateForm
      className="mt-0"
      initialValues={initialValues}
      onSubmit={onSubmit}
      submitError={createMutation.error}
    />
  );
}
