import React from 'react';
import * as api from 'src/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FileRow } from 'shared/types/files';
import FileCreateForm, {FileCreateFormValues} from 'src/file/FileCreateForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitCreates } from 'src/hooks/useEventModels';
import {useNavigate} from 'react-router';

export default function FileCreateModalBody () {
  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const emitCreate = useEventModelEmitCreates<FileRow>('file');

  const createMutation = useMutation<FileRow, Error, FileCreateFormValues>({
    mutationFn: async vars => {
      const file = await api.request({
        url: '/file',
        method: 'POST',
        data: vars,
      });

      queryClient.setQueryData([`/file/${file.id}`], file);
      notyf.success({type: 'success', message: 'Filen skapades'});
      modalStateContext.onHide();
      emitCreate(file);
      navigate(`/files/${file.id}`);

      return file;
    },
  });

  const onSubmit = async values => {
    return await createMutation.mutateAsync(values);
  };

  const initialValues = {
    name: '',
  };

  return (
    <div>
      <FileCreateForm
        className="mt-0"
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitError={createMutation.error}
      />
    </div>
  );
}

