import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import * as pepQueryFilter from 'shared/filter/pep_query';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import { PepQueryRow } from 'shared/types/pep_query';

interface NameProps {
  value: Pick<PepQueryRow, 'id'>;
}

export function Name (props: NameProps) {
  const { value:pepQuery } = props;
  if (!isObjectLike(pepQuery)) return null;
  const { id } = pepQuery;
  return (
    <>{id}</>
  );
}

interface StatusProps {
  value: PepQueryRow['status'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  return pepQueryFilter.status(value);
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: PepQueryRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={pepQueryFilter.status}
      {...restOfProps}
    />
  );
}

interface StatusResultProps {
  value: PepQueryRow;
}

export function StatusResult (props: StatusResultProps) {
  const { value } = props;
  return pepQueryFilter.status(value);
}

interface StatusResultLabelProps extends StatusBaseLabelProps {
  value: PepQueryRow;
}

export function StatusResultLabel (props: StatusResultLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={pepQueryFilter.statusResultValue(value)}
      formatter={() => pepQueryFilter.statusResult(value)}
      {...restOfProps}
    />
  );
}
