import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SmsTemplateRow } from 'shared/types/sms_template';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitCreates, useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';
import SmsTemplateEditForm, {SmsTemplateEditFormValues, smsTemplateToFormValues, formValuesToUpdate} from 'src/smsTemplate/SmsTemplateEditForm';
import {useNavigate} from 'react-router';

interface SmsTemplateModalBodyProps {
  id: string;
}

export default function SmsTemplateModalBody (props: SmsTemplateModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const emitCreate = useEventModelEmitCreates<SmsTemplateRow>('smsTemplate');
  const emitUpdate = useEventModelEmitUpdates<SmsTemplateRow>('smsTemplate');
  const emitDelete = useEventModelEmitDeletes<SmsTemplateRow>('smsTemplate');

  const query = useQuery<SmsTemplateRow, Error>({
    queryKey: [`/template/sms/${id}`],
    enabled: Boolean(id),
    placeholderData: keepPreviousData,
  });

  const smsTemplate = query.data;

  const updateMutation = useMutation<SmsTemplateRow, Error, Partial<SmsTemplateEditFormValues>>({
    mutationFn: async vars => {
      const smsTemplate = await api.request({
        url: `/template/sms/${id}`,
        method: 'PATCH',
        data: vars,
      });

      queryClient.setQueryData([`/template/sms/${id}`], smsTemplate);
      notyf.success({type: 'default', message: 'SMS-mallen uppdaterades'});
      modalStateContext.onHide();
      emitUpdate(smsTemplate);

      return smsTemplate;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/template/sms/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'SMS-mallen raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const cloneMutation = useMutation<SmsTemplateRow, Error, any>({
    mutationFn: async () => {
      // FIXME yes, the form values are not used when cloning
      // update backend to be able to clone the local vars
      const smsTemplate = await api.request({
        url: `/template/sms/${id}/clone`,
        method: 'POST',
        data: {},
      });
      notyf.success({type: 'success', message: 'SMS-mallen klonades. Du redigerar nu kopian.'});
      emitCreate(smsTemplate);

      modalStateContext.onHide();
      navigate(`/sms_template/${smsTemplate.id}`);

      return smsTemplate;
    },
  });

  const onClone = async (values: SmsTemplateEditFormValues) => {
    return cloneMutation.mutateAsync(values);
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<SmsTemplateRow, SmsTemplateEditFormValues, Partial<SmsTemplateEditFormValues>>({
    queryDataToFormValues: smsTemplateToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(smsTemplateToFormValues(smsTemplate), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading || query.isRefetching} className="m-3" />
      {smsTemplate && (
        <SmsTemplateEditForm
          className="mt-0"
          initialValues={smsTemplateToFormValues(smsTemplate)}
          onSubmit={formCycleHelpers.onSubmit}
          submitError={updateMutation.error}
          cloneError={cloneMutation.error}
          onDelete={onDelete}
          onClone={onClone}
        />
      )}
    </div>
  );
}
