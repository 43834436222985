import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import FileModalBody from 'src/file/FileModalBody';
import { Name } from 'src/file/FileFormatters';
import {FileRow} from 'shared/types/files';
import Anchor from  'src/anchors/Anchor';

interface FileAnchorProps {
  file: Pick<FileRow, 'id' | 'name'>;
}

export default function FileAnchor (props: FileAnchorProps) {
  const { file, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={file} />}
      name="Redigera fil"
    >
      <FileModalBody id={file.id} />
    </ModalAnchor>
  );
}

export function FilePlainAnchor (props: FileAnchorProps) {
  const { file, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/files/${file.id}`}
      name="Filen"
    >
      <Name value={file} />
    </Anchor>
  );
}
