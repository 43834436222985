import React from 'react';
import emailForbiddenFilter from 'shared/filter/email_forbidden';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import TablePage from 'src/tables/TablePage';
import {Trash, XOctagon} from 'lucide-react';
import {GuardPermission} from 'src/guards/AuthGuards';

export default function EmailForbiddenTablePage () {
  return (
    <TablePage
      title="Förbjudna e-postadresser"
      pageTitle="Förbjudna e-postadresser"
      columnDefinitions={columnDefinitions}
      filterDefinitions={filterDefinitions}
      routeUrl="/email_forbidden"
      baseQueryUrl="/email_forbidden/table"
      baseQueryKey="EmailForbiddenTablePage"
      hasExport={false}
    />
  );
}

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.string({id: 'email'}),
  commonFilterDefs.string({id: 'reason'}),
  commonFilterDefs.boolean({id: 'override_ban'}),
  commonFilterDefs.uuid({id: 'customer_id'}),
  commonFilterDefs.dateRange({id: 'created_at'}),
  commonFilterDefs.dateRelative({id: 'created_at_relative'}),
].map(filterDefinition => ({
  title: emailForbiddenFilter.tableColumnName(filterDefinition.id),
  ...filterDefinition,
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.basic({id: 'email'}),
  commonColumnDefs.datetime({
    id: 'created_at',
    sortable: true,
  }),
  commonColumnDefs.basic({id: 'reason'}),
  commonColumnDefs.boolean({
    id: 'override_ban',
  }),
  commonColumnDefs.customerId({id: 'customer_id'}),
  commonColumnDefs.actions({}, [
    props => (
      <GuardPermission permission="email_forbidden_update">
        <TableCellActions.ExecuteButton
          {...props}
          title="Ändra blockeringen"
          variant="outline-primary"
          mutateVars={{
            optimistic: true,
            rowId: String(props.row.email),
            url: `/email_forbidden/${props.row.email}`,
            method: 'PATCH',
            data: props.row.override_ban ? {override_ban: false} : {override_ban: true},
            onSuccessNotyf: {type: 'warning', message: 'Blockeringen ändrades'},
          }}
        >
          <XOctagon size={14} />
        </TableCellActions.ExecuteButton>
      </GuardPermission>
    ),
    props => (
      <GuardPermission permission="email_forbidden_delete">
        <TableCellActions.ExecuteModalConfirmButton
          {...props}
          title="Radera blockeringen"
          variant="outline-danger"
          mutateVars={{
            deletion: true,
            rowId: String(props.row.email),
            url: `/email_forbidden/${props.row.email}`,
            method: 'DELETE',
            onSuccessNotyf: {type: 'warning', message: 'Blockeringen raderades'},
          }}
        >
          <Trash size={14} />
        </TableCellActions.ExecuteModalConfirmButton>
      </GuardPermission>
    ),
    TableCellActions.InspectRow,
  ]),
].map(columnDef => ({
  title: emailForbiddenFilter.tableColumnName(columnDef.id),
  ...columnDef,
}));
