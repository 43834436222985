import React from 'react';
import * as NumberFormatters from 'src/formatters/NumberFormatters';
import * as numbers from 'src/numbers';
import BooleanFormatter from 'src/formatters/Boolean';
import * as translations from 'shared/translations';
import DateTime from 'src/formatters/DateTime';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';
import {BankProcessRowOffer} from 'shared/types/bank_process';
import CopyToClipboardButton from 'src/buttons/CopyToClipboardButton';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import {BankProcessBidRow} from 'shared/types/bank_process_bid';
import * as bankProcessBidFilter from 'shared/filter/bank_process_bid';
import * as loanFilter from 'shared/filter/loan';
import {isPlainObject} from 'lodash';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';

interface OfferTypeProps {
  value: string; // type
}

export function OfferType (props: OfferTypeProps) {
  const { value } = props;
  return (
    <>{translations.application.offer_type(value)}</>
  );
}

interface OfferValueProps {
  type: string;
  value: any;
}

export function OfferValue (props: OfferValueProps) {
  const { type, value } = props;

  if (typeof value === 'undefined' || value === null) {
    return '-';
  }

  switch (type) {
    default: {
      if (numbers.isFiniteNumber(value)) {
        return <><NumberFormatters.Currency value={value} /></>;
      }

      if (isPlainObject(value)) {
        return (
          <InspectObjectModalButton object={value} size="sm" className="p-0" />
        );
      }

      return <>{value}</>;
    }

    case 'our_id':
    case 'their_id': return (
      <span>
        <code>{value}</code>{' '}
        <CopyToClipboardButton
          copy={value}
          size="sm"
          className="px-1 py-0"
          variant="outline-info"
          onCopyMessage={type === 'our_id' ? 'Långivarens Bud-ID kopierat' : 'Tryggas Bud-ID kopierat'}
        />
      </span>

    );

    case 'signing_link':
    case 'signing_link_co':
    case 'signing_link_main': return (
      <ExternalLinkAnchor href={value}>Öppna länk</ExternalLinkAnchor>
    );

    case 'expires_at':
      return <DateTime value={value} />;

    case 'after_no_repay_monthly_cost':
    case 'credento_insurance_monthly_cost':
    case 'monthly_cost_last':
    case 'monthly_cost':
    case 'no_repay_monthly_cost':
      return <><NumberFormatters.Number value={value} format={{maximumFractionDigits: 0}} />&nbsp;kr/mån</>;

    case 'repayment_years': {
      // repayment_years valued in whole years?
      if (Math.floor(value) === value) {
        return <>{value} år</>;
      }
      return loanFilter.repaymentTime(value * 12);
    }

    case 'verifications':
      return <>{translations.application.offer_verifications(value).join(', ')}</>;

    case 'amortization_type':
      return <>{translations.application.offer_amortization_type(value)}</>;

    case 'interest_rate':
    case 'interest_rate_effective':
      return <NumberFormatters.Percent value={value} format={{minimumFractionDigits: 2}} />;

    case 'co_applicant':
    case 'avida_existing_customer': // deprecated
    case 'norwegian_flex_loan':
    case 'credento_is_ppi_mandatory':
    case 'credento_is_preapproved':
    case 'credento_is_top_up':
    case 'resolve_required': // deprecated
    case 'preapproved_mc': // deprecated
    case 'existing_loan':
      return <BooleanFormatter value={value} />;
  }
}


interface TitleOneLinerProps {
  index?: number;
  value: BankProcessRowOffer;
}

interface TitleOneLinerChildProps {
  type: string;
  value: any;
}

export function TitleOneLiner (props: TitleOneLinerProps) {
  const { index, value:bid } = props;

  const childProps: (null | TitleOneLinerChildProps)[] = ['amount', 'repayment_years', 'interest_rate_effective', 'monthly_cost'].map(type => {
    const value = bid[type];
    if (!numbers.isFiniteNumber(value)) return null;
    return {type, value};
  });

  return (
    <span>
      {typeof index !== 'undefined' ? `#${index + 1}: ` : ''}
      {childProps.map((childProp, index) => (
        <span key={childProp?.type ?? index}>
          {childProp === null ? '-' : (
            <OfferValue {...childProp} />
          )}
          {index !== childProps.length - 1 ? ' | ' : ''}
        </span>
      ))}
    </span>
  );
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: BankProcessBidRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={bankProcessBidFilter.status}
      {...restOfProps}
    />
  );
}
