import React, {useState, useMemo} from 'react';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router';
import BlockSpinner from 'src/spinners/BlockSpinner';
import { ApplicationRow } from 'shared/types/application';
import useQueryClientUtils from 'src/hooks/useQueryClientUtils';
import useWebsocketModelUpdates from 'src/hooks/useWebsocketModelUpdates';
import { BankProcessRow, BankProcessRowWithRelations } from 'shared/types/bank_process';
import { BankRow } from 'shared/types/bank';
import classNames from 'classnames';
import {Card, Form, InputGroup, Button} from 'react-bootstrap';
import {X} from 'lucide-react';
import { sortBy } from 'lodash';
import IdProvider from 'src/components/IdProvider';
import BankProcessCard from 'src/bankProcess/BankProcessCard';
import BankProcessUncreatedCard from 'src/bankProcess/BankProcessUncreatedCard';

interface ApplicationPageTabProcessProps {
  className?: string;
}

interface ApplicationPageTabProcessResponse {
  application: ApplicationRow;
  banks: BankRow[];
  banksUncreated: BankRow[];
  processes: BankProcessRowWithRelations[];
}

type OrderBy = 'relevance' | 'bank_id' | 'status_updated_at';

export default function ApplicationPageTabProcess (props: ApplicationPageTabProcessProps) {
  const { className } = props;
  const {
    applicationId,
    part1:activeBankProcessId,
    part2:activeBankProcessTab = 'form',
  } = useParams() as any;

  const queryClientUtils = useQueryClientUtils();

  const wsModelUpdates = useWebsocketModelUpdates<BankProcessRow>('bank_process', function (id, update) {
    const row = {...update, id};
    queryClientUtils.applyUpdates({queryKey: [`/application/${applicationId}/process`]}, [row], {
      subKey: 'processes',
    });
  });

  const query = useQuery<ApplicationPageTabProcessResponse>({
    queryKey: [`/application/${applicationId}/process`],
    meta: {
      onSuccess: data => {
        const processIds = data.processes?.map(p => p.id);
        wsModelUpdates.setIds(processIds);
      },
    },
  });

  const [orderBy, setOrderBy] = useState<OrderBy>('relevance');
  const [filter, setFilter] = useState<string>('');

  const filterRegExp = useMemo(() => new RegExp(`^${filter}`, 'i'), [filter]);

  const bankProcesses = useMemo(() => {
    return sortBy(query.data?.processes || [], bankProcess => {
      if (orderBy === 'relevance') {
        const priority = ['new', 'error', 'open', 'waiting', 'accepted', 'disbursed'];
        let { status } = bankProcess;
        if (query.data?.application?.accepted_process_id === bankProcess.id) {
          // the accepted process is considered "accepted" no matter the actual status
          status = 'accepted';
        }
        return -1 * priority.indexOf(status);
      } else if (orderBy === 'status_updated_at') {
        return -1 * (new Date(bankProcess[orderBy])).valueOf();
      }
      return bankProcess[orderBy];
    }).filter(bankProcess => {
      if (!filter) return true;
      return filterRegExp.test(bankProcess.bank_id) || filterRegExp.test(bankProcess.Bank?.name ?? '');
    });
  }, [orderBy, filter, query.data, filterRegExp]);

  const banksUncreated = useMemo(() => {
    return (query.data?.banksUncreated || []).filter(bank => {
      if (!filter) return true;
      return filterRegExp.test(bank.id) || filterRegExp.test(bank.name ?? '');
    });
  }, [filter, query.data, filterRegExp]);

  return (
    <div className={classNames(className, 'position-relative')}>
      <BlockSpinner isLoading={query.isLoading} className="mb-3 mx-1" />
      <BankProcessViewOptionsCard
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        filter={filter}
        setFilter={setFilter}
      />
      {query.isSuccess && (
        <div>
          {bankProcesses.map(bankProcess => (
            <BankProcessCard
              key={bankProcess.id}
              applicationId={applicationId}
              bankProcess={bankProcess}
              activeBankProcessId={activeBankProcessId}
              activeBankProcessTab={activeBankProcessTab}
            />
          ))}
          {banksUncreated.map(bank => (
            <BankProcessUncreatedCard
              key={bank.id}
              bank={bank}
              applicationId={applicationId}
            />
          ))}
          {!banksUncreated.length && !bankProcesses.length && (
            <p>Det finns ingenting här.</p>
          )}
        </div>
      )}
    </div>
  );
}

interface BankProcessViewOptionsCardProps {
  orderBy: OrderBy;
  setOrderBy: React.Dispatch<React.SetStateAction<OrderBy>>;
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
}

function BankProcessViewOptionsCard (props: BankProcessViewOptionsCardProps) {
  const { orderBy, setOrderBy, filter, setFilter } = props;

  const onChangeOrderBy = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setOrderBy(ev.target.value as OrderBy);
  };

  const onChangeFilter = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(ev.target.value);
  };

  const onClickClearFilter = () => {
    setFilter('');
  };

  return (
    <Card className="rounded">
      <Card.Body className="p-3 d-flex align-items-center gap-3 flex-wrap justify-content-center justify-content-md-start">
        <strong>Sortera efter:{' '}</strong>
        <IdProvider>
          {id => (
            <Form.Check
              className="me-0 mb-0"
              id={id}
              type="radio"
              label="Relevans"
              value="relevance"
              onChange={onChangeOrderBy}
              checked={orderBy === 'relevance'}
            />
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              className="me-0 mb-0"
              id={id}
              type="radio"
              label="Långivarnamn"
              value="bank_id"
              onChange={onChangeOrderBy}
              checked={orderBy === 'bank_id'}
            />
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              className="me-0 mb-0"
              id={id}
              type="radio"
              label="Statusuppdatering"
              value="status_updated_at"
              onChange={onChangeOrderBy}
              checked={orderBy === 'status_updated_at'}
            />
          )}
        </IdProvider>
        <strong>Filter: {' '}</strong>
        <IdProvider>
          {id => (
            <InputGroup className="w-auto">
              <Form.Control
                size="sm"
                id={id}
                name="filter"
                value={filter}
                onChange={onChangeFilter}
                placeholder="Oavsett långivarnamn"
              />
              {filter && (
                <Button
                  size="sm"
                  variant="outline-primary"
                  onClick={onClickClearFilter}
                  disabled={!filter}
                  className="px-1"
                >
                  <X size={14} />
                </Button>
              )}
            </InputGroup>
          )}
        </IdProvider>
      </Card.Body>
    </Card>
  );
}
