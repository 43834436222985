import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import constants from 'shared/constants';
import userFilters from 'shared/filter/user';
import * as UserFormatters from 'src/user/UserFormatters';
import {Button, Form} from 'react-bootstrap';
import { range, random } from 'lodash';
import {useField} from 'formik';
import * as api from 'src/api';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import * as formValidators from 'src/form/validators';
import SimpleModalOpeningButton from 'src/buttons/SimpleModalOpeningButton';
import { QRCode } from 'react-qr-svg';
import useModalStateContext from 'src/hooks/useModalStateContext';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {BankSelectOptionsProvider} from 'src/bank/BankSelectOptions';
import IdProvider from 'src/components/IdProvider';

export function Id (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="ID"
      required
      pattern="^[a-zåäö\-.]*$"
      {...props}
      name="id"
    />
  );
}

export function Name (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Namn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="name"
    />
  );
}

export function Email (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="E-postadress"
      type="email"
      minLength={0}
      maxLength={255}
      explainer="Används i kontaktmallar och för TOTP via e-post"
      {...props}
      name="email"
    />
  );
}

export function TelephoneNumber (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Telefon"
      type="tel"
      inputMode="tel"
      explainer="Används i kontaktmallar"
      {...props}
      name="telephone_number"
    />
  );
}

export function LoginMethod (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Inloggningsmetod"
      required
      {...props}
      name="login_method"
    >
      {constants.user.login_methods.map((value: string) => (
        <option key={value} value={value}>
          {userFilters.loginMethod(value)}
        </option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function Role (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Roll"
      required
      {...props}
      name="role"
    >
      {constants.user.roles.map(role => (
        <option key={role} value={role}>
          {userFilters.role(role)}
        </option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function BankId (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <BankSelectOptionsProvider>
      {options => (
        <FormikFormControls.FormGroupSelect
          label="Tillhörande långivare"
          required
          {...props}
          name="bank_id"
        >
          <option value="">Ingen långivare</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </FormikFormControls.FormGroupSelect>
      )}
    </BankSelectOptionsProvider>
  );
}

export function IsActive (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      className="mt-3"
      label="Användaren är aktiv"
      type="checkbox"
      {...props}
      name="is_active"
    />
  );
}

export function DefaultApp (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  const functionsField = useField('functions')[0];
  const functions = Array.isArray(functionsField.value) ? functionsField.value : [];

  const canOld = functions.includes('crm');
  const canNew = functions.includes('app');

  return (
    <FormikFormControls.FormGroupSelect
      label="Destination vid inloggning"
      {...props}
      name="default_app"
    >
      <option value="">Samma som inloggningsformulär</option>
      <option value="crm" disabled={!canOld}>Gamla CRM</option>
      <option value="app" disabled={!canNew}>Nya CRM</option>
    </FormikFormControls.FormGroupSelect>
  );
}

export function LoginMethodBankIdPersonalNumber (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Personnummer för BankID"
      inputMode="numeric"
      explainer="Ange personnumret med exakt 12 siffror"
      customValidate={formValidators.validatePersonalNumber()}
      {...props}
      name="login_method_bankid_personal_number"
    />
  );
}

export function IpWhitelist (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Tillåtna IP-adresser"
      {...props}
      name="ip_whitelist"
    />
  );
}

interface FunctionCheckProps {
  value: string;
  label: string;
}

export function FunctionCheck (props: FunctionCheckProps) {
  const { value, label } = props;

  const [field, , helpers] = useField<string[]>({
    name: 'functions',
  });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    if (ev.target.checked) {
      helpers.setValue([...field.value, value]);
    } else {
      helpers.setValue(field.value.filter(v => v !== value));
    }
    helpers.setTouched(true);
  };

  return (
    <IdProvider>
      {id => (
        <Form.Check
          id={id}
          value={value}
          label={label}
          name={`functions.${value}`}
          checked={field.value.includes(value)}
          onChange={onChange}
        />
      )}
    </IdProvider>
  );
}

interface LoginMethodTotpSecretProps extends Partial<FormikFormControls.InputGroupControlProps> {
  userId: string;
}

export function LoginMethodTotpSecret (props: LoginMethodTotpSecretProps) {
  const { userId, ...restOfProps } = props;

  const explainer = (
    <>
      Förslag på app:
      {' '}<a href="https://apps.apple.com/se/app/otp-auth/id659877384" target="_blank" rel="noreferrer">iOS</a>
      {', '}<a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US" target="_blank" rel="noreferrer">Android</a>
    </>
  );

  const [,, helpers] = useField('login_method_totp_secret');

  const onSecret = (secret: string) => {
    helpers.setTouched(true);
    helpers.setValue(secret);
  };

  const after = (
    <SimpleModalOpeningButton
      label="Registrera"
      variant="outline-primary"
      modalTitle="Scanna koden med OTP-appen"
      modalBodyClassName="text-center"
      disabled={restOfProps.disabled}
    >
      <LoginMethodTotpAppEnrollModalBody userId={userId} onYieldSecret={onSecret} />
    </SimpleModalOpeningButton>
  );

  return (
    <FormikFormControls.InputGroupControl
      label="TOTP via app"
      type="password"
      explainer={explainer}
      {...restOfProps}
      name="login_method_totp_secret"
      after={after}
    />
  );
}

interface LoginMethodTotpAppEnrollModalBodyProps {
  userId: string;
  onYieldSecret: (secret: string) => void;
}

interface TotpAppEnrollResponseBody {
  qrCodeDataUrl: string;
  authUrl: string;
  secret: string;
}

function LoginMethodTotpAppEnrollModalBody (props: LoginMethodTotpAppEnrollModalBodyProps) {
  const { userId, onYieldSecret } = props;

  const modalState = useModalStateContext();

  const query = useQuery<TotpAppEnrollResponseBody>({
    queryKey: [`/user/${userId}/totpAppEnroll`, {method: 'POST'}],
  });

  const onClickClose = () => {
    onYieldSecret(query.data.secret);
    modalState.onHide();
  };

  return (
    <div>
      <BlockSpinner isLoading={query.isLoading} />
      <ErrorAlert error={query.error} />
      {query.isSuccess && (
        <div>
          <div>
            <QRCode style={{width: 150, height: 150}} value={query.data.authUrl} />
          </div>
          <div className="mt-3">
            <Button onClick={onClickClose} variant="secondary">
              Jag har lagt till koden i appen
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}


interface PasswordProps extends Partial<FormikFormControls.InputGroupControlProps> {
  passwordStrength?: number;
}

export function Password (props: PasswordProps) {
  const { passwordStrength, ...restOfProps } = props;

  const [field, meta, helpers] = useField('password');

  const onClickGeneratePassword = () => {
    const password = generatePassword(16);
    helpers.setValue(password);
    helpers.setTouched(true);
  };

  const query = useQuery<{score: number}>({
    enabled: Boolean(field.value && meta.touched),
    placeholderData: keepPreviousData,
    queryKey: ['/misc/passwordStrength', field.value],
    queryFn: async ctx => {
      const response = await api.request({
        method: 'POST',
        url: '/misc/passwordStrength',
        signal: ctx.signal,
        data: {password: field.value},
      });
      return response;
    },
  });

  const shownPasswordStrength = query.isSuccess && 'score' in query.data ? query.data.score : passwordStrength;

  return (
    <div>
      <FormikFormControls.InputGroupControl
        label="Lösenord"
        type="text"
        explainer="Lämna fältet tomt för att inte byta lösenord"
        after={<Button variant="outline-primary" onClick={onClickGeneratePassword}>Generera</Button>}
        {...restOfProps}
        name="password"
      />
      <UserFormatters.PasswordStrength value={shownPasswordStrength} />
    </div>
  );
}

function generatePassword (length: number = 16): string {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return range(length).map(i => {
    return chars[random(0, chars.length)];
  }).join('');
}
