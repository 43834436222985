import React from 'react';
import Anchor from  'src/anchors/Anchor';
import QuicklookAnchor from 'src/anchors/QuicklookAnchor';
import BankProcessQuicklook from 'src/bankProcess/BankProcessQuicklook';
import * as BankProcessFormatters from 'src/bankProcess/BankProcessFormatters';
import { FavoriteButton } from 'src/buttons/IconButtons';
import {BankProcessRow} from 'shared/types/bank_process';

interface BankProcessAnchorProps {
  bankProcess: Pick<BankProcessRow, 'id' | 'bank_id' | 'application_id'> & {bank_name?: string};
  title?: React.ReactNode;
}

export default function BankProcessAnchor (props: BankProcessAnchorProps) {
  const { bankProcess, title, ...restOfProps } = props;
  const url = `/application/${bankProcess.application_id}/bankProcess/${bankProcess.id}/form`;
  return (
    <QuicklookAnchor
      {...restOfProps}
      url={url}
      title={title ? title : <BankProcessFormatters.Title value={bankProcess as any} />}
      favorite={<FavoriteButton className="px-0" type="bank_process" id={bankProcess.id} />}
      name="Process"
      objectId={bankProcess.id}
    >
      <BankProcessQuicklook id={bankProcess.id} />
    </QuicklookAnchor>
  );
}

export function BankProcessPlainAnchor (props: BankProcessAnchorProps) {
  const { bankProcess, title, ...restOfProps } = props;
  const url = `/application/${bankProcess.application_id}/bankProcess/${bankProcess.id}/form`;
  return (
    <Anchor {...restOfProps} name="Bankprocess" url={url}>
      {title ? title : <BankProcessFormatters.Title value={bankProcess as any} />}
    </Anchor>
  );
}
