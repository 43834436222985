import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import { EmailTemplateRow } from 'shared/types/email_template';

interface NameProps {
  value: Pick<EmailTemplateRow, 'id' | 'name'>;
}

export function Name (props: NameProps) {
  const { value:emailTemplate } = props;
  if (!isObjectLike(emailTemplate)) return null;
  return <>{emailTemplate.name}</>;
}
