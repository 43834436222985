import React from 'react';
import { BankAdapterValidatorResult } from 'shared/types/bank_adapter';
import validateFilters from 'shared/filter/validate';

interface BankAdapterValidationErrorsListProps {
  errors?: BankAdapterValidatorResult['errors'];
}

export default function BankAdapterValidationErrorsList (props: BankAdapterValidationErrorsListProps) {
  const { errors } = props;
  if (!Array.isArray(errors) || !errors.length) return null;
  return (
    <ul className="list-unstyled mb-0 text-start">
      {errors.map((error, index) => (
        <li key={index}>
          <strong className="text-danger">{error.field}:</strong> {validateFilters.reason(error.reason)}
        </li>
      ))}
    </ul>
  );
}
