import React from 'react';
import {
  Bell,
  UserPlus,
  DollarSign,
  Gift,
  Divide,
} from 'lucide-react';

export default function UserLogDataTypeIcon (props) {
  const { type, ...otherProps } = props;
  switch (type) {
    default: return <Bell {...otherProps} />;
    case 'application_created': return <UserPlus {...otherProps} />;
    case 'application_disbursed': return <DollarSign {...otherProps} />;
    case 'application_assigned': return <Gift {...otherProps} />;
    case 'new_offer': return <Divide {...otherProps} />;
  }
}
