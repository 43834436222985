import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from 'src/components/StatusLabelContainer';
import { StatusLabel } from 'src/complaint/ComplaintFormatters';
import { useQuery } from '@tanstack/react-query';
import { allWidgets, widgetGroups } from 'src/complaint/ComplaintWidgets';
import WidgetList from 'src/widgets/WidgetList';
import useWidgetList from 'src/hooks/useWidgetList';
import {ComplaintRow} from 'shared/types/complaint';

interface ComplaintQuicklookProps {
  id: string;
}

const ComplaintQuicklook: React.FC<ComplaintQuicklookProps> = React.memo(function ComplaintQuicklook (props: ComplaintQuicklookProps) {
  const { id } = props;

  const query = useQuery<ComplaintRow>({
    queryKey: [`/complaint/${id}`],
  });

  const [state, dispatch] = useWidgetList('complaintQuicklook', [
    'properties',
  ]);

  const complaint = query?.data;

  return (
    <div className="position-relative">
      <ErrorAlert error={query.error} className="mx-3 my-0" />
      <BlockSpinner isLoading={query.isLoading} className="mx-3" />
      {complaint && (
        <>
          <StatusLabelContainer>
            <StatusLabel value={complaint.status} />
          </StatusLabelContainer>
          <WidgetList
            allWidgets={allWidgets}
            widgetGroups={widgetGroups}
            state={state}
            dispatch={dispatch}
            widgetProps={{complaint}}
          />
        </>
      )}
    </div>
  );
});
export default ComplaintQuicklook;
