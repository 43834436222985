import { useEffect } from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';

function useSettingsState (key: string, initialValue: any) {
  const [value, setValue] = useLocalStorage(key, initialValue);

  useEffect(() => {
    // Set data attribute on root <html> element
    document.documentElement.dataset[key] = value;
  }, [value, key]);

  return [value, setValue];
}

export default useSettingsState;
