import React, { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

export interface ModalOpeningButtonModalProps {
  show: boolean;
  onHide: () => void;
}

export interface ModalOpeningButtonProps<M extends React.ComponentType<ModalOpeningButtonModalProps>> extends ButtonProps {
  children: React.ReactNode;
  Modal: M;
  modalProps?: Omit<React.ComponentProps<M>, 'show' | 'onHide'>;
}

function ModalOpeningButton<M extends React.ComponentType<ModalOpeningButtonModalProps>> (props: ModalOpeningButtonProps<M>) {
  const {
    children,
    // eslint-disable-next-line
    Modal:OtherModal,
    modalProps = {},
    ...buttonProps
  } = props;

  const Modal = props.Modal as React.ComponentType<ModalOpeningButtonModalProps>;

  const [showModal, setShowModal] = useState<boolean>(false);

  const onHide = () => {
    setShowModal(false);
  };

  const onShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button {...buttonProps} onClick={onShow}>
        {children}
      </Button>
      <Modal
        {...modalProps}
        show={showModal}
        onHide={onHide}
      />
    </>
  );
}

// NOTE if this is React.memo()'ed the type checking for modalProps does not work
export default ModalOpeningButton;
