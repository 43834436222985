import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import constants from 'shared/constants';
import * as templateFilters from 'shared/filter/template';
import {useField} from 'formik';

export function Subject (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Ämnesrad"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="subject"
    />
  );
}

export function HtmlRenderer (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Renderare (HTML)"
      {...props}
      name="html_renderer"
    >
      <option value="">Ingen</option>
      {constants.template.email.html_renderers.map((value: string) => (
        <option key={value} value={value}>{value}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function HtmlLayouts (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Layout (HTML)"
      {...props}
      name="html_layout"
    >
      <option value="">Ingen</option>
      {constants.template.email.html_layouts.map((value: string) => (
        <option key={value} value={value}>{value}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function Category (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Tillhörande objekt"
      required
      {...props}
      name="category"
    >
      {constants.template.email.categories.map((value: string) => (
        <option key={value} value={value}>{templateFilters.category(value)}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function BodyHtml (props: Partial<FormikFormControls.FormGroupControlProps>) {
  const [field] = useField('body_html');
  return (
    <FormikFormControls.FormGroupControl
      label="Meddelande (HTML)"
      as="textarea"
      name="body_html"
      minLength={0}
      maxLength={10000}
      rows={Math.min(20, Math.max(5, field.value.split(/\n/g).length + 1))}
      {...props}
    />
  );
}
