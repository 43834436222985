import React from 'react';
import { Form } from 'react-bootstrap';
import {
  CustomerConsentMethodPartUserPhone,
} from 'shared/types/customer_consent';
import * as FormikFormControls from 'src/form/FormikFormControls';
import {Formik, FormikErrors} from 'formik';
import {FormikOnChangeIsValid, FormikOnValidSubmit} from 'src/form/FormikUtils';

interface FormValues {
  subject_confirmed: boolean;
  method_subject_confirmed: boolean;
}

interface CustomerConsentUserPhoneMethodPartProps {
  userId: string;
  subject: string;
  onConsentMethodPartGiven: (methodKey: string, part: CustomerConsentMethodPartUserPhone) => unknown;
  onConsentMethodPartRevoked: (methodKey: string) => unknown;
}

export default function CustomerConsentUserPhoneMethodPart (props: CustomerConsentUserPhoneMethodPartProps) {
  const { userId, subject, onConsentMethodPartGiven, onConsentMethodPartRevoked } = props;

  const onSubmit = () => {
    onConsentMethodPartGiven('user_phone', {
      done: true,
      user_id: userId,
      subject: 'Kunden har blivit identifierad med namn, personnummer och adress',
      created_at: (new Date()).toISOString(),
    });
  };

  const initialValues = {
    subject_confirmed: false,
    method_subject_confirmed: false,
  };

  const onChangeIsValid = (isValid: boolean) => {
    if (!isValid) onConsentMethodPartRevoked('user_phone');
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate} validateOnMount>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <FormikOnValidSubmit submitForm={formProps.submitForm} isValid={formProps.isValid} />
          <FormikOnChangeIsValid onChangeIsValid={onChangeIsValid} />
          <div className="p-3 pb-2">
            <FormikFormControls.FormGroupCheck
              className="mb-1"
              label="Kunden har blivit identifierad med namn, personnummer och adress"
              type="checkbox"
              name="method_subject_confirmed"
            />
            <FormikFormControls.FormGroupCheck
              className="mb-1"
              label={`Kunden har samtyckt till "${subject}"`}
              type="checkbox"
              name="subject_confirmed"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
function validate (values: FormValues) {
  const errors: FormikErrors<FormValues> = {};
  if (!values.subject_confirmed) errors.subject_confirmed = 'Du måste intyga alternativet';
  if (!values.method_subject_confirmed) errors.method_subject_confirmed = 'Du måste intyga alternativet';
  return errors;
}
