import React, {useState} from 'react';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import {Button, Card, Collapse} from 'react-bootstrap';
import {AlertOctagon} from 'lucide-react';
import {ApplicationRow} from 'shared/types/application';
import {useMutation} from '@tanstack/react-query';
import * as IconButtons from 'src/buttons/IconButtons';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import DateTime from 'src/formatters/DateTime';
import {useEventModelEmitUpdates} from 'src/hooks/useEventModels';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import useAuth from 'src/hooks/useAuth';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import {GuardPermission} from 'src/guards/AuthGuards';
import * as ApplicationFormatters from 'src/application/ApplicationFormatters';
import * as ApplicationBlocks from 'src/application/ApplicationBlocks';

interface SanctionListMatchResponseBody {
  application: ApplicationRow;
  customer: any;
  sanction_list: ApplicationRow['sanction_list'];
}

interface ApplicationSanctionListCardProps {
  application: ApplicationRow;
  applicationId: ApplicationRow['id'];
}

export default function ApplicationSanctionListCard (props: ApplicationSanctionListCardProps) {
  const { application, applicationId } = props;
  const sanctionList = application?.sanction_list;

  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const onClickToggleCreateForm = () => {
    setShowCreateForm(showCreateForm => !showCreateForm);
  };

  const notyf = useNotyf();
  const auth = useAuth();
  const emitUpdate = useEventModelEmitUpdates<ApplicationRow>('application');

  const createMutation = useMutation<SanctionListMatchResponseBody, Error, void>({
    mutationFn: async () => {
      const result = await api.request<SanctionListMatchResponseBody>({
        errorToNotyf: notyf,
        url: `/sanction_list/match/${applicationId}`,
        method: 'POST',
        data: {},
      });

      notyf.success({type: 'success', message: 'Huvudsökande matchades mot sanktionslistan'});
      emitUpdate(result.application);
      setShowCreateForm(false);

      return result;
    },
  });

  const onClickExecute = async () => {
    return await createMutation.mutateAsync();
  };

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Sanktionslista"
          Icon={<AlertOctagon size={18} />}
        >
          <GuardPermission permission="sanction_list_match_application">
            <IconButtons.CreateButton onClick={onClickToggleCreateForm} />
          </GuardPermission>
        </CardTitleIcon>
      </Card.Header>
      <GuardPermission permission="sanction_list_match_application">
        <Collapse in={showCreateForm}>
          <div>
            <div className="border-bottom p-3 d-flex justify-content-center">
              <ButtonPromiseSpinner
                onClick={onClickExecute}
                variant="success"
                disabled={createMutation.isPending || (sanctionList ? !auth.permission('admin') : false)}
              >
                Utför kontroll på huvudsökande{sanctionList ? <span> igen</span> : ''}
              </ButtonPromiseSpinner>
            </div>
          </div>
        </Collapse>
      </GuardPermission>
      <Card.Body className="p-2 pb-0">
        {sanctionList ? (
          <SanctionListResultCard sanctionList={sanctionList} className="mb-2" />
        ) : (
          <p className="mb-2 text-center text-muted">Ansökan har ej kontrollerats mot sanktionslistan.</p>
        )}
      </Card.Body>
    </Card>
  );
}

interface SanctionListResultCardProps {
  sanctionList: NonNullable<ApplicationRow['sanction_list']>;
  className?: string;
}

function SanctionListResultCard (props: SanctionListResultCardProps) {
  const { className, sanctionList } = props;

  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);
  const onClickShowMoreInfo = () => {
    setShowMoreInfo(showMoreInfo => !showMoreInfo);
  };

  return (
    <Card className={className}>
      <Card.Header className="p-2 border-bottom-0">
        <div className="d-flex gap-2 align-items-center flex-wrap justify-content-between">
          <div className="d-flex gap-2 flex-wrap flex-grow-1">
            <ApplicationFormatters.SanctionListStatusLabel
              value={sanctionList}
              style={{fontSize: '80%', minWidth: '40%'}}
              short
            />
            <DateTime className="small" value={sanctionList.date} format="YYYY-MM-DD" />
          </div>
          <div className="d-flex gap-1 flex-wrap">
            <Button className="py-0" variant="outline-info" size="sm" onClick={onClickShowMoreInfo}>
              {showMoreInfo ? <span>Dölj</span> : <span>Mer</span>}
            </Button>
            <InspectObjectModalButton object={sanctionList} size="sm" className="py-0" />
          </div>
        </div>
      </Card.Header>
      <Collapse in={showMoreInfo}>
        <div>
          <Card.Body className="border-top p-0">
            <ApplicationBlocks.SanctionListTable sanctionList={sanctionList} className="no-border-bottom" />
          </Card.Body>
        </div>
      </Collapse>
    </Card>
  );
}
