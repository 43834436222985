import React from 'react';
import { Helmet } from 'react-helmet-async';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import ErrorAlert from 'src/alerts/ErrorAlert';
import OverlaySpinner from 'src/spinners/OverlaySpinner';
import { useQuery } from '@tanstack/react-query';
import * as api from 'src/api';
import StateTable from 'src/tables/StateTable';
import { TableBatchDefinition } from 'src/tables/types';
import { TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';

interface TablePageProps {
  columnDefinitions: TableColumnDefinition[];
  filterDefinitions: TableFilterDefinition[];
  title: string;
  pageTitle: string;
  extraButtons?: React.ReactNode;
  routeUrl: string;
  baseQueryUrl: string;
  baseQueryKey: string;
  queryResultRowsKey?: string;
  batchDefinition?: TableBatchDefinition;
  hasExport?: boolean;
}

const TablePage: React.FC<TablePageProps> = React.memo(function TablePage (props: TablePageProps) {
  const {
    columnDefinitions,
    filterDefinitions,
    pageTitle,
    extraButtons,
    title,
    routeUrl,
    baseQueryUrl,
    baseQueryKey,
    queryResultRowsKey,
    batchDefinition,
    hasExport = true,
  } = props;

  const stateQuery = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    queryKey: [`${baseQueryKey}TableStateFetch`],
    queryFn: () => {
      return api.request({
        url: baseQueryUrl,
        params: {getState: true},
      });
    },
  });

  const defaultState = {...(stateQuery?.data?.defaultSearch || {}), skipCount: true};
  const initialState = {...(stateQuery?.data?.initialSearch || {}), skipCount: true};

  return (
    <Main flush className="position-relative">
      <Navbar />
      <Helmet title={pageTitle} />
      <ErrorAlert error={stateQuery.error} className="m-3" />
      <OverlaySpinner isLoading={stateQuery.isLoading} />
      {stateQuery.isSuccess && (
        <StateTable
          title={title}
          routeUrl={routeUrl}
          queryUrl={baseQueryUrl}
          exportUrl={hasExport ? `/api${baseQueryUrl}/csv` : undefined}
          extraButtons={extraButtons}
          queryKey={baseQueryKey}
          queryResultRowsKey={queryResultRowsKey}
          columnDefinitions={columnDefinitions}
          filterDefinitions={filterDefinitions}
          defaultState={defaultState}
          initialSavedStates={stateQuery.data.initialSavedSearches}
          initialState={initialState}
          batchDefinition={batchDefinition}
        />
      )}
    </Main>
  );
});
export default TablePage;
