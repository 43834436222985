import React from 'react';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import {
  Container,
  Row,
  Col,
  ColProps,
} from 'react-bootstrap';
import { GuardFunction, GuardPermission } from 'src/guards/AuthGuards';
import ErroredApplicationsCard from 'src/cards/ErroredApplicationsCard';
import LatestComplaintsCard from 'src/cards/LatestComplaintsCard';
import FavoritesCard from 'src/cards/FavoritesCard';
import AccountNotesCard from 'src/cards/AccountNotesCard';
import AccountLogCard from 'src/cards/AccountLogCard';
import FilesCard from 'src/cards/FilesCard';
import DashboardStatusCounterCard from 'src/cards/DashboardStatusCounterCard';
import {StatisticsToplistGroup} from 'src/statistics/StatisticsToplistCard';

const colProps: ColProps = {
  xl: 6,
  xxl: 4,
};

export default function DashboardPage () {
  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Översikt" />
        <Container fluid className="p-0">
          <Header />
          <GuardPermission permission="dashboard_status">
            <DashboardStatusCounterCard />
          </GuardPermission>
          <Row>
            <GuardFunction fn="errors">
              <Col {...colProps}>
                <ErroredApplicationsCard />
              </Col>
            </GuardFunction>
            <GuardPermission permission="account_favorite">
              <Col {...colProps}>
                <FavoritesCard />
              </Col>
            </GuardPermission>
            <GuardPermission permission="account_log">
              <Col {...colProps} className="d-flex">
                <AccountLogCard />
              </Col>
            </GuardPermission>
            <GuardFunction fn="klagomalsansvarig">
              <Col {...colProps}>
                <LatestComplaintsCard />
              </Col>
            </GuardFunction>
            <GuardPermission permission="dashboard_files">
              <Col {...colProps} className="d-flex">
                <FilesCard />
              </Col>
            </GuardPermission>
            <GuardPermission permission="account_notes">
              <Col {...colProps} className="d-flex">
                <AccountNotesCard />
              </Col>
            </GuardPermission>
            <GuardPermission permission={['statistic_toplist_group', 'asdf']}>
              <Col {...colProps}>
                <StatisticsToplistGroup
                  tableKey="sum_disbursed"
                  title="Utbetald summa"
                />
              </Col>
              <Col {...colProps}>
                <StatisticsToplistGroup
                  tableKey="sum_ever_accepted"
                  title="Skuldebrevsumma ute"
                />
              </Col>
              <Col {...colProps}>
                <StatisticsToplistGroup
                  tableKey="tot"
                  title="Antal ansökningar"
                />
              </Col>
            </GuardPermission>
          </Row>
        </Container>
      </Content>
    </Main>
  );
}

function Header () {
  const { user } = useAuth();
  return (
    <Row className="mb-2 mb-xl-3">
      <Col xs="auto" className="d-none d-sm-block">
        <h3>Välkommen, {user?.name}!</h3>
      </Col>
    </Row>
  );
}
