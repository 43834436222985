import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { WorkTriggerTemplateRow } from 'shared/types/work_trigger_template';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as WorkTriggerTemplateFormFields from 'src/workTriggerTemplate/WorkTriggerTemplateFormFields';

export interface WorkTriggerTemplateCreateFormValues extends Pick<WorkTriggerTemplateRow, 'task' | 'description' | 'object_type'> {
}

interface WorkTriggerTemplateCreateFormProps {
  className?: string;
  initialValues: WorkTriggerTemplateCreateFormValues;
  onSubmit: (values: WorkTriggerTemplateCreateFormValues, helpers: FormikHelpers<WorkTriggerTemplateCreateFormValues>) => Promise<any>;
  submitError?: Error | null;
  disabled?: boolean;
}

export default function WorkTriggerTemplateCreateForm (props: WorkTriggerTemplateCreateFormProps) {
  const {
    initialValues,
    submitError,
    onSubmit,
    disabled,
    className,
  } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <WorkTriggerTemplateFormFields.Description disabled={disabled} />
            <WorkTriggerTemplateFormFields.Task disabled={disabled} />
            <WorkTriggerTemplateFormFields.ObjectType disabled={disabled} />
          </div>
          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
