import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import { LetterTemplateRow } from 'shared/types/letter_template';

interface NameProps {
  value: Pick<LetterTemplateRow, 'id' | 'name'>;
}

export function Name (props: NameProps) {
  const { value:letterTemplate } = props;
  if (!isObjectLike(letterTemplate)) return null;
  return <>{letterTemplate.name}</>;
}

