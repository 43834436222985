import {FormikProps, FormikValues, useFormikContext} from 'formik';
import {useEffect} from 'react';

interface FormikOnChangeIsValidProps {
  onChangeIsValid: (isValid: boolean, values: FormikValues) => unknown;
}

export function FormikOnChangeIsValid (props: FormikOnChangeIsValidProps) {
  const { onChangeIsValid } = props;

  const formProps = useFormikContext();

  useEffect(() => {
    onChangeIsValid(formProps.isValid, formProps.values as any);
  }, [formProps.isValid, onChangeIsValid, formProps.values]);

  return null;
}

interface FormikOnValidSubmitProps {
  submitForm: FormikProps<any>['submitForm'];
  isValid: FormikProps<any>['isValid'];
}

export function FormikOnValidSubmit (props: FormikOnValidSubmitProps) {
  const { submitForm, isValid } = props;

  useEffect(() => {
    if (isValid) submitForm();
  }, [submitForm, isValid]);

  return null;
}
