import React, {useState} from 'react';
import {keepPreviousData, useQuery} from '@tanstack/react-query';
import * as IconButtons from 'src/buttons/IconButtons';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { Mail, Trash } from 'lucide-react';
import { LetterRow } from 'shared/types/letter';
import {Card} from 'react-bootstrap';
import TemplateQuickCreateForm from 'src/template/TemplateQuickCreateForm';
import {GuardPermission} from 'src/guards/AuthGuards';
import {PermissionDefinition} from 'src/contexts/AuthContext';
import { TableStateOrder  } from 'src/tables/types';
import { TableContextProvider } from 'src/tables/TableContext';
import CardBodyTable from 'src/tables/CardBodyTable';
import * as TableCells from 'src/tables/TableCells';
import * as TableCellActions from 'src/tables/TableCellActions';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import {useTableUpdateRowMutation} from 'src/hooks/useTableUtils';
import { useEventModelSubscribeToQuery } from 'src/hooks/useEventModels';
import letterFilter from 'shared/filter/letter';
import classNames from 'classnames';

interface LetterContactControlCardProps {
  className?: string;
  createPermission?: PermissionDefinition;
  searchParams: Record<string, any>;
  searchTemplateParams: Record<string, any>;
  objectType: string;
  objectId: string;
}

export default function LetterContactControlCard (props: LetterContactControlCardProps) {
  const { className, searchParams, searchTemplateParams, createPermission, objectId, objectType } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const queryKey = ['/letter/search', {params: {...searchParams, order}}];
  const query = useQuery<LetterRow[], Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  useEventModelSubscribeToQuery({queryKey: [queryKey[0]]}, 'letter');

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
    onSuccess: () => {
      query.refetch();
    },
  });

  const reload = () => {
    query.refetch();
  };

  const rows = query.data ?? [];

  return (
    <Card className={classNames('flex-fill w-100', className)}>
      <Card.Header className="border-bottom-0">
        <CardTitleIcon
          title="Brev"
          Icon={<Mail size={18} />}
          spinning={query.isRefetching}
        >
          <IconButtons.RefreshObjectButton
            disabled={query.isRefetching}
            onClick={reload}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
        <CardBodyTable
          striped
          order={order}
          columnDefinitions={columnDefs}
          setOrder={setOrder}
          rows={rows}
          isFetched={query.isFetched}
        />
      </TableContextProvider>
      {createPermission && (
        <GuardPermission permission={createPermission}>
          <Card.Footer className="position-relative border-top-0">
            <TemplateQuickCreateForm
              objectType={objectType}
              objectId={objectId}
              category="letter"
              searchParams={searchTemplateParams}
              onCreated={reload}
            />
          </Card.Footer>
        </GuardPermission>
      )}
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.letterId({
    id: 'id',
    title: 'Brev',
    cellProps: {rowAsObject: true},
  }),
  commonColumnDefs.basic({
    id: 'status',
    title: 'Status',
    Cell: TableCells.LetterStatusTableCell,
  }),
  commonColumnDefs.basic({
    id: 'name',
    title: 'Mottagare',
    cellValue: row => letterFilter.indexField(row, 'name'),
  }),
  commonColumnDefs.basic({
    id: 'pdf',
    title: 'PDF',
    Cell: props => {
      if (!props.row.pdf) return <>-</>;
      return (
        <a
          className="px-1 py-0 d-inline-flex align-items-center btn btn-sm btn-outline-info"
          rel="noreferrer"
          href={`/api/letter/${props.row.id}/pdf`}
          target="_blank"
        >
          Öppna
        </a>
      );
    },
  }),
  commonColumnDefs.datetime({
    id: 'status_at',
    title: 'Statustid',
    sortable: true,
    cellProps: {emptyValue: '-'},
  }),
  commonColumnDefs.actions({}, [
    props => (
      <GuardPermission permission="letter_execute">
        {props.row.status !== 'sent' && (
          <TableCellActions.ExecuteButton
            {...props}
            title="Rendera brevet"
            mutateVars={{
              rowId: String(props.row.id),
              url: `/letter/${props.row.id}/render`,
              method: 'POST',
              onSuccessNotyf: {type: 'default', message: 'Brevet har köats för rendering'},
            }}
          >
            Rendera
          </TableCellActions.ExecuteButton>
        )}
      </GuardPermission>
    ),
    props => (
      <GuardPermission permission="letter_execute">
        {props.row.status !== 'sent' && props.row.pdf && (
          <TableCellActions.ExecuteButton
            {...props}
            title="Skicka brevet"
            mutateVars={{
              rowId: String(props.row.id),
              url: `/letter/${props.row.id}/send`,
              method: 'POST',
              onSuccessNotyf: {type: 'default', message: 'Brevet har köats för skickning'},
            }}
          >
            Skicka
          </TableCellActions.ExecuteButton>
        )}
      </GuardPermission>
    ),
    props => (
      <GuardPermission permission="letter_delete">
        <TableCellActions.ExecuteModalConfirmButton
          {...props}
          title="Radera brevet"
          variant="outline-danger"
          modalProps={{message: 'Är du säker på att du vill radera brevet?'}}
          mutateVars={{
            rowId: String(props.row.id),
            url: `/letter/${props.row.id}`,
            deletion: true,
            method: 'DELETE',
            onSuccessNotyf: {type: 'warning', message: 'Brevet har raderats'},
          }}
        >
          <span><Trash size={14} /></span>
        </TableCellActions.ExecuteModalConfirmButton>
      </GuardPermission>
    ),
    TableCellActions.InspectRow,
  ]),
];
