import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/letterTemplate/LetterTemplateFormatters';
import { LetterTemplateRow } from 'shared/types/letter_template';
import LetterTemplateModalBody from 'src/letterTemplate/LetterTemplateModalBody';

interface LetterTemplateAnchorProps {
  letterTemplate: Pick<LetterTemplateRow, 'id' | 'name'>;
}

export default function LetterTemplateAnchor (props: LetterTemplateAnchorProps) {
  const { letterTemplate, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={letterTemplate} />}
      name="Redigera Brevmallen"
      subTitle={letterTemplate.id}
      size="xl"
      backdrop="static"
    >
      <LetterTemplateModalBody id={letterTemplate.id} />
    </ModalAnchor>
  );
}

export function LetterTemplatePlainAnchor (props: LetterTemplateAnchorProps) {
  const { letterTemplate, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/letter_template/${letterTemplate.id}`}
      name="Brevmallen"
    >
      <Name value={letterTemplate} />
    </Anchor>
  );
}
