import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import SimpleModalOpeningButton from 'src/buttons/SimpleModalOpeningButton';
import { ApplicationRow } from 'shared/types/application';
import * as api from 'src/api';
import * as ApplicationFormFields from 'src/application/ApplicationFormFields';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {useMutation} from '@tanstack/react-query';
import useModalStateContext from 'src/hooks/useModalStateContext';
import useNotyf from 'src/hooks/useNotyf';

interface FormValues {
  closed_reason: ApplicationRow['closed_reason'];
}

interface ApplicationCloseModalButtonProps {
  application: ApplicationRow;
}

export default function ApplicationCloseModalButton (props: ApplicationCloseModalButtonProps) {
  const { application } = props;

  return (
    <SimpleModalOpeningButton
      modalBodyClassName="pt-0 pb-3"
      label="Stäng ansökan"
      variant="outline-secondary"
      modalTitle="Stäng ansökan"
      disabled={application.status === 'closed'}
    >
      <ModalBodyForm application={application}/>
    </SimpleModalOpeningButton>
  );
}

interface ModalBodyFormProps {
  application: ApplicationRow;
}

function ModalBodyForm (props: ModalBodyFormProps) {
  const { application } = props;

  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const initialValues: FormValues = {closed_reason: ''};

  const mutation = useMutation<ApplicationRow, Error, FormValues>({
    mutationFn: async data => {
      const result = await api.request({
        url: `/application/${application.id}/close`,
        method: 'POST',
        data,
      });
      const message = `Ansökan med ID "${application.id}" har blivit stängd`;
      notyf.success({type: 'default', message});
      modalStateContext.onHide();
      return result;
    },
  });

  const onSubmit = async (values: FormValues) => {
    await mutation.mutateAsync(values);
    modalStateContext.onHide();
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <ApplicationFormFields.ClosedReason required />
          <div className="d-flex mt-3 gap-2 align-items-center justify-content-end">
            <Button onClick={() => modalStateContext.onHide()} variant="outline-secondary">
              Avbryt
            </Button>
            <ButtonSpinner
              variant="primary"
              type="submit"
              disabled={mutation.isPending || !formProps.isValid || formProps.isSubmitting}
              isLoading={formProps.isSubmitting}
            >
              Utför
            </ButtonSpinner>
          </div>
          <ErrorAlert error={mutation.error} className="mt-3 mb-0" />
        </Form>
      )}
    </Formik>
  );
}
