import React from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { UserRow } from 'shared/types/user';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import * as UserFormFields from 'src/user/UserFormFields';
import * as formUtils from 'src/utils/form';
import {UserSwitchToButton} from 'src/user/UserSwitchButtons';

export interface UserEditFormValues extends Omit<UserRow, 'ip_whitelist' | 'default_app'> {
  ip_whitelist: string;
  default_app: string;
}

interface UserEditFormProps {
  user?: UserRow;
  initialValues: UserEditFormValues;
  onSubmit: (values: UserEditFormValues, helpers: FormikHelpers<UserEditFormValues>) => Promise<any>;
  submitError?: Error | null;
}

function UserEditForm (props: UserEditFormProps) {
  const { user, initialValues, submitError, onSubmit } = props;
  const colProps = {md: 6, lg: 4};
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="pt-2">
            <Row>
              <Col {...colProps}>
                <UserFormFields.Id disabled />
              </Col>

              <Col {...colProps}>
                <UserFormFields.Name />
              </Col>

              <Col {...colProps}>
                <UserFormFields.Role />
              </Col>
            </Row>

            {formProps.values.role === 'bank' && (
              <Row>
                <Col {...colProps}>
                  <UserFormFields.BankId required={formProps.values.role === 'bank'} />
                </Col>
              </Row>
            )}

            <hr className="mt-4 mb-1" />

            <Row>
              <Col>
                <UserFormFields.IsActive />
              </Col>
            </Row>

            <hr className="mt-3 mb-1" />

            <Row>
              <Col {...colProps}>
                <UserFormFields.Email
                  required={formProps.values.login_method === 'password_email_totp'}
                />
              </Col>

              <Col {...colProps}>
                <UserFormFields.TelephoneNumber />
              </Col>
            </Row>

            <hr className="mt-4 mb-1" />

            <Row>
              <Col {...colProps}>
                <UserFormFields.LoginMethod />
              </Col>
            </Row>

            <Row>
              <Col {...colProps}>
                <UserFormFields.Password passwordStrength={initialValues.password_strength} />
              </Col>
              <Col {...colProps}>
                <UserFormFields.LoginMethodBankIdPersonalNumber
                  required={formProps.values.login_method === 'bankid'}
                  disabled={formProps.values.login_method !== 'bankid'}
                />
              </Col>
              <Col {...colProps}>
                <UserFormFields.LoginMethodTotpSecret
                  userId={formProps.values.id}
                  disabled={formProps.values.login_method !== 'password_app_totp'}
                />
              </Col>
            </Row>

            <Row>
              <Col {...colProps}>
                <UserFormFields.IpWhitelist />
              </Col>
              <Col {...colProps}>
                <UserFormFields.DefaultApp />
              </Col>
            </Row>

            <hr className="mt-4 mb-1" />

            <h4 className="mt-3">Specialfunktioner</h4>
            <Row>
              <Col {...colProps}>
                <UserFormFields.FunctionCheck
                  label="Centralt funktionsansvarig"
                  value="centralt_funktionsansvarig"
                />
              </Col>
              <Col {...colProps}>
                <UserFormFields.FunctionCheck
                  label="Klagomålsansvarig"
                  value="klagomalsansvarig"
                />
                <UserFormFields.FunctionCheck
                  label="Felande objekt"
                  value="errors"
                />
              </Col>
              <Col {...colProps}>
                <UserFormFields.FunctionCheck
                  label="Åtkomst till nya CRM"
                  value="app"
                />
                <UserFormFields.FunctionCheck
                  label="Åtkomst till gamla CRM"
                  value="crm"
                />
              </Col>
            </Row>

          </Card.Body>
          <Card.Footer className="border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap">
              <InspectObjectModalButton object={formProps} />
              {user && user.role !== 'administrator' && (
                <UserSwitchToButton variant="outline-primary" userId={user.id} />
              )}
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}

export default React.memo(UserEditForm);

export function userToFormValues (user: UserRow): UserEditFormValues {
  const {
    ip_whitelist,
    default_app,
  } = user;
  return {
    ...formUtils.rowToFormValues(user) as UserEditFormValues,
    login_method_totp_secret: '', // should always be set empty
    password: '', // should always be set empty
    ip_whitelist: (ip_whitelist || []).join(','),
    default_app: default_app || '',
  };
}

export function formValuesToUpdate (initialValues: UserEditFormValues, values: UserEditFormValues): Partial<UserRow> {
  const changes = formUtils.changes(initialValues, values);
  const { ip_whitelist, default_app, ...restOfChanges } = changes;
  const result: Partial<UserRow> = {...restOfChanges};
  if (ip_whitelist) result.ip_whitelist = ip_whitelist.split(',').filter(v => v);
  if (values.password) result.password = values.password;
  if (values.login_method_totp_secret) result.login_method_totp_secret = values.login_method_totp_secret;
  if (typeof default_app !== 'undefined') result.default_app = default_app || null as any;
  return result;
}

