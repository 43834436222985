import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import constants from 'shared/constants';
import * as templateFilters from 'shared/filter/template';
import {useField} from 'formik';

export function Category (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Tillhörande objekt"
      required
      {...props}
      name="category"
    >
      {constants.template.sms.categories.map((value: string) => (
        <option key={value} value={value}>{templateFilters.category(value)}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function Body (props: Partial<FormikFormControls.FormGroupControlProps>) {
  const [field] = useField('body');
  return (
    <FormikFormControls.FormGroupControl
      label="Meddelandetext"
      as="textarea"
      name="body"
      minLength={0}
      maxLength={10000}
      rows={Math.min(20, Math.max(5, field.value.split(/\n/g).length + 1))}
      {...props}
    />
  );
}
