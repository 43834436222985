import React from 'react';
import * as JaycomInsuranceFormFields from 'src/jaycomInsurance/JaycomInsuranceFormFields';
import { CustomerJaycomInsuranceRow, CustomerJaycomInsuranceBankIdSignData } from 'shared/types/customer_jaycom_insurance';
import {GuardPermission} from 'src/guards/AuthGuards';
import FormikSubmitButton from 'src/buttons/FormikSubmitButton';
import {Form, Formik} from 'formik';
import * as api from 'src/api';
import { Card } from 'react-bootstrap';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import useNotyf from 'src/hooks/useNotyf';
import * as formUtils from 'src/utils/form';
import {pick} from 'lodash';
import CustomerConsentModalButton from 'src/customerConsent/CustomerConsentModalButton';
import CustomerConsentCreateOnTemplateForm from 'src/customerConsent/CustomerConsentCreateOnTemplateForm';
import {Edit3, Settings} from 'lucide-react';
import {CardTitleIcon} from 'src/cards/CardHelpers';

interface JaycomInsuranceControlPanelCardProps {
  insurance: CustomerJaycomInsuranceRow;
  onReload: () => any;
}

type FormValues = Pick<CustomerJaycomInsuranceRow, 'status'>;

export default function JaycomInsuranceControlPanelCard (props: JaycomInsuranceControlPanelCardProps) {
  const { insurance, onReload = () => {} } = props;

  const notyf = useNotyf();
  const initialValues = pick(insurance, 'status');
  const queryClient = useQueryClient();

  const updateMutation = useMutation<CustomerJaycomInsuranceRow, Error, Partial<CustomerJaycomInsuranceRow>>({
    mutationFn: vars => api.request({
      url: `/customer_jaycom_insurance/${insurance.id}`,
      method: 'PATCH',
      data: vars,
    }),
    onSuccess: updatedInsurance => {
      queryClient.setQueryData([`/customer_jaycom_insurance/${updatedInsurance.id}`], updatedInsurance);
      notyf.success({type: 'default', message: 'Försäkringen uppdaterades'});
    },
  });

  const executeMutation = useMutation<CustomerJaycomInsuranceRow, Error>({
    mutationFn: async data => {
      const action = insurance.jaycom_customer_id ? 'submit' : 'syncCustomerAndSubmit';
      const result = await api.request({
        url: `/customer_jaycom_insurance/${insurance.id}/${action}`,
        method: 'POST',
        data,
      });
      await onReload();
      return result;
    },
  });

  const signDataQuery = useQuery<CustomerJaycomInsuranceBankIdSignData>({
    queryKey: [`/customer_jaycom_insurance/${insurance.id}/customerBankIdSignData`],
  });

  const onConsentCreated = async () => {
    await executeMutation.mutateAsync();
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<CustomerJaycomInsuranceRow, FormValues, Partial<CustomerJaycomInsuranceRow>>({
    queryDataToFormValues: insuranceToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Kontrollpanel"
          Icon={<Settings size={18} />}
        >
        </CardTitleIcon>
      </Card.Header>
      <Formik initialValues={initialValues} onSubmit={formCycleHelpers.onSubmit} enableReinitialize>
        {formProps => (
          <Form>
            <Card.Body className="py-0 mb-3">
              <JaycomInsuranceFormFields.Status />
            </Card.Body>
            <Card.Footer className="border-top d-flex align-items-center gap-2 flex-wrap">
              <GuardPermission permission="customer_jaycom_insurance_execute">
                {!insurance.jaycom_insurance_id && !['active', 'queued', 'cancelled'].includes(insurance.status) && (
                  <CustomerConsentModalButton
                    label={<><Edit3 size={14} />{' '}Inhämta samtycke och aktivera försäkring</>}
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    variant="outline-secondary"
                  >
                    <CustomerConsentCreateOnTemplateForm
                      subject="Teckna ny försäkring"
                      allowedMethods={['customer_bankid_sign', 'user_phone']}
                      onConsentCreated={onConsentCreated}
                      customerConsentTemplateUrl={`/customer_consent/template/jaycom_insurance/${insurance.id}`}
                      customerId={insurance.customer_id}
                      submitButtonLabel="Registrera samtycke och aktivera försäkring"
                      methodComponentProps={{customer_bankid_sign: signDataQuery.data}}
                    />
                  </CustomerConsentModalButton>
                )}
              </GuardPermission>
              <FormikSubmitButton />
            </Card.Footer>
          </Form>
        )}
      </Formik>
    </Card>
  );
}

function insuranceToFormValues (insurance: CustomerJaycomInsuranceRow): FormValues {
  return pick(insurance, 'status');
}

function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<CustomerJaycomInsuranceRow> {
  const changes = formUtils.changes(initialValues, values);
  const update: Partial<CustomerJaycomInsuranceRow> = {};
  if (typeof changes.status !== 'undefined') update.status = changes.status;
  return update;
}
