import React from 'react';
import {useQuery} from '@tanstack/react-query';
import { Card } from 'react-bootstrap';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { CustomerNoteRowWithRelations } from 'shared/types/customer_note';
import {FileText} from 'lucide-react';
import {GuardPermission} from 'src/guards/AuthGuards';
import CustomerNoteItem from 'src/customer/CustomerNoteItem';
import CustomerNoteForm from 'src/customer/CustomerNoteForm';

interface CustomerNoteListCardProps {
  customerId: string;
  className?: string;
  extendedTitle?: boolean;
}

export default function CustomerNoteListCard (props: CustomerNoteListCardProps) {
  const { className, customerId, extendedTitle } = props;

  const query = useQuery<CustomerNoteRowWithRelations[]>({
    queryKey: [`/customer/${customerId}/notes`],
  });

  const onReload = () => {
    query.refetch();
  };

  const notes = query.data ?? [];

  return (
    <Card className={className}>
      <Card.Header>
        <CardTitleIcon
          title={extendedTitle ? 'Notat (kundkort)' : 'Notat'}
          Icon={<FileText size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={onReload}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <GuardPermission permission="customer:notes:create">
        <Card.Body className="border-bottom p-2">
          <CustomerNoteForm
            customerId={customerId}
            onReload={onReload}
          />
        </Card.Body>
      </GuardPermission>
      <Card.Body className="p-2 pb-0">
        {!notes.length && (
          <p>Det finns inga notat här.</p>
        )}
        {notes.map(note => (
          <CustomerNoteItem
            className="mb-2"
            customerId={customerId}
            onReload={onReload}
            key={note.id}
            note={note}
          />
        ))}
      </Card.Body>
    </Card>
  );
}
