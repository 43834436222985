import React, {useMemo} from 'react';
import * as api from 'src/api';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {ApplicationRow} from 'shared/types/application';
import {Form} from 'react-bootstrap';
import * as ApplicationFormHelpers from 'src/application/ApplicationFormHelpers';
import {Formik} from 'formik';
import {FormikOnUpdateProvider} from 'src/form/FormikOnUpdateContext';
import useNotyf from 'src/hooks/useNotyf';
import {foldObjToSets} from 'shared/format';
import * as formUtils from 'src/utils/form';
import {cloneDeep} from 'lodash';
import * as yup from 'yup';
import { ApplicationRowForm } from 'shared/types/application';
import ApplicationValidateAndSubmitCard from 'src/application/ApplicationValidateAndSubmitCard';

interface FormValues extends Partial<ApplicationRow['form']> {
  'form.loan.amount.now'?: null | number;
  'form.loan.amount.new'?: null | number;
  'form.loan.repayment_years'?: null | number;
  'form.loan.purpose'?: null | string;
  'form.main_applicant.ssn'?: null | string;
  'form.main_applicant.first_name'?: null | string;
  'form.main_applicant.last_name'?: null | string;
  'form.main_applicant.telephone_mobile'?: null | string;
  'form.main_applicant.email'?: null | string;
  'form.main_applicant.status'?: null | string;
  'form.main_applicant.address.since'?: null | string;
  'form.main_applicant.address.type'?: null | string;
  'form.main_applicant.address.street'?: null | string;
  'form.main_applicant.address.co'?: null | string;
  'form.main_applicant.address.postcode'?: null | string;
  'form.main_applicant.address.city'?: null | string;
  'form.main_applicant.habitation_cost'?: null | number;
  'form.main_applicant.children'?: null | number;
  'form.main_applicant.employment_type'?: null | string;
  'form.main_applicant.income'?: null | number;
  'form.main_applicant.employer_since'?: null | string;
  'form.main_applicant.employer_until'?: null | string;
  'form.main_applicant.employer_name'?: null | string;
  'form.main_applicant.employer_contact'?: null | string;
  'form.include_co_applicant'?: null | boolean;
  'form.co_applicant.ssn'?: null | string;
  'form.co_applicant.first_name'?: null | string;
  'form.co_applicant.last_name'?: null | string;
  'form.co_applicant.status'?: null | string;
  'form.co_applicant.telephone_mobile'?: null | string;
  'form.co_applicant.address.since'?: null | string;
  'form.co_applicant.address.type'?: null | string;
  'form.co_applicant.address.street'?: null | string;
  'form.co_applicant.address.co'?: null | string;
  'form.co_applicant.address.postcode'?: null | string;
  'form.co_applicant.address.city'?: null | string;
  'form.co_applicant.email'?: null | string;
  'form.co_applicant.employment_type'?: null | string;
  'form.co_applicant.income'?: null | number;
  'form.co_applicant.employer_since'?: null | string;
  'form.co_applicant.employer_until'?: null | string;
  'form.co_applicant.employer_name'?: null | string;
  'form.co_applicant.employer_contact'?: null | string;
  'form.co_applicant.children'?: null | number;
  'form.co_applicant.habitation_cost'?: null | number;
}

interface ApplicationFormProps {
  application: ApplicationRow;
}

export default function ApplicationForm (props: ApplicationFormProps) {
  const { application } = props;

  const notyf = useNotyf();
  const queryClient = useQueryClient();

  const updateMutation = useMutation<ApplicationRow, Error, FormValues>({
    mutationFn: async data => {
      if (!Object.keys(data).length) return application;
      const updatedApplication = await api.request({
        errorToNotyf: notyf,
        url: `/application/${application.id}`,
        method: 'PATCH',
        data,
      });
      queryClient.setQueryData([`/application/${updatedApplication.id}`], updatedApplication);
      return updatedApplication;
    },
  });

  const initialValues = useMemo(() => applicationToApplicationFormValues(application), [application]);

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<ApplicationRow, ApplicationRow['form'], FormValues>({
    queryDataToFormValues: applicationToApplicationFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  const onFieldUpdate = async (update: FormValues) => {
    if (!initialValues) return;
    const initialSet = foldObjToSets(initialValues, 'form.');
    const valueSet = foldObjToSets(update, 'form.');
    const changes = formUtils.changesInverted(initialSet, valueSet);
    await updateMutation.mutateAsync(changes);
  };

  const disabled = application.status !== 'new';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formCycleHelpers.onSubmit}
      enableReinitialize
      validateOnMount
      validationSchema={validationSchema}
    >
      {formProps => (
        <div>
          <Form>
            <FormikOnUpdateProvider onUpdate={onFieldUpdate}>
              <ApplicationFormHelpers.ApplicationForm
                embedCustomerId={application.customer_id}
                embedCustomerIdCo={application.customer_id_co}
                disabled={disabled}
              />
            </FormikOnUpdateProvider>
          </Form>
          {!disabled && (
            <ApplicationValidateAndSubmitCard
              applicationId={application.id}
              outerFormIsValid={formProps.isValid}
              initialForBanks={application.for_banks}
            />
          )}
        </div>
      )}
    </Formik>
  );
}

export function applicationToApplicationFormValues (application: ApplicationRow): FormValues {
  const { form } = application;
  return cloneDeep(form);
}

export function formValuesToUpdate (initialValues: FormValues, values: FormValues): FormValues {
  const initialSet = foldObjToSets(initialValues, 'form.');
  const valueSet = foldObjToSets(values, 'form.');
  const changes = formUtils.allChanges(initialSet, valueSet);
  return changes;
}

const validationSchema = yup.object().test({
  name: 'Personnummer',
  message: 'Huvudsökande och medsökande får inte ha samma personnummer',
  test: (form: ApplicationRowForm) => {
    if (!form.include_co_applicant) return true;
    const mainSsn = form.main_applicant.ssn;
    const coSsn = form.co_applicant?.ssn;
    return !(mainSsn && coSsn && mainSsn === coSsn);
  },
} as yup.TestConfig);
