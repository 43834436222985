import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as formValidators from 'src/form/validators';
import * as numberUtils from 'src/numbers';
import { InputGroup } from 'react-bootstrap';

export function RequestObject (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Huvudsökande"
      minLength={11}
      maxLength={11}
      customValidate={formValidators.validatePersonalNumber({format: 'short-dash'})}
      required
      {...props}
      name="request_object"
    />
  );
}


export function RequestCoObject (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Medsökande"
      minLength={11}
      maxLength={11}
      customValidate={formValidators.validatePersonalNumber({format: 'short-dash'})}
      {...props}
      name="request_coObject"
      placeholder="Ingen"
    />
  );
}

export function RequestCreditSeek (props: Partial<FormikFormControls.InputGroupControlProps>) {
  return (
    <FormikFormControls.NumberFormattedInputGroupControl
      format={numberUtils.numberfmt}
      label="Sökt belopp"
      type="number"
      step={1}
      min={0}
      after={<InputGroup.Text>kr</InputGroup.Text>}
      emptyValue={0}
      {...props}
      name="request_creditSeek"
      placeholder="Ej angivet"
    />
  );
}
