import React, {useMemo, useState} from 'react';
import * as api from 'src/api';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {Card, Form, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { ErrorAlertCardBody, CardTitleIcon } from 'src/cards/CardHelpers';
import {Formik} from 'formik';
import {FormikOnUpdateProvider} from 'src/form/FormikOnUpdateContext';
import useNotyf from 'src/hooks/useNotyf';
import {isEqual, keyBy, pick, sortBy} from 'lodash';
import { ApplicationRow } from 'shared/types/application';
import { BankRow } from 'shared/types/bank';
import * as FormikFormControls from 'src/form/FormikFormControls';
import styled from 'styled-components';
import { BankValidatorResult } from 'shared/types/bank';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import {BankAdapterValidatorResult} from 'shared/types/bank_adapter';
import classNames from 'classnames';
import BankAdapterValidationErrorsList from 'src/components/BankAdapterValidationErrorsList';
import {Send} from 'lucide-react';
import ErrorAlert from 'src/alerts/ErrorAlert';
import useAwaitConfirmActionModalProps from 'src/hooks/useAwaitConfirmActionModalProps';
import ConfirmActionModal from 'src/modals/ConfirmActionModal';

interface FormValues {
  bypassValidation: boolean;
  for_banks: ApplicationRow['for_banks'];
}

interface ApplicationValidateAndSubmitCardProps {
  applicationId: string;
  outerFormIsValid: boolean;
  initialForBanks: string[];
}

const BankCheckColumn = styled.div`
	column-count: auto;
	column-width: 8rem;
	column-gap: 10%;
`;

export default function ApplicationValidateAndSubmitCard (props: ApplicationValidateAndSubmitCardProps) {
  const { applicationId, initialForBanks = [], outerFormIsValid = false } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();

  const query = useQuery<{banks: BankRow[]}>({
    queryKey: [`/application/${applicationId}/form`],
  });

  const awaitConfirmActionModal = useAwaitConfirmActionModalProps();

  const submitMutation = useMutation<ApplicationRow | void, Error, FormValues>({
    mutationFn: async data => {
      if (data.bypassValidation) {
        const msg = 'Är du säker på att du vill åsidosätta valideringen?';
        if (!(await awaitConfirmActionModal.confirm(msg))) {
          return;
        }
      }

      const updatedApplication = await api.request({
        url: `/application/${applicationId}/submit`,
        method: 'POST',
        data,
      });
      queryClient.setQueryData([`/application/${updatedApplication.id}`], updatedApplication);

      notyf.success({type: 'success', message: 'Ansökan skickades in till de valda långivarna'});

      return updatedApplication;
    },
  });

  const updateMutation = useMutation<ApplicationRow, Error, Pick<FormValues, 'for_banks'>>({
    mutationFn: async data => {
      const updatedApplication = await api.request({
        errorToNotyf: notyf,
        url: `/application/${applicationId}`,
        method: 'PATCH',
        data,
      });
      queryClient.setQueryData([`/application/${updatedApplication.id}`], updatedApplication);
      return updatedApplication;
    },
  });

  const [validationResultByBank, setValidationResultByBank] = useState<null | Record<string, BankValidatorResult>>(null);

  const validateSubmitMutation = useMutation<BankValidatorResult[], Error, string[]>({
    mutationFn: async bankIds => {
      const result = await api.request({
        errorToNotyf: notyf,
        url: `/application/${applicationId}/validateMockBankProcessesForSubmission`,
        method: 'POST',
        data: {bank_ids: bankIds},
      });
      setValidationResultByBank(keyBy(result, 'bank_id'));
      return result;
    },
  });

  const onClickValidate = async () => {
    await validateSubmitMutation.mutateAsync(banks.map(bank => bank.id));
  };

  const onClickChooseValidBanks = async (selectedBankIds: string[]) => {
    if (!validateSubmitMutation.data) return;
    const validBankIds = validateSubmitMutation.data.filter(bank => {
      return bank.is_valid || bank.is_valid_without_co_applicant;
    }).map(bank => bank.bank_id);
    // if all valid banks are already selected, unselect everything
    const update = isEqual(sortBy(selectedBankIds), sortBy(validBankIds)) ? [] : validBankIds;
    await updateMutation.mutateAsync({for_banks: update});
  };

  const onSubmit = async (values: FormValues) => {
    await submitMutation.mutateAsync(values);
  };

  const banks = useMemo(() => {
    if (!Array.isArray(query.data?.banks)) return [];
    return sortBy(query.data.banks, 'name');
  }, [query.data]);

  const initialValues: FormValues = {
    for_banks: initialForBanks,
    bypassValidation: false,
  };

  const onFieldUpdate = async (update: FormValues) => {
    await updateMutation.mutateAsync(pick(update, 'for_banks'));
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      enableReinitialize
    >
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <FormikOnUpdateProvider onUpdate={onFieldUpdate}>
            <ConfirmActionModal {...awaitConfirmActionModal.props} />
            <Card>
              <Card.Header className="d-flex justify-content-between flex-wrap gap-2 align-items-center">
                <CardTitleIcon
                  title="Skicka in ansökan"
                  spinning={query.isLoading || query.isRefetching}
                  Icon={<Send size={18} />}
                />
              </Card.Header>
              <ErrorAlertCardBody error={query.error} />
              <ErrorAlertCardBody error={updateMutation.error} />
              <Card.Body>
                <BankCheckColumn>
                  {banks.map(bank => (
                    <Form.Group key={bank.id}>
                      <FormikFormControls.StringListCheck
                        value={bank.id}
                        fieldName="for_banks"
                        label={(
                          <BankLabel
                            bank={bank}
                            validationResult={validationResultByBank?.[bank.id]}
                          />
                        )}
                      />
                    </Form.Group>
                  ))}
                </BankCheckColumn>
              </Card.Body>
              <Card.Footer className="border-top">
                <ErrorAlert error={validateSubmitMutation.error} />
                <ErrorAlert error={submitMutation.error} />
                <div className="d-flex flex-wrap gap-2 align-items-center justify-content-end">
                  <FormikFormControls.FormGroupCheck
                    name="bypassValidation"
                    label="Åsidosätt validering"
                  />
                  <ButtonPromiseSpinner
                    onClick={onClickValidate}
                    disabled={validateSubmitMutation.isPending}
                  >
                    Validera ansökan
                  </ButtonPromiseSpinner>
                  <ButtonPromiseSpinner
                    onClick={() => onClickChooseValidBanks(formProps.values.for_banks)}
                    variant="outline-primary"
                    disabled={!validationResultByBank || validateSubmitMutation.isPending}
                  >
                    Välj giltiga långivare
                  </ButtonPromiseSpinner>
                  <ButtonSpinner
                    type="submit"
                    isLoading={formProps.isSubmitting}
                    disabled={formProps.isSubmitting || !formProps.isValid || !formProps.values.for_banks.length || validateSubmitMutation.isPending || !outerFormIsValid}
                    variant="secondary"
                  >
                    Skicka in
                  </ButtonSpinner>
                </div>
              </Card.Footer>
            </Card>
          </FormikOnUpdateProvider>
        </Form>
      )}
    </Formik>
  );
}

interface BankLabelProps {
  bank: BankRow;
  validationResult?: BankAdapterValidatorResult;
}

function BankLabel (props: BankLabelProps) {
  const { bank, validationResult:result } = props;
  const validated = Boolean(result);

  const hasTooltip = validated && !result.is_valid;

  const className = classNames({
    'text-danger': validated && !result?.is_valid && !result?.is_valid_without_co_applicant,
    'text-warning': validated && !result?.is_valid && result?.is_valid_without_co_applicant,
    'text-success': validated && result.is_valid,
  });

  if (hasTooltip) {
    return (
      <OverlayTrigger
        placement="top"
        overlay={(
          <Tooltip style={{position: 'fixed'}}>
            <BankAdapterValidationErrorsList errors={result.errors} />
          </Tooltip>
        )}
      >
        <span className={classNames(className, 'cursor-help')}>
          {bank.name}
        </span>
      </OverlayTrigger>
    );
  }

  return (
    <span className={className}>
      {bank.name}
    </span>
  );
}
