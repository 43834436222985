import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LetterTemplateRow } from 'shared/types/letter_template';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitCreates, useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';
import LetterTemplateEditForm, {LetterTemplateEditFormValues, letterTemplateToFormValues, formValuesToUpdate} from 'src/letterTemplate/LetterTemplateEditForm';
import {useNavigate} from 'react-router';

interface LetterTemplateModalBodyProps {
  id: string;
}

export default function LetterTemplateModalBody (props: LetterTemplateModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const emitCreate = useEventModelEmitCreates<LetterTemplateRow>('letterTemplate');
  const emitUpdate = useEventModelEmitUpdates<LetterTemplateRow>('letterTemplate');
  const emitDelete = useEventModelEmitDeletes<LetterTemplateRow>('letterTemplate');

  const query = useQuery<LetterTemplateRow, Error>({
    queryKey: [`/template/letter/${id}`],
    enabled: Boolean(id),
    placeholderData: keepPreviousData,
  });

  const letterTemplate = query.data;

  const updateMutation = useMutation<LetterTemplateRow, Error, Partial<LetterTemplateEditFormValues>>({
    mutationFn: async vars => {
      const letterTemplate = await api.request({
        url: `/template/letter/${id}`,
        method: 'PATCH',
        data: vars,
      });

      queryClient.setQueryData([`/template/letter/${id}`], letterTemplate);
      notyf.success({type: 'default', message: 'Brevmallen uppdaterades'});
      modalStateContext.onHide();
      emitUpdate(letterTemplate);

      return letterTemplate;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/template/letter/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'Brevmallen raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const cloneMutation = useMutation<LetterTemplateRow, Error, any>({
    mutationFn: async () => {
      // FIXME yes, the form values are not used when cloning
      // update backend to be able to clone the local vars
      const letterTemplate = await api.request({
        url: `/template/letter/${id}/clone`,
        method: 'POST',
        data: {},
      });
      notyf.success({type: 'success', message: 'Brevmallen klonades. Du redigerar nu kopian.'});
      emitCreate(letterTemplate);

      modalStateContext.onHide();
      navigate(`/letter_template/${letterTemplate.id}`);

      return letterTemplate;
    },
  });

  const onClone = async (values: LetterTemplateEditFormValues) => {
    return cloneMutation.mutateAsync(values);
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<LetterTemplateRow, LetterTemplateEditFormValues, Partial<LetterTemplateEditFormValues>>({
    queryDataToFormValues: letterTemplateToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(letterTemplateToFormValues(letterTemplate), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading || query.isRefetching} className="m-3" />
      {letterTemplate && (
        <LetterTemplateEditForm
          className="mt-0"
          initialValues={letterTemplateToFormValues(letterTemplate)}
          onSubmit={formCycleHelpers.onSubmit}
          submitError={updateMutation.error}
          cloneError={cloneMutation.error}
          onDelete={onDelete}
          onClone={onClone}
        />
      )}
    </div>
  );
}
