import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import * as insuranceFilters from 'shared/filter/customer_jaycom_insurance';
import * as JaycomInsuranceFormatters from 'src/jaycomInsurance/JaycomInsuranceFormatters';
import constants from 'shared/constants';

export function Status () {
  return (
    <FormikFormControls.FormGroupSelect
      label="Status"
      name="status"
      required
    >
      {constants.customerJaycomInsurance.statusTypes.map(status => (
        <option value={status} key={status}>{insuranceFilters.status(status)}</option>
      ))}
    </FormikFormControls.FormGroupSelect>
  );
}

interface FormInsuredAmountProps extends Partial<FormikFormControls.FormGroupSelectProps> {
  flagCreatedByUser?: boolean;
}

export function FormInsuredAmount (props: FormInsuredAmountProps) {
  const { flagCreatedByUser = false } = props;
  const allOptions: {amount: number, disabled: boolean}[] = constants.customerJaycomInsurance.insuredAmounts.map(amount => {
    return {amount, disabled: flagCreatedByUser ? false : amount < 2500};
  });
  return (
    <FormikFormControls.FormGroupSelect
      label="Försäkrat månadsbelopp"
      name="insured_amount"
      required
      explainer="Om skapad av kunden så är månadsbelop under 2500 inaktiverade"
    >
      <option value="">Ange önskad summa</option>
      {allOptions.map((option, index) => (
        <option value={option.amount} key={option.amount} disabled={option.disabled}>
          <JaycomInsuranceFormatters.InsuredAmount value={option.amount} />
        </option>
      ))}
    </FormikFormControls.FormGroupSelect>
  );
}

export function FormPaymentMethod () {
  return (
    <FormikFormControls.FormGroupSelect
      label="Betalningsmetod"
      name="payment_method"
      required
    >
      <option value="autogiro">Autogiro</option>
      <option value="invoice">Faktura</option>
    </FormikFormControls.FormGroupSelect>
  );
}

export function FormFreeMonths () {
  return (
    <FormikFormControls.FormGroupSelect
      label="Gratismånader"
      name="free_months"
      explainer="Skaparstyrt innebär 1 gratismånad om skapad av kunden"
    >
      <option value="">Skaparstyrt</option>
      <option value="0">0 gratismånader</option>
      <option value="1">1 gratismånad</option>
      <option value="2">2 gratismånader</option>
    </FormikFormControls.FormGroupSelect>
  );
}

export function FormClearingNo (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Clearingnummer"
      minLength={4}
      maxLength={4}
      name="clearing_no"
      pattern="^\d{4}$"
      required
      {...props}
    />
  );
}

export function FormAccountNo (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Kontonummer"
      minLength={1}
      maxLength={100}
      name="account_no"
      pattern="^\d+$"
      required
      {...props}
    />
  );
}
