import React from 'react';
import { Alert } from 'react-bootstrap';
import { XOctagon } from 'lucide-react';
import classNames from 'classnames';
import { ErrorType, errorToMessage } from 'src/utils/error';

interface ErrorAlertProps {
  error?: ErrorType | null;
  title?: string;
  className?: string;
}

export default function ErrorAlert (props: ErrorAlertProps) {
  const { error, title, className } = props;
  if (!error) return null;
  return (
    <Alert variant="danger" className={classNames(className, 'd-flex p-3 align-items-center text-danger gap-2')}>
      <div><XOctagon size={24} /></div>
      <div className="ms-1">
        {title && (
          <>
            <strong className="text-danger">{title}</strong>
            <br />
          </>
        )}
        {errorToMessage(error)}
      </div>
    </Alert>
  );
}

export function ErrorAlertCentered (props: ErrorAlertProps) {
  const { error, title, className } = props;
  if (!error) return null;
  return (
    <Alert variant="danger" className={classNames(className, 'p-4 flex-column align-items-center text-danger text-center gap-2')}>
      <div><XOctagon className="d-block mb-2" size={24} /></div>
      {title && (
        <>
          <strong className="text-danger text-lg mb-2">{title}</strong>
        </>
      )}
      {errorToMessage(error)}
    </Alert>
  );
}
