import React, {useRef, useState} from 'react';
import * as api from 'src/api';
import { Alert, Card } from 'react-bootstrap';
import { Image, Trash2 } from 'lucide-react';
import { CardTitleIcon, ErrorAlertCardBody  } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import {useMutation, useQuery} from '@tanstack/react-query';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import useNotyf from 'src/hooks/useNotyf';
import {Upload} from 'lucide-react';

interface UserEditPhotoCardProps {
  className?: string;
  userId: string;
  onUpdatePhoto?: () => void;
}

export default function UserEditPhotoCard (props: UserEditPhotoCardProps) {
  const { className, userId, onUpdatePhoto } = props;

  const notyf = useNotyf();

  const query = useQuery<Blob | null>({
    queryKey: [`/user/${userId}/photo`],
    queryFn: async ctx => {
      try {
        const response = await api.request({
          method: 'GET',
          signal: ctx.signal,
          url: ctx.queryKey[0] as string,
          headers: {'Accept': '*/*'},
          returnData: false,
          responseType: 'blob',
        });
        return response.data;
      } catch (err) {
        if (err.response?.status === 404) return null;
        throw err;
      }
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async () => {
      await api.request({
        errorToNotyf: notyf,
        method: 'DELETE',
        url: `/user/${userId}/photo`,
      });
    },
  });

  const onConfirmDelete = async () => {
    await deleteMutation.mutateAsync();
    notyf.success({type: 'warning', message: 'Bilden raderades'});
    await query.refetch();
    onUpdatePhoto?.();
  };

  const onUploadPhoto = async () => {
    await query.refetch();
    onUpdatePhoto?.();
  };

  const hasPhoto = Boolean(query.isSuccess && query.data);
  const imgUrlBlob = hasPhoto ? URL.createObjectURL(query.data) : null;

  return (
    <Card className={className}>
      <Card.Header>
        <CardTitleIcon
          title="Porträtt"
          Icon={<Image size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <Card.Body>
        {hasPhoto ? (
          <Card.Img
            src={imgUrlBlob}
            alt="Användarens porträtt"
            style={{maxHeight: '250px', maxWidth: '250px'}}
          />
        ) : (
          <p className="mb-0 text-muted">Användaren har inget porträtt uppladdat.</p>
        )}
        {hasPhoto && (
          <Alert variant="warning" className="p-3 mb-0 mt-3">
            <p className="mb-0">
              <strong>OBS!{' '}</strong> Om du ser en röd kant på bilden behöver du beskära den så att den är kvadratisk (samma höjd som bredd) och sen ladda upp den igen för att den ska visas rätt i systemet.
            </p>
          </Alert>
        )}
      </Card.Body>
      <Card.Footer className="border-top d-flex flex-wrap align-items-center gap-2">
        <PhotoUploadButton userId={userId} hasPhoto={hasPhoto} onUploadSuccess={onUploadPhoto} />
        {hasPhoto && (
          <ConfirmActionModalButton
            variant="outline-danger"
            message="Är du säker på att du vill radera användarens porträtt?"
            onConfirm={onConfirmDelete}
            className="d-flex gap-1 align-items-center"
          >
            <Trash2 size={16} />
            Radera bild
          </ConfirmActionModalButton>
        )}
      </Card.Footer>
    </Card>
  );
}

interface PhotoUploadButtonProps {
  userId: string;
  hasPhoto: boolean;
  onUploadSuccess?: () => void;
}

function PhotoUploadButton (props: PhotoUploadButtonProps) {
  const { userId, hasPhoto, onUploadSuccess } = props;

  const notyf = useNotyf();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onChangeFile: React.ChangeEventHandler<HTMLInputElement> = async ev => {
    ev.preventDefault();
    setIsSubmitting(true);

    const file = fileRef.current?.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append('photo', file);

    try {
      await api.request({
        errorToNotyf: notyf,
        url: `/user/${userId}/photo`,
        method: 'POST',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      });
      notyf.success({type: 'success', message: 'Bilden laddades upp'});
      onUploadSuccess?.();
    } finally {
      setIsSubmitting(false);
    }
  };

  const fileRef = useRef<HTMLInputElement | null>(null);

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (!fileRef.current) return;
    fileRef.current.click();
  };

  return (
    <div>
      <input
        className="d-none"
        ref={fileRef}
        type="file"
        name="file"
        onChange={onChangeFile}
      />
      <ButtonSpinner
        type="button"
        className="d-flex gap-1"
        disabled={isSubmitting}
        variant="outline-primary"
        isLoading={isSubmitting}
        onClick={onClick}
      >
        <Upload size={16} />
        {hasPhoto ? ' Ladda upp och ersätt' : ' Ladda upp'}
      </ButtonSpinner>
    </div>
  );
}
