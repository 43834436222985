import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import Pluralizer from 'src/formatters/Pluralizer';
import * as IconButtons from 'src/buttons/IconButtons';
import * as fileFilter from 'shared/filter/file';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import * as TableCellActions from 'src/tables/TableCellActions';
import { File, Download, Trash } from 'lucide-react';
import { Container, Card } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { FileRow } from 'shared/types/files';
import { TableColumnCellProps, TableColumnDefinition, TableFilterDefinition } from 'src/tables/Table';
import RouteParameterModal from 'src/modals/RouteParameterModal';
import { TableStateOrder, TableQueryKey, TableStateFilterMap } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { ErrorAlertCardBody } from 'src/cards/CardHelpers';
import { useTableUpdateRowMutation } from 'src/hooks/useTableUtils';
import { TableContextProvider } from 'src/tables/TableContext';
import { useEventModelSubscribeToQuery } from 'src/hooks/useEventModels';
import FileCreateModalBody from 'src/file/FileCreateModalBody';
import {FilePlainAnchor} from 'src/file/FileAnchor';
import FileModalBody from 'src/file/FileModalBody';

const defaultFilter = {
  searchTerm: '',
};

const filterDefinitions: TableFilterDefinition[] = [
  commonFilterDefs.string({
    id: 'searchTerm',
    title: 'Sök',
  }),
];

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.basic({
    id: 'id',
    Cell: props => <FilePlainAnchor file={props.row} />,
    title: 'ID',
    sortable: true,
  }),
  // commonColumnDefs.fileId({
  //   id: 'id',
  //   title: 'ID',
  //   cellProps: {rowAsObject: true},
  //   sortable: true,
  // }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
  }),
  commonColumnDefs.basic({
    id: 'ext',
    title: 'Filändelse',
  }),
  commonColumnDefs.basic({
    id: 'folder',
    title: 'Mapp',
    sortable: true,
  }),
  commonColumnDefs.datetime({
    id: 'uploaded_at',
    sortable: true,
    title: 'Uppladdad',
  }),
  commonColumnDefs.basic({
    id: 'role',
    Cell: FileRoleTableCell,
    title: 'Roll',
  }),
  commonColumnDefs.basic({
    id: 'size',
    Cell: FileSizeTableCell,
    title: 'Storlek',
  }),
  commonColumnDefs.actions({}, [
    TableCellActions.InspectRow,
    FileDownloadActionTableCell,
    props => (
      <TableCellActions.ExecuteModalConfirmButton
        {...props}
        title="Radera filen"
        variant="outline-danger"
        modalProps={{message: 'Är du säker på att du vill radera filen?'}}
        mutateVars={{
          optimistic: true,
          rowId: String(props.row.id),
          url: `/file/${props.row.id}`,
          deletion: true,
          method: 'DELETE',
          onSuccessNotyf: {type: 'warning', message: 'Filen har raderats'},
        }}
      >
        <Trash size={14} />
      </TableCellActions.ExecuteModalConfirmButton>
    ),
  ]),
];

export default function FilesTablePage () {
  const [filter, setFilter] = React.useState<TableStateFilterMap>(defaultFilter);
  const [order, setOrder] = React.useState<TableStateOrder>({id: 'asc'});

  const queryKey: TableQueryKey = React.useMemo(() => {
    return ['/file/search', {params: {...filter, order}}];
  }, [filter, order]);

  const query = useQuery<FileRow[], Error>({
    queryKey,
    placeholderData: keepPreviousData,
  });

  useEventModelSubscribeToQuery({queryKey: [queryKey[0]]}, 'file');

  const tableUpdateRowMutation = useTableUpdateRowMutation({
    queryKey,
  });

  const rows = query.data ?? [];

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Filer" />
        <Container fluid className="p-0">
          <Card className="mb-1">
            <Card.Header>
              <CardTitleIcon
                title="Filer"
                Icon={<File size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <IconButtons.SimpleCreateModalButton modalTitle="Ny fil">
                  <FileCreateModalBody />
                </IconButtons.SimpleCreateModalButton>
                <IconButtons.RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1">
                <small>
                  <Pluralizer
                    count={query.data?.length ?? 0}
                    zero="Inga filer"
                    one="Visar 1 fil"
                    otherwise="Visar %% filer"
                  />
                </small>
              </Card.Subtitle>
            </Card.Header>
            <RouteParameterModal
              parameter="fileId"
              title="Redigera Fil"
              backdrop="static"
              subTitle={fileId => fileId}
              hideUrl="/files"
            >
              {fileId => (
                <FileModalBody id={fileId} />
              )}
            </RouteParameterModal>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <TableContextProvider updateRowMutation={tableUpdateRowMutation}>
              <CardBodyTable
                filter={filter}
                order={order}
                filterDefinitions={filterDefinitions}
                columnDefinitions={columnDefinitions}
                setFilter={setFilter}
                setOrder={setOrder}
                rows={rows}
                isFetched={query.isFetched}
                striped
              />
            </TableContextProvider>
          </Card>
        </Container>
      </Content>
    </Main>
  );
}

function FileRoleTableCell (props: TableColumnCellProps) {
  const { row } = props;
  return fileFilter.role(row.role);
}

function FileSizeTableCell (props: TableColumnCellProps) {
  const { row } = props;
  if (typeof row.size !== 'number') return '';
  const size = fileFilter.size(row.size, 0);
  return (
    <>{size} KB</>
  );
}

function FileDownloadActionTableCell (props: TableColumnCellProps) {
  const { row } = props;
  if (!row.uploaded_at) return null;
  const url = `/api/file/${row.id}/open`;
  return (
    <a href={url} className="btn btn-outline-primary btn-sm px-1 py-0" target="_blank" rel="noopener noreferrer">
      <Download size={16} />
    </a>
  );
}
