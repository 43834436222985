import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import { VirtualTemplateRow } from 'shared/types/template';
import * as userFilters from 'shared/filter/user';
import * as templateFilters from 'shared/filter/template';
import {sortBy} from 'lodash';

interface NameProps {
  value: Pick<VirtualTemplateRow, 'id'>;
}

export function Name (props: NameProps) {
  const { value:template } = props;
  if (!isObjectLike(template)) return null;
  const id = template.id;
  return (
    <IdShort value={id} />
  );
}

interface RoleListProps {
  value?: string[]; // a list of user roles
  shorten?: boolean;
}

// used for template (email, sms, letter) role_visibility or role_editable
export function RoleList (props: RoleListProps) {
  const { value, shorten } = props;
  if (!value || !value.length || !Array.isArray(value)) return <></>;
  const str = sortBy(value).sort().map(role => userFilters.role(role).slice(0, shorten ? 1 : undefined)).join(', ');
  return <>{str}</>;
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: VirtualTemplateRow['is_archived'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      labelClassName={value ? 'status-label-red' : 'status-label-green'}
      formatter={templateFilters.is_archived}
      {...restOfProps}
    />
  );
}
