import React from 'react';
import Anchor from  'src/anchors/Anchor';
import moment from 'moment';
import { Notes as CustomerNotesWidget } from 'src/customer/CustomerWidgets';
import * as ApplicationBidCharts from 'src/charts/ApplicationBidCharts';
import UserAnchor from 'src/user/UserAnchor';
import { WidgetListCard } from 'src/cards/CardHelpers';
import * as CustomerFormatters from  'src/customer/CustomerFormatters';
import * as applicationLogFilters from 'shared/filter/application_log';
import { CurrencyFormControl } from  'src/inputs/CurrencyInput';
import { SuffixNumberFormControl } from  'src/inputs/SuffixNumberInput';
import { Row, Col, Form, ListGroup } from 'react-bootstrap';
import {
  User,
  Users,
} from 'lucide-react';
import { WidgetGroups, WidgetList } from 'src/widgets/types';
import { ApplicationRow } from 'shared/types/application';
import { WidgetLoader, WidgetTimeline } from 'src/widgets/CommonWidgets';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ApplicationLogRowWithRelations } from 'shared/types/application_log';
import {ApplicationRoleCardInnerListGroup} from 'src/application/ApplicationRoleCard';

interface ApplicationWidgetProps {
  application: ApplicationRow;
}

export const widgetGroups: WidgetGroups = [
  {id: 'properties', title: 'Egenskaper'},
  {id: 'charts', title: 'Diagram'},
  {id: 'form', title: 'Ansökningsformulär'},
  {id: 'external', title: 'Externt'},
];

export const allWidgets: WidgetList = {
  properties: {
    id: 'properties',
    Component: Properties,
    title: 'Egenskaper',
    group: 'properties',
  },
  interestRateAndAmountChart: {
    id: 'interestRateAndAmountChart',
    Component: InterestRateAndAmountChart,
    title: 'Budjämförelse',
    group: 'charts',
  },
  formLoan: {
    id: 'formLoan',
    Component: FormLoan,
    title: 'Låneansökan',
    group: 'form',
  },
  formMainApplicant: {
    id: 'formMainApplicant',
    Component: FormMainApplicant,
    title: 'Huvudsökande',
    group: 'form',
  },
  formCoApplicant: {
    id: 'formCoApplicant',
    Component: FormCoApplicant,
    title: 'Medsökande',
    group: 'form',
  },
  applicants: {
    id: 'applicants',
    Component: Applicants,
    title: 'Sökande',
    group: 'properties',
  },
  parties: {
    id: 'parties',
    Component: InterestedParties,
    title: 'Intressenter',
    group: 'properties',
  },
  pep: {
    id: 'pep',
    Component: Pep,
    title: 'PEP',
    group: 'external',
  },
  sanctionList: {
    id: 'sanctionList',
    Component: SanctionList,
    title: 'Sanktionslista',
    group: 'external',
  },
  uc: {
    id: 'uc',
    Component: UC,
    title: 'Kreditupplysningar',
    group: 'external',
  },
  customerComplaints: {
    id: 'customerComplaints',
    Component: CustomerComplaints,
    title: 'Klagomål',
    group: 'external',
  },
  customerNotes: {
    id: 'customerNotes',
    Component: CustomerNotes,
    title: 'Anteckningar',
    group: 'external',
  },
  log: {
    id: 'log',
    Component: Log,
    title: 'Logg',
    group: 'properties',
  },
  triggers: {
    id: 'triggers',
    Component: Triggers,
    title: 'Triggers',
    group: 'external',
  },
  tasks: {
    id: 'tasks',
    Component: Tasks,
    title: 'Återkomster',
    group: 'external',
  },
};

// TODO fix
function Pep (props) {
  return (
    <p className="mt-3 text-center">PEP</p>
  );
}

// TODO fix
function SanctionList (props) {
  return (
    <WidgetListCard {...props}>
      <p className="mt-3 text-center">Sanktionslista</p>
    </WidgetListCard>
  );
}

// TODO fix
function UC (props) {
  return (
    <WidgetListCard {...props}>
      <p className="mt-3 text-center">Kreditupplysningar</p>
    </WidgetListCard>
  );
}

// TODO fix
function CustomerComplaints (props) {
  return (
    <WidgetListCard {...props}>
      <p className="mt-3 text-center">Klagomål</p>
    </WidgetListCard>
  );
}

// TODO fix
function CustomerNotes (props) {
  const { application: { customer_id }, ...otherProps } = props;
  const customer = {id: customer_id}; // fake customer object
  return (
    <CustomerNotesWidget  {...otherProps} customer={customer} />
  );
}

interface ApplicationLogResponse {
  logs: ApplicationLogRowWithRelations[];
  application: ApplicationRow;
}

function Log (props: ApplicationWidgetProps) {
  const { application } = props;

  const query = useQuery<ApplicationLogResponse, Error>({
    queryKey: [`/application/${application.id}/log`, {params: {limit: 5}}],
  });

  return (
    <WidgetListCard {...props}>
      <WidgetLoader isLoading={query.isLoading} error={query.error}>
        {query.isSuccess && (
          <WidgetTimeline
            className="m-3"
            list={query.data.logs}
            description={applicationLogFilters.description}
            by={(item: any) => item.user_id ? <UserAnchor user={item.User} /> : 'System'}
          />
        )}
      </WidgetLoader>
      <div className="d-flex justify-content-center mb-2 pt-2 border-top">
        <Link className="btn btn-outline-primary btn-sm" to={`/application/${application.id}/log`}>
          Visa hela loggen
        </Link>
      </div>
    </WidgetListCard>
  );
}

// TODO fix
function Triggers (props) {
  return (
    <WidgetListCard {...props}>
      <p className="mt-3 text-center">Triggers</p>
    </WidgetListCard>
  );
}

// TODO fix
function Tasks (props) {
  return (
    <WidgetListCard {...props}>
      <p className="mt-3 text-center">Återkomster</p>
    </WidgetListCard>
  );
}

function Properties (props) {
  const { application } = props;
  return (
    <WidgetListCard {...props}>
      <div className="p-2">
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label className="mb-0">Skapad</Form.Label>
              <Form.Control
                size="sm"
                type="date"
                value={moment(application.created_at).format('YYYY-MM-DD')}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label className="mb-0">Stängd</Form.Label>
              <Form.Control
                size="sm"
                type="date"
                value={application.closed_at ? moment(application.closed_at).format('YYYY-MM-DD') : ''}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
    </WidgetListCard>
  );
}

function InterestRateAndAmountChart (props) {
  const { application } = props;
  const amount = application.form.loan.amount;
  const amountToRefinance = amount.now || 0;
  const appliedAmount = amountToRefinance + amount.new || 0;
  return (
    <WidgetListCard {...props}>
      <ApplicationBidCharts.InterestRateAndAmountChart
        applicationId={application.id}
        amountToRefinance={amountToRefinance}
        appliedAmount={appliedAmount}
      />
    </WidgetListCard>
  );
}

// TODO fix purpose translation
function FormLoan (props) {
  const { application } = props;
  const formLoan = application.form?.loan || {};
  return (
    <WidgetListCard {...props}>
      <div className="p-2">
        <Row className="mb-2">
          <Col sm={4}>
            <Form.Group>
              <Form.Label className="mb-0">Lån att lösa</Form.Label>
              <CurrencyFormControl
                value={formLoan?.amount?.now || '0'}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label className="mb-0">Nylån</Form.Label>
              <CurrencyFormControl
                size="sm"
                value={formLoan?.amount?.new || '0'}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label className="mb-0">Totalt</Form.Label>
              <CurrencyFormControl
                size="sm"
                value={(formLoan?.amount?.new || 0) + (formLoan?.amount?.now || 0)}
                disabled
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Form.Group>
              <Form.Label className="mb-0">Återbetalningstid</Form.Label>
              <SuffixNumberFormControl
                suffix=" år"
                value={formLoan?.repayment_years}
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={6}>
            <Form.Group>
              <Form.Label className="mb-0">Lånesyfte</Form.Label>
              <Form.Select
                size="sm"
                value={formLoan?.purpose}
                disabled
              >
                <option>{formLoan?.purpose}</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </WidgetListCard>
  );
}

// TODO fill in remaining form fields properly
function FormApplicant (props) {
  const { applicant } = props;
  return (
    <WidgetListCard {...props}>
      {!applicant && (
        <p className="text-center mt-3">
          <em>Ej inkluderad</em>
        </p>
      )}
      {applicant && (
        <div className="p-2">
          <Row className="mb-2">
            <Col sm={6}>
              <Form.Group>
                <Form.Label className="mb-0">Förnamn</Form.Label>
                <Form.Control
                  size="sm"
                  value={applicant.first_name || ''}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label className="mb-0">Efternamn</Form.Label>
                <Form.Control
                  size="sm"
                  value={applicant.last_name || ''}
                  disabled
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={6}>
              <Form.Group>
                <Form.Label className="mb-0">Civilstånd</Form.Label>
                <Form.Select
                  size="sm"
                  value={applicant.status || ''}
                  disabled
                >
                  <option>{applicant.status}</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group>
                <Form.Label className="mb-0">Boendeform</Form.Label>
                <Form.Select
                  size="sm"
                  value={applicant?.address?.type || ''}
                  disabled
                >
                  <option>{applicant?.address?.type}</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </div>
      )}
    </WidgetListCard>
  );
}

function FormCoApplicant (props) {
  const { application, ...otherProps } = props;
  const { co_applicant:applicant, include_co_applicant:hasCoApplicant } = application;
  return (
    <FormApplicant {...otherProps} applicant={hasCoApplicant ? applicant : null} />
  );
}

function FormMainApplicant (props) {
  const { application, ...otherProps } = props;
  const applicant = application.form.main_applicant;
  return (
    <FormApplicant {...otherProps} applicant={applicant} />
  );
}

function Applicants (props) {
  const { application } = props;
  return (
    <WidgetListCard {...props}>
      <ListGroup variant="flush">
        <Applicant value={application.Customer} Icon={User} />
        <Applicant value={application.CustomerCo} Icon={Users} />
      </ListGroup>
    </WidgetListCard>
  );
}

function InterestedParties (props) {
  const { application } = props;
  return (
    <WidgetListCard {...props}>
      <ApplicationRoleCardInnerListGroup application={application} />
    </WidgetListCard>
  );
}

function Applicant (props: any) {
  const { value:customer, Icon } = props;
  if (!customer) {
    return null;
  }
  return (
    <ListGroup.Item className="d-flex justify-content-between align-items-center p-2 overflow-hidden">
      <div className="d-flex align-items-center">
        <Icon className="feather me-2" />
        <Anchor
          className="d-block"
          url={`/customer/${customer.id}`}
          name="Öppna kundkortet"
        >
          <CustomerFormatters.Name value={customer} />
        </Anchor>
      </div>
      <CustomerFormatters.StatusLabel value={customer.status} short className="small" />
    </ListGroup.Item>
  );
}
