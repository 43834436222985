import React from 'react';
import * as api from 'src/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { GroupRow } from 'shared/types/group';
import GroupCreateForm, {GroupCreateFormValues} from 'src/group/GroupCreateForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitCreates } from 'src/hooks/useEventModels';
import {useNavigate} from 'react-router';

export default function GroupCreateModalBody () {
  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const emitCreate = useEventModelEmitCreates<GroupRow>('group');

  const createMutation = useMutation<GroupRow, Error, GroupCreateFormValues>({
    mutationFn: async vars => {
      const group = await api.request({
        url: '/group',
        method: 'POST',
        data: vars,
      });

      queryClient.setQueryData([`/group/${group.id}`], group);
      notyf.success({type: 'success', message: 'Gruppen skapades'});
      modalStateContext.onHide();
      emitCreate(group);
      navigate(`/groups/${group.id}`);

      return group;
    },
  });

  const onSubmit = async values => {
    return await createMutation.mutateAsync(values);
  };

  const initialValues = {
    name: '',
  };

  return (
    <div>
      <GroupCreateForm
        className="mt-0"
        initialValues={initialValues}
        onSubmit={onSubmit}
        submitError={createMutation.error}
      />
    </div>
  );
}
