/**
 * WARNING: This file is used clientside
 */

exports.tableColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'actions': return 'Åtgärder';
    case 'reason': return 'Orsak';
    case 'customer_id': return 'Kund';
    case 'override_ban': return 'Avblockerad';
    case 'email': return 'E-postadress';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
  }
};
