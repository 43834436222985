const settings = {
  loanClamps: {
    minYears: 3,
    maxYears: 12,
    minTotal: 20000,
    maxTotal: 500000,
    minTotalClamp: false,
  },
  hasVerifications: true,
  hasVerificationsInBid: true,
  change: {
    changedByResubmitting: true,
    amountNewInput: true,
  },
  accept: {
  },
};
export default settings;
