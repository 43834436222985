import React from 'react';
import { Form, Card } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { BankProcessRow } from 'shared/types/bank_process';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as BankProcessFormFields from 'src/bankProcess/BankProcessFormFields';
import * as formUtils from 'src/utils/form';

export interface FormValues {
  'data.decision'?: 'accept' | 'reject' | 'change';
}

interface BankProcessDecisionFormProps {
  initialValues: FormValues;
  onSubmit: (values: FormValues, helpers: FormikHelpers<FormValues>) => Promise<any>;
  submitError?: Error | null;
}

const BankProcessDecisionForm: React.FC<BankProcessDecisionFormProps> = React.memo(function BankProcessDecisionForm (props: BankProcessDecisionFormProps) {
  const { initialValues, submitError, onSubmit } = props;
  // const colProps = {md: 6, lg: 4};
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="pt-0">
            <BankProcessFormFields.DataDecision />
          </Card.Body>
          <Card.Footer className="border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap">
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
});
export default BankProcessDecisionForm;

export function toFormValues (bankProcess: BankProcessRow): FormValues {
  return formUtils.rowToFormValues(bankProcess) as FormValues;
}

export function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<BankProcessRow> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
