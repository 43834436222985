/**
 * @desc Contact Template Filters (email, sms, letter)
 */

exports.type = function (type) {
  switch (type) {
    default: return type;
    case 'email': return 'E-postmall';
    case 'letter': return 'Brevmall';
    case 'sms': return 'SMS-mall';
  }
};

exports.category = function (category) {
  switch (category) {
    default: return category;
    case 'prospect': return 'Prospekt';
    case 'complaint': return 'Klagomål';
    case 'application': return 'Ansökan';
    case 'customer_cloud_insurance': return 'Försäkring (Cloud)';
    case 'customer_jaycom_insurance': return 'Försäkring (Jaycom)';
  }
};

exports.is_archived = function (value) {
  return value ? 'Arkiverad' : 'Valbar';
};
