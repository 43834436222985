const getter = require('shared/get');

/**
 * WARNING: This file is used clientside
 */

exports.indexColumnName = function (columnName) {
  switch (columnName) {
    default: return columnName;
    case 'id': return 'ID';
    case 'select': return 'Markera';
    case 'actions': return 'Åtgärder';
    case 'application_id': return 'Ansökan';
    case 'customer_id': return 'Kund';
    case 'status': return 'Status';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'trapets_doquery_result_at': return 'Omfrågad';
    case 'trapets_doquery_result_at_relative': return 'Omfrågad';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'subject': return 'Subjekt';
    case 'created_by_user_id': return 'Skapare';
  }
};

exports.indexField = function (work, columnName) {
  const get = (...args) => getter(work, ...args);
  switch (columnName) {
    default: return get(columnName);
    case 'status': return exports.status(get('status'));
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'error': return 'Fel';
    case 'queued': return 'Köad';
    case 'result': return 'Resultat';
  }
};

exports.statusResultValue = function (pepQuery) {
  const status = pepQuery.status;
  const hasMatch = getHasMatch(pepQuery);

  switch (status) {
    default: return 'null';
    case 'result': return hasMatch ? 'yellow' : 'green';
    case 'error': return 'red';
  }
};

exports.statusResult = function (pepQuery) {
  const status = pepQuery.status;
  const hasMatch = getHasMatch(pepQuery);

  switch (status) {
    default: return 'Resultat saknas';
    case 'result': return hasMatch ? 'Matchande personer' : 'Inga träffar';
    case 'error': return 'Fel';
  }
}

function getHasMatch(pepQuery) {
  const result = getter(pepQuery, 'trapets_doquery_result');
  // "individuals.length" is the old SOAP api result
  // any non-null result with Name key is the new REST api result
  const hasSoapMatch = getter(result, 'individuals.length', 0) > 0;
  const hasRestMatch = Boolean(getter(result, 'Name'));
  return hasSoapMatch || hasRestMatch;
}
