import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { StatusLabel } from  'src/group/GroupFormatters';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GroupRow } from 'shared/types/group';
import * as formUtils from 'src/utils/form';
import GroupEditForm, { GroupEditFormValues, groupToFormValues, formValuesToUpdate } from 'src/group/GroupEditForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';

interface GroupModalBodyProps {
  id: string;
}

export default function GroupModalBody (props: GroupModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();

  const emitUpdate = useEventModelEmitUpdates<GroupRow>('group');
  const emitDelete = useEventModelEmitDeletes<GroupRow>('group');

  const query = useQuery<GroupRow, Error>({
    queryKey: [`/group/${id}`],
  });

  const group = query.data;

  const updateMutation = useMutation<GroupRow, Error, Partial<GroupEditFormValues>>({
    mutationFn: async vars => {
      const { member_user_ids, ...groupUpdate } = vars;

      if (member_user_ids) {
        await api.request({
          url: `/group/${id}/users`,
          method: 'PUT',
          data: member_user_ids,
        });
      }

      if (Object.keys(groupUpdate).length > 0) {
        await api.request({
          url: `/group/${id}`,
          method: 'PATCH',
          data: groupUpdate,
        });
      }

      // we always get here so we can get a complete list of member user objects
      const group = await api.request({
        url: `/group/${id}`,
        method: 'GET',
      });

      queryClient.setQueryData([`/group/${id}`], group);
      notyf.success({type: 'default', message: 'Gruppen uppdaterades'});
      modalStateContext.onHide();
      emitUpdate(group);

      return group;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/group/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'Gruppen raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<GroupRow, GroupEditFormValues, Partial<GroupEditFormValues>>({
    queryDataToFormValues: groupToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(groupToFormValues(group), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} />
      {group && (
        <>
          <StatusLabelContainer className="mt-3">
            <StatusLabel value={group.is_active} />
          </StatusLabelContainer>
          <GroupEditForm
            initialValues={groupToFormValues(group)}
            onSubmit={formCycleHelpers.onSubmit}
            submitError={updateMutation.error}
            onDelete={onDelete}
          />
        </>
      )}
    </div>
  );
}
