/**
 * WARNING: This file is used clientside
 */

const getter = require('shared/get');

exports.indexColumnName = function (columnKey) {
  switch (columnKey) {
    default: return columnKey;
    case 'id': return 'ID';
    case 'customer_id': return 'Kund';
    case 'application_id': return 'Ansökan';
    case 'created_by_user_id': return 'Skapad av';
    case 'updated_by_user_id': return 'Uppdaterad av';
    case 'error': return 'Fel';
    case 'status': return 'Status';
    case 'jaycom_customer_id': return 'Kundnummer (Jaycom)';
    case 'jaycom_insurance_id': return 'Försäkringsnummer (Jaycom)';
    case 'insured_amount': return 'Försäkrat månadsbelopp';
    case 'form.insured_amount': return 'Försäkrat månadsbelopp';
    case 'form.monthly_costs': return 'Fasta månadskostnader';
    case 'form.clearing_no': return 'Clearingnummer';
    case 'form.account_no': return 'Kontonummer';
    case 'form.payment_method': return 'Betalningsmetod';
    case 'form.free_months': return 'Gratismånader';
    case 'cancelled_reason': return 'Annullationsorsak';
    case 'created_at': return 'Skapad';
    case 'created_at_relative': return 'Skapad';
    case 'updated_at': return 'Uppdaterad';
    case 'updated_at_relative': return 'Uppdaterad';
    case 'status_updated_at': return 'Statusuppdatering';
    case 'status_updated_at_relative': return 'Statusuppdatering';
    case 'flag_imported': return 'Importerad';
    case 'flag_status_ever_active': return 'Aktiv (någonsin)';
    case 'flag_has_adtraction_code': return 'Adtraction-kod?';
    case 'flag_created_by_user': return 'Skapad av användare?';
    case 'portal_tracking_code_adtraction': return 'Adtraction-kod';
    case 'portal_is_from': return 'Portalförsäkring';
    case 'actions': return 'Åtgärder';
  }
};

exports.indexField = function (customerJaycomInsurance, columnKey) {
  const get = (...args) => getter(customerJaycomInsurance, ...args);
  switch (columnKey) {
    default: return get(columnKey);
    case 'form.free_months': return get(columnKey) ?? 'Skaparstyrt';
    case 'form.payment_method': return exports.paymentMethod(get(columnKey));
    case 'status': return exports.status(get('status'));
    case 'customer_id': return get('Customer.name') || get('Customer.id') || get('customer_id') || '';
    case 'created_by_user_id': return get('Creator.name') || get('Creator.id') || get('created_by_user_id') || '';
    case 'updated_by_user_id': return get('Updater.name') || get('Updater.id') || get('updated_by_user_id') || '';
  }
};

exports.status = function (status) {
  switch (status) {
    default: return status;
    case 'new': return 'Nyskapad';
    case 'lead': return 'Lead';
    case 'uninterested': return 'Ointresserad';
    case 'queued': return 'Köad för aktivering';
    case 'active': return 'Aktiv';
    case 'cancelled': return 'Annullerad';
    case 'error': return 'Fel';
  }
};

exports.creator = function (row = {}) {
  const { created_by_user_id = null, flag_imported = false } = row;
  if (flag_imported) return 'Importerad';
  if (created_by_user_id) return 'Användare';
  return 'Kund via portal';
};

exports.paymentMethod = function (paymentMethod) {
  switch (paymentMethod) {
    default: return paymentMethod;
    case 'invoice': return 'Faktura';
    case 'autogiro': return 'Autogiro';
  }
}
