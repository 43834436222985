import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { GroupRow, GroupRowWithRelations } from 'shared/types/group';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as GroupFormFields from 'src/group/GroupFormFields';
import * as formUtils from 'src/utils/form';
import {Trash} from 'lucide-react';

export interface GroupEditFormValues extends GroupRow {
  member_user_ids?: string[];
}

interface GroupEditFormProps {
  initialValues: GroupEditFormValues;
  onSubmit: (values: GroupEditFormValues, helpers: FormikHelpers<GroupEditFormValues>) => Promise<any>;
  onDelete?: () => void;
  submitError?: Error | null;
}

const GroupEditForm: React.FC<GroupEditFormProps> = React.memo(function GroupEditForm (props: GroupEditFormProps) {
  const { initialValues, submitError, onDelete, onSubmit } = props;

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <div className="mx-3">
            <Row>
              <Col>
                <GroupFormFields.Id disabled />
              </Col>
              <Col>
                <GroupFormFields.Name />
              </Col>
            </Row>
            <Row>
              <Col>
                <GroupFormFields.ParentGroupId />
              </Col>
            </Row>
            <GroupFormFields.IsActive />
            <GroupFormFields.MemberUserIds />
          </div>
          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              {onDelete ? (
                <ConfirmActionModalButton
                  type="button"
                  className="rounded d-flex gap-1 align-items-center"
                  variant="danger"
                  disabled={!formProps.isValid || formProps.isSubmitting}
                  onConfirm={onDelete}
                  message="Är du säker på att du vill radera gruppen?"
                  title="Radera den här gruppen"
                >
                  <Trash size={18} />
                  Radera
                </ConfirmActionModalButton>
              ) : <div></div>}
              <div className="d-flex gap-2">
                <InspectObjectModalButton object={formProps} />
                <ButtonSpinner
                  type="submit"
                  className="rounded"
                  variant="success"
                  isLoading={formProps.isSubmitting}
                  disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                >
                  Spara
                </ButtonSpinner>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
});
export default GroupEditForm;

export function groupToFormValues (group: GroupRowWithRelations): GroupEditFormValues {
  const form = formUtils.rowToFormValues(group) as GroupEditFormValues;
  if (group.Users) form.member_user_ids = group.Users.map(user => user.id);
  return form;
}

export function formValuesToUpdate (initialValues: GroupEditFormValues, values: GroupEditFormValues): Partial<GroupRow> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
