import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EmailTemplateRow } from 'shared/types/email_template';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitCreates, useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';
import EmailTemplateEditForm, {EmailTemplateEditFormValues, emailTemplateToFormValues, formValuesToUpdate} from 'src/emailTemplate/EmailTemplateEditForm';
import {useNavigate} from 'react-router';

interface EmailTemplateModalBodyProps {
  id: string;
}

export default function EmailTemplateModalBody (props: EmailTemplateModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();
  const navigate = useNavigate();

  const emitCreate = useEventModelEmitCreates<EmailTemplateRow>('emailTemplate');
  const emitUpdate = useEventModelEmitUpdates<EmailTemplateRow>('emailTemplate');
  const emitDelete = useEventModelEmitDeletes<EmailTemplateRow>('emailTemplate');

  const query = useQuery<EmailTemplateRow, Error>({
    queryKey: [`/template/email/${id}`],
    enabled: Boolean(id),
    placeholderData: keepPreviousData,
  });

  const emailTemplate = query.data;

  const updateMutation = useMutation<EmailTemplateRow, Error, Partial<EmailTemplateEditFormValues>>({
    mutationFn: async vars => {
      const emailTemplate = await api.request({
        url: `/template/email/${id}`,
        method: 'PATCH',
        data: vars,
      });

      queryClient.setQueryData([`/template/email/${id}`], emailTemplate);
      notyf.success({type: 'default', message: 'E-postmallen uppdaterades'});
      // modalStateContext.onHide();
      emitUpdate(emailTemplate);

      return emailTemplate;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/template/email/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'E-postmallen raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const cloneMutation = useMutation<EmailTemplateRow, Error, any>({
    mutationFn: async () => {
      // FIXME yes, the form values are not used when cloning
      // update backend to be able to clone the local vars
      const emailTemplate = await api.request({
        url: `/template/email/${id}/clone`,
        method: 'POST',
        data: {},
      });
      notyf.success({type: 'success', message: 'E-postmallen klonades. Du redigerar nu kopian.'});
      emitCreate(emailTemplate);

      modalStateContext.onHide();
      navigate(`/email_template/${emailTemplate.id}`);

      return emailTemplate;
    },
  });

  const onClone = async (values: EmailTemplateEditFormValues) => {
    return cloneMutation.mutateAsync(values);
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<EmailTemplateRow, EmailTemplateEditFormValues, Partial<EmailTemplateEditFormValues>>({
    queryDataToFormValues: emailTemplateToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(emailTemplateToFormValues(emailTemplate), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading || query.isRefetching} className="m-3" />
      {emailTemplate && (
        <EmailTemplateEditForm
          className="mt-0"
          initialValues={emailTemplateToFormValues(emailTemplate)}
          onSubmit={formCycleHelpers.onSubmit}
          submitError={updateMutation.error}
          cloneError={cloneMutation.error}
          onDelete={onDelete}
          onClone={onClone}
          onHide={modalStateContext.onHide}
        />
      )}
    </div>
  );
}
