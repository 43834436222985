import React from 'react';
import { Form, Card, Row, Col } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import * as UserFormFields from 'src/user/UserFormFields';

export interface UserCreateFormValues {
  id: string;
}

interface UserCreateFormProps {
  className?: string;
  initialValues: UserCreateFormValues;
  onSubmit: (values: UserCreateFormValues, helpers: FormikHelpers<UserCreateFormValues>) => Promise<any>;
  submitError?: Error | null;
}

function UserCreateForm (props: UserCreateFormProps) {
  const { className, initialValues, submitError, onSubmit } = props;
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} className={className} validateOnMount>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="p-3 pt-0">
            <Row>
              <Col>
                <UserFormFields.Id explainer="Bara små bokstäver, bindestreck eller punkt" />
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className="border-top p-3">
            <ErrorAlert error={submitError} />
            <div className="d-flex gap-2 flex-wrap">
              <InspectObjectModalButton object={formProps} />
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}

export default React.memo(UserCreateForm);
