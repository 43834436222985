import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import useAuth from 'src/hooks/useAuth';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';

interface UserSwitchToButtonProps extends ButtonProps {
  userId: string;
}

export function UserSwitchToButton (props: UserSwitchToButtonProps) {
  const { userId, ...restOfProps } = props;

  const auth = useAuth();
  if (!auth.hasAnyRole('administrator')) {
    return null;
  }

  const onClick = async () => {
    await auth.switchTo(userId);
  };

  return (
    <ButtonPromiseSpinner {...restOfProps} onClick={onClick}>
      Logga in
    </ButtonPromiseSpinner>
  );
}

interface UserSwitchBackButtonProps extends ButtonProps {
}

export function UserSwitchBackButton (props: UserSwitchBackButtonProps) {
  const { ...restOfProps } = props;

  const auth = useAuth();
  if (!auth.user.oldUser) {
    return null;
  }

  const onClick = async () => {
    await auth.switchBack();
  };

  return (
    <ButtonPromiseSpinner {...restOfProps} onClick={onClick}>
      Återgå
    </ButtonPromiseSpinner>
  );
}
