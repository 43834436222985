import React, {useMemo} from 'react';
import { CardTitleIcon  } from 'src/cards/CardHelpers';
import {Card, Col, Form, Row} from 'react-bootstrap';
import {Settings} from 'lucide-react';
import {ApplicationRow} from 'shared/types/application';
import useAuth from 'src/hooks/useAuth';
import ApplicationCloseModalButton from 'src/application/ApplicationCloseModalButton';
import moment from 'moment';
import {useMutation} from '@tanstack/react-query';
import * as api from 'src/api';
import useNotyf from 'src/hooks/useNotyf';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import {Formik} from 'formik';
import * as formUtils from 'src/utils/form';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as ApplicationFormFields from 'src/application/ApplicationFormFields';
import ApplicationInvokeForm from 'src/application/ApplicationInvokeForm';
import {GuardPermission} from 'src/guards/AuthGuards';

interface ApplicationControlPanelCardProps {
  application: ApplicationRow;
}

function ApplicationControlPanelCard (props: ApplicationControlPanelCardProps) {
  const { application } = props;

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Kontrollpanel (ansökan)"
          Icon={<Settings size={18} />}
        >
        </CardTitleIcon>
      </Card.Header>
      <GuardPermission permission="bank_process_execute:invoke">
        <Card.Body className="border-bottom">
          <ApplicationInvokeForm application={application} />
        </Card.Body>
      </GuardPermission>
      <ApplicationControlPanelForm application={application} />
    </Card>
  );
}

export default function ApplicationControlPanelCardPermissionGuard (props: ApplicationControlPanelCardProps) {
  const { application } = props;
  const { closed_at, status } = application;

  const auth = useAuth();

  const canExecuteClose = useMemo(() => {
    return auth.permission('application_execute:close') && !closed_at;
  }, [closed_at, auth]);

  const canUpdateNote = useMemo(() => {
    if (auth.permission('admin')) return true;
    if (status === 'new' && auth.permission('application_update:submitter')) return true;
    if (!['closed', 'disbursed'].includes(status) && auth.permission('application_update:processer')) return true;
    return false;
  }, [auth, status]);

  const canDoAnythingHere = useMemo(() => {
    return auth.hasAnyPermissions([
      'admin',
      'application_execute:invoke',
      'application_update:processer',
      'application_update:disbursed',
    ]) || canExecuteClose || canUpdateNote;
  }, [canExecuteClose, auth, canUpdateNote]);

  if (!canDoAnythingHere) {
    return null;
  }

  return (
    <ApplicationControlPanelCard {...props} />
  );
}

interface FormValues {
  created_at: string;
  closed_at: string;
  accepted_at: string;
  disbursed: number | string;
  closed_reason: string;
  flag_advisor_is_done: boolean;
  note: string;
}

interface ApplicationControlPanelFormProps {
  application: ApplicationRow;
}

function ApplicationControlPanelForm (props: ApplicationControlPanelFormProps) {
  const { application } = props;

  const auth = useAuth();
  const notyf = useNotyf();

  const isAdmin = auth.permission('admin');

  const initialValues = applicationToFormValues(application);

  const canExecuteClose = useMemo(() => {
    return auth.permission('application_execute:close') && !application.closed_at;
  }, [application, auth]);

  const canUpdateNote = useMemo(() => {
    if (auth.permission('admin')) return true;
    if (application.status === 'new' && auth.permission('application_update:submitter')) return true;
    if (!['closed', 'disbursed'].includes(application.status) && auth.permission('application_update:processer')) return true;
    return false;
  }, [auth, application.status]);

  const updateMutation = useMutation<ApplicationRow, Error, Partial<ApplicationRow>>({
    mutationFn: async data => {
      const result = await api.request({
        url: `/application/${application.id}`,
        method: 'PATCH',
        data,
      });

      const message = `Ansökan med ID "${application.id}" har blivit uppdaterad`;
      notyf.success({type: 'default', message});

      return result;
    },
  });

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<ApplicationRow, FormValues, Partial<ApplicationRow>>({
    queryDataToFormValues: applicationToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  const colProps = {xxl: 6, xl: 12, lg: 12, md: 6, sm: 6};

  const isAccepted = ['accepted', 'disbursed'].includes(application.status);
  const isFinished = ['closed', 'disbursed'].includes(application.status);
  const isClosed = application.status === 'closed';

  return (
    <Formik initialValues={initialValues} onSubmit={formCycleHelpers.onSubmit} enableReinitialize>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>
          <Card.Body className="pt-0">
            <ApplicationFormFields.Note disabled={!canUpdateNote} />
            <Row>

              <Col {...colProps}>
                <ApplicationFormFields.CreatedAt disabled />
              </Col>

              <Col {...colProps}>
                <ApplicationFormFields.AcceptedAt
                  required={isAccepted}
                  disabled={!isAccepted || !isAdmin}
                />
              </Col>

              <Col {...colProps}>
                <ApplicationFormFields.ClosedAt
                  required={isFinished}
                  disabled={!isFinished || !isAdmin}
                />
              </Col>

              <GuardPermission permission="application_update:disbursed">
                <Col {...colProps}>
                  <ApplicationFormFields.Disbursed
                    required={isAccepted}
                    disabled={!isAccepted}
                    applicationStatus={application.status}
                  />
                </Col>
              </GuardPermission>

              <GuardPermission permission="application_update:processer">

                <Col lg={12}>
                  <ApplicationFormFields.ClosedReason
                    required={isClosed}
                    disabled={!isClosed || !isAdmin}
                  />
                </Col>

                <Col>
                  <hr />
                  <ApplicationFormFields.FlagAdvisorIsDone />
                  <ApplicationFormFields.FlagCustomerIsDone />
                  <ApplicationFormFields.FlagWantsTryggsam />
                </Col>
              </GuardPermission>

            </Row>

          </Card.Body>
          <Card.Footer className="border-top">
            <ErrorAlert error={updateMutation.error} />
            <div className="d-flex gap-2 flex-wrap">
              <ButtonSpinner
                type="submit"
                className="rounded"
                variant="success"
                isLoading={formProps.isSubmitting}
                disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
              >
                Spara
              </ButtonSpinner>
              {canExecuteClose && (
                <ApplicationCloseModalButton application={application} />
              )}
            </div>
          </Card.Footer>
        </Form>
      )}
    </Formik>
  );
}

export function applicationToFormValues (application: ApplicationRow): FormValues {
  const { note, created_at, closed_at, accepted_at, disbursed, closed_reason } = application;
  return {
    created_at: created_at ? moment(created_at).format('YYYY-MM-DD') : '',
    closed_at: closed_at ? moment(closed_at).format('YYYY-MM-DD') : '',
    accepted_at: accepted_at ? moment(accepted_at).format('YYYY-MM-DD') : '',
    disbursed: disbursed ?? '',
    closed_reason: closed_reason ?? '',
    flag_advisor_is_done: application.flag_advisor_is_done ?? false,
    note,
  };
}

export function formValuesToUpdate (initialValues: FormValues, values: FormValues): Partial<ApplicationRow> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
