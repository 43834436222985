import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import constants from 'shared/constants';
import {useField} from 'formik';

export function Template (props: Partial<FormikFormControls.FormGroupSelectProps>) {
  return (
    <FormikFormControls.FormGroupSelect
      label="Renderingsmall"
      required
      {...props}
      name="template"
    >
      <option value="">Välj renderingsmall</option>
      {constants.template.letter.templates.map((value: string) => (
        <option key={value} value={value}>{value}</option>
      ))}                  
    </FormikFormControls.FormGroupSelect>
  );
}

export function Body (props: Partial<FormikFormControls.FormGroupControlProps>) {
  const [field] = useField('body');
  return (
    <FormikFormControls.FormGroupControl
      label="Brödtext"
      as="textarea"
      name="body"
      minLength={0}
      maxLength={10000}
      rows={Math.min(20, Math.max(5, field.value.split(/\n/g).length + 1))}
      {...props}
    />
  );
}

