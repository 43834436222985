import React, {useCallback, useId, useMemo} from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import classNames from 'classnames';
import { TableFilterDefinition } from 'src/tables/Table';
import { TableStateFilterValue } from 'src/tables/types';
import * as tableHelpers from 'src/tables/helpers';

export type TOnChangeFilterValue = (rawValue: any) => void;

interface TableFilterFormGroupProps {
  id: string;
  filterDefinition: TableFilterDefinition;
  visible?: boolean;
  value: TableStateFilterValue;
  onToggleVisible?: (id: string) => void;
  onChangeFilterValue: (id: string, value?: TableStateFilterValue) => void;
}

const TableFilterFormGroup: React.FC<TableFilterFormGroupProps> = React.memo(function TableFilterFormGroup (props: TableFilterFormGroupProps) {
  const {
    id,
    filterDefinition,
    onToggleVisible,
    onChangeFilterValue,
    visible = true,
    value,
  } = props;

  const {
    Filter,
    title,
    Title,
    required = false,
    filterProps = {},
  } = filterDefinition ?? {};

  const htmlId = useId();

  const valueIsNotDefined: boolean = useMemo(() => !tableHelpers.filterValueIsDefined(value), [value]);

  const onClickReset: React.MouseEventHandler<HTMLLabelElement> = useCallback(ev => {
    if (valueIsNotDefined || !ev.altKey || required) return;
    ev.preventDefault();
    onChangeFilterValue(id);
  }, [valueIsNotDefined, onChangeFilterValue, id, required]);

  const onChangeValue: TOnChangeFilterValue = useCallback(rawValue => {
    const value = !tableHelpers.filterValueIsDefined(rawValue) ? undefined : rawValue;
    onChangeFilterValue(id, value);
  }, [onChangeFilterValue, id]);

  const onChangeToggleVisible: React.ChangeEventHandler<HTMLInputElement> = useCallback(() => {
    onToggleVisible(id);
  }, [onToggleVisible, id]);

  if (!Filter) {
    return null;
  }

  // only highlight the filter group if the value is not defined, and not required
  const labelTextClassName = classNames({'bg-secondary bg-gradient border-secondary text-white': !valueIsNotDefined && !required});

  return (
    <Form.Group className="me-3 mb-3">
      <InputGroup>
        <InputGroup.Text
          className={labelTextClassName}
          as="label"
          htmlFor={htmlId}
          onClick={onClickReset}
        >
          {Title ? (
            <Title>{title || id}</Title>
          ) : (
            <>{title || id}</>
          )}
        </InputGroup.Text>
        <Filter
          required={required}
          {...filterProps}
          id={htmlId}
          name={id}
          value={value}
          onChangeValue={onChangeValue}
        />
        {onToggleVisible && (
          <InputGroup.Checkbox
            name="visible"
            className="mt-0"
            checked={visible}
            onChange={onChangeToggleVisible}
            title="Filtret synligt utanför inställningar"
          />
        )}
      </InputGroup>
    </Form.Group>
  );
});

export default TableFilterFormGroup;
