import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import { EmailTemplateRow } from 'shared/types/email_template';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import ErrorAlert from 'src/alerts/ErrorAlert';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as EmailTemplateFormFields from 'src/emailTemplate/EmailTemplateFormFields';
import * as TemplateFormFields from 'src/template/TemplateFormFields';
import * as formUtils from 'src/utils/form';
import { VariableTable } from 'src/template/TemplateVariableTables';
import {Copy, Trash} from 'lucide-react';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';
import TemplatePreviewCard, {ReloadOnSubmissionUrlProvider} from 'src/template/TemplatePreviewCard';
import InspectObjectModalButton from 'src/buttons/InspectObjectModalButton';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';
import {GuardPermission} from 'src/guards/AuthGuards';

export interface EmailTemplateEditFormValues extends EmailTemplateRow {
}

interface EmailTemplateEditFormProps {
  className?: string;
  initialValues: EmailTemplateEditFormValues;
  onSubmit: (values: EmailTemplateEditFormValues, helpers: FormikHelpers<EmailTemplateEditFormValues>) => Promise<any>;
  onDelete?: () => void;
  onClone?: (values: EmailTemplateEditFormValues) => Promise<any>;
  onHide: () => void;
  submitError?: Error | null;
  cloneError?: Error | null;
  disabled?: boolean;
}

export default function EmailTemplateEditForm (props: EmailTemplateEditFormProps) {
  const {
    initialValues,
    submitError,
    cloneError,
    onDelete,
    onClone,
    onSubmit,
    onHide,
    className,
  } = props;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      className={className}
      enableReinitialize
    >
      {formProps => (
        <Form onSubmit={formProps.handleSubmit}>

          <Row className="px-3">
            <Col xl={5}>
              <TemplateFormFields.Name className="mt-0" />
              <TemplateFormFields.IsArchived />
              <Row>
                <Col>
                  <TemplateFormFields.Language />
                </Col>
                <Col>
                  <EmailTemplateFormFields.Category />
                </Col>
              </Row>
              <hr className="mb-0" />
              <Row>
                <Col>
                  <TemplateFormFields.RoleVisible />
                </Col>
                <Col>
                  <TemplateFormFields.RoleEditable />
                </Col>
              </Row>
              <hr className="mb-0" />
              <Row>
                <Col>
                  <EmailTemplateFormFields.HtmlRenderer />
                </Col>
                <Col>
                  <EmailTemplateFormFields.HtmlLayouts />
                </Col>
              </Row>
              <hr />
              <EmailTemplateFormFields.Subject />
              <EmailTemplateFormFields.BodyHtml />
            </Col>
            <Col xl={7}>
              <div className="form-label mt-3 mb-1 d-flex align-items-center gap-2">
                Förhandsgranskning
                {formProps.dirty && (
                  <strong className="text-danger">OBS! Inaktuell. Spara för att ladda om.</strong>
                )}
                <ExternalLinkAnchor href={`/api/template/email/${initialValues.id}/htmlPreview`} style={{fontSize: '80%'}}>
                  Öppna i nytt fönster
                </ExternalLinkAnchor>
              </div>
              <ReloadOnSubmissionUrlProvider
                url={`/api/template/email/${initialValues.id}/htmlPreview`}
                isSubmitting={formProps.isSubmitting}
              >
                {url => (
                  <TemplatePreviewCard
                    className="mb-1"
                    height={400}
                    url={url}
                  />
                )}
              </ReloadOnSubmissionUrlProvider>
              <div className="form-label mt-3 mb-1">Tillgängliga variabler</div>
              <VariableTable
                maxHeight={400}
                className="mb-1"
                templateType="email"
                templateCategory={formProps.values.category}
              />
            </Col>
          </Row>

          <div className="mt-3 p-3 border-top">
            <ErrorAlert error={submitError} />
            <ErrorAlert error={cloneError} />
            <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
              {onDelete ? (
                <GuardPermission permission="template_delete">
                  <ConfirmActionModalButton
                    type="button"
                    className="rounded d-flex align-items-center gap-1"
                    variant="outline-danger"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    onConfirm={onDelete}
                    message="Är du säker på att du vill radera e-postmallen?"
                    title="Radera den här e-postmallen"
                  >
                    <Trash size={18} />
                    Radera
                  </ConfirmActionModalButton>
                </GuardPermission>
              ) : <div></div>}
              <div className="d-flex gap-2 flex-wrap align-items-center justify-content-end">
                <InspectObjectModalButton object={formProps} />
                {onClone && (
                  <ButtonPromiseSpinner
                    type="button"
                    className="rounded d-flex gap-1"
                    variant="success"
                    disabled={!formProps.isValid || formProps.isSubmitting}
                    onClick={() => onClone(formProps.values)}
                    title="Klona den här mallen"
                  >
                    <Copy size={18} />
                    Klona
                  </ButtonPromiseSpinner>
                )}
                <Button variant="outline-secondary" onClick={onHide}>
                  Stäng
                </Button>
                <ButtonSpinner
                  type="submit"
                  className="rounded"
                  variant="success"
                  isLoading={formProps.isSubmitting}
                  disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                >
                  Spara
                </ButtonSpinner>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export function emailTemplateToFormValues (emailTemplate: EmailTemplateRow): EmailTemplateEditFormValues {
  return formUtils.rowToFormValues(emailTemplate) as EmailTemplateEditFormValues;
}

export function formValuesToUpdate (initialValues: EmailTemplateEditFormValues, values: EmailTemplateEditFormValues): Partial<EmailTemplateEditFormValues> {
  const changes = formUtils.changes(initialValues, values);
  return changes;
}
