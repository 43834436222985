import React, {useState} from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';
import { Card, Table, Form, Button } from 'react-bootstrap';
import { keepPreviousData, QueryFunctionContext, useIsFetching, useQuery } from '@tanstack/react-query';
import GroupSelectOptions from 'src/group/GroupSelectOptions';
import {useField} from 'formik';
import {Trash2} from 'lucide-react';
import {GroupRowWithRelations} from 'shared/types/group';
import {UserPlainAnchor} from 'src/user/UserAnchor';
import IdProvider from 'src/components/IdProvider';
import Pluralizer from 'src/formatters/Pluralizer';
import QuickSelect from 'src/components/QuickSelect';
import * as api from 'src/api';
import {UserRow} from 'shared/types/user';

export function Id (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="ID"
      required
      {...props}
      name="id"
    />
  );
}


export function Name (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Gruppnamn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="name"
    />
  );
}

export function IsActive (props: Partial<FormikFormControls.FormGroupCheckProps>) {
  return (
    <FormikFormControls.FormGroupCheck
      className="mt-3"
      label="Gruppen är aktiv"
      type="checkbox"
      {...props}
      name="is_active"
    />
  );
}

export function ParentGroupId (props: Partial<FormikFormControls.FormGroupSelectProps>) {

  const isLoadingOptions: boolean = useIsFetching({
    queryKey: ['groupSelectOptions'],
  }) > 0;

  return (
    <FormikFormControls.FormGroupSelect
      label="Överordnad grupp"
      {...props}
      disabled={isLoadingOptions}
      name="parent_group_id"
    >
      <option value="">Ingen överordnad grupp</option>
      <GroupSelectOptions />
    </FormikFormControls.FormGroupSelect>
  );
}

// TODO this should work with group member permissions too
// maybe when we remove group edit functionality from old crm we can amend the
// patch (group) and put (group members) backend function to also accept permissions
export function MemberUserIds () {

  const [field, , helpers] = useField<string[]>({
    name: 'member_user_ids',
  });

  const [filter, setFilter] = useState<'all' | 'active' | 'inactive'>('active');

  const onChangeFilterCheck: React.ChangeEventHandler<HTMLInputElement> = ev => {
    setFilter(ev.target.value as any);
  };

  const memberUserIds = field.value || [];

  const userQuery = useQuery<UserRow[]>({
    queryKey: ['/user/search', {params: {id: memberUserIds}}],
    placeholderData: keepPreviousData,
  });

  const memberUsers: (GroupRowWithRelations['Users'][number] | {id: string})[] = memberUserIds.map(userId => {
    const user = (userQuery.data ?? []).find(user => user.id === userId) ?? {};
    return {id: userId, ...user};
  }).filter((user: any) => {
    if (filter === 'all' || typeof user.is_active === 'undefined') return true;
    if (filter === 'inactive') return !user.is_active;
    return user.is_active;
  });

  const onClickDelete: React.MouseEventHandler<HTMLButtonElement> = ev => {
    const userId = ev.currentTarget.dataset.userId;
    if (!userId) return;
    helpers.setValue(memberUserIds.filter(existingUserId => userId !== existingUserId));
    helpers.setTouched(true);
  };

  const onAdd = userId => {
    if (memberUserIds.some(existingUserId => existingUserId === userId)) return;
    helpers.setValue([...memberUserIds, userId]);
    helpers.setTouched(true);
  };

  return (
    <Card className="mt-3">
      <Card.Header>
        <IdProvider>
          {id => (
            <Form.Check
              className="mb-0"
              id={id}
              inline
              type="radio"
              name="filter"
              label="Alla"
              onChange={onChangeFilterCheck}
              value="all"
              checked={filter === 'all'}
            />
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              className="mb-0"
              id={id}
              inline
              type="radio"
              name="filter"
              label="Inaktiva användare"
              onChange={onChangeFilterCheck}
              value="inactive"
              checked={filter === 'inactive'}
            />
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              className="mb-0"
              id={id}
              inline
              type="radio"
              name="filter"
              label="Aktiva användare"
              onChange={onChangeFilterCheck}
              value="active"
              checked={filter === 'active'}
            />
          )}
        </IdProvider>
      </Card.Header>
      <Table striped>
        <tbody>
          {memberUsers.length > 0 ? (
            <>
              {memberUsers.map(user => (
                <tr key={user.id}>
                  <td>
                    <UserPlainAnchor user={user} />
                  </td>
                  <td className="text-end">
                    <Button
                      size="sm"
                      data-user-id={user.id}
                      className="px-1"
                      variant="outline-danger"
                      onClick={onClickDelete}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td className="small text-muted">
                Det finns ingenting här
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <Card.Footer className="d-flex flex-wrap justify-content-between align-items-baseline border-top">
        <p className="mb-0">
          <Pluralizer
            count={memberUserIds.length}
            zero="Inga medlemmar"
            one="1 medlem totalt"
            otherwise="%% medlemmar totalt"
          />
        </p>
        <AddUserControl memberUserIds={memberUserIds} onAdd={onAdd} />
      </Card.Footer>
    </Card>
  );
}

interface AddUserControlProps {
  memberUserIds: string[];
  onAdd: (userId: string) => void;
}

// TODO break out into a generic quick select for users
function AddUserControl (props: AddUserControlProps) {
  const { onAdd, memberUserIds } = props;

  const onSelect = (user: UserRow) => {
    onAdd(user.id);
  };

  const queryFn = async (ctx: QueryFunctionContext ) => {
    const { signal, queryKey } = ctx;
    const input = queryKey[1] as string;
    if (!input) return [];
    const users = await api.request({
      url: '/user/search',
      params: {searchTerm: input, is_active: true, not_id: memberUserIds},
      signal,
    });
    return users;
  };

  return (
    <Form.Group>
      <QuickSelect
        placeholder="Sök efter användare"
        onSelect={onSelect}
        queryKeyBase="quickSelectUserId"
        queryFn={queryFn}
      >
        {state => state.results.map((user: UserRow, index) => (
          <UserResultItem
            key={user.id}
            user={user}
            index={index}
            selected={state.selectedIndex === index}
            onSelectIndex={state.onSelectIndex}
          />
        ))}
      </QuickSelect>
    </Form.Group>
  );
}

interface UserResultItemProps {
  user: UserRow;
  index: number;
  selected: boolean;
  onSelectIndex: (index: number) => void;
}

function UserResultItem (props: UserResultItemProps) {
  const { user, index, selected, onSelectIndex } = props;

  const onClickSelect = () => {
    onSelectIndex(index);
  };

  return (
    <li className="d-flex mb-2">
      <Button
        size="sm"
        className="flex-grow-1 text-start"
        variant="outline-primary"
        onClick={onClickSelect}
      >
        {selected && '→ '}
        {user.name ?? user.id}
      </Button>
    </li>
  );
}
