import React, {useState} from 'react';
import { Button, ButtonProps, Modal, ModalProps } from 'react-bootstrap';
import ModalStateContext from 'src/contexts/ModalStateContext';

export interface SimpleModalOpeningButtonProps extends ButtonProps {
  label: React.ReactNode;
  children: React.ReactNode;
  modalTitle?: React.ReactNode;
  modalBodyClassName?: string;
  modalSize?: 'sm' | 'lg' | 'xl';
  modalProps?: ModalProps;
}

const SimpleModalOpeningButton: React.FC<SimpleModalOpeningButtonProps> = React.memo(function ModalOpeningButton (props: SimpleModalOpeningButtonProps) {
  const {
    children,
    label,
    modalTitle,
    modalBodyClassName = 'm-2',
    modalSize,
    modalProps = {},
    ...buttonProps
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);

  const onHide = () => {
    setShowModal(false);
  };

  const onShow = () => {
    setShowModal(true);
  };

  return (
    <>
      <Button {...buttonProps} onClick={onShow}>
        {label}
      </Button>
      <Modal show={showModal} onHide={onHide} size={modalSize} {...modalProps}>
        {modalTitle && (
          <Modal.Header closeButton>
            <Modal.Title>
              {modalTitle}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={modalBodyClassName}>
          <ModalStateContext.Provider value={{onHide}}>
            {children}
          </ModalStateContext.Provider>
        </Modal.Body>
      </Modal>
    </>
  );
});
export default SimpleModalOpeningButton;
