import React from 'react';
import { NavLink } from 'react-router-dom';
import {Nav} from 'react-bootstrap';
import {Lock} from 'lucide-react';

interface NavLinkLockableProps {
  locked?: boolean;
  to: string;
  Icon: React.FunctionComponent<{size: number | string, style: any}>;
  title: string;
}

export default function NavLinkLockable (props: NavLinkLockableProps) {
  const { locked, to, Icon, title } = props;
  if (locked) {
    return (
      <Nav.Item>
        <div className="nav-link text-muted">
          <span className="d-inline-flex gap-1 align-items-center">
            <Lock size={12} style={{height: '12px', width: '12px'}} />{' '}
            {title}
          </span>
        </div>
      </Nav.Item>
    );
  }
  return (
    <Nav.Item>
      <NavLink className="nav-link" to={to}>
        <span className="d-inline-flex gap-1 align-items-center">
          <Icon size={12} style={{height: '12px', width: '12px'}} />{' '}
          {title}
        </span>
      </NavLink>
    </Nav.Item>
  );
}
