import React from 'react';
import { Edit } from 'lucide-react';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import useAuth from 'src/hooks/useAuth';
import { useMutation } from '@tanstack/react-query';
import { Formik, Field } from 'formik';
import * as api from 'src/api';
import { Card, Form } from 'react-bootstrap';

export default function AccountNotesCard (props) {
  const { user } = useAuth();

  const mutation = useMutation({
    mutationFn: data => api.request({
      method: 'put',
      url: '/account/notes',
      data,
    }),
  });

  const initialValues = {notes: user?.notes ?? ''};

  const onSubmit = async (data, formProps) => {
    const result = await mutation.mutateAsync(data);
    formProps.resetForm({values: result});
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formProps => (
        <Form onSubmit={formProps.handleSubmit} className="w-100">
          <Card className="flex-fill w-100">
            <Card.Header className="card-header-flush">
              <CardTitleIcon
                title="Egna anteckningar"
                Icon={<Edit size={16} />}
                spinning={formProps.isSubmitting}
              />
            </Card.Header>
            <Card.Body className="pt-0">
              <Field name="notes">
                {fieldProps => (
                  <Form.Control
                    {...fieldProps.field}
                    style={{minHeight: '300px'}}
                    as="textarea"
                    maxLength="10000"
                    placeholder="Skriv vad du vill..."
                    onBlur={ev => {
                      fieldProps.field.onBlur(ev);
                      if (formProps.dirty) {
                        formProps.submitForm();
                      }
                    }}
                  />
                )}
              </Field>
            </Card.Body>
          </Card>
        </Form>
      )}
    </Formik>
  );
}
