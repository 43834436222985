import React from 'react';
import { Card } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import * as api from 'src/api';
import {Formik} from 'formik';
import * as formUtils from 'src/utils/form';
import useNotyf from 'src/hooks/useNotyf';
import * as CardHelpers from 'src/cards/CardHelpers';

interface CustomerUpdateFormCardBuilderProps extends React.PropsWithChildren {
  title: string;
  customer: CustomerRow;
  customerToFormValues: (customer: CustomerRow) => Record<string, any>;
  formValuesToUpdate: (initialValues: Record<string, any>, values: Record<string, any>) => Record<string, any>;
  Icon?: React.FC<{size: string | number | undefined}>;
}

export default function CustomerUpdateFormCardBuilder (props: CustomerUpdateFormCardBuilderProps) {
  const { Icon, title, customer, customerToFormValues, formValuesToUpdate, children } = props;

  const notyf = useNotyf();
  const queryClient = useQueryClient();

  const updateMutation = useMutation<CustomerRow, Error, Partial<CustomerRow>>({
    mutationFn: async vars => {
      if (!Object.keys(vars).length) return customer;
      const updatedCustomer = await api.request({
        errorToNotyf: notyf,
        url: `/customer/${customer.id}`,
        method: 'PATCH',
        data: vars,
      });
      queryClient.setQueryData([`/customer/${updatedCustomer.id}`], updatedCustomer);
      notyf.success({type: 'default', message: 'Kunden uppdaterades'});
    },
  });

  const initialValues = customerToFormValues(customer);

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<CustomerRow, Record<string, any>, Partial<CustomerRow>>({
    queryDataToFormValues: customerToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues, values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <Card className="mb-4">
      <Card.Header>
        <CardHelpers.CardTitleIcon title={title} Icon={Icon ? <Icon size={18} /> : undefined} />
      </Card.Header>
      <Formik
        initialValues={initialValues}
        onSubmit={formCycleHelpers.onSubmit}
        enableReinitialize
      >
        {children}
      </Formik>
    </Card>
  );
}
