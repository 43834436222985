import React from 'react';
import { Form } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import useFocusControl, { HTMLFormControls } from 'src/hooks/useFocusControl';
import dateFilter from 'shared/filter/date';
import miscFilter from 'shared/filter/misc';
import useTimezone from 'src/hooks/useTimezone';
import { TableStateFilterValue } from 'src/tables/types';
import { TOnChangeFilterValue } from 'src/tables/TableFilterFormGroup';
import IdProvider from 'src/components/IdProvider';
import styled from 'styled-components';

const FilterPill = styled.div`
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
`;

interface Option {
  label: string;
  value: string;
}

export type OptionList = Option[];

export interface BooleanFilterProps {
  placeholder?: string;
  value: TableStateFilterValue;
  onChangeValue: TOnChangeFilterValue;
  hasSelectableNull?: boolean;
  labels?: {
    false: string;
    true: string;
  },
}

export function BooleanFilter (props: BooleanFilterProps) {
  const {
    value,
    placeholder = 'Oavsett värde',
    onChangeValue,
    hasSelectableNull = false,
    labels = {false: 'Nej', true: 'Ja'},
    ...restOfProps
  } = props;

  const inputProps = useFocusControl(value, onChangeValue, {
    getValue: (target: HTMLFormControls) => {
      if (target.value === 'true') return true;
      if (target.value === 'false') return false;
      // note! now null string is returned because that is what dataHelpers.where.booleanNull expects on the server
      if (hasSelectableNull && target.value === 'null') return 'null';
      return '';
    },
  });

  return (
    <Form.Select
      {...restOfProps}
      {...inputProps}
    >
      <option value="">{placeholder}</option>
      {hasSelectableNull ? <option value="null">Inget värde</option> : null}
      <option value="false">{labels.false}</option>
      <option value="true">{labels.true}</option>
    </Form.Select>
  );
}

export function StringFilter (props) {
  const {
    value,
    placeholder = 'Oavsett värde',
    onChangeValue,
    ...restOfProps
  } = props;

  const inputProps = useFocusControl(value, onChangeValue);

  return (
    <Form.Control
      {...restOfProps}
      {...inputProps}
      placeholder={placeholder}
    />
  );
}

export function JSONFilter (props) {
  const {
    value,
    placeholder = 'Oavsett värde',
    onChangeValue,
    ...restOfProps
  } = props;

  const inputProps = useFocusControl(value, onChangeValue, {
    undefinedValue: '{}',
    getInvalid: (target) => {
      try {
        JSON.parse(target.value);
      } catch (err) {
        return true;
      }
      return false;
    },
  });

  return (
    <Form.Control
      {...restOfProps}
      {...inputProps}
      placeholder={placeholder}
    />
  );
}

interface SelectFilterProps {
  options: OptionList;
  value: TableStateFilterValue;
  onChangeValue: TOnChangeFilterValue;
  placeholder?: string;
  required?: boolean;
}

export function SelectFilter (props: SelectFilterProps) {
  const {
    value,
    options,
    onChangeValue,
    placeholder = 'Oavsett värde',
    required,
    ...restOfProps
  } = props;

  const selectProps = useFocusControl(value, onChangeValue);

  return (
    <Form.Select
      {...restOfProps}
      {...selectProps}
      required={required}
    >
      {!required && (
        <option value="">{placeholder}</option>
      )}
      {options.map(option => (
        <option key={option.value} value={option.value}>{option.label}</option>
      ))}
    </Form.Select>
  );
}

interface InlineRadioFilterProps {
  options: OptionList;
  value: TableStateFilterValue;
  onChangeValue: TOnChangeFilterValue;
  required?: boolean;
}

export function InlineRadioFilter (props: InlineRadioFilterProps) {
  const {
    value,
    options,
    onChangeValue,
    required,
    ...restOfProps
  } = props;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    onChangeValue(ev.target.value);
  };

  return (
    <FilterPill className="d-flex gap-3 align-items-center px-3">
      {options.map(option => (
        <IdProvider key={option.value}>
          {id => (
            <Form.Check
              {...restOfProps}
              className="m-0"
              id={id}
              inline
              type="radio"
              label={option.label}
              value={option.value}
              onChange={onChange}
              required={required}
              checked={value === option.value}
            />
          )}
        </IdProvider>
      ))}
    </FilterPill>
  );
}

export function DatalistFilter (props) {
  const {
    value,
    options,
    placeholder = 'Oavsett värde',
    onChangeValue,
    ...restOfProps
  } = props;

  const datalistId = React.useId();
  const inputProps = useFocusControl(value, onChangeValue);

  return (
    <>
      <datalist id={datalistId}>
        {options.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </datalist>
      <Form.Control
        {...restOfProps}
        {...inputProps}
        placeholder={placeholder}
        list={datalistId}
      />
    </>
  );
}

export function QueriedDatalistFilter (props) {
  const { queryKey, queryFn, ...restOfProps } = props;
  const query = useQuery({
    queryKey,
    queryFn,
  });
  return (
    <DatalistFilter
      {...restOfProps}
      options={query.data ?? []}
    />
  );
}

export function DateRangeFilter (props) {
  const {
    value,
    onChangeValue:onChangeValueOuter,
    ...restOfProps
  } = props;

  const timezone = useTimezone();

  const onChangeValue = (inputValue, target) => {
    const newValue = {
      ...(value || {}),
      timezone,
      [target.name]: inputValue,
    };
    if (newValue.from || newValue.to) {
      onChangeValueOuter(newValue);
    } else {
      onChangeValueOuter();
    }
  };

  const fromProps = useFocusControl(value?.from, onChangeValue);
  const toProps = useFocusControl(value?.to, onChangeValue);

  return (
    <>
      <Form.Control
        type="date"
        {...restOfProps}
        {...fromProps}
        name="from"
      />
      <Form.Control
        type="date"
        {...restOfProps}
        {...toProps}
        name="to"
      />
    </>
  );
}

export function DateTimeRangeFilter (props) {
  const {
    value,
    onChangeValue:onChangeValueOuter,
    ...restOfProps
  } = props;

  const timezone = useTimezone();

  const onChangeValue = (inputValue, target) => {
    const newValue = {
      ...(value || {}),
      timezone,
      [target.name]: inputValue,
    };
    if (newValue.from || newValue.to) {
      onChangeValueOuter(newValue);
    } else {
      onChangeValueOuter();
    }
  };

  const fromProps = useFocusControl(value?.from, onChangeValue);
  const toProps = useFocusControl(value?.to, onChangeValue);

  return (
    <>
      <Form.Control
        {...restOfProps}
        {...fromProps}
        name="from"
        type="datetime-local"
      />
      <Form.Control
        {...restOfProps}
        {...toProps}
        name="to"
        type="datetime-local"
      />
    </>
  );
}

export function DateRangeOnFieldFilter (props) {
  const {
    value,
    onChangeValue:onChangeValueOuter,
    fieldOptions,
    ...restOfProps
  } = props;

  const timezone = useTimezone();

  const onChangeValue = (inputValue, target) => {
    const newValue = {
      ...(value || {}),
      timezone,
      [target.name]: inputValue,
    };
    if (newValue.from || newValue.to) {
      onChangeValueOuter(newValue);
    } else {
      onChangeValueOuter();
    }
  };

  const fieldProps = useFocusControl(value?.field, onChangeValue);
  const fromProps = useFocusControl(value?.from, onChangeValue);
  const toProps = useFocusControl(value?.to, onChangeValue);

  return (
    <>
      <Form.Select
        {...restOfProps}
        {...fieldProps}
        name="field"
      >
        {fieldOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Select>
      <Form.Control
        type="date"
        {...restOfProps}
        {...fromProps}
        name="from"
      />
      <Form.Control
        type="date"
        {...restOfProps}
        {...toProps}
        name="to"
      />
    </>
  );
}

export function IntOpFilter (props) {
  const {
    value,
    onChangeValue:onChangeValueOuter,
    fieldOps = ['eq', 'gt', 'lt'],
    ...restOfProps
  } = props;

  const onChangeValue = (inputValue, target) => {
    const newValue = {
      op: fieldOps[0],
      ...(value || {}),
      [target.name]: inputValue,
    };
    if (newValue.value !== '') {
      onChangeValueOuter(newValue);
    } else {
      onChangeValueOuter();
    }
  };

  const opProps = useFocusControl(value?.op, onChangeValue);
  const valueProps = useFocusControl(value?.value, onChangeValue);

  const fieldOptions = fieldOps.map(value => ({
    value,
    label: miscFilter.logicalComparison(value),
  }));

  return (
    <>
      <Form.Select {...opProps} name="op">
        {fieldOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </Form.Select>
      <Form.Control
        {...restOfProps}
        {...valueProps}
        type="number"
        name="value"
      />
    </>
  );
}

const dateRelativeOptions = [
  'today',
  'yesterday',
  'thisweek',
  'lastweek',
  'thismonth',
  'lastmonth',
  'thisquarter',
  'lastquarter',
  'thisyear',
  'lastyear',
  'trailing7',
  'trailing30',
  'trailing90',
  'trailing365',
].map(value => ({
  value,
  label: dateFilter.dateRelative(value),
}));

export function DateRelativeFilter (props) {
  return (
    <SelectFilter {...props} options={dateRelativeOptions} />
  );
}
