import React, {useState} from 'react';
import { Badge, Modal, ModalProps } from 'react-bootstrap';
import ModalStateContext from 'src/contexts/ModalStateContext';
import classNames from 'classnames';

export interface ModalAnchorProps extends React.PropsWithChildren {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  name: React.ReactNode;
  className?: string;
  size?: 'sm' | 'lg' | 'xl';
  modalBodyClassName?: string;
  backdrop?: ModalProps['backdrop'];
}

const ModalAnchor: React.FC<ModalAnchorProps> = React.memo(function ModalAnchor (props: ModalAnchorProps) {
  const {
    title,
    subTitle,
    name,
    children,
    className,
    size,
    modalBodyClassName = 'p-0',
    backdrop = 'static',
    ...restOfProps
  } = props;

  const [show, setShow] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  const onHide = () => setShow(false);

  const onClickShow = () => {
    setMounted(true);
    setShow(true);
  };

  const onExited = () => {
    setMounted(false);
  };

  return (
    <Badge
      bg="white"
      className={classNames(className, 'd-inline-flex align-items-center border p-0')}
      {...restOfProps}
    >
      <span className="p-0 d-inline-block">
        <button
          type="button"
          onClick={onClickShow}
          className="btn px-1 py-0 border-0 btn-link d-inline-block"
          title={`Öppna ${name}`}
          style={{ marginTop: '-1px' }}
        >
          {title}
        </button>
      </span>
      {mounted && (
        <Modal
          show={show}
          onHide={onHide}
          onExited={onExited}
          placement="end"
          size={size}
          backdrop={backdrop}
        >
          <Modal.Header>
            <div className="d-flex justify-content-between align-items-center w-100">
              <Modal.Title>
                {name}
                {subTitle && (
                  <small className="d-block text-muted">{subTitle}</small>
                )}
              </Modal.Title>
              <button type="button" className="btn-close" onClick={onHide}></button>
            </div>
          </Modal.Header>
          <Modal.Body className={modalBodyClassName}>
            <ModalStateContext.Provider value={{onHide}}>
              {children}
            </ModalStateContext.Provider>
          </Modal.Body>
        </Modal>
      )}
    </Badge>
  );
});
export default ModalAnchor;
