import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { get, set, debounce } from 'lodash';
import { Form } from 'react-bootstrap';
import IdProvider from 'src/components/IdProvider';
import ObjectInspector from 'src/components/ObjectInspector';

export interface InspectObjectModalProps {
  object: any;
  show: boolean;
  onHide: () => void;
}

const InspectObjectModal: React.FC<InspectObjectModalProps> = React.memo(function InspectObjectModal (props: InspectObjectModalProps) {
  const {
    show,
    object,
    onHide,
  } = props;
  const [isTextMode, setIsTextMode] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [debouncedInputValue, setDebouncedInputValue] = useState('');

  // uhhh what?
  // eslint-disable-next-line
  const debouncedHandleInputChange = useCallback(debounce((value: string) => {
    setDebouncedInputValue(value.replace(/\s/g, ''));
  }, 500), [setDebouncedInputValue]);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInputValue(e.target.value);
    debouncedHandleInputChange(e.target.value);
  };

  const filteredData = debouncedInputValue
    ? debouncedInputValue.match(/[.[]/) ? get(object, debouncedInputValue) : searchMatchInObjectKeys(object, debouncedInputValue)
    : object;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          Granska objekt
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="d-flex gap-3 align-items-baseline border-bottom p-2 ps-3">
          <IdProvider>
            {id => (
              <Form.Check
                id={id}
                type="radio"
                label="Objekt"
                onChange={() => setIsTextMode(false)}
                value="true"
                checked={!isTextMode}
              />
            )}
          </IdProvider>
          <IdProvider>
            {id => (
              <Form.Check
                id={id}
                type="radio"
                label="Text"
                onChange={() => setIsTextMode(true)}
                value="en"
                checked={isTextMode}
              />
            )}
          </IdProvider>
          <Form.Control
            type="text"
            placeholder="Sök"
            value={inputValue}
            onChange={handleInputChange}
          />
        </div>
        <div className="p-3">
          <div style={{overflow: 'auto'}}>
            {isTextMode ? (
              <pre className="m-0">{JSON.stringify(filteredData, null, 2)}</pre>
            ) : (
              <ObjectInspector
                object={filteredData}
                expandLevel={debouncedInputValue ? 10 : 1}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
});
export default InspectObjectModal;

const searchMatchInObjectKeys = (obj: any, searchString: string, path: string[] = [], acc: Record<any, any> = {}) => {
  if (!obj) {
    return acc;
  }
  
  Object.keys(obj).forEach((key) => {
    if (key.includes(searchString)) {
      set(acc, [...path, key], obj[key]);
    }
    if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
      searchMatchInObjectKeys(obj[key], searchString, [...path, key], acc);
    }
  });

  return acc;
};
