import React from 'react';
import BlockSpinner from 'src/spinners/BlockSpinner';
import * as api from 'src/api';
import ErrorAlert from 'src/alerts/ErrorAlert';
import StatusLabelContainer from  'src/components/StatusLabelContainer';
import { StatusLabel } from  'src/letter/LetterFormatters';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { LetterRow } from 'shared/types/letter';
import * as formUtils from 'src/utils/form';
import LetterEditForm, { LetterEditFormValues, letterToFormValues, formValuesToUpdate } from 'src/letter/LetterEditForm';
import useNotyf from 'src/hooks/useNotyf';
import useModalStateContext from 'src/hooks/useModalStateContext';
import { useEventModelEmitDeletes, useEventModelEmitUpdates } from 'src/hooks/useEventModels';

interface LetterModalBodyProps {
  id: string;
}

export default function LetterModalBody (props: LetterModalBodyProps) {
  const { id } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();
  const modalStateContext = useModalStateContext();

  const emitUpdate = useEventModelEmitUpdates<LetterRow>('letter');
  const emitDelete = useEventModelEmitDeletes<LetterRow>('letter');

  const query = useQuery<LetterRow, Error>({
    queryKey: [`/letter/${id}`],
  });

  const letter = query.data;

  const updateMutation = useMutation<LetterRow, Error, Partial<LetterEditFormValues>>({
    mutationFn: async vars => {
      const letter = await api.request({
        url: `/letter/${id}`,
        method: 'PATCH',
        data: vars,
      });

      queryClient.setQueryData([`/letter/${id}`], letter);
      notyf.success({type: 'default', message: 'Brevet uppdaterades'});
      modalStateContext.onHide();
      emitUpdate(letter);

      return letter;
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: async () => {
      await api.request({
        url: `/letter/${id}`,
        method: 'DELETE',
      });

      notyf.success({type: 'danger', message: 'Brevet raderades'});
      if (query.data) emitDelete(query.data);
      modalStateContext.onHide();
    },
  });

  const onDelete = async () => {
    return deleteMutation.mutateAsync();
  };

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<LetterRow, LetterEditFormValues, Partial<LetterEditFormValues>>({
    queryDataToFormValues: letterToFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(letterToFormValues(letter), values),
    mutateAsync: updateMutation.mutateAsync,
  });

  return (
    <div>
      <ErrorAlert error={query.error} className="m-3" />
      <BlockSpinner isLoading={query.isLoading} className="m-3" />
      {letter && (
        <>
          <StatusLabelContainer className="mt-3">
            <StatusLabel value={letter.status} />
          </StatusLabelContainer>
          <LetterEditForm
            className="mt-0"
            disabled={letter.status !== 'new'}
            initialValues={letterToFormValues(letter)}
            onSubmit={formCycleHelpers.onSubmit}
            submitError={updateMutation.error}
            onDelete={letter.status === 'new' ? onDelete : undefined}
          />
        </>
      )}
    </div>
  );
}
