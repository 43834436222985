import React from 'react';
import { ApplicationRowWithRelations } from 'shared/types/application';
import AssociatedObjectList from 'src/components/AssociatedObjectList';
import {oldCrmUrl} from 'src/misc';

interface ApplicationAssociatedObjectListProps {
  application: ApplicationRowWithRelations;
}

function ApplicationAssociatedObjectList (props: ApplicationAssociatedObjectListProps) {
  const { application } = props;

  const associatedObjects = [
    application.customer_id ? {type: 'customer', value: {id: application.customer_id, ...(application.Customer ?? {})}, label: 'Huvudsökande'} : null,
    application.customer_id_co ? {type: 'customer', value: {id: application.customer_id_co, ...(application.CustomerCo ?? {})}, label: 'Medsökande'} : null,
    application.created_by_user_id ? {type: 'user', value: {id: application.created_by_user_id, ...(application.Creator ?? {})}, label: 'Skapad av'} : null,
    application.role_advisor ? {type: 'user', value: {id: application.role_advisor, ...(application.Advisor ?? {})}, label: 'Rådgivare'} : null,
    application.accepted_process_id ? {type: 'bankProcess', value: {id: application.accepted_process_id, application_id: application.id, bank_id: application.accepted_bank_id}, label: 'Accepterad'} : null,
  ].filter(v => v);

  const externalLinks = [
    {url: oldCrmUrl(`/application/${application.id}/form`), label: 'Gamla CRM'},
  ].filter(v => v);

  return (
    <AssociatedObjectList
      associatedObjects={associatedObjects}
      externalLinks={externalLinks}
    />
  );
}
export default React.memo(ApplicationAssociatedObjectList);
