import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import IdShort from 'src/formatters/IdShort';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import * as ucFilter from 'shared/filter/uc';
import {UcRow} from 'shared/types/uc';

interface NameProps {
  value: Pick<UcRow, 'id'>;
}

export function Name (props: NameProps) {
  const { value:uc } = props;
  if (!isObjectLike(uc)) return null;
  const id = uc.id;
  return (
    <IdShort value={id} />
  );
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: UcRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={ucFilter.status}
      {...restOfProps}
    />
  );
}
