import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import PepQueryModalBody from 'src/pepQuery/PepQueryModalBody';
import { Name } from 'src/pepQuery/PepQueryFormatters';
import { PepQueryRow } from 'shared/types/pep_query';

interface PepQueryAnchorProps extends Partial<ModalAnchorProps> {
  pepQuery: Pick<PepQueryRow, 'id'>;
}

export default function PepQueryAnchor (props: PepQueryAnchorProps) {
  const { pepQuery, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={pepQuery} />}
      name="PEP-fråga"
      backdrop
    >
      <PepQueryModalBody id={pepQuery.id} />
    </ModalAnchor>
  );
}

