import React from 'react';
import {Table} from 'react-bootstrap';
import {UcRow} from 'shared/types/uc';
import DateTime from 'src/formatters/DateTime';
import * as UcFormatters from 'src/uc/UcFormatters';
import * as NumberFormatters from 'src/formatters/NumberFormatters';
import UserAnchor from 'src/user/UserAnchor';
import { errorToMessage } from 'src/utils/error';
import { ssn as ssnFormat } from 'shared/format';
import useAuth from 'src/hooks/useAuth';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';
import {GuardPermission} from 'src/guards/AuthGuards';
import UcAnchor from 'src/uc/UcAnchor';

interface ResponseTableProps {
  uc: UcRow;
  hasAnchor?: boolean;
}

export function ResponseTable (props: ResponseTableProps) {
  const { uc, hasAnchor } = props;

  return (
    <Table size="sm" className="mb-0 align-middle no-border-bottom" style={{fontSize: '90%'}}>
      <tbody>
        {hasAnchor && (
          <GuardPermission permission="admin">
            <tr>
              <th>Objekt</th>
              <td><UcAnchor uc={uc} /></td>
            </tr>
          </GuardPermission>
        )}
        <tr>
          <th>Utförd</th>
          <td>
            <DateTime value={uc.response_at} />
          </td>
        </tr>
        {uc.submitted_by_user_id && (
          <tr>
            <th>Utförd av</th>
            <td><UserAnchor user={{id: uc.submitted_by_user_id}} /></td>
          </tr>
        )}
        {uc.request?.object && (
          <tr>
            <th>Sökande (PNR)</th>
            <td>
              {uc.request.object}
            </td>
          </tr>
        )}
        {uc.request?.coObject && (
          <tr>
            <th>Medsökande (PNR)</th>
            <td>
              {uc.request.coObject}
            </td>
          </tr>
        )}
        {(typeof uc.request?.creditSeek === 'number') && (
          <tr>
            <th>Belopp</th>
            <td>
              <NumberFormatters.Currency value={uc.request.creditSeek} />
            </td>
          </tr>
        )}

        {uc.response_main && (
          <UcResponseTableRow uc={uc} index={0} response={uc.response_main} />
        )}

        {uc.response_co && (
          <UcResponseTableRow uc={uc} index={1} response={uc.response_co} />
        )}

        {uc.error && (
          <tr>
            <td className="text-danger text-center" colSpan={2}>
              {uc.error.message && <div><strong className="text-danger">{uc.error.message}</strong></div>}
              {Array.isArray(uc.error.cause) ? (
                <ol className="mb-0 list-unstyled">
                  {uc.error.cause.map((cause, index) => <li key={index}>{cause}</li>)}
                </ol>
              ) : (
                <div>{errorToMessage(uc.error)}</div>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}

interface UcResponseTableRowProps {
  uc: UcRow;
  index: number;
  response: UcRow['response_main'] | UcRow['response_co'];
}

function UcResponseTableRow (props: UcResponseTableRowProps) {
  const { uc, index, response } = props;
  const auth = useAuth();
  const { ssn, result, cause } = response;
  return (
    <tr>
      <td colSpan={2} className="py-2">
        <div className="d-flex flex-column gap-2 align-items-center">
          {auth.permission('uc_read_html') ? (
            <ExternalLinkAnchor
              href={`/api/uc/${uc.id}/${index}/html`}
              title="Visa upplysningen som HTML i en ny flik"
            >
              {ssnFormat('short-dash', ssn)}
            </ExternalLinkAnchor>
          ) : (
            <span>{ssnFormat('short-dash', ssn)}</span>
          )}
          <UcFormatters.StatusLabel value={uc.status} formatter={() => result} short wrap />
          {Array.isArray(cause) && (
            <ul className="list-unstyled text-danger mb-0 text-center">
              {cause.map((cause, index) => <li key={index}>{cause}</li>)}
            </ul>
          )}
        </div>
      </td>
    </tr>
  );
}
