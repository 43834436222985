import React from 'react';
import * as FormikFormControls from 'src/form/FormikFormControls';

export function ToAddress (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Mottagarens adress"
      type="email"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="to_address"
    />
  );
}

export function ToName (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Mottagarens namn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="to_name"
    />
  );
}

export function FromName (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Avsändarens namn"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="from_name"
    />
  );
}

export function FromAddress (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Avsändarens adress"
      type="email"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="from_address"
    />
  );
}

export function Subject (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Ämne"
      minLength={1}
      maxLength={255}
      required
      {...props}
      name="subject"
    />
  );
}

export function BodyHtml (props: Partial<FormikFormControls.FormGroupControlProps>) {
  return (
    <FormikFormControls.FormGroupControl
      label="Meddelande (HTML)"
      as="textarea"
      rows={10}
      minLength={0}
      maxLength={250000}
      required
      {...props}
      name="body_html"
    />
  );
}
