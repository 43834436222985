/* eslint-disable unused-imports/no-unused-vars */
import React, {useId} from 'react';
import { FieldValidator, useField } from 'formik';
import { FormCheckType } from 'react-bootstrap/esm/FormCheck';
import classNames from 'classnames';
import BasicSpinner from 'src/spinners/BasicSpinner';
import {
  Form,
  FormCheckProps,
  FormControlProps,
  FormSelectProps,
  InputGroup,
} from 'react-bootstrap';
import {
  checkPropsToFormikValidate,
  inputPropsToFormikValidate,
  inputPropsToValue,
  selectPropsToFormikValidate,
  useFieldExtended,
} from 'src/utils/form';
import { useFormikOnUpdate } from 'src/form/FormikOnUpdateContext';
import NumberFormattedInput from 'src/inputs/NumberFormattedInput';
import FormGroupLabel from 'src/form/FormGroupLabel';

export interface FormGroupControlProps extends FormControlProps {
  emptyValue?: any;
  error?: string;
  label?: React.ReactNode;
  className?: string;
  name: string;
  type?: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  step?: number;
  min?: number | string;
  max?: number | string;
  rows?: number | string;
  isLoading?: boolean;
  explainer?: React.ReactNode;
  customValidate?: FieldValidator;
  helpText?: React.ReactNode;
}

export function FormGroupControl (props: FormGroupControlProps) {
  const {
    name,
    error:outerError,
    isLoading,
    type,
    className,
    label,
    emptyValue,
    explainer,
    helpText,
    customValidate, // eslint-disable-line no-unused-vars
    ...restOfProps
  } = props;

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  }) as any;

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  const error = meta.error || outerError;

  const id = useId();

  return (
    <Form.Group className={className}>
      <FormGroupLabel id={id} label={label} isLoading={isLoading} helpText={helpText} />
      <Form.Control
        {...restOfProps}
        {...field}
        onBlur={onBlur}
        value={inputPropsToValue(props, field.value)}
        id={id}
        name={name}
        type={type}
        isInvalid={Boolean(error)}
      />
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

export interface InputGroupControlProps extends FormGroupControlProps {
  error?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

export function InputGroupControl (props: InputGroupControlProps) {
  const {
    name,
    error:outerError,
    isLoading,
    label,
    className,
    type,
    before,
    emptyValue,
    after,
    explainer,
    customValidate, // eslint-disable-line no-unused-vars
    helpText,
    ...restOfProps
  } = props;

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  });

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  const error = outerError || meta.error;

  const id = useId();

  return (
    <Form.Group className={className}>
      <FormGroupLabel id={id} label={label} isLoading={isLoading} helpText={helpText} />
      <InputGroup hasValidation>
        {before}
        <Form.Control
          {...restOfProps}
          {...field}
          onBlur={onBlur}
          value={inputPropsToValue(props, field.value) as any}
          type={type}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

interface NumberFormattedInputGroupControlProps extends InputGroupControlProps {
  format: (str: string | number) => string;
}

export function NumberFormattedInputGroupControl (props: NumberFormattedInputGroupControlProps) {
  const {
    name,
    error:outerError,
    isLoading,
    label,
    className,
    type,
    before,
    emptyValue,
    after,
    explainer,
    customValidate, // eslint-disable-line no-unused-vars
    format,
    helpText,
    ...restOfProps
  } = props;

  const [field, meta] = useFieldExtended({
    name,
    type,
    validate: inputPropsToFormikValidate(props),
    emptyValue,
  });

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  const error = outerError || meta.error;

  const id = useId();

  return (
    <Form.Group className={className}>
      <FormGroupLabel id={id} label={label} isLoading={isLoading} helpText={helpText} />
      <InputGroup hasValidation>
        {before}
        <NumberFormattedInput
          {...restOfProps}
          {...field}
          format={format}
          Component={Form.Control}
          onBlur={onBlur}
          value={inputPropsToValue(props, field.value) as any}
          type={type}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
      </InputGroup>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

export interface FormGroupSelectProps extends FormSelectProps, React.PropsWithChildren {
  emptyValue?: any;
  error?: string;
  label?: React.ReactNode;
  className?: string;
  name: string;
  required?: boolean;
  isLoading?: boolean;
  explainer?: React.ReactNode;
  customValidate?: FieldValidator;
  helpText?: React.ReactNode;
}

export function FormGroupSelect (props: FormGroupSelectProps) {
  const {
    name,
    isLoading,
    error:outerError,
    children,
    className,
    emptyValue,
    label,
    explainer,
    customValidate, // eslint-disable-line no-unused-vars
    helpText,
    ...restOfProps
  } = props; // eslint-disable-line no-unused-vars

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  }) as any;

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  const error = outerError || meta.error;

  const id = useId();

  return (
    <Form.Group className={className}>
      <FormGroupLabel id={id} label={label} isLoading={isLoading} helpText={helpText} />
      <Form.Select
        {...restOfProps}
        {...field}
        onBlur={onBlur}
        id={id}
        name={name}
        isInvalid={Boolean(error)}
      >
        {children}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {error}
      </Form.Control.Feedback>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

export interface InputGroupSelectProps extends FormGroupSelectProps {
  before?: React.ReactNode;
  after?: React.ReactNode;
}

export function InputGroupSelect (props: InputGroupSelectProps) {
  const {
    name,
    error:outerError,
    label,
    before,
    after,
    emptyValue,
    className,
    explainer,
    customValidate, // eslint-disable-line no-unused-vars
    isLoading,
    helpText,
    ...restOfProps
  } = props;

  const [field, meta] = useFieldExtended({
    name,
    validate: selectPropsToFormikValidate(props),
    emptyValue,
  });

  const formikOnUpdate = useFormikOnUpdate();
  const onBlur = formikOnUpdate.wrapOnBlur(field, meta);

  // TODO check field.value type

  const error = meta.error || outerError;
  const id = useId();

  return (
    <Form.Group className={className}>
      <FormGroupLabel id={id} label={label} isLoading={isLoading} helpText={helpText} />
      <InputGroup hasValidation>
        {before}
        <Form.Select
          {...field}
          value={field.value as string}
          {...restOfProps}
          onBlur={onBlur}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {after}
        <Form.Control.Feedback type="invalid">
          {error}
        </Form.Control.Feedback>
        {explainer && <Form.Text>{explainer}</Form.Text>}
      </InputGroup>
    </Form.Group>
  );
}

export interface FormGroupCheckProps extends FormCheckProps {
  error?: string;
  name: string;
  value?: string;
  type?: FormCheckType;
  className?: string;
  isLoading?: boolean;
  explainer?: React.ReactNode;
  customValidate?: FieldValidator;
}

export function FormGroupCheck (props: FormGroupCheckProps) {
  const {
    error:outerError,
    name,
    label:outerLabel,
    type = 'checkbox',
    className,
    isLoading,
    explainer,
    customValidate, // eslint-disable-line no-unused-vars
    ...restOfProps
  } = props;

  const id = useId();

  const [field, meta] = useField({
    name,
    type,
    value: restOfProps.value,
    validate: checkPropsToFormikValidate(props),
  });

  const formikOnUpdate = useFormikOnUpdate();
  const onChange = formikOnUpdate.wrapOnChange(field, meta, ev => ev.target.checked);

  const error = outerError || meta.error;

  const label = (
    <div className={classNames('d-inline-flex gap-2 align-items-center', {'text-danger': Boolean(error)})}>
      {outerLabel}
      <BasicSpinner isLoading={isLoading} />
    </div>
  );

  return (
    <Form.Group className={className}>
      <Form.Check.Label className="align-items-center d-flex gap-2">
        <Form.Check.Input
          {...field}
          {...restOfProps}
          onChange={onChange}
          className="my-0"
          type={type as any}
          id={id}
          name={name}
          isInvalid={Boolean(error)}
        />
        {outerLabel && label}
      </Form.Check.Label>
      <Form.Control.Feedback type="invalid" className={classNames({'d-block': Boolean(error)})}>
        {error}
      </Form.Control.Feedback>
      {explainer && <Form.Text>{explainer}</Form.Text>}
    </Form.Group>
  );
}

interface StringListCheckProps extends FormCheckProps {
  value: string;
  fieldName: string;
}

export function StringListCheck (props: StringListCheckProps) {
  const { fieldName, value, ...restOfProps } = props;

  const [field,, helpers] = useField<string[]>({
    name: fieldName,
  });

  const formikOnUpdate = useFormikOnUpdate();

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    if (ev.target.checked) {
      const newValue = [...field.value, value];
      helpers.setValue(newValue);
      formikOnUpdate.update({[fieldName]: newValue});
    } else {
      const newValue = field.value.filter(v => v !== value);
      helpers.setValue(newValue);
      formikOnUpdate.update({[fieldName]: newValue});
    }
    helpers.setTouched(true);
  };

  const name = `${fieldName}.${value}`;
  const checked = Array.isArray(field.value) ? field.value.includes(value) : false;
  const id = useId();

  return (
    <Form.Check
      {...restOfProps}
      id={id}
      value={value}
      name={name}
      checked={checked}
      onChange={onChange}
    />
  );
}
