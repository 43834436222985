import React, {useState} from 'react';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { CustomerLogRowWithRelations } from 'shared/types/customer_log';
import * as customerLogFilters from 'shared/filter/customer_log';
import { Card } from 'react-bootstrap';
import * as TableCells from 'src/tables/TableCells';
import CardBodyTable from 'src/tables/CardBodyTable';
import { Activity } from 'lucide-react';
import { TableStateOrder  } from 'src/tables/types';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';

interface CustomerLogCardProps {
  className?: string;
  customerId: string;
}

export default function CustomerLogCard (props: CustomerLogCardProps) {
  const { customerId, className } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const query = useQuery<CustomerLogRowWithRelations[], Error>({
    queryKey: [`/customer/${customerId}/log`, {params: {order}}],
    placeholderData: keepPreviousData,
  });

  const rows = query.data ?? [];

  return (
    <Card className={className}>
      <Card.Header className="border-bottom-0">
        <CardTitleIcon
          title="Kundlogg"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        tableClassName="no-border-bottom"
        striped
        order={order}
        columnDefinitions={columnDefs}
        setOrder={setOrder}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.datetime({
    id: 'date',
    title: 'Datum',
    sortable: true,
  }),
  commonColumnDefs.userId({
    id: 'user_id',
    title: 'Utförare',
    sortable: true,
    cellProps: {objKey: 'User', value: 'System'},
  }),
  commonColumnDefs.basic({
    id: 'data.ip',
    title: 'IP',
    Cell: TableCells.IpWithCountryFlag,
    cellProps: {ipCountryKey: 'data.ip_country'},
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    Cell: props => <>{customerLogFilters.description(props.row)}</>,
  }),
  commonColumnDefs.inspect(),
];
