import React from 'react';
import classNames from 'classnames';
import {Table} from 'react-bootstrap';
import {ApplicationRow} from 'shared/types/application';
import DateTime from 'src/formatters/DateTime';
import ExternalLinkAnchor from 'src/anchors/ExternalLinkAnchor';

interface SanctionListTableProps {
  className?: string;
  sanctionList: NonNullable<ApplicationRow['sanction_list']>;
}

export function SanctionListTable (props: SanctionListTableProps) {
  const { className, sanctionList } = props;
  return (
    <Table size="sm" className={classNames(className, 'mb-0 align-middle')} style={{fontSize: '90%'}}>
      <tbody>
        <tr>
          <th>Namn</th>
          <td>
            {sanctionList.name}
          </td>
        </tr>
        <tr>
          <th>Utförd</th>
          <td>
            <DateTime value={sanctionList.date} />
          </td>
        </tr>
        {'match_id' in sanctionList && sanctionList.match_id && (
          <tr>
            <th>ID</th>
            <td>{sanctionList.match_id}</td>
          </tr>
        )}
        {'sanction_entity_remark' in sanctionList && (
          <tr>
            <th>Kommentar</th>
            <td>{sanctionList.sanction_entity_remark}</td>
          </tr>
        )}
        {'sanction_entity_publication_url' in sanctionList && (
          <tr>
            <th>Länk</th>
            <td>
              <ExternalLinkAnchor href={sanctionList.sanction_entity_publication_url}>
                {sanctionList.sanction_entity_publication_url}
              </ExternalLinkAnchor>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
}
