import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';
import { Paperclip } from 'lucide-react';
import useNotyf from 'src/hooks/useNotyf';

interface CopyToClipboardButtonProps extends ButtonProps {
  copy: string | (() => string);
  onCopyMessage?: string;
}

export default function CopyToClipboardButton (props: CopyToClipboardButtonProps) {
  const { copy, size, onClick:outerOnClick, onCopyMessage, children, ...restOfProps } = props;
  const notyf = useNotyf();

  const onClick = ev => {
    const str = typeof copy === 'function' ? copy() : copy;
    notyf.success({type: 'default', message: onCopyMessage ?? 'Text kopierad'});
    navigator.clipboard.writeText(str);
    outerOnClick?.(ev);
  };

  // TODO handle lg size button
  const iconSize = size === 'sm' ? 14 : 18;

  return (
    <Button {...restOfProps} size={size} onClick={onClick}>
      {children ? children : (
        <Paperclip size={iconSize} />
      )}
    </Button>
  );
}
