/* eslint no-unused-vars: 0 */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import Content from 'src/ui/Content';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import { RefreshObjectButton } from 'src/buttons/IconButtons';
import Pluralizer from 'src/formatters/Pluralizer';
import * as api from 'src/api';
import { Link } from 'react-router-dom';
import * as commonFilterDefs from 'src/tables/commonFilterDefinitions';
import { DollarSign } from 'lucide-react';
import { Container, Card } from 'react-bootstrap';
import { useQuery, keepPreviousData } from '@tanstack/react-query';
import { DisbursedStatisticRow } from 'shared/types/statistic_disbursed';
import { TableColumnDefinition } from 'src/tables/Table';
import { getLinkToFilteredTable } from 'src/tables/helpers';
import { TableStateOrder, TableHeaderGroups, TableStateFilterMap } from 'src/tables/types';
import * as TableCells from 'src/tables/TableCells';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import { ErrorAlertCardBody, CardTitleIcon  } from 'src/cards/CardHelpers';
import statisticDisbursedFilter from 'shared/filter/statistic_disbursed';
import moment from 'moment';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { sortBy } from 'lodash';
import * as SetFilterButtons from 'src/buttons/SetFilterButton';

interface StatisticsDisbursedFilterProps extends TableStateFilterMap {
  month?: {
    from?: string;
    to?: string;
  };
  portal_is_from?: boolean;
  portal_tracking_code_adtraction?: boolean | null;
  portal_tracking_code_adservice?: boolean | null;
  portal_tracking_code_gclid?: boolean | null;
  portal_tracking_code_ga_id?: boolean | null;
}

export default function StatisticsDisbursedPage () {
  const [filter, setFilter] = useLocalStorage<TableStateFilterMap>('StatisticsDisbursedPageFilter', defaultFilter);
  const [order, setOrder] = useLocalStorage<TableStateOrder>('StatisticsDisbursedPageOrder', {
    bank_id: 'asc',
  });

  const query = useQuery<DisbursedStatisticRow, Error>({
    queryKey: ['StatisticsDisbursedPage', filter],
    placeholderData: keepPreviousData,
    queryFn: props => {
      const params = {...(props.queryKey[1] as StatisticsDisbursedFilterProps)};
      return api.request({
        method: 'GET',
        url:  '/statistic/disbursed',
        params: {...params},
      });
    },
  });

  const rows: any[] = React.useMemo(() => {
    const byBankId = query.data?.by_bank_id ?? {};
    const keys = Object.keys(byBankId);
    const values = keys.map(id => byBankId[id].total);
    const orderKey = Object.keys(order)[0];
    const orderDir = order[orderKey] || 'asc';
    const sorted = sortBy(values, obj => {
      return obj[orderKey];
    });
    if (orderDir === 'desc') sorted.reverse();
    return sorted;
  }, [query.data, order]);

  const footerRows: any[] = React.useMemo(() => {
    const total = query.data?.total;
    return total ? [total] : [];
  }, [query.data]);

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Utbetalningsstatistik" />
        <Container fluid className="p-0">
          <Card className="mb-1">
            <Card.Header className="pb-2">
              <CardTitleIcon
                title="Utbetalningsstatistik"
                Icon={<DollarSign size={16} />}
                spinning={query.isLoading || query.isRefetching}
              >
                <RefreshObjectButton
                  disabled={query.isRefetching}
                  onClick={() => query.refetch()}
                />
              </CardTitleIcon>
              <Card.Subtitle className="mt-1 small d-flex align-items-baseline">
                <Pluralizer
                  count={rows.length ?? 0}
                  zero="Inga rader"
                  one="Visar 1 rad"
                  otherwise="Visar %% rader"
                />
                <SetFilterButtons.ThisYear filter={filter} setFilter={setFilter} filterDateKey="month" format="YYYY-MM"/>
                <SetFilterButtons.LastYear filter={filter} setFilter={setFilter} filterDateKey="month" format="YYYY-MM"/>
                <SetFilterButtons.ThisQuarter filter={filter} setFilter={setFilter} filterDateKey="month" format="YYYY-MM"/>
                <SetFilterButtons.LastQuarter filter={filter} setFilter={setFilter} filterDateKey="month" format="YYYY-MM"/>
                <SetFilterButtons.ThisMonth filter={filter} setFilter={setFilter} filterDateKey="month" format="YYYY-MM"/>
                <SetFilterButtons.LastMonth filter={filter} setFilter={setFilter} filterDateKey="month" format="YYYY-MM"/>
              </Card.Subtitle>
            </Card.Header>
            <ErrorAlertCardBody error={query.error} className="border-top p-3" />
            <CardBodyTable
              size="sm"
              filter={filter}
              order={order}
              filterDefinitions={filterDefinitions}
              columnDefinitions={columnDefinitions}
              headerGroups={headerGroups}
              setFilter={setFilter}
              setOrder={setOrder}
              rows={rows}
              footerRows={footerRows}
              isFetched={query.isFetched}
              params={{filter}}
            />
          </Card>
        </Container>
      </Content>
    </Main>
  );
}

const defaultFilter = {
  month: {
    from: moment().startOf('month').format('YYYY-MM'),
    to: moment().endOf('month').format('YYYY-MM'),
  },
  portal_tracking_code_adtraction: null,
  portal_tracking_code_adservice: null,
  portal_tracking_code_gclid: null,
  portal_tracking_code_ga_id: null,
};

const filterDefinitions: any[] = [
  commonFilterDefs.dateRange({
    id: 'month',
    filterProps: {type: 'month'},
  }),

  commonFilterDefs.groupId({
    id: 'created_by_user_groups',
  }),

  commonFilterDefs.boolean({id: 'portal_is_from'}),
  commonFilterDefs.boolean({id: 'portal_tracking_code_adtraction_set'}),
  commonFilterDefs.boolean({id: 'portal_tracking_code_adservice_set'}),
  commonFilterDefs.boolean({id: 'portal_tracking_code_gclid_set'}),
  // commonFilterDefs.boolean({id: 'portal_tracking_code_ga_id_set'}),
].map(def => ({
  ...def,
  title: statisticDisbursedFilter.columnName(def.id),
}));

const columnDefinitions: TableColumnDefinition[] = [
  commonColumnDefs.basic({
    id: 'bank_id',
    title: 'Långivare',
    Cell: MaybeBankTableCell,
    cellProps: {idKey: 'bank_id'},
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'count',
    title: 'Totalt',
    Cell: CountLinkedCell,
    cellProps: {rowFilter: []},
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'count_has_co_applicant',
    title: 'Medsökande',
    Cell: TableCells.Count,
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'count_is_new_customer',
    title: 'Nykunder',
    Cell: TableCells.Count,
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'count_flag_loan_only_new',
    title: 'Bara nylån',
    Cell: TableCells.Count,
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'sum_disbursed',
    title: 'Utbetalt',
    Cell: TableCells.Currency,
    sortable: true,
  }),

  commonColumnDefs.basic({
    id: 'sum_commission_base',
    title: 'Grundersättning',
    Cell: TableCells.Currency,
    sortable: true,
  }),
];

const headerGroups: TableHeaderGroups = [
  {
    id: 'bank_id',
    title: '',
    columns: ['bank_id'],
  },
  {
    id: 'applications',
    title: 'Utbetalda ansökningar',
    columns: [
      'count',
      'count_has_co_applicant',
      'count_is_new_customer',
      'count_flag_loan_only_new',
    ],
  },
  {
    id: 'sum',
    title: 'Summor',
    columns: [
      'sum_disbursed',
      'sum_commission_base',
    ],
  },
];

function MaybeBankTableCell (props) {
  const { row } = props;
  if (row.id !== 'sum') {
    return <TableCells.BankTableCell {...props} />;
  } 
  return (
    <strong>Summa</strong>
  );
}

function CountLinkedCell (props) {
  const { value, row, params, rowFilter } = props;
  const { filter } = params;
  const finalRowFilter = [
    {key: 'status', value: 'disbursed'},
    ...rowFilter,
    row.id === 'sum' ? null : {key: 'bank_id', value: row.bank_id},
  ].filter(v => v);
  const to = getLinkToFilteredTable('/bank_processes', filter, finalRowFilter);
  return (
    <Link to={to}>
      {value} st
    </Link>
  );
}
