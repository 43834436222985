import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import EmailModalBody from 'src/email/EmailModalBody';
import { Name } from 'src/email/EmailFormatters';
import {EmailRow} from 'shared/types/email';

interface EmailAnchorProps extends Partial<ModalAnchorProps> {
  email: Pick<EmailRow, 'id'>;
}

export default function EmailAnchor (props: EmailAnchorProps) {
  const { email, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={email} />}
      name="Redigera E-post"
    >
      <EmailModalBody id={email.id} />
    </ModalAnchor>
  );
}
