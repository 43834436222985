import React from 'react';
import {useMutation} from '@tanstack/react-query';
import { BankProcessRow } from 'shared/types/bank_process';
import {Card} from 'react-bootstrap';
import {FastForward} from 'lucide-react';
import { CardTitleIcon } from 'src/cards/CardHelpers';
import BankProcessDecisionForm, { FormValues, toFormValues, formValuesToUpdate } from 'src/bankProcess/BankProcessDecisionForm';
import * as formUtils from 'src/utils/form';

interface BankProcessCardDecisionTabCardProps {
  bankProcess: BankProcessRow;
}

export default function BankProcessCardDecisionTabCard (props: BankProcessCardDecisionTabCardProps) {
  const { bankProcess } = props;

  const submitMutation = useMutation<any, any, any>({
    mutationFn: async () => {
      throw new Error('asdf');
    },
  });

  const formCycleHelpers = formUtils.getFormikFormCycleHelpers<BankProcessRow, FormValues, Partial<BankProcessRow>>({
    queryDataToFormValues: toFormValues,
    formValuesToMutationVars: values => formValuesToUpdate(initialValues as any, values),
    mutateAsync: submitMutation.mutateAsync,
  });

  const initialValues = toFormValues(bankProcess);

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Beslut"
          Icon={<FastForward size={18} />}
        >
        </CardTitleIcon>
      </Card.Header>
      <BankProcessDecisionForm
        initialValues={initialValues}
        onSubmit={formCycleHelpers.onSubmit}
        submitError={submitMutation.error}
      />
    </Card>
  );
}
