import React, {useState} from 'react';
import { CardTitleIcon, ErrorAlertCardBody } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import { useQuery } from '@tanstack/react-query';
import { BankLogRowWithRelations } from 'shared/types/bank_log';
import * as bankLogFilters from 'shared/filter/bank_log';
import { Card } from 'react-bootstrap';
import { Activity } from 'lucide-react';
import * as commonColumnDefs from 'src/tables/commonColumnDefinitions';
import CardBodyTable from 'src/tables/CardBodyTable';
import {TableStateOrder} from 'src/tables/types';

interface BankLogCardProps {
  className?: string;
  bankId: string;
}

export default function BankLogCard (props: BankLogCardProps) {
  const { bankId, className } = props;

  const [order, setOrder] = useState<TableStateOrder>({date: 'desc'});

  const query = useQuery<BankLogRowWithRelations[], Error>({
    queryKey: [`/bank/${bankId}/log`, {params: {order}}],
  });

  const rows = query.data ?? [];

  return (
    <Card className={className}>
      <Card.Header>
        <CardTitleIcon
          title="Långivarlogg"
          Icon={<Activity size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <CardBodyTable
        striped
        order={order}
        columnDefinitions={columnDefs}
        setOrder={setOrder}
        rows={rows}
        isFetched={query.isFetched}
      />
    </Card>
  );
}

const columnDefs = [
  commonColumnDefs.datetime({
    id: 'date',
    title: 'Datum',
    sortable: true,
  }),
  commonColumnDefs.userId({
    id: 'user_id',
    title: 'Utförare',
    sortable: true,
    cellProps: {objKey: 'User', value: 'System'},
  }),
  commonColumnDefs.basic({
    id: 'description',
    title: 'Beskrivning',
    Cell: props => <>{bankLogFilters.description(props.row)}</>,
  }),
  commonColumnDefs.inspect(),
];
