import React from 'react';
import ErrorAlert from 'src/alerts/ErrorAlert';
import {TemplateType} from 'shared/types/template';
import EmailTemplateModalBody from 'src/emailTemplate/EmailTemplateModalBody';
import SmsTemplateModalBody from 'src/smsTemplate/SmsTemplateModalBody';
import LetterTemplateModalBody from 'src/letterTemplate/LetterTemplateModalBody';

interface TemplateModalBodyProps {
  id: string;
  type: TemplateType;
}

export default function TemplateModalBody (props: TemplateModalBodyProps) {
  const { id, type } = props;

  if (type === 'email') return <EmailTemplateModalBody id={id} />;
  if (type === 'letter') return <LetterTemplateModalBody id={id} />;
  if (type === 'sms') return <SmsTemplateModalBody id={id} />;
  return (
    <ErrorAlert className="m-3" error={`Det finns ingen mall av typen "${type}".`} />
  );
}

