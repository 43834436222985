/**
 * WARNING: This file is used clientside
 */

const customerJaycomInsurance = require('shared/filter/customer_jaycom_insurance');
const customerConsent = require('shared/filter/customer_consent');
const { errorLogDiagnose } = require('shared/filter/misc');

exports.description = function (log) {
  const data = log.data || {};
  const { error, result } = data;
  if (result === 'error') {
    return errorLogDiagnose(data);
  }

  switch (data.type) {
    default: return JSON.stringify(data);
    case 'imported': return 'Importerad från Cloud';

    case 'consent_created': {
      const { consent_method:method = '?', consent_subject:subject = '?', consent_id:id } = data;
      return `Samtycke "${subject}" via ${customerConsent.method(method)} lämnat. ID "${id}"`;
    }

    case 'adtraction_callback':
      return 'Återkoppling till Adtraction';

    case 'submitAutogiroResult': {
      if (error) {
        return ['Fel i uppdateringen av autogirouppgifter', JSON.stringify(error)].join('\n');
      }
      return 'Autogirouppgifter uppdaterade';
    }

    case 'submitAnslutResult': {
      if (error) {
        return ['Fel vid aktivering av försäkring', JSON.stringify(error)].join('\n');
      }
      return 'Aktivering av försäkringen lyckades';
    }

    case 'status_changed': return 'Status ändrad till "' + customerJaycomInsurance.status(data.to_status) + '"';
    case 'created': return 'Försäkring skapad';
  }
};
