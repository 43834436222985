import React from 'react';
import * as api from 'src/api';
import Main from 'src/ui/Main';
import Navbar from 'src/navbar/Navbar';
import Content from 'src/ui/Content';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { BankRow } from 'shared/types/bank';
import { Card } from 'react-bootstrap';
import Breadcrumbs from 'src/components/Breadcrumbs';
import BankCreateForm, { BankCreateFormValues } from 'src/bank/BankCreateForm';
import { FormikErrors, FormikHelpers } from 'formik';
import { maybeAxiosErrorToErrorMap } from 'src/utils/error';
import { AxiosError } from 'axios';
import useNotyf from 'src/hooks/useNotyf';

const BankCreatePage: React.FC = React.memo(function BankCreatePage () {
  const notyf = useNotyf();
  const navigate = useNavigate();

  const createMutation = useMutation<BankRow, Error, BankCreateFormValues>({
    mutationFn: vars => api.request({
      url: '/bank',
      method: 'POST',
      data: vars,
    }),
    onSuccess: bank => {
      notyf.success({type: 'success', message: 'Långivaren skapades'});
      navigate(`/bank/${bank.id}/form`);
    },
  });

  const onSubmitCreate = async (values: BankCreateFormValues, helpers: FormikHelpers<BankCreateFormValues>) => {
    try {
      await createMutation.mutateAsync(values);
    } catch (err) {
      helpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err as AxiosError);
      if (errorMap) {
        helpers.setErrors(errorMap as FormikErrors<BankCreateFormValues>);
        return;
      }
      throw err;
    }
  };

  return (
    <Main>
      <Navbar />
      <Content>
        <Helmet title="Långivare" />
        <h1>Ny långivare</h1>
        <Breadcrumbs
          crumbs={[
            ['/banks', 'Långivare'],
            ['/bank/new', 'Ny långivare'],
          ]}
        />
        <Card>
          <BankCreateForm
            initialValues={{id: ''}}
            onSubmit={onSubmitCreate}
            submitError={createMutation.error}
          />
        </Card>
      </Content>
    </Main>
  );
});
export default BankCreatePage;
