import React from 'react';
import ModalAnchor, {ModalAnchorProps} from 'src/anchors/ModalAnchor';
import TaskModalBody from 'src/task/TaskModalBody';
import { Name } from 'src/task/TaskFormatters';
import {TaskRow} from 'shared/types/task';

interface TaskAnchorProps extends Partial<ModalAnchorProps> {
  task: Pick<TaskRow, 'id'>;
}

export default function TaskAnchor (props: TaskAnchorProps) {
  const { task, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={task} />}
      name="Redigera Återkomst"
    >
      <TaskModalBody id={task.id} />
    </ModalAnchor>
  );
}

