import React from 'react';
import ModalAnchor from 'src/anchors/ModalAnchor';
import Anchor from  'src/anchors/Anchor';
import { Name } from 'src/smsTemplate/SmsTemplateFormatters';
import { SmsTemplateRow } from 'shared/types/sms_template';
import SmsTemplateModalBody from 'src/smsTemplate/SmsTemplateModalBody';

interface SmsTemplateAnchorProps {
  smsTemplate: Pick<SmsTemplateRow, 'id' | 'name'>;
}

export default function SmsTemplateAnchor (props: SmsTemplateAnchorProps) {
  const { smsTemplate, ...restOfProps } = props;
  return (
    <ModalAnchor
      {...restOfProps}
      title={<Name value={smsTemplate} />}
      name="Redigera SMS-mallen"
      subTitle={smsTemplate.id}
      size="xl"
      backdrop="static"
    >
      <SmsTemplateModalBody id={smsTemplate.id} />
    </ModalAnchor>
  );
}

export function SmsTemplatePlainAnchor (props: SmsTemplateAnchorProps) {
  const { smsTemplate, ...restOfProps } = props;
  return (
    <Anchor
      {...restOfProps}
      url={`/sms_template/${smsTemplate.id}`}
      name="SMS-mallen"
    >
      <Name value={smsTemplate} />
    </Anchor>
  );
}
