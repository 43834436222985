import React, {useMemo, useState} from 'react';
import * as api from 'src/api';
import { Button, Card, Form, InputGroup, Table } from 'react-bootstrap';
import { Check, Lock, Package, X } from 'lucide-react';
import { CardTitleIcon, ErrorAlertCardBody  } from 'src/cards/CardHelpers';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import {useMutation, useQuery} from '@tanstack/react-query';
import useNotyf from 'src/hooks/useNotyf';
import { UserRow } from 'shared/types/user';
import IdProvider from 'src/components/IdProvider';
import {preventDefaultSubmit} from 'src/misc';
import ButtonPromiseSpinner from 'src/spinners/ButtonPromiseSpinner';

interface UserPermissionsUpdateRequestBody {
  op: 'add' | 'del';
  permission: string;
  listKey: 'permissions_grant' | 'permissions_revoke';
}

interface UserPermissionsResponseBody {
  allPermissions: string[];
  defaultPermissions: string[];
  grantedPermissions: string[];
  revokedPermissions: string[];
}

interface EditPermissionsFilter {
  search: string;
  all: boolean;
  default: boolean;
  grant: boolean;
  revoke: boolean;
}

interface UserPermission {
  name: string;
  isDefault: boolean;
  isGranted: boolean;
  isRevoked: boolean;
}

interface UserEditPermissionsCardProps {
  className?: string;
  userId: string;
}

export default function UserEditPermissionsCard (props: UserEditPermissionsCardProps) {
  const { className, userId } = props;

  const notyf = useNotyf();

  const query = useQuery<UserPermissionsResponseBody>({
    queryKey: [`/user/${userId}/permissions`],
  });

  const [filter, setFilter] = useState<EditPermissionsFilter>({...defaultFilter});

  const updateMutation = useMutation<UserRow, Error, UserPermissionsUpdateRequestBody>({
    mutationFn: async data => {
      const { permission, op, listKey } = data;

      let list = [];
      if (listKey === 'permissions_grant') list = grantedPermissions;
      if (listKey === 'permissions_revoke') list = revokedPermissions;

      const update = {
        [listKey]: op === 'add' ? [...list, permission] : list.filter(value => value !== permission),
      };

      const user = await api.request({
        errorToNotyf: notyf,
        method: 'PATCH',
        url: `/user/${userId}`,
        data: update,
      });

      await query.refetch({throwOnError: false, cancelRefetch: false});
      return user;
    },
  });

  const {
    defaultPermissions = [],
    grantedPermissions = [],
    revokedPermissions = [],
    allPermissions = [],
  } = query.data ?? {};

  const permissions: UserPermission[] = useMemo(() => {
    if (!query.data) return [];
    const regexp = filter.search ? new RegExp(filter.search, 'i') : null;

    const permissions = allPermissions.filter(permission => {
      if (filter.all) return true;
      if (filter.default && defaultPermissions.includes(permission)) return true;
      if (filter.grant && grantedPermissions.includes(permission)) return true;
      if (filter.revoke && revokedPermissions.includes(permission)) return true;
      return false;
    }).filter(permission => {
      if (!filter.search) return true;
      return regexp.test(permission);
    });

    return permissions.map(permission => {
      return {
        name: permission,
        isDefault: defaultPermissions.includes(permission),
        isGranted: grantedPermissions.includes(permission),
        isRevoked: revokedPermissions.includes(permission),
      };
    });
  }, [filter, defaultPermissions, grantedPermissions, revokedPermissions, allPermissions, query.data]);

  const onClickToggleRevoke = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    const permission = ev.currentTarget.dataset['permission'];
    const op = revokedPermissions.includes(permission) ? 'del' : 'add';
    await updateMutation.mutateAsync({
      permission,
      listKey: 'permissions_revoke',
      op,
    });
  };

  const onClickToggleGrant = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    const permission = ev.currentTarget.dataset['permission'];
    const op = grantedPermissions.includes(permission) ? 'del' : 'add';
    await updateMutation.mutateAsync({
      permission,
      listKey: 'permissions_grant',
      op,
    });
  };

  return (
    <Card className={className}>
      <Card.Header>
        <CardTitleIcon
          title="Behörigheter"
          Icon={<Lock size={18} />}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardTitleIcon>
      </Card.Header>
      <ErrorAlertCardBody error={query.error} />
      <Card.Body className="border-bottom p-2">
        <FilterForm filter={filter} setFilter={setFilter} />
      </Card.Body>
      {query.data && (
        <div className="position-relative">
          <Table striped className="mb-0">
            <thead>
              <tr>
                <th>Behörighetsnamn</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {permissions.length > 0 ? (
                <>
                  {permissions.map(item => (
                    <tr key={item.name}>
                      <th className="align-middle">
                        <code style={{fontSize: '100%'}}>{item.name}</code>
                      </th>
                      <td>
                        <div className="d-flex gap-1 flex-wrap justify-content-end">
                          {item.isDefault && (
                            <Button
                              disabled
                              variant="info"
                              className="p-1"
                              size="sm"
                              title="Grundbehörighet"
                            >
                              <Package size={18} />
                            </Button>
                          )}
                          <ButtonPromiseSpinner
                            data-permission={item.name}
                            disabled={!item.isGranted && item.isDefault || updateMutation.isPending}
                            variant={item.isGranted ? 'success' : 'outline-info'}
                            className="p-1"
                            size="sm"
                            title="Tilläggsbehörighet"
                            onClick={onClickToggleGrant}
                          >
                            <Check size={18} />
                          </ButtonPromiseSpinner>
                          <ButtonPromiseSpinner
                            data-permission={item.name}
                            disabled={!item.isRevoked && !item.isDefault || updateMutation.isPending}
                            variant={item.isRevoked ? 'danger' : 'outline-info'}
                            className="p-1"
                            size="sm"
                            title="Avdragsbehörighet"
                            onClick={onClickToggleRevoke}
                          >
                            <X size={18} />
                          </ButtonPromiseSpinner>
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>
                  <td className="text-muted" colSpan={2}>Det finns ingenting här</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      )}
    </Card>
  );
}

interface FilterCardBodyProps {
  filter: EditPermissionsFilter;
  setFilter: React.Dispatch<React.SetStateAction<EditPermissionsFilter>>;
}

function FilterForm (props: FilterCardBodyProps) {
  const { filter, setFilter } = props;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const { name, value } = ev.target;
    setFilter(prevFilter => ({...prevFilter, [name]: value}));
  };

  const onChangeCheckbox: React.ChangeEventHandler<HTMLInputElement> = ev => {
    const { name, checked } = ev.target;
    setFilter(prevFilter => ({...prevFilter, [name]: checked}));
  };

  const onClickResetSearch = () => {
    setFilter(prevFilter => ({...prevFilter, search: ''}));
  };

  return (
    <Form onSubmit={preventDefaultSubmit} className="d-flex gap-2 align-items-center flex-wrap">
      <Form.Group>
        <InputGroup>

          <Form.Control
            htmlSize={30}
            name="search"
            value={filter.search}
            onChange={onChange}
            placeholder="Sök"
          />
          {filter.search && (
            <Button variant="outline-primary" onClick={onClickResetSearch} disabled={!filter.search} className="px-1">
              <X size={19} />
            </Button>
          )}
        </InputGroup>
      </Form.Group>
      <div className="border rounded d-flex gap-1 align-items-center flex-wrap py-1 px-2">
        <Form.Group>
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                id={id}
                label="Alla behörigheter"
                inline
                name="all"
                checked={filter.all}
                onChange={onChangeCheckbox}
              />
            )}
          </IdProvider>
        </Form.Group>
        <Form.Group>
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                id={id}
                label="Grundbehörigheter"
                inline
                name="default"
                checked={filter.default}
                onChange={onChangeCheckbox}
              />
            )}
          </IdProvider>
        </Form.Group>
        <Form.Group>
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                id={id}
                label="Tilläggsbehörigheter"
                inline
                name="grant"
                checked={filter.grant}
                onChange={onChangeCheckbox}
              />
            )}
          </IdProvider>
        </Form.Group>
        <Form.Group>
          <IdProvider>
            {id => (
              <Form.Check
                className="mb-0"
                id={id}
                label="Avdragsbehörigheter"
                inline
                name="revoke"
                checked={filter.revoke}
                onChange={onChangeCheckbox}
              />
            )}
          </IdProvider>
        </Form.Group>
      </div>
    </Form>
  );
}

const defaultFilter = {
  search: '',
  all: false,
  default: true,
  grant: true,
  revoke: true,
};
