/* eslint-disable */
import {FieldValidator} from 'formik';
import * as api from 'src/api';
import * as validate from 'shared/validate';

interface ValidatePersonalNumberOptions {
  format?: 'long' | 'short' | 'long-dash' | 'short-dash';
}

export function validatePersonalNumber (options: ValidatePersonalNumberOptions = {}): FieldValidator {
  const { format } = options;
  return function (value: string) {
    if (!value) return '';
    switch (format) {
      default:
      case 'long':
        if (value.length !== 12) return 'Ange ett personnummer med 12 siffror';
        break;
      case 'long-dash':
        if (value.length !== 13) return 'Ange ett personnummer med 12 siffror och bindestreck';
        break;
      case 'short-dash':
        if (value.length !== 11) return 'Ange ett personnummer med 10 siffror och bindestreck';
        break;
      case 'short':
        if (value.length !== 10) return 'Ange ett personnummer med 10 siffror';
        break;
    }
    if (!validate.ssn(value)) return 'Ange ett giltigt personnummer';
    return '';
  };
}

interface ValidateEmailRemoteResult {
  ok: boolean;
  error?: string;
}

export function validateEmail (): FieldValidator {
  return async function (email: string) {
    return '';
    // try {
    //   const result = await api.request<ValidateEmailRemoteResult>({
    //     url: '/misc/emailValidate',
    //     method: 'POST',
    //     data: {email},
    //   });
    //   if (result.ok) {
    //     return '';
    //   }
    //   return `Ogiltig e-postadress: ${result.error}`;
    // } catch (err: any) {
    //   return '';
    // }
  };
}
