import React, {useState} from 'react';
import {ApplicationRowWithRelations} from 'shared/types/application';
import { CardTitleIcon  } from 'src/cards/CardHelpers';
import { Button, Card } from 'react-bootstrap';
import {Key, Users} from 'lucide-react';
import {ListGroup, ListGroupProps} from 'react-bootstrap';
import {PhoneOutgoing, Trash2, UserPlus, Wind} from 'lucide-react';
import UserAnchor from 'src/user/UserAnchor';
import CustomerAnchor from 'src/customer/CustomerAnchor';
import useAuth from 'src/hooks/useAuth';
import ModalOpeningButton from 'src/buttons/ModalOpeningButton';
import UserPickModal from 'src/user/UserPickModal';
import ConfirmActionModalButton from 'src/buttons/ConfirmActionModalButton';
import * as api from 'src/api';
import {useMutation} from '@tanstack/react-query';
import classNames from 'classnames';
import GroupAnchor from 'src/group/GroupAnchor';
import * as applicationFilters from 'shared/filter/application';

interface ApplicationRoleCardProps {
  application: ApplicationRowWithRelations;
}

export default function ApplicationRoleCard (props: ApplicationRoleCardProps) {
  const { application } = props;

  return (
    <Card>
      <Card.Header>
        <CardTitleIcon
          title="Intressenter"
          Icon={<Users size={18} />}
        >
        </CardTitleIcon>
      </Card.Header>
      <Card.Body className="p-0">
        <ApplicationRoleCardInnerListGroup application={application} editable />
      </Card.Body>
    </Card>
  );
}


interface ApplicationRoleCardInnerListGroupProps extends ListGroupProps {
  application: ApplicationRowWithRelations;
  editable?: boolean;
}

export function ApplicationRoleCardInnerListGroup (props: ApplicationRoleCardInnerListGroupProps) {
  const { application, editable, ...restOfProps } = props;
  const auth = useAuth();

  const [showMoreInfo, setShowMoreInfo] = useState<boolean>(false);

  const updateAdvisorMutation = useMutation<ApplicationRowWithRelations, Error, string | null>({
    mutationFn: async roleAdvisor => {
      const result = await api.request({
        url: `/application/${application.id}`,
        method: 'PATCH',
        data: {role_advisor: roleAdvisor},
      });
      return result;
    },
  });

  const onClickShowMoreInfo = () => {
    setShowMoreInfo(showMoreInfo => !showMoreInfo);
  };

  const onPickAdvisor = async (userId: string) => {
    await updateAdvisorMutation.mutateAsync(userId);
  };

  const onConfirmRemoveAdvisor = async () => {
    await updateAdvisorMutation.mutateAsync(null);
  };

  const onConfirmSetAdvisorToSelf = async () => {
    await updateAdvisorMutation.mutateAsync(auth.userId);
  };

  return (
    <ListGroup variant="flush" className="overflow-hidden" {...restOfProps}>
      {application.portal_is_from && (
        <ListGroupItemIcon Icon={Wind}>
          <strong>Portalansökan</strong>
          <CustomerAnchor customer={application.Customer ?? {id: application.customer_id}} />
        </ListGroupItemIcon>
      )}

      {application.created_by_user_id && (
        <ListGroupItemIcon Icon={UserPlus}>
          <div className="d-flex flex-column justify-content-center">
            <strong>Skapare</strong>
            <UserAnchor user={application.Creator ?? {id: application.created_by_user_id}} />
          </div>
          <div className="d-flex gap-1 flex-wrap">
            <Button variant="outline-info" size="sm" onClick={onClickShowMoreInfo}>
              {showMoreInfo ? <span>Dölj</span> : <span>Mer</span>}
            </Button>
          </div>
        </ListGroupItemIcon>
      )}

      {showMoreInfo && (
        <ListGroupItemIcon Icon={Key}>
          <div>
            <strong>Skaparens roll</strong>
            <div>{applicationFilters.createdByRole(application.created_by_role)}</div>
          </div>
        </ListGroupItemIcon>
      )}

      {showMoreInfo && (
        <ListGroupItemIcon Icon={Users}>
          <div>
            <strong>Skaparens grupper</strong>
            <div>
              {application.created_by_user_groups?.length > 0 ? (
                <>
                  {application.created_by_user_groups.map(groupId => (
                    <GroupAnchor key={groupId} group={{id: groupId}} />
                  ))}
                </>
              ) : (
                <span className="text-muted">Inga gruppmedlemskap</span>
              )}
            </div>
          </div>
        </ListGroupItemIcon>
      )}

      <ListGroupItemIcon Icon={PhoneOutgoing}>
        <div>
          <strong>Rådgivare</strong>
          {auth.permission('application:see_advisor') ? (
            <div>
              {application.role_advisor ? (
                <UserAnchor user={application.Advisor ?? {id: application.role_advisor}} style={{marginBottom: '-1px'}} />
              ) : (
                <span className="text-muted">(Ingen)</span>
              )}
            </div>
          ) : (
            <div>
              {application.role_advisor ? (
                <span className="text-muted">En användare</span>
              ) : (
                <span className="text-muted">(Ingen)</span>
              )}
            </div>
          )}
        </div>
        {editable && auth.permission('application_update:roles') && (
          <div className="d-flex gap-1 flex-wrap">
            {(!application.role_advisor || auth.userId !== application.role_advisor) && (
              <ConfirmActionModalButton
                message="Är du säker på att du vill ange dig själv som rådgivare på ansökan?"
                onConfirm={onConfirmSetAdvisorToSelf}
                variant="outline-primary"
                size="sm"
              >
                Ta över själv
              </ConfirmActionModalButton>
            )}
            <ModalOpeningButton
              Modal={props => <UserPickModal onPick={onPickAdvisor} {...props} />}
              className={classNames('px-2 d-inline-flex gap-1 align-items-center', {'d-none': application.role_advisor})}
              title="Välj användare"
              variant="outline-primary"
              size="sm"
            >
              Välj
            </ModalOpeningButton>
            {application.role_advisor && (
              <ConfirmActionModalButton
                onConfirm={onConfirmRemoveAdvisor}
                message="Är du säker på att du vill ta bort rådgivaren från ansökan?"
                variant="outline-danger"
                size="sm"
              >
                <Trash2 size={16} />
              </ConfirmActionModalButton>
            )}
          </div>
        )}
      </ListGroupItemIcon>
    </ListGroup>
  );
}

interface ListGroupItemIconProps extends React.PropsWithChildren {
  Icon: React.ComponentType<{size: string | number}>;
}

function ListGroupItemIcon (props: ListGroupItemIconProps) {
  const { Icon, children } = props;
  return (
    <ListGroup.Item className="d-flex p-0 align-items-center">
      <div className="px-3 py-2">
        <Icon size={30} />
      </div>
      <div className="py-2 d-flex align-items-center justify-content-between flex-grow-1 pe-3 flex-wrap">
        {children}
      </div>
    </ListGroup.Item>
  );
}
