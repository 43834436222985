import React from 'react';
import * as customerTryggsamLeadFilter from 'shared/filter/customer_tryggsam_lead';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import { CustomerTryggsamLeadRow } from 'shared/types/customer_tryggsam_lead';

interface StatusProps {
  value: CustomerTryggsamLeadRow['status'];
}

export function Status (props: StatusProps) {
  const { value } = props;
  return customerTryggsamLeadFilter.status(value);
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: CustomerTryggsamLeadRow['status'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={customerTryggsamLeadFilter.status}
      {...restOfProps}
    />
  );
}
