import React from 'react';
import isObjectLike from 'lodash/isObjectLike';
import StatusBaseLabel, {StatusBaseLabelProps} from 'src/formatters/StatusBaseLabel';
import * as groupFilter from 'shared/filter/group';
import { GroupRow } from 'shared/types/group';

interface NameProps {
  value: {id: GroupRow['id'], name?: GroupRow['name']} ;
}

export function Name (props: NameProps) {
  const { value:group } = props;
  if (!isObjectLike(group)) return null;
  if (group.name) return <>{group.name}</>;
  return (
    <>{group.id}</>
  );
}

interface StatusLabelProps extends StatusBaseLabelProps {
  value: GroupRow['is_active'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return (
    <StatusBaseLabel
      value={value}
      formatter={groupFilter.status}
      {...restOfProps}
    />
  );
}
