import React from 'react';
import { Card, Row, Col, ColProps } from 'react-bootstrap';
import { CustomerRow } from 'shared/types/customer';
import * as CustomerFormFields from 'src/customer/CustomerFormFields';
import * as formUtils from 'src/utils/form';
import {FormikOnUpdateProvider, FormikOnUpdateCallback} from 'src/form/FormikOnUpdateContext';
import {Formik} from 'formik';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as CardHelpers from 'src/cards/CardHelpers';
import * as CustomerFormatters from 'src/customer/CustomerFormatters';
import {RefreshObjectButton} from 'src/buttons/IconButtons';
import * as api from 'src/api';
import {User} from 'lucide-react';
import useNotyf from 'src/hooks/useNotyf';

type Fields =
  | 'first_name'
  | 'last_name'
  | 'telephone_mobile'
  | 'email'
  | 'preferred_language'
  | 'address_city'
  | 'address_street'
  | 'address_postcode'
  | 'address_co'
;

type FormValues = Pick<CustomerRow, Fields>;

interface CustomerApplicationInfoFormCardProps {
  disabled?: boolean;
  customerId: string;
  title: string;
}

export default function CustomerApplicationInfoFormCard (props: CustomerApplicationInfoFormCardProps) {
  const { customerId, disabled, title } = props;

  const queryClient = useQueryClient();
  const notyf = useNotyf();

  const query = useQuery<CustomerRow>({
    queryKey: [`/customer/${customerId}`],
  });

  const updateMutation = useMutation<CustomerRow, Error, Partial<FormValues>>({
    mutationFn: async data => {
      if (!Object.keys(data).length) return customer;
      const updatedCustomer = await api.request({
        errorToNotyf: notyf,
        url: `/customer/${customer.id}`,
        method: 'PATCH',
        data,
      });
      queryClient.setQueryData([`/customer/${updatedCustomer.id}`], updatedCustomer);
    },
  });

  const onUpdate = async (update: Partial<FormValues>) => {
    return await updateMutation.mutateAsync(update);
  };

  const customer = query.data;

  return (
    <Card className="my-4">
      <Card.Header className="py-2 px-3">
        <CardHelpers.CardTitleIconStatus
          title={title}
          Icon={<User size={18} />}
          Status={(
            <CustomerFormatters.StatusLabel
              value={customer?.status}
              style={{fontSize: '80%'}}
            />
          )}
          spinning={query.isRefetching}
        >
          <RefreshObjectButton
            disabled={query.isRefetching}
            onClick={() => query.refetch()}
          />
        </CardHelpers.CardTitleIconStatus>
      </Card.Header>
      <CardHelpers.ErrorAlertCardBody error={query.error} />
      {query.isSuccess && customer && (
        <FormContainer customer={customer} onUpdate={onUpdate} disabled={disabled} />
      )}
    </Card>
  );
}

interface FormContainerProps {
  customer: CustomerRow;
  onUpdate: FormikOnUpdateCallback;
  disabled?: boolean;
}

function FormContainer (props: FormContainerProps) {
  const { customer, disabled, onUpdate } = props;

  const initialValues = customerToFormValues(customer);
  const colProps: ColProps = {sm: 6, md: 12, lg: 6};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formUtils.emptyFormikOnSubmit}
      enableReinitialize
      validateOnMount
    >
      {formProps => (
        <FormikOnUpdateProvider onUpdate={onUpdate}>
          <Card.Body className="py-0 pb-2 mb-3">
            <Row>
              <Col {...colProps}>
                <CustomerFormFields.FirstName disabled={disabled} />
              </Col>

              <Col {...colProps}>
                <CustomerFormFields.LastName disabled={disabled} />
              </Col>
            </Row>

            <Row>
              <Col {...colProps}>
                <CustomerFormFields.TelephoneMobile disabled={disabled} />
              </Col>
              <Col {...colProps}>
                <CustomerFormFields.Email disabled={disabled} />
              </Col>
            </Row>

            <Row>
              <Col {...colProps}>
                <CustomerFormFields.PreferredLanguage disabled={disabled} />
              </Col>
            </Row>

            <h3 className="mb-0 mt-4">Adressuppgifter</h3>
            <hr className="mb-0 mt-1" />

            <Row>
              <Col {...colProps}>
                <CustomerFormFields.AddressStreet disabled={disabled} />
              </Col>
              <Col {...colProps}>
                <CustomerFormFields.AddressPostcode disabled={disabled} />
              </Col>
            </Row>

            <Row>
              <Col {...colProps}>
                <CustomerFormFields.AddressCo disabled={disabled} />
              </Col>
              <Col {...colProps}>
                <CustomerFormFields.AddressCity disabled={disabled} />
              </Col>
            </Row>

          </Card.Body>
        </FormikOnUpdateProvider>
      )}
    </Formik>
  );
}

function customerToFormValues (customer: CustomerRow): FormValues {
  const {
    first_name,
    last_name,
    telephone_mobile,
    email,
    preferred_language,
    address_city,
    address_street,
    address_postcode,
    address_co,
  } = customer;
  return {
    first_name: first_name || '',
    last_name: last_name || '',
    telephone_mobile: telephone_mobile || '',
    email: email || '',
    preferred_language: preferred_language || 'sv',
    address_city: address_city || '',
    address_street: address_street || '',
    address_postcode: address_postcode || '',
    address_co: address_co || '',
  };
}
