import React from 'react';
import ErrorAlert from 'src/alerts/ErrorAlert';
import * as CustomerConsentFormatters from 'src/customerConsent/CustomerConsentFormatters';
import BlockSpinner from 'src/spinners/BlockSpinner';
import {useQuery} from '@tanstack/react-query';
import { CustomerConsentRowWithRelations } from 'shared/types/customer_consent';

interface CustomerConsentModalBodyProps {
  id: string;
}

export default function CustomerConsentModalBody (props: CustomerConsentModalBodyProps) {
  const { id } = props;

  const query = useQuery<CustomerConsentRowWithRelations, Error>({
    queryKey: [`/customer_consent/${id}`],
  });

  return (
    <div className="m-2 my-3">
      <ErrorAlert error={query.error} />
      <BlockSpinner className="m-3" isLoading={query.isLoading || query.isRefetching} />
      {query.data && (
        <CustomerConsentFormatters.ConsentDump consent={query.data} />
      )}
    </div>
  );
}
