import React from 'react';
import { Card, Table } from 'react-bootstrap';

interface VariableTableProps {
  className?: string;
  templateType?: string;
  templateCategory?: string;
  maxHeight?: number;
}

export function VariableTable (props: VariableTableProps) {
  const { className, templateType:type, templateCategory:category, maxHeight } = props;
  return (
    <Card className={className} style={{maxHeight, overflowY: 'auto'}}>
      {type === 'sms' && <Sms />}
      {type === 'email' && <Email />}
      {type === 'letter' && <Letter />}
      {category === 'application' && <Application />}
      {category === 'complaint' && <Complaint />}
      {category === 'prospect' && <Prospect />}
      {category === 'customer_cloud_insurance' && <Insurance />}
      {category === 'customer_jaycom_insurance' && <Insurance />}
    </Card>
  );
}

interface GroupProps {
  desc: string;
}

function Group (props: GroupProps) {
  const { desc } = props;
  return (
    <tr>
      <th colSpan={2}>{desc}</th>
    </tr>
  );
}

interface VarProps {
  name: string;
  desc: string;
}

function VarRow (props: VarProps) {
  const { name, desc } = props;
  return (
    <tr className="small">
      <td>{`{{ ${name} }}`}</td>
      <td>{desc}</td>
    </tr>
  );
}

export function Application () {
  return (
    <Table size="sm">
      <tbody>
        <Group desc="Variabler för mall som tillhör ansökan" />
        <VarRow name="customer" desc="Kundobjektet" />
        <VarRow name="application" desc="Ansökningsobjektet" />
        <VarRow name="application_id_md5" desc="Ansökans ID hashat med MD5" />
        <VarRow name="offers" desc="Lista över bud" />
        <Group desc='Lista "offers"' />
        <VarRow name="unknown" desc="Om info om låneerbjudandet är okänt" />
        <VarRow name="repayment_time" desc="Återbetalningstid" />
        <VarRow name="principal" desc="Lånestorlek" />
        <VarRow name="interest_rate" desc="Nominell ränta" />
        <VarRow name="invoice_fee" desc="Aviavgift" />
        <VarRow name="interest_rate_effective" desc="Effektiv ränta" />
        <VarRow name="initial_fee" desc="Uppläggningsavgift" />
        <VarRow name="monthly_cost" desc="Månadskostnad" />
        <VarRow name="amortization_type" desc="Amorteringstyp" />
        <VarRow name="total_cost" desc="Total kostnad" />
        <VarRow name="verifications" desc="Kompletteringar" />
        <VarRow name="bank_id" desc="Långivar-ID" />
        <VarRow name="bank_name" desc="Långivarnamn" />
        <VarRow name="bank_logo_file_name" desc="Långivarens loggos filnamn" />
        <VarRow name="representative_example" desc="Representativt exempel" />
        <VarRow name="warning_high_interest_rate" desc="Om långivaren ska ha en varningstriangel för höga räntor" />
        <Group desc="Variabler när ansökan har en accepterad process" />
        <VarRow name="bank_review_accept_followup" desc='Text från banktexter "Instruktioner efter accept" för accepterad långivare' />
        <VarRow name="accepted_bank_i" desc="ID accepterad långivare" />
        <VarRow name="accepted_bank_nam" desc="Namn accepterad långivare" />
        <VarRow name="accepted_process_signing_link" desc="Signeringslänk accepterat bud (om finns)" />
        <Group desc="Variabler för tillhörande rådgivare" />
        <VarRow name="has_advisor" desc="Om det finns en rådgivare på ansökan" />
        <VarRow name="advisor_name" desc="Namnet på rådgivaren kopplad till ansökan" />
        <VarRow name="advisor_email" desc="E-post till rådgivaren kopplad till ansökan" />
        <VarRow name="advisor_telephone_number" desc="Telefonnummer till rådgivaren kopplad till ansökan" />
        <Group desc="Variabler för försäkringsofferter" />
        <VarRow name="cloudinsurance_latest_url" desc="URL för att signera" />
        <Group desc="Variabler för senaste återkomst" />
        <VarRow name="has_task" desc="Finns det en oavklarad återkomst på tillhörande ansökan?" />
        <VarRow name="task_time" desc="Tid för återkomst" />
      </tbody>
    </Table>
  );
}

export function Sms () {
  return (
    <Table size="sm">
      <tbody>
        <VarRow name="gsm" desc="Mobiltelefonnummer" />
      </tbody>
    </Table>
  );
}

export function Email () {
  return (
    <Table size="sm">
      <tbody>
        <Group desc="E-postadresser" />
        <VarRow name="to.name" desc="Mottagande namn" />
        <VarRow name="to.address" desc="Mottagande adress" />
        <VarRow name="from.name" desc="Skickande namn" />
        <VarRow name="from.address" desc="Skickande adress" />
      </tbody>
    </Table>
  );
}

export function Insurance () {
  return (
    <Table size="sm">
      <tbody>
        <Group desc="Försäkring" />
        <VarRow name="insurance" desc="Försäkringsobjektet" />
        <VarRow name="creator" desc="Användarobjekt: försäkringens skapare" />
      </tbody>
    </Table>
  );
}

export function Letter () {
  return (
    <Table size="sm">
      <tbody>
        <Group desc="Kund" />
        <VarRow name="customer" desc="Kundobjektet" />
        <VarRow name="first_name" desc="Förnamn" />
        <VarRow name="last_name" desc="Efternamn" />
        <Group desc="Ansökning" />
        <VarRow name="street" desc="Gata" />
        <VarRow name="co" desc="C/O" />
        <VarRow name="postcode" desc="Postnummer" />
        <VarRow name="city" desc="Stad" />
        <Group desc="Övrigt" />
        <VarRow name="body" desc='Fältet "Brödtext"' />
      </tbody>
    </Table>
  );
}

export function Complaint () {
  return (
    <Table size="sm">
      <tbody>
        <Group desc="Variabler för mall som tillhör klagomålet" />
        <VarRow name="complaint" desc="Klagomålsobjektet" />
      </tbody>
    </Table>
  );
}

export function Prospect () {
  return (
    <Table size="sm">
      <tbody>
        <Group desc="Variabler för mall som tillhör prospekten" />
        <VarRow name="prospect" desc="Prospektobjektet" />
      </tbody>
    </Table>
  );
}
