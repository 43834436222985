import React, {useState} from 'react';
import {useMutation, useQuery} from '@tanstack/react-query';
import ErrorAlert from 'src/alerts/ErrorAlert';
import { Card, Form } from 'react-bootstrap';
import useModalStateContext from 'src/hooks/useModalStateContext';
import {
  CustomerConsentRow,
  CustomerConsentMethodPartBankIdAuth,
} from 'shared/types/customer_consent';
import * as api from 'src/api';
import ButtonSpinner from 'src/spinners/ButtonSpinner';
import {CustomerRow} from 'shared/types/customer';
import BlockSpinner from 'src/spinners/BlockSpinner';
import useNotyf from 'src/hooks/useNotyf';
import { maybeAxiosErrorToErrorMap } from 'src/utils/error';
import { AxiosError } from 'axios';
import {Formik, FormikErrors, FormikHelpers} from 'formik';
import * as CustomerConsentFormFields from 'src/customerConsent/CustomerConsentFormFields';
import useAuth from 'src/hooks/useAuth';
import CustomerConsentCreateBankIdMethodPart from 'src/customerConsent/CustomerConsentBankIdMethodPart';

interface CustomerConsentCreateModalBodyProps {
  customerId: string;
  method: 'customer_bankid_auth' | 'customer_bankid_sign';
}

interface FormValues {
  subject: string;
}

interface CreateConsentBody {
  customer_id: string;
  subject: string;
  method: 'customer_bankid_auth' | 'customer_bankid_sign';
  method_parts: {
    customer_bankid_auth: CustomerConsentMethodPartBankIdAuth;
  };
}

export default function CustomerConsentCreateModalBody (props: CustomerConsentCreateModalBodyProps) {
  const { customerId, method } = props;

  const notyf = useNotyf();
  const auth = useAuth();
  const modalStateContext = useModalStateContext();

  const [methodPart, setMethodPart] = useState<CustomerConsentMethodPartBankIdAuth | null>(null);

  const onConsentMethodPartGiven = (methodKey: string, methodPart: CustomerConsentMethodPartBankIdAuth) => {
    setMethodPart(methodPart);
  };

  const createMutation = useMutation<CustomerConsentRow, Error, CreateConsentBody>({
    mutationFn: formValues => api.request({
      url: '/customer_consent/template/customer',
      method: 'POST',
      data: formValues,
    }),
    onSuccess: () => {
      notyf.success({type: 'success', message: 'Samtycket registrerades på kunden'});
      modalStateContext.onHide();
    },
  });

  const onSubmitCreate = async (values: FormValues, helpers: FormikHelpers<FormValues>) => {
    try {
      await createMutation.mutateAsync({
        ...values,
        customer_id: customerId,
        method,
        method_parts: {
          customer_bankid_auth: methodPart,
        },
      });
    } catch (err) {
      helpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err as AxiosError);
      if (errorMap) {
        helpers.setErrors(errorMap as FormikErrors<FormValues>);
        return;
      }
      throw err;
    }
  };

  const query = useQuery<CustomerRow>({
    queryKey: [`/customer/${customerId}`],
  });

  const initialValues = {
    subject: '',
  };

  const customer = query.data;

  return (
    <div>
      <Card className="mb-0">
        <BlockSpinner className="m-3" isLoading={query.isLoading} />
        {customer && (
          <CustomerConsentCreateBankIdMethodPart
            userId={auth.userId}
            method="customer_bankid_auth"
            personalNumber={customer.ssn}
            onConsentMethodPartGiven={onConsentMethodPartGiven}
          />
        )}
      </Card>
      {methodPart && (
        <Formik initialValues={initialValues} onSubmit={onSubmitCreate} className="border-top">
          {formProps => (
            <Card>
              <Form onSubmit={formProps.handleSubmit}>
                <Card.Body className="pt-1 pb-2">
                  <CustomerConsentFormFields.Subject />
                </Card.Body>
                <Card.Footer className="border-top">
                  <ErrorAlert error={createMutation.error} />
                  <div className="d-flex gap-2 flex-wrap">
                    <ButtonSpinner
                      type="submit"
                      className="rounded"
                      variant="success"
                      isLoading={formProps.isSubmitting}
                      disabled={!formProps.isValid || formProps.isSubmitting || !formProps.dirty}
                    >
                      Registrera samtycke
                    </ButtonSpinner>
                  </div>
                </Card.Footer>
              </Form>
            </Card>
          )}
        </Formik>
      )}
    </div>
  );
}
