import React, {useMemo} from 'react';
import UserAnchor from 'src/user/UserAnchor';
import {Trash2} from 'lucide-react';
import { CustomerNoteRowWithRelations } from 'shared/types/customer_note';
import DateTime from 'src/formatters/DateTime';
import { Card } from 'react-bootstrap';
import {GuardPermission} from 'src/guards/AuthGuards';
import ConfirmActionModalExecuteButton from 'src/buttons/ConfirmActionModalExecuteButton';
import { nl2br } from 'shared/format';
import striptags from 'striptags';

interface CustomerNoteItemProps {
  className?: string;
  customerId: string;
  note: CustomerNoteRowWithRelations;
  onReload: () => void;
}

export default function CustomerNoteItem (props: CustomerNoteItemProps) {
  const { className, customerId, note, onReload } = props;

  const textAsHtml = useMemo(() => {
    return striptags(nl2br(note.text), {allowedTags: new Set(['br'])});
  }, [note.text]);

  return (
    <Card className={className}>
      <Card.Header className="p-2 d-flex justify-content-between flex-wrap bg-white">
        <div className="d-flex gap-2 align-items-center flex-wrap">
          <UserAnchor user={note.User ?? {id: note.created_by_user_id}} />
          <DateTime className="small" value={note.created_at} />
        </div>
        <GuardPermission permission="customer:notes:delete">
          <ConfirmActionModalExecuteButton
            url={`/customer/${customerId}/notes/${note.id}`}
            className="px-1"
            method="DELETE"
            onSuccess={onReload}
            onSuccessNotyf={{type: 'warning', message: 'Notatet raderades'}}
            size="sm"
            variant="outline-danger"
            message="Är du säker på att du vill ta bort notatet?"
          >
            <Trash2 size={14 }/>
          </ConfirmActionModalExecuteButton>
        </GuardPermission>
      </Card.Header>
      <Card.Body className="p-2" dangerouslySetInnerHTML={{__html: textAsHtml}} />
    </Card>
  );
}
